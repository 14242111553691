define('scientia-resourcebooker/router', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _scientiaResourcebookerConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('logged-in', { path: 'app' }, function () {
      this.route('user-profile');
      this.route('search-results');

      this.route('booking-type', { path: 'booking-types' }, function () {
        this.route('index', { path: '/' });
        this.route('show', { path: ':id' });
      });

      this.route('resource.show', { path: 'booking-types/:bookingTypeId/resources/:id' });

      this.route('booking-request', { path: 'booking-requests' }, function () {
        this.route('index', { path: '/' });
      });

      this.route('notifications');
    });

    this.route('manage-resources', { path: 'manage' }, function () {
      this.route('resource-type', { path: 'resource-types' }, function () {
        this.route('index', { path: '/' });
        this.route('new', { path: '/new' });
        this.route('importer', { path: 'importer' }, function () {
          this.route('index', { path: '/' });
          this.route('permissions');
          this.route('custom-fields');
        });
        this.route('show', { path: ':id' });
        this.route('edit', { path: ':id/edit' });
      });

      this.route('booking-type', { path: 'booking-types' }, function () {
        this.route('index', { path: '/' });
        this.route('new', { path: '/new' });
        this.route('edit', { path: ':id/edit' });
      });

      this.route('booking-form', { path: 'booking-forms' }, function () {
        this.route('index', { path: '/' });
        this.route('new', { path: '/new' });
        this.route('edit', { path: ':id/edit' });
      });

      this.route('user-group', { path: 'user-groups' }, function () {
        this.route('index', { path: '/' });
        this.route('new', { path: '/new' });
        this.route('importer', { path: '/importer' });
        this.route('edit', { path: ':id/edit' });
      });

      this.route('resource-group', { path: 'resource-types/:resourceTypeId/resource-groups' }, function () {
        this.route('new');
        this.route('edit', { path: ':id/edit' });
        this.route('resources', { path: ':id/resources' });
      });

      this.route('resource', { path: 'resource-types/:resourceTypeId/resources' }, function () {
        this.route('new');
        this.route('edit', { path: ':id/edit' });
        this.route('show', { path: ':id' });
      });

      this.route('reports', { path: 'reports' }, function () {
        this.route('index', { path: '/' });
      });

      this.route('system', { path: 'system' }, function () {
        this.route('index', { path: '/' });
      });
    });

    this.route('page-not-found', { path: '/*wildcard' });

    this.route('print', function () {
      this.route('booking', { path: '/booking/:id' });
    });
  });

  exports['default'] = Router;
});