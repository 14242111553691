define('scientia-resourcebooker/services/resource-booking-requests-fetcher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    nestedResourceStore: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var resourceId = _ref.resourceId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;
      var _ref$checkSplitPermissions = _ref.checkSplitPermissions;
      var checkSplitPermissions = _ref$checkSplitPermissions === undefined ? false : _ref$checkSplitPermissions;

      var data = {
        startDate: startDate.startOf('minute').toISOString(),
        endDate: endDate.startOf('minute').toISOString(),
        checkSplitPermissions: checkSplitPermissions
      };

      return this.get('nestedResourceStore').fetch({
        type: 'resource.bookingRequest',
        parentId: resourceId,
        data: data
      });
    }
  });
});