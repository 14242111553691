define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/select-choices-explanation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':bookingRequestForm-label'],

    maxLength: _ember['default'].computed.alias('field.maxLength'),

    currentLength: (function () {
      return this.get('field.explanation.length') || '0';
    }).property('field.explanation.length'),

    label: (function () {
      return 'components.booking_request_form.description_custom_fields.' + this.get('field').label;
    }).property('field'),

    choices: (function () {
      var _this = this;

      return this.get('field.choices').map(function (choice) {
        return {
          label: _this.get('i18n').t('components.booking_request_form.description_custom_fields.choices.' + choice)
        };
      });
    }).property('field')

  });
});