define('scientia-resourcebooker/services/calendar-translation', ['exports', 'ember'], function (exports, _ember) {
  // jshint camelcase:false
  //jscs:disable requireCamelCaseOrUpperCaseIdentifiers
  exports['default'] = _ember['default'].Service.extend({

    fetch: function fetch() {
      return {
        header: {
          tags: {
            resource: 'Resource',
            template: 'Template',
            booking: 'Booking',
            event: 'Event',
            general: 'General'
          }
        },
        dateFormats: {
          date: {
            short: 'D MMM',
            medium: 'D MMM YYYY',
            long: 'dddd D MMMM YYYY',
            short_with_weekday: 'ddd D MMM'
          },
          datetime: {
            short: 'D MMM HH:mm',
            medium: 'D MMM YYYY HH:mm',
            long: 'dddd D MMMM YYYY HH:mm'
          },
          time: {
            shortA: 'HH:mm',
            short: 'HH:mm',
            medium: 'HH:mm',
            long: 'HH:mm',
            meridiem: 'HH'
          },
          day: 'D MMMM YYYY',
          week: 'D MMMM YYYY',
          month: 'MMMM YYYY',
          year: 'YYYY'
        },

        agenda: {
          second: 'Second',
          minute: 'Minute',
          hour: 'Hour',
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
          day_ago: 'day ago',
          days_ago: 'days ago',
          event_started: 'This event started'
        },

        common: {
          agenda: 'Agenda',
          today: 'Today',
          day: 'Day',
          week: 'Week',
          month: 'Month',
          year: 'Year',
          previous: 'Previous',
          next: 'Next',
          uncategorized: 'Uncategorized',
          all_day: 'All day',
          all_day_until: 'All day until',
          all_day_from: 'All day from'
        },

        weekdays: {
          names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

          abbreviations: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        },

        time_periods: {
          AM: 'AM',
          PM: 'PM'
        },

        month_view: {
          more_events: 'more'
        },

        agenda_view: {
          no_events: 'No events for this month'
        }
      };
    }
  });
});