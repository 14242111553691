define('scientia-resourcebooker/pods/components/confirm-modal/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog', 'ember-i18n'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    classNames: ['confirmModal'],
    classNameBindings: ['location'],
    location: '',
    confirmTitle: '',
    confirmMessage: '',
    buttonIsDisabled: false,
    headerIconClass: '',
    singleButtonMode: false,

    confirmYesLabel: (0, _emberI18n.translationMacro)('general.ok'),
    confirmNoLabel: (0, _emberI18n.translationMacro)('general.cancel'),

    model: null,
    actions: {
      confirm: function confirm() {
        this.set('buttonIsDisabled', true);
        if (this.attrs.singleButtonAction && this.get('singleButtonMode')) {
          this.attrs.singleButtonAction(this.get('model'));
        } else {
          this.attrs.confirm(this.get('model'));
        }
      }
    }
  });
});