define('scientia-resourcebooker/pods/manage-resources/reports/index/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAppInsights['default'], {

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.system.page_title');
    },

    queryParams: {
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true }
    },

    reportsProcessor: _ember['default'].inject.service(),

    model: function model(params) {
      return this.get('reportsProcessor').fetch({ page: params.page, itemsPerPage: params.perPage });
    },

    afterModel: function afterModel(model) {

      // If there is no model wait another 5 seconds and try again
      if (!model) _ember['default'].run.later(this, 'send', 'refresh', 5000);

      if (model.results) {

        var statuses = model.results.getEach('isReady');
        var hasPending = statuses.includes(false);

        if (hasPending) _ember['default'].run.later(this, 'refresh', 5000);
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          page: 1,
          perPage: 10,
          generateFromDate: null,
          generateToDate: null
        });
      }
    },

    // This action gets called only when a new report has just been generated so that we keep updating until the status
    // of the report is "isReady"
    actions: {

      invalidateModel: function invalidateModel() {
        this.refresh();
      }

    }

  });
});