define('scientia-resourcebooker/serializers/booking-type', ['exports', 'ember-data', 'scientia-resourcebooker/serializers/application'], function (exports, _emberData, _scientiaResourcebookerSerializersApplication) {

  var EDIT_PERMISSION_GROUPS = ['all', 'status', 'availability', 'resourceRestrictions', 'permissions', 'rules'];

  exports['default'] = _scientiaResourcebookerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      resourceRestrictionRules: { embedded: 'always' },
      bookingForm: { embedded: 'always' }
    },

    normalize: function normalize(type, payload) {
      var applicationSerializer = this.store.serializerFor('application');

      payload = this.store.serializerFor('application')._camelizeKeysDeep(payload);
      payload = applicationSerializer._setPermissionGroupDefaults(payload);
      payload = applicationSerializer._normalizeNestedPermissionGroups(payload, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');

      // Default template mode in case template has not been set one yet (for backwards compat)
      if (!payload.Mode && !payload.mode) payload.Mode = '0';

      // Default Notification types set to "Banner"
      if (payload.infoType !== 0 && payload.infoType !== 1) payload.infoType = 0;
      if (payload.alertType !== 0 && payload.alertType !== 1) payload.alertType = 0;
      return this._super(type, payload);
    },

    serialize: function serialize() /*snapshot*/{
      var serialized = this._super.apply(this, arguments);
      var applicationSerializer = this.store.serializerFor('application');
      serialized = applicationSerializer._serializeNestedPermissionGroups(serialized, EDIT_PERMISSION_GROUPS, 'editPermissionsGroups', 'edit%@PermissionGroups');
      return serialized;
    }

  });
});