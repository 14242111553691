define('scientia-resourcebooker/pods/logged-in/resource-group/show/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    controllerName: 'logged-in.booking-type.show',
    titleToken: function titleToken(model) {
      return model.resourceGroup.get('name') + ' — ' + this.get('i18n').t('pods.logged_in.booking_type.show.bookings');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    resourceFilterer: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),

    queryParams: {
      query: { replace: true, refreshModel: true },
      spanMultipleDays: { replace: true },
      availableNow: { replace: true, refreshModel: true },
      firstDay: { replace: true, refreshModel: true },
      lastDay: { replace: true, refreshModel: true },
      duration: { replace: true, refreshModel: true },
      fromTime: { replace: true, refreshModel: true },
      toTime: { replace: true, refreshModel: true },
      page: { replace: true, refreshModel: true }
    },

    customFilters: null,
    lastParams: {},
    lastTransition: {},

    model: function model(params) {
      this.transitionTo('logged-in.booking-type.show', params.bookingTypeId, { queryParams: { group: params.id } });
      return false;

      // let controller = this.controllerFor('logged-in.booking-type.show');
      // let filters = this.getResourceFilters(transition.queryParams);
      // let resourcesPerPage = this.get('systemSettings.options.resourcesListInitialLoadSize');
      // if (filters && filters.filters) {
      //   filters.filters = this._deserializeFilters(filters.filters);
      //   resourcesPerPage = this.get('systemSettings.options.resourcesListAdditionalLoadSize');
      // }

      // // Backup params and transition
      // this.set('lastParams', params);
      // this.set('lastTransition', transition);

      // // Reset No more results button
      // controller.set('hasNoMoreResults', false);

      // filters.resourceGroupIds = [params.id];

      // let recurrenceEnabled = controller.get('bookingMode') === 2;
      // let recurrence = recurrenceEnabled ? controller.get('recurrence') : null;
      // recurrence = recurrence ? this.get('recurrenceProcessor').generate(recurrence) : null;

      // let bookingType = this.store.peekRecord('booking-type', params.bookingTypeId);

      // return Ember.RSVP.hash({
      //   bookingType: bookingType ? bookingType.reload() : this.store.find('booking-type', params.id),
      //   filteredResources: this.get('resourceFilterer').filterBy({bookingTypeId: params.id, type: 'resource', filters, recurrence, pageNumber: params.page, itemsPerPage: resourcesPerPage}),
      // });

      // return Ember.RSVP.hash({
      //   bookingType: bookingType ? bookingType.reload() : this.store.find('booking-type', params.bookingTypeId),
      //   resourceGroup: this.get('nestedResourceStore').fetch({
      //     type: 'bookingType.resourceGroup',
      //     parentId: params.bookingTypeId,
      //     id: params.id
      //   }),
      //   filteredResources: this.get('resourceFilterer').filterBy({
      //     bookingTypeId: params.bookingTypeId,
      //     type: 'resource',
      //     filters,
      //     recurrence,
      //     itemsPerPage: params.itemsPerPage || 10,
      //     pageNumber: params.page
      //   })
      // });
    },

    afterModel: function afterModel(model) {

      var meta = model.filteredResources;

      model.resourcesMeta = {
        currentPage: meta.currentPage,
        totalPages: meta.totalPages
      };

      model.resourceGroup.set('bookingType', model.bookingType);

      var resourceTypeId = model.bookingType.get('resourceRestrictionRules').filterBy('isPrimary').get('firstObject.resourceType.id');

      if (resourceTypeId) {
        // return this.store.getById('resource-type', resourceTypeId).reload().then((resourceType) => {
        return this.get('nestedResourceStore').fetch({ type: 'bookingType.resourceType', parentId: model.bookingType.get('id'), isSingleResource: true }).then(function (resourceType) {
          model.customFilters = resourceType.get('properties');
          model.customFilters = model.customFilters.filter(function (property) {
            return property.isFilterable;
          });
        });
      }
    },

    loadMore: function loadMore() {
      var itemsPerPage = this.get('systemSettings.options.resourcesListAdditionalLoadSize');

      var params = this.get('lastParams');
      var transition = this.get('lastTransition');
      var controller = this.controllerFor('logged-in.booking-type.show');
      var filters = this.getResourceFilters(transition.queryParams);

      if (filters && filters.filters) {
        filters.filters = this._deserializeFilters(filters.filters);
      }

      var loadedIds = controller.get('model.filteredResources.results').getEach('id');
      controller.set('isLoadingMore', true);

      var recurrenceEnabled = controller.get('bookingMode') === 2;
      var recurrence = recurrenceEnabled ? controller.get('recurrence') : null;
      recurrence = recurrence ? this.get('recurrenceProcessor').generate(recurrence) : null;

      this.get('resourceFilterer').filterBy({
        bookingTypeId: params.id,
        type: 'resource',
        filters: filters,
        loadedIds: loadedIds,
        itemsPerPage: itemsPerPage,
        recurrence: recurrence
      }).then(function (results) {
        controller.get('model.filteredResources.results').pushObjects(results.results);
        controller.set('hasNoMoreResults', results.results.length === 0);
      })['finally'](function () {
        controller.set('isLoadingMore', false);
      });
    },

    getResourceFilters: function getResourceFilters(transition) {
      var properties = _ember['default'].getProperties(transition, ['query', 'filters', 'availableNow', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'page']);

      return this.hasOneFilterAttribute(properties) ? properties : {};
    },

    hasOneFilterAttribute: function hasOneFilterAttribute(_ref) {
      var availableNow = _ref.availableNow;
      var firstDay = _ref.firstDay;
      var lastDay = _ref.lastDay;
      var fromTime = _ref.fromTime;
      var toTime = _ref.toTime;
      var duration = _ref.duration;
      var query = _ref.query;
      var filters = _ref.filters;

      return this.hasOneTimeFilterAttribute({ availableNow: availableNow, firstDay: firstDay, lastDay: lastDay, fromTime: fromTime, toTime: toTime, duration: duration }) || this.hasOneSearchFilterAttribute({ query: query, filters: filters });
    },

    hasOneTimeFilterAttribute: function hasOneTimeFilterAttribute(_ref2) {
      var availableNow = _ref2.availableNow;
      var firstDay = _ref2.firstDay;
      var lastDay = _ref2.lastDay;
      var fromTime = _ref2.fromTime;
      var toTime = _ref2.toTime;
      var duration = _ref2.duration;

      return !!(availableNow || firstDay || lastDay || fromTime || toTime || duration);
    },

    hasOneSearchFilterAttribute: function hasOneSearchFilterAttribute(_ref3) {
      var query = _ref3.query;
      var filters = _ref3.filters;

      return !!query || !!filters;
    },

    hasOneMetaFilterAttribute: function hasOneMetaFilterAttribute(_ref4) {
      var page = _ref4.page;

      return !!page;
    },

    _deserializeFilters: function _deserializeFilters(filters) {
      return JSON.parse(decodeURIComponent(filters));
    },

    actions: {

      loadMoreResources: function loadMoreResources() {
        this.loadMore();
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      willTransition: function willTransition(transition) {
        // If we're navigating to a route other than the 3 below we need to clear the resource filters
        var names = ['logged-in.resource.show.index', 'logged-in.booking-type.show'];
        if (!names.includes(transition.targetName)) {
          this.get('customEvents').triggerClearResourceFilters();

          this.setProperties({
            customFilters: null,
            lastParams: null,
            lastTransition: null
          });
        }

        if (transition.targetName.indexOf('logged-in') !== 0) {
          this.store.unloadAll();
        }
      }

    }
  });
});