define('scientia-resourcebooker/services/resource-events-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var bookingTypeId = _ref.bookingTypeId;
      var resourceTypeId = _ref.resourceTypeId;
      var resourceId = _ref.resourceId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;

      // Default endpoint for a resource BusyTimes
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS.resource.busyTime.loc(bookingTypeId, resourceId);

      // If there is a resourceTypeId instead of bookingTypeId it means this is to be viewed in the
      // resource calendar so we use a different endpoint
      if (resourceTypeId) {
        url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_ADMIN_BUSY_TIMES.loc(resourceTypeId, resourceId);
      }

      var data = {
        startDate: startDate.startOf('minute').toISOString(),
        endDate: endDate.startOf('minute').toISOString()
      };

      return this.get('authenticatedRequest').fetch(url, data);
    }
  });
});