define('scientia-resourcebooker/services/permissions-validator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    validate: function validate(permissions) {
      var _this = this;

      var isValid = true;
      var lastFailedIndex = null;
      permissions.forEach(function (permission, index) {
        if (permission.get('permissionType') === 'request') {
          if (!_this.validateRequestPermissionValue(permission.get('permissionValue'))) {
            isValid = false;
            lastFailedIndex = index;
          }
        } else {
          if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionValue'))) {
            isValid = false;
            lastFailedIndex = index;
          }
        }
      });

      return { isValid: isValid, index: lastFailedIndex };
    },

    /**
     * Validates a request permission by checking if `permissionTarget` is empty or if `approvers` is empty.
     * Initially thinking about validating each named object but seems overkill as there would be no way
     * to get into a state where a usergroup picked would not have an `id` or `name`
     * @param {*} permission Request Permission
     */
    validateRequestPermissionValue: function validateRequestPermissionValue(permission) {
      if (_ember['default'].isEmpty(permission)) return false;
      if (_ember['default'].isEmpty(_ember['default'].get(permission, 'approvers'))) return false;
      if (_ember['default'].isEmpty(_ember['default'].get(permission, 'permissionTarget'))) return false;
      return true;
    }

  });
});