define('scientia-resourcebooker/mixins/availability-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    availabilitySlotPersister: _ember['default'].inject.service(),

    showAddUnavailabilityDateSlot: null,
    showAddUnavailabilityWeekdaySlot: null,
    showRemoveUnavailabilityDateSlot: null,
    showRemoveUnavailabilityWeekdaySlot: null,

    specificStartDate: null,
    specificEndDate: null,

    availabilityFromTime: null,
    availabilityToTime: null,
    weekdayHour: null,
    weekdayIndex: null,

    startTimeInMinutes: null,
    endTimeInMinutes: null,

    dayOffset: null,
    dstDifference: null,

    actions: {
      showAddUnavailabilityDateSlot: function showAddUnavailabilityDateSlot(moment, userGroup) {
        this.setProperties({
          specificStartDate: moment,
          specificEndDate: moment.clone().add(1, 'hour'),
          userGroup: userGroup,
          showAddUnavailabilityDateSlot: true
        });
      },

      showAddUnavailabilityWeekdaySlot: function showAddUnavailabilityWeekdaySlot(index, hour, userGroup) {
        this.setProperties({
          weekdayIndex: index,
          startTimeInMinutes: hour * 60,
          endTimeInMinutes: (hour + 1) * 60,
          userGroup: userGroup,
          showAddUnavailabilityWeekdaySlot: index
        });
      },

      showRemoveUnavailabilityDateSlot: function showRemoveUnavailabilityDateSlot(fromTime, toTime, userGroup) {
        this.setProperties({
          availabilityFromTime: fromTime,
          availabilityToTime: toTime,
          userGroup: userGroup,
          showRemoveUnavailabilityDateSlot: fromTime
        });
      },

      showRemoveUnavailabilityWeekdaySlot: function showRemoveUnavailabilityWeekdaySlot(weekdayIndex, fromTime, toTime, userGroup, dayOffset, dstDifference) {
        this.setProperties({
          weekdayIndex: weekdayIndex,
          weekdayFromTime: fromTime,
          weekdayToTime: toTime,
          userGroup: userGroup,
          dayOffset: dayOffset,
          dstDifference: dstDifference,
          showRemoveUnavailabilityWeekdaySlot: fromTime
        });
      },

      saveUnavailabilityDateSlot: function saveUnavailabilityDateSlot(_ref) {
        var _this = this;

        var specificStartDate = _ref.specificStartDate;
        var specificEndDate = _ref.specificEndDate;
        var userGroup = _ref.userGroup;

        var type = this.get('type');
        var item = this.get('model')[type];
        var itemParent = this.get('typeParentId') ? item.get(this.get('typeParentId')) : null;

        this.get('availabilitySlotPersister').persistDate({
          item: item,
          itemParent: itemParent,
          specificStartDate: specificStartDate,
          specificEndDate: specificEndDate,
          userGroup: userGroup,
          type: type
        }).then(function () {
          _this.setProperties({
            specificStartDate: null,
            specificEndDate: null,
            userGroup: null,
            showAddUnavailabilityDateSlot: null
          });

          _this.send('reload', {
            specificDate: true,
            startDate: specificStartDate.clone().subtract(1, 'week'),
            endDate: specificEndDate.clone().add(1, 'week'),
            userGroup: userGroup
          });
        });
      },

      saveUnavailabilityWeekdaySlot: function saveUnavailabilityWeekdaySlot(_ref2) {
        var _this2 = this;

        var weekdayIndex = _ref2.weekdayIndex;
        var specificDate = _ref2.specificDate;
        var duration = _ref2.duration;
        var userGroup = _ref2.userGroup;

        var type = this.get('type');
        var item = this.get('model')[type];
        var itemParent = this.get('typeParentId') ? item.get(this.get('typeParentId')) : null;

        this.get('availabilitySlotPersister').persistWeekday({
          item: item,
          itemParent: itemParent,
          weekdayIndex: weekdayIndex,
          specificDate: specificDate,
          duration: duration,
          userGroup: userGroup,
          type: type
        }).then(function () {
          _this2.setProperties({
            weekdayIndex: null,
            weekdayHour: null,
            showAddUnavailabilityWeekdaySlot: null
          });

          _this2.send('reload', { userGroup: userGroup });
        });
      },

      deleteUnavailabilityDateSlot: function deleteUnavailabilityDateSlot(_ref3) {
        var _this3 = this;

        var specificDate = _ref3.specificDate;
        var duration = _ref3.duration;
        var userGroup = _ref3.userGroup;

        var type = this.get('type');
        var item = this.get('model')[type];
        var itemParent = this.get('typeParentId') ? item.get(this.get('typeParentId')) : null;

        this.get('availabilitySlotPersister').deleteDate({
          item: item,
          itemParent: itemParent,
          specificDate: specificDate,
          duration: duration,
          userGroup: userGroup,
          type: type
        }).then(function () {
          _this3.setProperties({
            availabilityFromTime: null,
            availabilityToTime: null,
            userGroup: null,
            showRemoveUnavailabilityDateSlot: null
          });

          _this3.send('reload', {
            startDate: _this3.get('startDate'),
            endDate: _this3.get('endDate'),
            specificDate: specificDate,
            userGroup: userGroup
          });
        });
      },

      deleteUnavailabilityWeekdaySlot: function deleteUnavailabilityWeekdaySlot(_ref4) {
        var _this4 = this;

        var weekdayIndex = _ref4.weekdayIndex;
        var specificDate = _ref4.specificDate;
        var duration = _ref4.duration;
        var userGroup = _ref4.userGroup;
        var dayOffset = _ref4.dayOffset;
        var dstDifference = _ref4.dstDifference;

        var type = this.get('type');
        var item = this.get('model')[type];
        var itemParent = this.get('typeParentId') ? item.get(this.get('typeParentId')) : null;

        this.get('availabilitySlotPersister').deleteWeekday({
          item: item,
          itemParent: itemParent,
          weekdayIndex: weekdayIndex,
          specificDate: specificDate,
          duration: duration,
          userGroup: userGroup,
          type: type,
          dayOffset: dayOffset,
          dstDifference: dstDifference
        }).then(function () {
          _this4.setProperties({
            weekdayIndex: null,
            weekdayFromTime: null,
            weekdayToTime: null,
            showRemoveUnavailabilityWeekdaySlot: null
          });

          _this4.send('reload', { userGroup: userGroup });
        });
      }
    }
  });
});