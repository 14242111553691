define("scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/text/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/text/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" (");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("/");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(")");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 2, 2);
        morphs[2] = dom.createMorphAt(element0, 4, 4);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", [["subexpr", "to-s", [["get", "label", ["loc", [null, [1, 89], [1, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 83], [1, 95]]], 0, 0]], [], ["loc", [null, [1, 79], [1, 97]]], 0, 0], ["content", "currentLength", ["loc", [null, [1, 99], [1, 116]]], 0, 0, 0, 0], ["content", "maxLength", ["loc", [null, [1, 117], [1, 130]]], 0, 0, 0, 0], ["inline", "textarea", [], ["maxlength", ["subexpr", "@mut", [["get", "maxLength", ["loc", [null, [3, 21], [3, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input bookingRequestForm-textrea", "value", ["subexpr", "@mut", [["get", "field.value", ["loc", [null, [3, 83], [3, 94]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 0], [3, 96]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});