define('scientia-resourcebooker/pods/components/manage-resources/custom-field-item/component', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;

  var BOOLEAN = 'yesno';
  var INTEGER = 'number';
  var STRING = 'text';
  var LIST = 'list';
  var DISCRETE = 'discrete';
  var SINGLESELECTION = 'singleselection';
  var MULTIPLESELECTIONS = 'multipleselections';

  var COMPONENT_PATH = 'manage-resources/custom-field-item/value-types/%@';

  var TYPES = [BOOLEAN, INTEGER, STRING, SINGLESELECTION, MULTIPLESELECTIONS];

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-form-groupWrapper'],
    isEditing: false,
    errors: [],
    displayReadOnly: false,
    userGroupSearcher: _ember['default'].inject.service(),

    isListValueType: (function () {
      var type = this.get('model.valueType');
      return type === LIST || type === DISCRETE || type === SINGLESELECTION || type === MULTIPLESELECTIONS;
    }).property('model.valueType'),

    resetValueChoicesObserver: (function () {
      if (!this.get('isListValueType')) this.get('model.valueChoicesWithId').clear();
    }).observes('isListValueType'),

    possibleValueTypes: (function () {
      var _this = this;

      return TYPES.map(function (type) {
        return { id: type, value: type, label: _this.get('i18n').t('components.manage_resources.custom_field_item.value_types.' + type) };
      });
    }).property(),

    valueTypeExampleComponent: (function () {
      var componentPath = COMPONENT_PATH.loc(this.get('model.valueType'));
      if (getOwner(this).lookup('component:' + componentPath)) return componentPath;
    }).property('model.valueType'),

    isValid: (function () {
      var errors = this._generateErrors({ name: this.get('model.name') });

      if (this.get('isListValueType') && !this.get('model.valueChoicesWithId.length')) return false;
      return !errors.length;
    }).property('model.name', 'model.valueChoicesWithId.length', 'model.valueType'),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('focusFirstInput');
      });
    }).on('init'),

    _generateErrors: function _generateErrors(_ref) {
      var name = _ref.name;

      this.get('errors').clear();
      if (!name) this.get('errors').pushObject(name);

      return this.get('errors');
    },

    _toggleEditing: function _toggleEditing() {
      this.toggleProperty('model.isEditing');
    },

    actions: {
      toggleEditing: function toggleEditing() {
        if (this.get('isValid')) {
          this._toggleEditing();
        } else {
          this.send('focusFirstInput');
        }
      },

      focusFirstInput: function focusFirstInput() {
        var _this2 = this;

        _ember['default'].run.next(function () {
          if (!_this2.get('isDestroyed')) {
            _this2.$().find('.manageResources-itemForm-form-input').first().focus();
          }
        });
      },

      focusLastChoicesInput: function focusLastChoicesInput() {
        var _this3 = this;

        _ember['default'].run.next(function () {
          if (!_this3.get('isDestroyed')) {
            _this3.$().find('.manageResources-customFieldItem-valueChoices-input').last().focus();
          }
        });
      },

      deleteProperty: function deleteProperty() {
        this.sendAction('deleteProperty', this.get('model.displayId'));
      },

      addChoice: function addChoice() {
        var valueChoices = this.get('model.valueChoicesWithId');
        if (!valueChoices) valueChoices = [];
        valueChoices.pushObject({ Identity: null, Name: '' });
        this.send('focusLastChoicesInput');
      },

      removeChoice: function removeChoice(choice) {
        this.get('model.valueChoicesWithId').removeObject(choice);
        this.send('focusLastChoicesInput');
      },

      searchUserGroups: function searchUserGroups(query, deferred) {
        _ember['default'].run.debounce(this, '_searchUserGroups', query, deferred, 1000);
      },

      didSelectPermissionGroups: function didSelectPermissionGroups(groups) {
        var userGroups = groups.map(function (group) {
          return { id: group.id, name: group.name };
        });

        this.set('model.restrictedVisibilityPermissionGroups', userGroups);
      }
    },

    _searchUserGroups: function _searchUserGroups(query, deferred) {
      query.systemGroupsOnly = true;
      this.get('userGroupSearcher').search(query).then(function (data) {
        deferred.resolve(data);
      });
    }
  });
});