define("scientia-resourcebooker/pods/components/rb-calendar-day-view/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-calendar-day-view/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "moment-format", [["get", "displayed-date", ["loc", [null, [2, 18], [2, 32]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["components.rb_calendar_day_view.date_format"], [], ["loc", [null, [2, 39], [2, 88]]], 0, 0]], [], ["loc", [null, [2, 33], [2, 89]]], 0, 0]], [], ["loc", [null, [2, 2], [2, 91]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-calendar-day-view/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "rb-calendar", [], ["class", "at-rbCalendarDayView", "calendarEvents", ["subexpr", "@mut", [["get", "calendar-events", ["loc", [null, [8, 19], [8, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "displayed-date", ["subexpr", "@mut", [["get", "displayed-date", ["loc", [null, [9, 19], [9, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "default-view-type", "day", "show-week-button", false, "show-month-button", false, "show-day-button", false, "show-previous-arrow", false, "show-next-arrow", false, "show-today-button", false, "event-id-path", "id", "event-title-path", ["subexpr", "@mut", [["get", "titlePath", ["loc", [null, [19, 21], [19, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "event-subtitle-path", ["subexpr", "@mut", [["get", "subtitlePath", ["loc", [null, [20, 24], [20, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "event-from-time-path", ["subexpr", "@mut", [["get", "fromTimePath", ["loc", [null, [21, 25], [21, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "event-to-time-path", ["subexpr", "@mut", [["get", "toTimePath", ["loc", [null, [22, 23], [22, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "event-tag-path", ["subexpr", "@mut", [["get", "tagPath", ["loc", [null, [23, 19], [23, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "on-date-click", "onDateClick", "on-event-click", "onEventClick", "on-event-count-badge-click", "onEventCountBadgeClick"], ["loc", [null, [6, 2], [28, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/rb-calendar-day-view/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [1, 32], [1, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 24], [1, 38]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [3, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [5, 0], [29, 18]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});