define('scientia-resourcebooker/pods/components/modal-header/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'header',
    classNames: ['lf-dialog-header'],
    classNameBindings: ['class', 'needsCloseButton'],

    title: '',
    needsCloseButton: true,

    actions: {
      close: function close() {
        this.sendAction('onClose');
      }
    }
  });
});