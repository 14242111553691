define("scientia-resourcebooker/pods/components/booking-request-form/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 3,
                "column": 107
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "class", "bookingRequestForm-titleIcon");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element17 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element17, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "mainResource.imageUri", ["loc", [null, [3, 43], [3, 64]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestForm-titleIconWrapper");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element18, 1, 1);
          morphs[1] = dom.createMorphAt(element18, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "mainResource.imageUri", ["loc", [null, [3, 8], [3, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [3, 114]]]], ["content", "mainResource.name", ["loc", [null, [3, 115], [3, 136]]], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-errors");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "formError", ["loc", [null, [10, 6], [10, 21]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 4
                },
                "end": {
                  "line": 29,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--reference");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element13, [3]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_overview.reference"], [], ["loc", [null, [25, 85], [25, 138]]], 0, 0], ["content", "model.reference", ["loc", [null, [27, 12], [27, 31]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--status");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-mainResource");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [2]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element14, [3]), 0, 0);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.reference", ["loc", [null, [23, 10], [23, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 4], [29, 11]]]], ["inline", "t", ["pods.logged_in.resource.show.status"], [], ["loc", [null, [31, 85], [31, 128]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.booking_request_overview.statuses.", ["get", "model.status", ["loc", [null, [33, 120], [33, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 56], [33, 133]]], 0, 0]], [], ["loc", [null, [33, 52], [33, 135]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 2
              },
              "end": {
                "line": 49,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "recurring-tabs", [], ["selected", ["subexpr", "@mut", [["get", "bookingMode", ["loc", [null, [48, 30], [48, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingMode", ["loc", [null, [48, 64], [48, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 59], [48, 76]]], 0, 0]], [], ["loc", [null, [48, 51], [48, 77]]], 0, 0], "location", "at-bookingRequest-form"], ["loc", [null, [48, 4], [48, 113]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 6
                },
                "end": {
                  "line": 60,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-updateRecurrencePattern");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("input");
              dom.setAttribute(el3, "type", "checkbox");
              dom.setAttribute(el3, "class", "at-updateRecurrencePattern-checkbox");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1, 1]);
              var element12 = dom.childAt(element11, [1]);
              if (this.cachedFragment) {
                dom.repairClonedNode(element12, [], true);
              }
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element12, 'checked');
              morphs[1] = dom.createElementMorph(element12);
              morphs[2] = dom.createMorphAt(dom.childAt(element11, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "checked", ["subexpr", "eq", [["get", "updateOccurrencePattern", ["loc", [null, [56, 88], [56, 111]]], 0, 0, 0, 0], true], [], ["loc", [null, [null, null], [56, 118]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["toggleUpdateOccurrencePattern"], ["on", "change"], ["loc", [null, [56, 119], [56, 173]]], 0, 0], ["inline", "t", ["components.booking_request_form.update_recurrence_pattern"], [], ["loc", [null, [57, 14], [57, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 10
                  },
                  "end": {
                    "line": 67,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "recurring-event-fields-limited", [], ["model", ["subexpr", "@mut", [["get", "model.recurrencePattern", ["loc", [null, [66, 51], [66, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "atResourceFilters"], ["loc", [null, [66, 12], [66, 102]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 8
                },
                "end": {
                  "line": 69,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "updateOccurrencePattern", ["loc", [null, [65, 20], [65, 43]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isEditing", ["loc", [null, [65, 49], [65, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 44], [65, 59]]], 0, 0]], [], ["loc", [null, [65, 16], [65, 60]]], 0, 0]], [], 0, null, ["loc", [null, [65, 10], [67, 17]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 10
                  },
                  "end": {
                    "line": 72,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "recurring-event-fields", [], ["model", ["subexpr", "@mut", [["get", "model.recurrencePattern", ["loc", [null, [71, 43], [71, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "atBookingRequestForm"], ["loc", [null, [71, 12], [71, 97]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 8
                },
                "end": {
                  "line": 73,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "updateOccurrencePattern", ["loc", [null, [70, 20], [70, 43]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isEditing", ["loc", [null, [70, 49], [70, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 44], [70, 59]]], 0, 0]], [], ["loc", [null, [70, 16], [70, 60]]], 0, 0]], [], 0, null, ["loc", [null, [70, 10], [72, 17]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 4
              },
              "end": {
                "line": 75,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--recurring");
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "isEditing", ["loc", [null, [53, 12], [53, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [53, 6], [60, 13]]]], ["block", "if", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [64, 14], [64, 55]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [64, 8], [73, 15]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 8
                },
                "end": {
                  "line": 110,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "date-input bookingRequestForm-input");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("input");
              dom.setAttribute(el2, "disabled", "true");
              dom.setAttribute(el2, "class", "form-input");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'value');
              morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              return morphs;
            },
            statements: [["attribute", "value", ["subexpr", "moment-format", [["get", "model.startDateTime", ["loc", [null, [106, 39], [106, 58]]], 0, 0, 0, 0], ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [106, 65], [106, 93]]], 0, 0]], [], ["loc", [null, [106, 59], [106, 94]]], 0, 0]], [], ["loc", [null, [null, null], [106, 96]]], 0, 0], 0, 0, 0, 0], ["inline", "time-input", [], ["value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [109, 29], [109, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [109, 64], [109, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [109, 59], [109, 77]]], 0, 0]], [], ["loc", [null, [109, 51], [109, 78]]], 0, 0], "class", "bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [109, 10], [109, 149]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 8
                },
                "end": {
                  "line": 126,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-input date-input");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.endDateTime", ["loc", [null, [115, 15], [115, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [117, 23], [117, 51]]], 0, 0]], [], ["loc", [null, [117, 17], [117, 52]]], 0, 0], "updateDate", ["subexpr", "action", ["updateEndDateTime"], [], ["loc", [null, [118, 21], [118, 49]]], 0, 0], "readonly", true, "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [114, 10], [122, 33]]], 0, 0], ["inline", "time-input", [], ["value", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [125, 29], [125, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.toTime", ["loc", [null, [125, 64], [125, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [125, 59], [125, 77]]], 0, 0]], [], ["loc", [null, [125, 51], [125, 78]]], 0, 0], "class", "bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [125, 10], [125, 149]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 4
              },
              "end": {
                "line": 131,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--datetime");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-label");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "bookingRequestForm-label--inlineItems");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "bookingRequestForm-input date-input");
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestForm-label");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "bookingRequestForm-label--inlineItems");
            var el4 = dom.createTextNode("\n\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [2]);
            var element8 = dom.childAt(element7, [1]);
            var element9 = dom.childAt(element8, [3]);
            var element10 = dom.childAt(element7, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [2]), 1, 1);
            morphs[2] = dom.createMorphAt(element9, 4, 4);
            morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_form.from"], [], ["loc", [null, [80, 85], [80, 129]]], 0, 0], ["inline", "bs-datetimepicker", [], ["date", ["subexpr", "@mut", [["get", "model.startDateTime", ["loc", [null, [85, 15], [85, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "noIcon", true, "format", ["subexpr", "to-s", [["subexpr", "t", ["date_formats.date_full"], [], ["loc", [null, [87, 23], [87, 51]]], 0, 0]], [], ["loc", [null, [87, 17], [87, 52]]], 0, 0], "updateDate", ["subexpr", "action", ["updateStartDateTime"], [], ["loc", [null, [88, 21], [88, 51]]], 0, 0], "readonly", true, "position", "left,auto", "class", "at-bookingRequestForm", "allowInputToggle", true], ["loc", [null, [84, 10], [92, 33]]], 0, 0], ["inline", "time-input", [], ["onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.fromTime", ["loc", [null, [95, 43], [95, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 38], [95, 58]]], 0, 0]], [], ["loc", [null, [95, 30], [95, 59]]], 0, 0], "value", ["subexpr", "@mut", [["get", "model.fromTime", ["loc", [null, [95, 66], [95, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "bookingRequestForm-input bookingRequestForm-input--timeInput"], ["loc", [null, [95, 8], [95, 151]]], 0, 0], ["inline", "t", ["components.booking_request_form.to"], [], ["loc", [null, [100, 85], [100, 127]]], 0, 0], ["block", "if", [["get", "bookingType.hasExternallyManagedResources", ["loc", [null, [104, 14], [104, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [104, 8], [126, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 144,
                    "column": 6
                  },
                  "end": {
                    "line": 146,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookOnBehalfOfText", ["loc", [null, [145, 25], [145, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.bobo.placeholder"], [], ["loc", [null, [145, 73], [145, 127]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-textarea"], ["loc", [null, [145, 8], [145, 201]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 137,
                  "column": 2
                },
                "end": {
                  "line": 148,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--bobo");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "bookingRequestForm-labelText");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var element4 = dom.childAt(element3, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element4, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element3, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "bookingRequestForm-input", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "boboBaseSettings.bookOnBehalfOfChecked", ["loc", [null, [140, 75], [140, 113]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [140, 10], [140, 115]]], 0, 0], ["inline", "t", ["components.booking_request_form.bobo.title"], [], ["loc", [null, [141, 53], [141, 103]]], 0, 0], ["block", "if", [["get", "boboBaseSettings.bookOnBehalfOfChecked", ["loc", [null, [144, 12], [144, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [144, 6], [146, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 158,
                    "column": 6
                  },
                  "end": {
                    "line": 162,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                return morphs;
              },
              statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseOwner", ["loc", [null, [159, 34], [159, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.identifier_placeholder"], [], ["loc", [null, [159, 86], [159, 151]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [159, 8], [159, 227]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseEmail", ["loc", [null, [160, 34], [160, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.email_placeholder"], [], ["loc", [null, [160, 86], [160, 146]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [160, 8], [160, 222]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseName", ["loc", [null, [161, 34], [161, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.booking_request_form.base.name_placeholder"], [], ["loc", [null, [161, 85], [161, 144]]], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-text-input"], ["loc", [null, [161, 8], [161, 220]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 151,
                  "column": 3
                },
                "end": {
                  "line": 164,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--base");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "bookingRequestForm-labelText");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element2, 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["inline", "input", [], ["class", "bookingRequestForm-input", "type", "checkbox", "checked", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseChecked", ["loc", [null, [154, 75], [154, 116]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [154, 10], [154, 118]]], 0, 0], ["inline", "t", ["components.booking_request_form.base.title"], [], ["loc", [null, [155, 53], [155, 103]]], 0, 0], ["block", "if", [["get", "boboBaseSettings.bookAsSomeoneElseChecked", ["loc", [null, [158, 12], [158, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [158, 6], [162, 13]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 176,
                  "column": 2
                },
                "end": {
                  "line": 192,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-request-form/description-custom-fields", [], ["title", ["subexpr", "@mut", [["get", "model.title", ["loc", [null, [178, 12], [178, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [179, 11], [179, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [180, 18], [180, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "startDay", ["subexpr", "@mut", [["get", "model.startDay", ["loc", [null, [181, 15], [181, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "endDay", ["subexpr", "@mut", [["get", "model.endDay", ["loc", [null, [182, 13], [182, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "model.fromTime", ["loc", [null, [183, 15], [183, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "model.toTime", ["loc", [null, [184, 13], [184, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "fields", ["subexpr", "@mut", [["get", "lseCustomFields", ["loc", [null, [185, 13], [185, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "isBaseBooking", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseChecked", ["loc", [null, [186, 20], [186, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "bookAsSomeoneElseOwner", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseOwner", ["loc", [null, [187, 29], [187, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "bookAsSomeoneElseEmail", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseEmail", ["loc", [null, [188, 29], [188, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "bookAsSomeoneElseName", ["subexpr", "@mut", [["get", "boboBaseSettings.bookAsSomeoneElseName", ["loc", [null, [189, 28], [189, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "onValueChange", "updateCustomFieldsValue"], ["loc", [null, [177, 4], [191, 6]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 194,
                  "column": 2
                },
                "end": {
                  "line": 198,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-request-form/cuhk-custom-fields", [], ["model", ["subexpr", "@mut", [["get", "cuhkCustomFields", ["loc", [null, [196, 50], [196, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [196, 2], [196, 68]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 201,
                  "column": 2
                },
                "end": {
                  "line": 209,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "additional-resource-booking", [], ["resourcesSelected", ["subexpr", "@mut", [["get", "resourcesSelected", ["loc", [null, [203, 22], [203, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "resourceRestrictions", ["subexpr", "@mut", [["get", "bookingType.resourceRestrictionRules", ["loc", [null, [204, 25], [204, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "atBookingForm", true, "didSelectResourceRestrictionResource", ["subexpr", "action", [["get", "didSelectResourceRestrictionResource", ["loc", [null, [206, 49], [206, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [206, 41], [206, 86]]], 0, 0], "searchResources", ["subexpr", "action", [["get", "searchResources", ["loc", [null, [207, 28], [207, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [207, 20], [207, 44]]], 0, 0]], ["loc", [null, [202, 4], [208, 6]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 215,
                    "column": 6
                  },
                  "end": {
                    "line": 219,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" (");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("/");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(")");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element0, 0, 0);
                morphs[1] = dom.createMorphAt(element0, 2, 2);
                morphs[2] = dom.createMorphAt(element0, 4, 4);
                morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_form.description"], [], ["loc", [null, [216, 85], [216, 136]]], 0, 0], ["content", "customFieldsDescriptionLength", ["loc", [null, [216, 138], [216, 171]]], 0, 0, 0, 0], ["content", "customFieldsDescriptionMaxLength", ["loc", [null, [216, 172], [216, 208]]], 0, 0, 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [217, 25], [217, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", ["subexpr", "@mut", [["get", "customFieldsDescriptionMaxLength", ["loc", [null, [217, 53], [217, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-textrea"], ["loc", [null, [217, 8], [217, 158]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 221,
                      "column": 8
                    },
                    "end": {
                      "line": 224,
                      "column": 8
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["components.booking_request_form.description"], [], ["loc", [null, [222, 89], [222, 140]]], 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [223, 27], [223, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input bookingRequestForm-input bookingRequestForm-textrea"], ["loc", [null, [223, 10], [223, 117]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 219,
                    "column": 6
                  },
                  "end": {
                    "line": 226,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "if", [["get", "shouldShowDescriptionField", ["loc", [null, [221, 14], [221, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [221, 8], [224, 15]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 211,
                  "column": 2
                },
                "end": {
                  "line": 230,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--description");
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "bookingRequestForm-label");
              var el3 = dom.createTextNode("\n\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "showDescriptionCustomFields", ["loc", [null, [215, 12], [215, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [215, 6], [226, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 232,
                  "column": 4
                },
                "end": {
                  "line": 234,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "booking-request-form-tabs", [], ["onTabClick", ["subexpr", "action", ["onTabClick"], [], ["loc", [null, [233, 47], [233, 68]]], 0, 0], "model", ["subexpr", "@mut", [["get", "bookingForm.sections", ["loc", [null, [233, 75], [233, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [233, 108], [233, 119]]], 0, 0, 0, 0]], [], [], 0, 0], "formId", ["subexpr", "@mut", [["get", "bookingForm.id", ["loc", [null, [233, 127], [233, 141]]], 0, 0, 0, 0]], [], [], 0, 0], "isEditing", ["subexpr", "@mut", [["get", "isEditing", ["loc", [null, [233, 152], [233, 161]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [233, 8], [233, 163]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 2
              },
              "end": {
                "line": 236,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--guests bookingRequestForm-fields--separator");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "bookingRequestForm-label");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [5, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element5, 3, 3);
            morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 13, 13, contextualElement);
            morphs[8] = dom.createMorphAt(fragment, 15, 15, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canBookOnBehalfOf", ["loc", [null, [137, 8], [137, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [137, 2], [148, 9]]]], ["block", "if", [["get", "canBookAsSomeoneElse", ["loc", [null, [151, 9], [151, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [151, 3], [164, 9]]]], ["inline", "t", ["components.booking_request_form.guests"], [], ["loc", [null, [170, 85], [170, 131]]], 0, 0], ["inline", "rb-select", [], ["placeholder", ["subexpr", "t", ["components.booking_request_form.select_guest"], [], ["loc", [null, [171, 30], [171, 80]]], 0, 0], "class", "bookingRequestForm-select"], ["loc", [null, [171, 6], [171, 116]]], 0, 0], ["block", "if", [["get", "showDescriptionCustomFields", ["loc", [null, [176, 8], [176, 35]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [176, 2], [192, 9]]]], ["block", "if", [["get", "showCUHKCustomFields", ["loc", [null, [194, 8], [194, 28]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [194, 2], [198, 9]]]], ["block", "if", [["get", "shouldShowAdditionalResources", ["loc", [null, [201, 8], [201, 37]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [201, 2], [209, 9]]]], ["block", "if", [["subexpr", "or", [["get", "showDescriptionCustomFields", ["loc", [null, [211, 12], [211, 39]]], 0, 0, 0, 0], ["get", "shouldShowDescriptionField", ["loc", [null, [211, 40], [211, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [211, 8], [211, 67]]], 0, 0]], [], 5, null, ["loc", [null, [211, 2], [230, 9]]]], ["block", "if", [["get", "bookingForm", ["loc", [null, [232, 10], [232, 21]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [232, 4], [234, 11]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 237,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestForm-fields bookingRequestForm-fields--separator bookingRequestForm-fields--title bookingRequestForm-title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "bookingRequestForm-label");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block ");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "text");
          dom.setAttribute(el3, "class", "form-input bookingRequestForm-input bookingRequestForm-title-input at-bookingRequestForm-bookingName");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element15 = dom.childAt(fragment, [2, 1]);
          var element16 = dom.childAt(element15, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element15, [1]), 0, 0);
          morphs[2] = dom.createAttrMorph(element16, 'disabled');
          morphs[3] = dom.createAttrMorph(element16, 'value');
          morphs[4] = dom.createAttrMorph(element16, 'oninput');
          morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[7] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "formError", ["loc", [null, [8, 8], [8, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 2], [12, 9]]]], ["inline", "t", ["components.booking_request_form.name_placeholder"], [], ["loc", [null, [16, 86], [16, 142]]], 0, 0], ["attribute", "disabled", ["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [17, 36], [17, 74]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "model.name", ["loc", [null, [17, 85], [17, 95]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "oninput", ["subexpr", "action", [["subexpr", "mut", [["get", "model.name", ["loc", [null, [17, 120], [17, 130]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 115], [17, 131]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [17, 154]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isEditing", ["loc", [null, [22, 8], [22, 17]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [22, 2], [36, 9]]]], ["block", "unless", [["get", "disableRecurring", ["loc", [null, [47, 12], [47, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [47, 2], [49, 13]]]], ["block", "if", [["subexpr", "and", [["get", "repeatBooking", ["loc", [null, [51, 15], [51, 28]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [51, 34], [51, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 29], [51, 73]]], 0, 0], ["subexpr", "not", [["get", "disableRecurring", ["loc", [null, [51, 79], [51, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 74], [51, 96]]], 0, 0]], [], ["loc", [null, [51, 10], [51, 97]]], 0, 0]], [], 3, 4, ["loc", [null, [51, 4], [131, 9]]]], ["block", "unless", [["get", "bookingRequestToEditIsSingleOccurrence", ["loc", [null, [134, 12], [134, 50]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [134, 2], [236, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 240,
                "column": 2
              },
              "end": {
                "line": 240,
                "column": 185
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "if", [["get", "isEditing", ["loc", [null, [240, 86], [240, 95]]], 0, 0, 0, 0], ["subexpr", "t", ["components.booking_request_form.update"], [], ["loc", [null, [240, 96], [240, 140]]], 0, 0], ["subexpr", "t", ["components.booking_request_form.book"], [], ["loc", [null, [240, 141], [240, 183]]], 0, 0]], [], ["loc", [null, [240, 81], [240, 185]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 239,
              "column": 0
            },
            "end": {
              "line": 241,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [240, 26], [240, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "submit", "class", "button button--primary"], 0, null, ["loc", [null, [240, 2], [240, 202]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 242,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [1, 32], [1, 37]]], 0, 0, 0, 0], ["get", "isSaving", ["loc", [null, [1, 38], [1, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 24], [1, 47]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [5, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [7, 0], [237, 18]]]], ["block", "modal-footer", [], ["class", "bookingRequestForm-modalFooter"], 2, null, ["loc", [null, [239, 0], [241, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});