define("scientia-resourcebooker/pods/manage-resources/booking-form/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteTab", ["loc", [null, [7, 25], [7, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 46]]], 0, 0], null], [], ["loc", [null, [7, 12], [7, 52]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_delete_tab_title"], [], ["loc", [null, [8, 19], [8, 89]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_delete_tab_message"], [], ["loc", [null, [9, 21], [9, 93]]], 0, 0], "confirm", ["subexpr", "action", ["removeTabConfirm"], [], ["loc", [null, [10, 14], [10, 41]]], 0, 0]], ["loc", [null, [5, 9], [10, 42]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showConfirmDeleteTab", ["loc", [null, [11, 32], [11, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 27], [11, 53]]], 0, 0], null], [], ["loc", [null, [11, 19], [11, 59]]], 0, 0]], ["loc", [null, [4, 7], [11, 60]]], 0, 0]], ["loc", [null, [2, 1], [12, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", ["leavePageCancel"], [], ["loc", [null, [21, 12], [21, 38]]], 0, 0], "confirmTitle", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_discard_changes_title"], [], ["loc", [null, [22, 19], [22, 94]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.confirm_discard_changes_message"], [], ["loc", [null, [23, 21], [23, 98]]], 0, 0], "confirm", ["subexpr", "action", ["leavePageConfirm"], [], ["loc", [null, [24, 14], [24, 41]]], 0, 0]], ["loc", [null, [19, 9], [24, 42]]], 0, 0], "onOutsideClick", ["subexpr", "action", ["leavePageCancel"], [], ["loc", [null, [25, 19], [25, 45]]], 0, 0]], ["loc", [null, [18, 7], [25, 46]]], 0, 0]], ["loc", [null, [16, 0], [26, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 6
                },
                "end": {
                  "line": 43,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [41, 17], [41, 78]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_form.edit.description"], [], ["loc", [null, [42, 6], [42, 65]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 6
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/bullet-list.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [45, 17], [45, 81]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_form.edit.fields"], [], ["loc", [null, [46, 6], [46, 60]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 49,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [41, 6], [43, 17]]]], ["block", "dc-tab", [], [], 1, null, ["loc", [null, [45, 6], [47, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 6
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/booking-form-form", [], ["localePrefix", "components.manage_resources.booking_form_edit", "name", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [54, 11], [54, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [55, 18], [55, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "associatedBookingTypes", ["subexpr", "@mut", [["get", "model.associatedBookingTypes", ["loc", [null, [56, 29], [56, 57]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [52, 6], [57, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 72,
                            "column": 14
                          },
                          "end": {
                            "line": 74,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "inline-svg", ["icons/reorder-option.svg"], ["class", "scientia-tabs-item-link-icon handle"], ["loc", [null, [73, 16], [73, 101]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 76,
                            "column": 14
                          },
                          "end": {
                            "line": 80,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "scientia-tabs-item-link-text");
                        var el2 = dom.createTextNode("\n                  ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                        return morphs;
                      },
                      statements: [["inline", "input", [], ["class", "form-input at-scientia-tabs-tab at-bookingFormTab--editing", "autofocus", true, "value", ["subexpr", "@mut", [["get", "tab.name", ["loc", [null, [78, 114], [78, 122]]], 0, 0, 0, 0]], [], [], 0, 0], "focus-out", ["subexpr", "action", ["renameTabFinished", ["get", "tab", ["loc", [null, [78, 161], [78, 164]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 133], [78, 165]]], 0, 0], "enter", ["subexpr", "action", ["renameTabFinished", ["get", "tab", ["loc", [null, [78, 200], [78, 203]]], 0, 0, 0, 0]], [], ["loc", [null, [78, 172], [78, 204]]], 0, 0], "maxlength", 20], ["loc", [null, [78, 18], [78, 219]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child2 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.8.3",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 83,
                              "column": 16
                            },
                            "end": {
                              "line": 85,
                              "column": 16
                            }
                          },
                          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                  ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("button");
                          dom.setAttribute(el1, "type", "button");
                          dom.setAttribute(el1, "class", "button button--link button--icon at-bookingForm-deleteTab");
                          var el2 = dom.createTextNode("✕");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var element1 = dom.childAt(fragment, [1]);
                          var morphs = new Array(1);
                          morphs[0] = dom.createElementMorph(element1);
                          return morphs;
                        },
                        statements: [["element", "action", ["removeTab", ["get", "tab", ["loc", [null, [84, 61], [84, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [84, 40], [84, 66]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 80,
                            "column": 14
                          },
                          "end": {
                            "line": 86,
                            "column": 14
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        dom.setAttribute(el1, "class", "scientia-tabs-item-link-text");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n\n");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element2 = dom.childAt(fragment, [1]);
                        var morphs = new Array(3);
                        morphs[0] = dom.createElementMorph(element2);
                        morphs[1] = dom.createMorphAt(element2, 0, 0);
                        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["element", "action", ["renameTab", ["get", "tab.rank", ["loc", [null, [81, 80], [81, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 59], [81, 90]]], 0, 0], ["content", "tab.name", ["loc", [null, [81, 91], [81, 103]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["subexpr", "eq", [["get", "tab.rank", ["loc", [null, [83, 36], [83, 44]]], 0, 0, 0, 0], 0], [], ["loc", [null, [83, 32], [83, 47]]], 0, 0]], [], ["loc", [null, [83, 27], [83, 48]]], 0, 0], ["subexpr", "eq", [["get", "formTabActiveRank", ["loc", [null, [83, 53], [83, 70]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [83, 71], [83, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [83, 49], [83, 80]]], 0, 0]], [], ["loc", [null, [83, 22], [83, 81]]], 0, 0]], [], 0, null, ["loc", [null, [83, 16], [85, 23]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 69,
                          "column": 10
                        },
                        "end": {
                          "line": 90,
                          "column": 10
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("a");
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("span");
                      dom.setAttribute(el2, "class", "scientia-tabs-item-link--content");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n              ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n            ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element3 = dom.childAt(fragment, [1]);
                      var element4 = dom.childAt(element3, [1]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createAttrMorph(element3, 'class');
                      morphs[1] = dom.createElementMorph(element3);
                      morphs[2] = dom.createMorphAt(element4, 1, 1);
                      morphs[3] = dom.createMorphAt(element4, 3, 3);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["scientia-tabs-item-link at-manageResources-bookingFormTab ", ["subexpr", "if", [["subexpr", "eq", [["get", "formTabActiveRank", ["loc", [null, [70, 142], [70, 159]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [70, 160], [70, 168]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 138], [70, 169]]], 0, 0], "scientia-tabs-item-link--active"], [], ["loc", [null, [70, 133], [70, 205]]], 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["get", "tabRankToRename", ["loc", [null, [70, 215], [70, 230]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [70, 231], [70, 239]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 211], [70, 240]]], 0, 0], "at-manageResources-bookingFormTab--editing"], [], ["loc", [null, [70, 206], [70, 287]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "formTabActiveRank", ["loc", [null, [70, 37], [70, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 32], [70, 55]]], 0, 0], ["get", "tab.rank", ["loc", [null, [70, 56], [70, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [70, 24], [70, 65]]], 0, 0]], [], ["loc", [null, [70, 15], [70, 67]]], 0, 0], ["block", "unless", [["subexpr", "eq", [["get", "tab.rank", ["loc", [null, [72, 28], [72, 36]]], 0, 0, 0, 0], 0], [], ["loc", [null, [72, 24], [72, 39]]], 0, 0]], [], 0, null, ["loc", [null, [72, 14], [74, 25]]]], ["block", "if", [["subexpr", "eq", [["get", "tabRankToRename", ["loc", [null, [76, 24], [76, 39]]], 0, 0, 0, 0], ["get", "tab.rank", ["loc", [null, [76, 40], [76, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [76, 20], [76, 49]]], 0, 0]], [], 1, 2, ["loc", [null, [76, 14], [86, 21]]]]],
                    locals: [],
                    templates: [child0, child1, child2]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 8
                      },
                      "end": {
                        "line": 91,
                        "column": 8
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "scientia-tabs-item", [], [], 0, null, ["loc", [null, [69, 10], [90, 33]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 6
                    },
                    "end": {
                      "line": 93,
                      "column": 6
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "sortable-item", [], ["model", ["subexpr", "@mut", [["get", "tab", ["loc", [null, [68, 31], [68, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "group", ["loc", [null, [68, 41], [68, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "handle", ".handle"], 0, null, ["loc", [null, [68, 8], [91, 26]]]]],
                locals: ["tab"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 4
                  },
                  "end": {
                    "line": 95,
                    "column": 4
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "each", [["subexpr", "sort-by", ["rank", ["get", "model.sections", ["loc", [null, [66, 30], [66, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 14], [66, 45]]], 0, 0]], [], 0, null, ["loc", [null, [66, 6], [93, 15]]]]],
              locals: ["group"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 4
                  },
                  "end": {
                    "line": 102,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "scientia-tabs-item-link at-bookingFormTabs-addNewTab");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "scientia-tabs-item-link-text at-bookingFormTabs-addNewTab");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["addTab"], [], ["loc", [null, [98, 7], [98, 26]]], 0, 0], ["inline", "inline-svg", ["icons/plus.svg"], ["class", "scientia-tabs-item-link-icon"], ["loc", [null, [99, 8], [99, 76]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_tab.add_new"], [], ["loc", [null, [100, 80], [100, 140]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 4
                },
                "end": {
                  "line": 104,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["block", "sortable-group", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [64, 28], [64, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "sortableContainer at-bookingFormTabs", "direction", ["subexpr", "@mut", [["get", "tabSortingDirection", ["loc", [null, [64, 94], [64, 113]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", "reorderTabs"], 0, null, ["loc", [null, [64, 4], [95, 23]]]], ["block", "scientia-tabs-item", [], [], 1, null, ["loc", [null, [97, 4], [102, 29]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 4
              },
              "end": {
                "line": 117,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "scientia-tabs", [], [], 0, null, ["loc", [null, [62, 4], [104, 22]]]], ["inline", "manage-resources/booking-form-tab", [], ["model", ["subexpr", "find-by", ["rank", ["get", "formTabActiveRank", ["loc", [null, [107, 26], [107, 43]]], 0, 0, 0, 0], ["get", "model.sections", ["loc", [null, [107, 44], [107, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [107, 10], [107, 59]]], 0, 0], "addItem", ["subexpr", "action", ["addItem"], [], ["loc", [null, [108, 12], [108, 30]]], 0, 0], "deleteItem", ["subexpr", "action", ["deleteItem"], [], ["loc", [null, [109, 15], [109, 36]]], 0, 0], "toggleEditing", ["subexpr", "action", ["toggleEditing"], [], ["loc", [null, [110, 18], [110, 42]]], 0, 0], "rollbackItem", ["subexpr", "action", ["rollbackItem"], [], ["loc", [null, [111, 17], [111, 40]]], 0, 0], "reorderItems", ["subexpr", "action", ["reorderItems"], [], ["loc", [null, [112, 17], [112, 40]]], 0, 0], "searchResourceType", ["subexpr", "action", ["searchResourceType"], [], ["loc", [null, [113, 23], [113, 52]]], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [114, 16], [114, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [106, 4], [115, 6]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 6
            },
            "end": {
              "line": 118,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [39, 6], [49, 22]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [51, 6], [58, 21]]]], ["block", "dc-tab-panel", [], [], 2, null, ["loc", [null, [60, 4], [117, 21]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 121,
              "column": 6
            },
            "end": {
              "line": 121,
              "column": 127
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["general.save"], [], ["loc", [null, [121, 107], [121, 127]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 6
            },
            "end": {
              "line": 122,
              "column": 135
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["general.save_back"], [], ["loc", [null, [122, 110], [122, 135]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 127,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-form/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns at-manageResources-bookingForm--edit");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-actions manageResources-itemForm-form-actions--dual-action");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [4]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element7, [5]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(element5, 1, 1);
        morphs[3] = dom.createMorphAt(element6, 1, 1);
        morphs[4] = dom.createMorphAt(element7, 1, 1);
        morphs[5] = dom.createMorphAt(element7, 3, 3);
        morphs[6] = dom.createMorphAt(element8, 1, 1);
        morphs[7] = dom.createMorphAt(element8, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showConfirmDeleteTab", ["loc", [null, [1, 6], [1, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 7]]]], ["block", "if", [["get", "showUnsavedChangesModal", ["loc", [null, [15, 6], [15, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [27, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [30, 39], [30, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 0], [30, 53]]], 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.booking_form.edit.page_title"], ["name", ["get", "model.name", ["loc", [null, [33, 86], [33, 96]]], 0, 0, 0, 0]], ["loc", [null, [33, 25], [33, 97]]], 0, 0]], ["loc", [null, [33, 4], [33, 99]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [36, 24], [36, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 6], [36, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [38, 59], [38, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 2, null, ["loc", [null, [38, 6], [118, 16]]]], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [121, 30], [121, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "button", "action", ["subexpr", "route-action", ["save"], [], ["loc", [null, [121, 60], [121, 81]]], 0, 0], "class", "button--primary"], 3, null, ["loc", [null, [121, 6], [121, 144]]]], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [122, 30], [122, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "button", "action", ["subexpr", "route-action", ["save", true], [], ["loc", [null, [122, 60], [122, 86]]], 0, 0], "class", "button--white"], 4, null, ["loc", [null, [122, 6], [122, 152]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});