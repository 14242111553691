define('scientia-resourcebooker/adapters/user-group', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    urlForQuery: function urlForQuery(query, modelName) {

      var data = {
        pageNumber: query.pageNumber || 1,
        itemsPerPage: query.itemsPerPage || 10,
        query: query.query
      };

      if (!query.query) return this._super.apply(this, arguments);

      var url = this._buildURL(modelName);
      url += '/Search?query=' + data.query + '&pageNumber=' + data.pageNumber + '&itemsPerPage=' + data.itemsPerPage;
      return url;
    }

  });
});