define('scientia-resourcebooker/abilities/user-group', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    // canList here decides if the userGroup menu entry is shown or not.
    // Seeing non-admins can now have the ability to edit user group claims, we always need to show the menu entry
    // and then the backend will decide which groups to retrieve based on users permissions.
    canList: (function () {
      return this.get('currentUser').isUserGroupOwner();
    }).property(),

    canCreate: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    // canEdit here decides if the pencil/edit icon is shown.
    canEdit: (function () {
      var model = this.get('model');
      return this.get('currentUser').isAdmin() || model.get('canEditClaims');
    }).property(),

    canEditClaims: (function () {
      var model = this.get('model');
      return this.get('currentUser').isAdmin() || model.get('canEditClaims');
    }).property('model.canEditClaims'),

    canEditPermissionTab: (function () {
      var model = this.get('model');
      if (model.get('isSystemGroup')) return false;
      return this.get('currentUser').isAdmin();
    }).property('model.isSystemGroup'),

    canDelete: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canImport: (function () {
      return this.get('currentUser').isUserGroupOwner();
    }).property()
  });
});