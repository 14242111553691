define('scientia-resourcebooker/services/resource-busy-patterns-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var DEFAULT_ALL_GROUPS = 'anyUsers';

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var _this = this;

      var type = _ref.type;
      var id = _ref.id;
      var parentId = _ref.parentId;
      var userGroupId = _ref.userGroupId;

      var url = this._urlFromIds(id, parentId, type);
      var data = {};

      if (userGroupId && userGroupId !== DEFAULT_ALL_GROUPS) data.userGroupIdentity = userGroupId;

      return this.get('authenticatedRequest').fetch(url, data).then(function (results) {
        return _this._normalizeResults(results);
      });
    },

    _urlFromIds: function _urlFromIds(id, parentId, type) {
      if (parentId) {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_PATTERNS[type].loc(parentId, id);
      } else {
        return _scientiaResourcebookerConfigEnvironment['default'].API.FETCH_BUSY_PATTERNS[type].loc(id);
      }
    },

    _normalizeResults: function _normalizeResults(data) {
      var _this2 = this;

      var events = new Array(7);

      data.forEach(function (item) {
        item.days.forEach(function (day) {

          // First we fix the hacky dates returned from the API and replace them with todays YYYY-MM-DD

          // 2018-12-13 RB-250: Not sure why we are search/replacing 01 02 and 03 dates below if all the patterns end up being [moment now]?
          // Perhaps instead of search/replace we could just do item.startDateTime = moment.utc().format('YYYY-MM-DD') ??
          item.startDateTime = item.startDateTime.replace('0001-01-01', moment.utc().format('YYYY-MM-DD'));
          item.startDateTime = item.startDateTime.replace('0001-01-02', moment.utc().format('YYYY-MM-DD'));
          item.startDateTime = item.startDateTime.replace('0001-01-03', moment.utc().format('YYYY-MM-DD'));

          var startDateTime = moment.utc(item.startDateTime).tz(_this2.get('systemSettings').getSetting('timeZone'));
          var endDateTime = startDateTime.clone().add(item.duration, 'minutes');

          // We need to know the diff between the start of day and the current unavailability slot so the calendar
          // can then render it in the right place
          var startOfDay = startDateTime.clone().startOf('day');

          var fromTime = moment.duration(startDateTime.diff(startOfDay, 'miliseconds'));
          var toTime = moment.duration(endDateTime.diff(startOfDay, 'miliseconds'));

          var currentEvent = _ember['default'].Object.create({ fromTime: fromTime, toTime: toTime, dayOffset: item.dayOffset, dstDifference: item.dstDifference });

          events[day] = events[day] || [];
          events[day].pushObject(currentEvent);
        });
      });

      return events;
    }
  });
});