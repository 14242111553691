define("scientia-resourcebooker/pods/application/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "waitingBar");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["logout-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [13, 25], [13, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 20], [13, 39]]], 0, 0], false], [], ["loc", [null, [13, 12], [13, 46]]], 0, 0], "singleLogoutMode", ["subexpr", "@mut", [["get", "singleLogoutMode", ["loc", [null, [14, 23], [14, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "singleLogoutCheckboxValue", ["subexpr", "@mut", [["get", "singleLogoutCheckboxValue", ["loc", [null, [15, 32], [15, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["confirmLogout"], [], ["loc", [null, [16, 14], [16, 38]]], 0, 0]], ["loc", [null, [11, 11], [16, 39]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmLogout", ["loc", [null, [17, 32], [17, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 27], [17, 46]]], 0, 0], false], [], ["loc", [null, [17, 19], [17, 53]]], 0, 0]], ["loc", [null, [10, 9], [17, 54]]], 0, 0]], ["loc", [null, [8, 1], [18, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 31,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["help-modal"], ["model", ["subexpr", "@mut", [["get", "helpDataForPage", ["loc", [null, [27, 14], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [28, 27], [28, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [28, 22], [28, 41]]], 0, 0], false], [], ["loc", [null, [28, 14], [28, 48]]], 0, 0]], ["loc", [null, [25, 11], [28, 49]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showHelpModal", ["loc", [null, [29, 34], [29, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 29], [29, 48]]], 0, 0], false], [], ["loc", [null, [29, 21], [29, 55]]], 0, 0]], ["loc", [null, [24, 9], [29, 56]]], 0, 0]], ["loc", [null, [22, 2], [30, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "flash-message", [], ["flash", ["subexpr", "@mut", [["get", "flash", ["loc", [null, [34, 24], [34, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [34, 2], [34, 31]]], 0, 0]],
        locals: ["flash"],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 37,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "timeout-overlay");
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/application/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "aria-hidden", "true");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'data-route-name');
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        return morphs;
      },
      statements: [["attribute", "data-route-name", ["concat", [["get", "router.currentRouteName", ["loc", [null, [1, 44], [1, 67]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "loading", ["loc", [null, [3, 6], [3, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [5, 7]]]], ["block", "if", [["get", "confirmLogout", ["loc", [null, [7, 6], [7, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 0], [19, 7]]]], ["block", "if", [["get", "showHelpModal", ["loc", [null, [21, 6], [21, 19]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 0], [31, 7]]]], ["block", "each", [["get", "flashMessages.queue", ["loc", [null, [33, 8], [33, 27]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [33, 0], [35, 9]]]], ["block", "if", [["get", "isSessionTimedout", ["loc", [null, [37, 6], [37, 23]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [37, 0], [39, 7]]]], ["content", "liquid-outlet", ["loc", [null, [39, 7], [39, 24]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});