define('scientia-resourcebooker/services/availability-slot-persister', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    persistDate: function persistDate(_ref) {
      var item = _ref.item;
      var itemParent = _ref.itemParent;
      var specificStartDate = _ref.specificStartDate;
      var specificEndDate = _ref.specificEndDate;
      var userGroup = _ref.userGroup;
      var type = _ref.type;

      var duration = specificEndDate.diff(specificStartDate, 'minutes');
      var url = this._urlFromResource(type, specificStartDate, 'ADD', item, itemParent, userGroup);

      return this.putUnavailabilityDate(url, { duration: duration, specificDate: specificStartDate, userGroup: userGroup });
    },

    persistWeekday: function persistWeekday(_ref2) {
      var item = _ref2.item;
      var itemParent = _ref2.itemParent;
      var weekdayIndex = _ref2.weekdayIndex;
      var duration = _ref2.duration;
      var specificDate = _ref2.specificDate;
      var userGroup = _ref2.userGroup;
      var type = _ref2.type;

      var url = this._urlFromResource(type, null, 'ADD', item, itemParent, userGroup);

      return this.putUnavailabilityWeekday(url, { duration: duration, weekdayIndex: weekdayIndex, specificDate: specificDate, userGroup: userGroup });
    },

    deleteDate: function deleteDate(_ref3) {
      var item = _ref3.item;
      var itemParent = _ref3.itemParent;
      var duration = _ref3.duration;
      var specificDate = _ref3.specificDate;
      var userGroup = _ref3.userGroup;
      var type = _ref3.type;

      var url = this._urlFromResource(type, specificDate, 'REMOVE', item, itemParent, userGroup);

      return this.putUnavailabilityDate(url, { duration: duration, specificDate: specificDate, userGroup: userGroup });
    },

    deleteWeekday: function deleteWeekday(_ref4) {
      var item = _ref4.item;
      var itemParent = _ref4.itemParent;
      var weekdayIndex = _ref4.weekdayIndex;
      var duration = _ref4.duration;
      var specificDate = _ref4.specificDate;
      var userGroup = _ref4.userGroup;
      var type = _ref4.type;
      var dayOffset = _ref4.dayOffset;
      var dstDifference = _ref4.dstDifference;

      var url = this._urlFromResource(type, null, 'REMOVE', item, itemParent, userGroup);

      return this.putUnavailabilityWeekday(url, { duration: duration, weekdayIndex: weekdayIndex, specificDate: specificDate, userGroup: userGroup, dayOffset: dayOffset, dstDifference: dstDifference });
    },

    putUnavailabilityDate: function putUnavailabilityDate(url, _ref5) {
      var duration = _ref5.duration;
      var specificDate = _ref5.specificDate;

      var data = this._specificDateData({ duration: duration, specificDate: specificDate.utc() });

      return this.get('authenticatedRequest').put(url, data);
    },

    putUnavailabilityWeekday: function putUnavailabilityWeekday(url, _ref6) {
      var duration = _ref6.duration;
      var weekdayIndex = _ref6.weekdayIndex;
      var specificDate = _ref6.specificDate;
      var dayOffset = _ref6.dayOffset;
      var dstDifference = _ref6.dstDifference;

      var data = {
        days: [weekdayIndex],
        duration: duration,
        startDateTime: specificDate.format(),
        repeat: true,
        dayOffset: dayOffset,
        dstDifference: dstDifference
      };
      return this.get('authenticatedRequest').put(url, data);
    },

    _weekdayData: function _weekdayData(_ref7) {
      var duration = _ref7.duration;
      var weekdayIndex = _ref7.weekdayIndex;
      var specificDate = _ref7.specificDate;

      var repeatStartDate = '0001-01-01T' + specificDate.format('HH:mm:ss') + '+00:00';

      return {
        days: [weekdayIndex],
        duration: duration,
        startDateTime: repeatStartDate,
        repeat: true
      };
    },

    _specificDateData: function _specificDateData(_ref8) {
      var duration = _ref8.duration;
      var specificDate = _ref8.specificDate;

      var formattedStartDate = specificDate.format('YYYY-MM-DDT') + specificDate.format('HH:mm:ssZ'); // + "+00:00";

      return {
        duration: duration,
        startDateTime: formattedStartDate,
        repeat: false
      };
    },

    _urlFromResource: function _urlFromResource(type, specificDate, action, item, itemParent) {
      var baseUrl = specificDate ? _scientiaResourcebookerConfigEnvironment['default'].API.SPECIFIC_TIMES[type][action] : _scientiaResourcebookerConfigEnvironment['default'].API.PATTERN_TIMES[type][action];
      var url = undefined;

      if (itemParent) {
        url = baseUrl.loc(itemParent, item.get('id'));
      } else {
        url = baseUrl.loc(item.get('id'));
      }

      // This will be used when the user group are listed in the component.
      //if (userGroup) url = `${url}?userGroupIdentity=[${userGroup}]`;

      return url;
    }
  });
});