define('scientia-resourcebooker/pods/manage-resources/booking-type/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),
    rulesFetcher: _ember['default'].inject.service(),
    rulesProcessor: _ember['default'].inject.service(),

    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),

    can: _ember['default'].inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_type.edit.page_title');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        bookingType: this.get('store').find('booking-type', params.id),
        eventPatterns: [],
        events: [],
        availableRules: this.get('rulesFetcher').fetchRules(),
        bookingTypeRules: this.get('rulesFetcher').fetch('booking-type', params.id)
      });
    },

    afterModel: function afterModel(model, transition) {
      var _this = this;

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.bookingType.get('name'),
        id: model.bookingType.get('id')
      });

      // If we are in the availability tab force a refresh of unav. patterns and events
      transition.then(function () {
        if (_this.controller.get('selectedTab') === 2) {
          transition.send('refresUnavailabilityAfterModelSave');
        }
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          name: '',
          error: '',
          showDeleteRestrictionWarning: false,
          resourceRestrictionToDeleteDeferred: null
        });
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.booking-type.edit');
        if (model.bookingType.get('hasDirtyAttributes')) model.bookingType.rollbackAttributes();
      },

      updateBookingType: function updateBookingType(transitionIndex) {
        var _this2 = this;

        var model = this.modelFor('manage-resources.booking-type.edit');
        var controller = this.controllerFor('manage-resources.booking-type.edit');
        var permissions = controller.get('permissions');
        var permissionsTypes = model.bookingType.get('permissionsKeys');

        controller.set('error', '');
        // Validate permissions
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, permissionsTypes);
        Object.keys(permissionFragments).forEach(function (type) {
          model.bookingType.get(type).setObjects(permissionFragments[type]);
        });

        // Validate name
        if (_ember['default'].isEmpty(model.bookingType.get('name').trim())) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.name_required').toString(), 0);
        }

        // Validate Resource Restrictions
        var resourceRestrictions = model.bookingType.get('resourceRestrictionRules').filter(function (r) {
          return r.get('resourceType');
        });
        if (!this._validateResourceRestrictionRules(resourceRestrictions)) return;
        model.bookingType.set('resourceRestrictionRules', resourceRestrictions);

        // Validate rules
        if (this.get('can').can('edit-rules booking-type', { model: controller.get('model.bookingType') })) {
          var rules = controller.get('model.bookingTypeRules');
          if (!this._validateRules(rules)) return;
          this.get('rulesProcessor').saveAllRules({ model: model.bookingType, rules: rules });
        }

        controller.set('isSaving', true);
        model.bookingType.save().then(function () {
          _this2.editSuccessFlash('booking-type');

          _this2.store.peekAll('resource-restriction-rule').filterBy('isNew').forEach(function (rule) {
            _this2.store.unloadRecord(rule);
          });

          if (transitionIndex) {
            return _this2.transitionTo('manage-resources.booking-type.index');
          } else {
            _this2.refresh();
          }
        })['catch'](function (jqXHR) {
          if (jqXHR && jqXHR.responseJSON) {
            controller.set('error', jqXHR.responseJSON);
          } else {
            _this2.editErrorFlash('booking-type');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate, userGroupId) {
        var _this3 = this;

        this.send('loading');
        var model = this.modelFor('manage-resources.booking-type.edit');
        return this.get('resourceBusyTimesFetcher').fetch({
          type: 'bookingType',
          id: model.bookingType.get('id'),
          startDate: startDate,
          endDate: endDate,
          userGroupId: userGroupId
        }).then(function (events) {
          model.events.clear();
          model.events.pushObjects(events);
        })['catch'](function (e) {
          LE.error({ location: 'fetchBusyTimes', error: e });
        })['finally'](function () {
          _this3.send('finished');
        });
      },

      fetchBusyPatterns: function fetchBusyPatterns(userGroupId) {
        var _this4 = this;

        this.send('loading');
        var model = this.modelFor('manage-resources.booking-type.edit');

        return this.get('resourceBusyPatternsFetcher').fetch({
          type: 'bookingType',
          id: model.bookingType.get('id'),
          userGroupId: userGroupId
        }).then(function (events) {
          model.eventPatterns.clear();
          model.eventPatterns.pushObjects(events);
        })['catch'](function (e) {
          LE.error({ location: 'fetchBusyPatterns', error: e });
        })['finally'](function () {
          _this4.send('finished');
        });
      },

      refresUnavailabilityAfterModelSave: function refresUnavailabilityAfterModelSave() {
        this.send('reload', { specificDate: true, startDate: moment(this.controller.get('startDate')), endDate: moment(this.controller.get('endDate')) });
        this.send('reload', { specificDate: false });
      },

      reload: function reload(_ref) {
        var specificDate = _ref.specificDate;
        var startDate = _ref.startDate;
        var endDate = _ref.endDate;
        var userGroup = _ref.userGroup;

        this.modelFor('manage-resources.booking-type.edit').bookingType.reload();

        if (specificDate) {
          this.send('fetchBusyTimes', startDate, endDate, userGroup);
        } else {
          this.send('fetchBusyPatterns', userGroup);
        }
      }
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), 4);
        // const items = Array.from(document.querySelectorAll('.manageResources-itemForm-form-group'));
        // Ember.$(items[validate.index]).addClass('manageResources-itemForm-form-group--error');
        return false;
      }

      return true;
    },

    // Check for any invalid rules
    _validateRules: function _validateRules(rules) {

      var errors = [];
      rules.forEach(function (rule) {
        var properties = rule.properties;
        properties.forEach(function (property) {
          var valueType = property.valueType.toLowerCase();
          var value = property.value;
          if (valueType === 'bool') {
            return; // Don't check boolean props as they are always false (true/false)
          } else if (valueType === 'number') {
              if (value === undefined || !Number.isInteger(parseInt(value, 10)) || parseInt(value, 10) < 0) {
                errors.pushObject({ ruleId: rule.id, propertyName: property.name });
              }
            } else {
              if (_ember['default'].isEmpty(property.value) || !property.value) {
                errors.pushObject({ ruleId: rule.id, propertyName: property.name });
              }
            }
        });

        // Validate "Applies to" selection (Is any user group selected?)
        if (_ember['default'].isEmpty(rule.appliesTo)) errors.pushObject({ ruleId: rule.id, propertyName: 'applies_to' });

        // Validates "Action" selection ( Create / Update / Delete)
        if (!rule.runOnCreate && !rule.runOnUdate && !rule.runOnDelete) errors.pushObject({ ruleId: rule.id, propertyName: 'action' });

        // Validate Exemption User Groups
        if (rule.permissionGroupsExempt && _ember['default'].isEmpty(rule.exemptPermissionGroups)) {
          errors.push({ ruleId: rule.id, propertyName: 'exemptiongroup' });
        }
      });

      // If there are errors display an error message and highlight them
      if (!_ember['default'].isEmpty(errors)) {
        this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.error_saving').toString(), 5);
        _ember['default'].run.later(this, '_highlightRulesErrors', errors, 200);
        return false;
      }

      return true;
    },

    _validateResourceRestrictionRules: function _validateResourceRestrictionRules(resourceRestrictions) {
      if (_ember['default'].isEmpty(resourceRestrictions)) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_empty').toString(), 3);
      }

      if (_ember['default'].isEmpty(resourceRestrictions.filterBy('isPrimary', true))) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_no_primary').toString(), 3);
      }

      var invalidMinMax = resourceRestrictions.filter(function (r) {
        return parseInt(r.get('min')) > parseInt(r.get('max')) || parseInt(r.get('max')) <= 0;
      });

      if (!_ember['default'].isEmpty(invalidMinMax)) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_invalid_max').toString(), 3);
      }
      return true;
    },

    // Highlight fields which are invalid
    _highlightRulesErrors: function _highlightRulesErrors(errors) {
      var groupClass = 'manageResources-itemForm-form-group';
      var itemClass = 'manageResources-itemForm-form-item';

      errors.forEach(function (error) {
        var groupObject = '.' + groupClass + '[data-rule-id=' + error.ruleId + ']';
        var itemObject = '.' + itemClass + '[data-property-name="' + error.propertyName + '"]';
        _ember['default'].$(groupObject + ' ' + itemObject).addClass('rule-property-invalid');
      });
    },

    _displayError: function _displayError(error, tabIndex) {
      var controller = this.controllerFor('manage-resources.booking-type.edit');
      controller.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    }
  });
});