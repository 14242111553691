define('scientia-resourcebooker/pods/manage-resources/resource-group/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/availability-form'], function (exports, _ember, _scientiaResourcebookerMixinsAvailabilityForm) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var TABS = { DESCRIPTION: 0, RESOURCES: 1, PERMISSIONS: 2 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsAvailabilityForm['default'], {
    queryParams: {
      selectedTab: 'section'
    },
    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),
    permissionDeprecatedChecker: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    selectedTab: 0,

    isSaving: false,
    error: '',

    canEditAll: _ember['default'].computed('model.resourceGroup', 'model.resourceGroup.id', function () {
      return this.get('can').can('edit-all resource-group', { model: this.get('model.resourceGroup') });
    }),
    canEditDescription: _ember['default'].computed('model.resourceGroup', 'model.resourceGroup.id', function () {
      return this.get('can').can('edit-description resource-group', { model: this.get('model.resourceGroup') });
    }),
    canEditResources: _ember['default'].computed('model.resourceGroup', 'model.resourceGroup.id', function () {
      return this.get('can').can('edit-resources resource-group', { model: this.get('model.resourceGroup') });
    }),
    canEditPermissions: _ember['default'].computed('model.resourceGroup', 'model.resourceGroup.id', function () {
      return this.get('can').can('edit-permissions resource-group', { model: this.get('model.resourceGroup') });
    }),

    permissionsTypesLabelOverrides: { 'manage-bookings': 'resources-manage-bookings' },

    /**
     * Returns all the permission types available which will be displayed in the dropdown
     */
    permissionsTypes: _ember['default'].computed('canEditAll', 'canEditPermissions', function () {
      var _permissionTypes,
          _this = this;

      var canEditPermissions = this.get('canEditPermissions');
      var canEditAll = this.get('canEditAll');

      var toObject = function toObject(value, disabled) {
        var deprecated = _this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource-group', name: value });
        return { value: value, disabled: disabled, deprecated: deprecated };
      };

      var initialPermissions = ['read', 'write', 'separator', 'show', 'request', 'book'];
      var editPermissions = ['separator', 'edit-description', 'edit-resources', 'edit-permissions', 'edit-all', 'separator'];
      var resourcePermissions = ['view-calendars', 'manage-bookings', 'resources-edit-description', 'resources-edit-custom-fields', 'resources-edit-availability', 'resources-edit-permissions', 'resources-edit-all'];

      var permissionTypes = [];

      // Map them from string to a value object
      initialPermissions = initialPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      editPermissions = editPermissions.map(function (p) {
        return toObject(p, !canEditAll);
      });
      resourcePermissions = resourcePermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });

      // Shove editPermissions into previous array and return them all
      (_permissionTypes = permissionTypes).push.apply(_permissionTypes, _toConsumableArray(initialPermissions).concat(_toConsumableArray(editPermissions), _toConsumableArray(resourcePermissions)));

      // Write permission should also be disabled if !canEditAll
      permissionTypes.find(function (p) {
        return p.value === 'write';
      }).disabled = !canEditAll;

      // Strip out deprecated properties if hidePermissionVersion3 is set
      if (this.get('systemSettings').getSetting('hidePermissionsVersion3')) {
        permissionTypes = permissionTypes.filter(function (perm) {
          return !_this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource-group', name: perm.value });
        });

        // If after removing deprecated properties the first property is a separator, then remove it
        if (permissionTypes.get('firstObject.value') === 'separator') permissionTypes.shift();
      }
      return permissionTypes;
    }),

    permissions: _ember['default'].computed('model.resourceGroup.readPermissionGroups', 'model.resourceGroup.writePermissionGroups', 'model.resourceGroup.showPermissionGroups', 'model.resourceGroup.requestPermissionGroups', 'model.resourceGroup.bookPermissionGroups', 'model.resourceGroup.viewCalendarsPermissionGroups', 'model.resourceGroup.manageBookingsPermissionGroups', 'model.resourceGroup.editAllPermissionGroups', 'model.resourceGroup.editDescriptionPermissionGroups', 'model.resourceGroup.editPermissionsPermissionGroups', 'model.resourceGroup.editResourcesPermissionGroups', 'model.resourceGroup.editResourcesPermissionGroups', 'model.resourceGroup.resourcesEditAllPermissionGroups', 'model.resourceGroup.resourcesEditDescriptionPermissionGroups', 'model.resourceGroup.resourcesEditCustomFieldsPermissionGroups', 'model.resourceGroup.resourcesEditAvailabilityPermissionGroups', 'model.resourceGroup.resourcesEditPermissionsPermissionGroups', function () {

      var model = this.get('model.resourceGroup');
      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(model);
      return fragments;
    }),

    actions: {
      save: function save() {
        var transitionIndex = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var model = this.get('model').resourceGroup;

        this.set('error', '');
        var modelName = model.get('name') || '';
        if (_ember['default'].isEmpty(modelName.trim())) {
          return this._displayError(this.get('i18n').t('error_messages.resourcegroup_noname').toString(), TABS.DESCRIPTION);
        }

        // Validate permissions
        var permissions = this.get('permissions');
        var permissionsKeys = this.get('model.resourceGroup.permissionsKeys');
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, permissionsKeys);
        Object.keys(permissionFragments).forEach(function (type) {
          model.get(type).setObjects(permissionFragments[type]);
        });

        this.send('updateResource', transitionIndex);
      },

      rollbackGroup: function rollbackGroup(group) {
        group.rollbackAttributes();
      },

      addUserGroup: function addUserGroup(resource) {
        this.set('addUserGroup', resource);
      }
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        return this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), TABS.PERMISSIONS);
      }

      return true;
    },

    _displayError: function _displayError(error, tabIndex) {
      this.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource.new.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resourceType.id')
      }, {
        label: this.get('model.resourceGroup.name'),
        linkable: false
      }];
    }).property('model.resourceGroup.name', 'model.resourceGroup.id', 'model.resourceType.name', 'model.resourceType.id', 'i18n.locale')

  });
});