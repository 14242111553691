define('scientia-resourcebooker/models/booking-type', ['exports', 'ember-data', 'model-fragments/attributes', 'ember'], function (exports, _emberData, _modelFragmentsAttributes, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    description: attr('string'),
    imageUri: attr('string'),
    bookingForm: attr('raw'),

    canEdit: attr('boolean'),
    canEditAll: attr('boolean'),
    canEditStatus: attr('boolean'),
    canEditAvailability: attr('boolean'),
    canEditResourceRestrictions: attr('boolean'),
    canEditPermissions: attr('boolean'),
    canEditRules: attr('boolean'),

    mode: attr('string'),
    isDisabled: _ember['default'].computed.equal('mode', '1'),

    canBookAsSomeoneElse: attr('boolean'),
    canBookOnBehalfOf: attr('boolean'),
    canBookRecurring: attr('boolean'),
    hasExternallyManagedResources: attr('boolean'),

    alertType: attr('number', { 'default': 0 }),
    infoType: attr('number', { 'default': 0 }),
    alertMessage: attr('string'),
    infoMessage: attr('string'),
    tileMessage: attr('string'),
    alertEnabled: attr('boolean'),
    infoEnabled: attr('boolean'),

    helpEnabled: attr('boolean'),
    helpMessage: attr('string'),

    readPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('read-permission-group'),
    basePermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('base-permission-group'),
    boboPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('bobo-permission-group'),
    bookPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('book-permission-group'),
    requestPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('request-permission-group'),
    recurringPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('recurring-permission-group'),

    editAllPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-all-permission-group'),
    editStatusPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-status-permission-group'),
    editAvailabilityPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-availability-permission-group'),
    editResourceRestrictionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-resource-restrictions-permission-group'),
    editPermissionsPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-permissions-permission-group'),
    editRulesPermissionGroups: (0, _modelFragmentsAttributes.fragmentArray)('edit-rules-permission-group'),

    resourceRestrictionRules: hasMany('resource-restriction-rule', { async: false }),

    permissionsKeys: ['read', 'book', 'request', 'recurring', 'base', 'bobo', 'edit-all', 'edit-status', 'edit-availability', 'edit-resource-restrictions', 'edit-permissions', 'edit-rules']
  });
});