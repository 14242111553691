define('scientia-resourcebooker/pods/logged-in/notifications/route', ['exports', 'ember', 'ember-can', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _emberCan, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_emberCan.CanMixin, _scientiaResourcebookerMixinsAppInsights['default'], {

    notificationsFetcher: _ember['default'].inject.service(),
    notificationsCountFetcher: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),

    queryParams: {
      page: { replace: true, refreshModel: true },
      perPage: { replace: true, refreshModel: true },
      selectedTab: { replace: true, refreshModel: true },
      serializedSearch: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.logged_in.notifications.page_title');
    },

    beforeModel: function beforeModel() {
      if (!this.get('can').can('access notifications')) {
        this.transitionTo('/');
      }

      this.get('customEvents').on('notificationCountUpdated', this, '_onNotificationCountUpdatedEvent');
    },

    model: function model(params) {
      var controller = this.controllerFor('logged-in.notifications');

      var results = undefined;

      if (controller.get('hasSearchOptions')) {
        return this.get('notificationsFetcher').search({ page: params.page, perPage: params.perPage, filters: controller.get('searchOptionsSelected') });
      } else {
        results = this.get('notificationsFetcher').fetch({ page: params.page, perPage: params.perPage, notificationType: params.selectedTab });
      }

      return results;
    },

    afterModel: function afterModel() {
      var controller = this.controllerFor('logged-in.notifications');
      controller.set('isLoadingNotifications', false);

      var shouldFetch = this.get('notificationsCountFetcher').shouldFetch();
      if (shouldFetch) {
        this.get('notificationsCountFetcher').updateNotificationCount();
      }
    },

    _onNotificationCountUpdatedEvent: function _onNotificationCountUpdatedEvent(countResults) {

      // Now check if we actually should show them on the notification tabs
      var shouldShowInTabs = this.get('notificationsCountFetcher').shouldFetchForNotificationsPage();

      if (shouldShowInTabs) {
        this.controller.setProperties({
          toApproveCount: countResults.toApprove,
          moreInfoActionCount: countResults.moreInfo
        });
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          page: 1,
          perPage: 10,
          searchQuery: ''
        });
      }
    },

    actions: {
      reload: function reload() {
        this.refresh();
      },

      willTransition: function willTransition() {
        this.get('notificationsCountFetcher').isLeavingNotificationPage();
        return true;
      }
    }
  });
});