define('scientia-resourcebooker/pods/manage-resources/resource-group/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource_group.edit.page_title') + ' — ' + model.resourceGroup.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    model: function model(params, transition) {
      var resourceTypeId = transition.params['manage-resources.resource-group'].resourceTypeId;

      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: resourceTypeId, adminPermissionsOnly: true }),
        resourceGroup: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resourceGroup',
          parentId: resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        eventPatterns: []
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          error: ''
        });
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.resource-group.edit');
        if (model.resourceGroup.get('hasDirtyAttributes')) model.resourceGroup.rollbackAttributes();
      },

      updateResource: function updateResource(transitionIndex) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.resource-group.edit');
        var model = controller.get('model.resourceGroup');

        controller.set('isSaving', true);
        model.save().then(function () {
          _this.editSuccessFlash('resource-group');
          if (transitionIndex) {
            return _this.transitionTo('manage-resources.resource-type.show', model.get('resourceTypeId'));
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('resource-group');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate, userGroupId) {
        var model = this.modelFor('manage-resources.resource-group.edit');

        return this.get('resourceBusyTimesFetcher').fetch({ type: 'resourceGroup', id: model.resourceGroup.get('id'), parentId: model.resourceGroup.get('resourceTypeId'), startDate: startDate, endDate: endDate, userGroupId: userGroupId }).then(function (events) {
          model.events.clear();
          model.events.pushObjects(events);
        });
      },

      fetchBusyPatterns: function fetchBusyPatterns(userGroupId) {
        var model = this.modelFor('manage-resources.resource-group.edit');

        return this.get('resourceBusyPatternsFetcher').fetch({ type: 'resourceGroup', id: model.resourceGroup.get('id'), parentId: model.resourceGroup.get('resourceTypeId'), userGroupId: userGroupId }).then(function (events) {
          model.eventPatterns.clear();
          model.eventPatterns.pushObjects(events);
        });
      },

      reload: function reload() {
        var model = this.modelFor('manage-resources.resource.edit');

        this.get('nestedResourceStore').fetch({
          type: 'resourceType.resourceGroup',
          parentId: model.resourceGroup.get('resourceTypeId'),
          id: model.resourceGroup.get('id')
        });
      }
    }
  });
});