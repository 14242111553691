define('scientia-resourcebooker/services/notifications-templates-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    fetch: function fetch() {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS_TEMPLATES.FEED).then(this._normalizeResults)['catch'](function () {
        return [];
      });
    },

    save: function save(template) {
      var payload = this._serializePayload(template);
      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS_TEMPLATES.SINGLE.loc(payload.id), payload);
    },

    _normalizeResults: function _normalizeResults(results) {
      results = results.map(function (result) {
        if (!result.options) {
          result.options = ['booking-title', 'booker-name', 'booking-from', 'booking-to'];
        }

        result.ccEmailList = !_ember['default'].isEmpty(result.ccEmailList) ? result.ccEmailList.join(',') : '';
        result.bcEmailList = !_ember['default'].isEmpty(result.bccEmailList) ? result.bccEmailList.join(',') : '';

        return _ember['default'].Object.create(result);
      });

      return results;
    },

    _serializePayload: function _serializePayload(template) {
      var cc = !_ember['default'].isArray(template.ccEmailList) ? template.ccEmailList.split(',') : template.ccEmailList;
      var bcc = !_ember['default'].isArray(template.bccEmailList) ? template.bccEmailList.split(',') : template.bccEmailList;
      template.set('ccEmailList', cc);
      template.set('bccEmailList', bcc);
      return template;
    }

  });
});