define('scientia-resourcebooker/adapters/resource-type', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    urlForQueryRecord: function urlForQueryRecord(query /*, modelName*/) {

      if (!query || !query.id) return this._super.apply(this, arguments);

      var url = undefined;
      // Append adminPermissionsOnly to queryParams
      if (query.id) {
        url = this.buildURL('resource-type', query.id, null, 'findRecord', query);
        delete query.id;
      }

      return url;
    }
  });
});