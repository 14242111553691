define('scientia-resourcebooker/services/permissions-processor', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    toModels: function toModels(permissions) {
      var _this = this;

      var permissionTypes = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var permissionObjects = permissions.reduce(function (memo, permission) {
        var permissionType = permission.get('permissionType');
        var permissionKey = permissionType.camelize() + 'PermissionGroups';
        if (!memo[permissionKey]) {
          memo[permissionKey] = [];
        }

        var permissionModel = undefined;
        if (permissionType === 'request') {
          permissionModel = _this.requestPermissionToModel(permission);
        } else {
          permissionModel = _this.namedObjectPermissionToModel(permission);
        }

        memo[permissionKey].push(permissionModel);

        return memo;
      }, []);

      permissionTypes.forEach(function (type) {
        var permissionKey = type.camelize() + 'PermissionGroups';
        if (!(permissionKey in permissionObjects)) {
          permissionObjects[permissionKey] = [];
        }
      });

      return permissionObjects;
    },

    modelPermissionGroupsToGenericPermissionGroup: function modelPermissionGroupsToGenericPermissionGroup(model) {
      var _this2 = this;

      var permissions = [];
      model.eachAttribute(function (key) {
        if (key.indexOf('PermissionGroups') !== -1) {
          var _ret = (function () {
            var permissionType = key.dasherize().split('-permission-groups')[0]; // get permissionType [read]-permission-groups]

            var modelPermissions = model.get(key);
            if (_ember['default'].isEmpty(modelPermissions)) return {
                v: undefined
              };

            modelPermissions.forEach(function (permission) {
              var fragment = _this2.get('store').createFragment('permission-group', { permissionType: permissionType, permissionValue: permission });
              permissions.push(fragment);
            });
          })();

          if (typeof _ret === 'object') return _ret.v;
        }
      });

      return permissions;
    },

    namedObjectPermissionToModel: function namedObjectPermissionToModel(permission) {
      var permissionType = permission.get('permissionType');
      var fragmentName = permissionType + '-permission-group';
      var fragment = this.get('store').createFragment(fragmentName, { identity: permission.get('permissionValue.identity'), name: permission.get('permissionValue.name') });
      return fragment;
    },

    requestPermissionToModel: function requestPermissionToModel(permission) {
      var approvers = permission.get('permissionValue.approvers') || [];
      return this.get('store').createFragment('request-permission-group', {
        permissionTarget: {
          identity: permission.get('permissionValue.permissionTarget.identity'),
          name: permission.get('permissionValue.permissionTarget.name')
        },
        approvers: approvers.map(function (app) {
          return { identity: app.identity, name: app.name };
        })
      });
    }

  });
});