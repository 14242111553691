define("scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "manageResources-customFieldItem-valueChoices-itemEdit");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "button button--link button--icon");
          var el3 = dom.createTextNode("✕");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "choice.name", ["loc", [null, [4, 20], [4, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-input manageResources-customFieldItem-valueChoices-input"], ["loc", [null, [4, 6], [4, 103]]], 0, 0], ["element", "action", ["remove", ["get", "choice", ["loc", [null, [5, 32], [5, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 14], [5, 40]]], 0, 0]],
        locals: ["choice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/custom-field-item/choices/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "type", "button");
        dom.setAttribute(el1, "class", "button button--white button--small");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element2, 'disabled');
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createMorphAt(element2, 0, 0);
        return morphs;
      },
      statements: [["block", "each", [["get", "choices", ["loc", [null, [2, 10], [2, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [7, 11]]]], ["attribute", "disabled", ["get", "hasEmptyChoices", ["loc", [null, [10, 36], [10, 51]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["add"], [], ["loc", [null, [10, 8], [10, 24]]], 0, 0], ["inline", "t", ["components.manage_resources.custom_field_item.add"], [], ["loc", [null, [10, 111], [10, 168]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});