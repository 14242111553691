define("scientia-resourcebooker/pods/components/container-sidebar/user/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/container-sidebar/user/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "src", "avatar-default.png");
        dom.setAttribute(el1, "class", "sidebarUser-image");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "sidebarUser-name");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "sidebarUser-title");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "button at-sidebarUser sidebarUser-profile");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(fragment, [6]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'title');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[3] = dom.createElementMorph(element1);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "title", ["get", "user.name", ["loc", [null, [2, 14], [2, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "user.name", ["loc", [null, [2, 51], [2, 64]]], 0, 0, 0, 0], ["content", "user.institutionName", ["loc", [null, [3, 32], [3, 56]]], 0, 0, 0, 0], ["element", "action", ["logout"], [], ["loc", [null, [5, 56], [5, 75]]], 0, 0], ["inline", "t", ["components.container_sidebar.user.logout"], [], ["loc", [null, [6, 2], [6, 50]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});