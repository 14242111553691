define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/description-custom-fields'], function (exports, _ember, _scientiaResourcebookerMixinsDescriptionCustomFields) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsDescriptionCustomFields['default'], {
    // Values from the parent component
    // We don’t want to change these values *ever*
    // They’re only passed so we can validate our own values
    title: '',
    name: '',
    description: '',
    startDay: null,
    endDay: null,
    fromTime: '',
    toTime: '',
    isBaseBooking: false,

    // Set values back to default when the popup is closed
    // willDestroyElement() {

    //   let defaults = this.get('fieldsDefaultValues');

    //   // Loop through each field
    //   this.get('fields').forEach((field, i) => {

    //     // Set its value to default
    //     field.set('value', defaults[i]);

    //     // If the field is of type select-choices-explanation then we should also
    //     // empty the explanation property which is a textarea input
    //     if (field.get('valueType') === 'select-choices-explanation') {
    //       field.set('explanation', '');
    //     }
    //   });

    // },

    fieldsValueDidChange: (function () {
      var _this = this;

      this.manageFundingFieldVisibility();

      var validationResult = this.validateCustomFields();
      this.set('isValid', validationResult.isValid);
      if (validationResult.message) this.set('errorMessage', validationResult.message);

      _ember['default'].run.later(function () {
        _this.sendAction('onValueChange', _this.get('value'), _this.get('isValid'), _this.get('errorMessage'));
      }, 200);
    }).observes('fields.@each.value', 'fromTime', 'toTime', 'description', 'isBaseBooking', 'bookAsSomeoneElseOwner', 'bookAsSomeoneElseEmail', 'bookAsSomeoneElseName').on('init'),

    manageFundingFieldVisibility: function manageFundingFieldVisibility() {
      var fields = this.get('fields');
      var fundingField = fields.findBy('label', 'organization_funding');
      var fundingDetailsField = fields.findBy('label', 'organization_funding_details');
      var fundingIsPositive = fundingField.get('value') === 'yes';

      fundingDetailsField.set('isVisible', fundingIsPositive);

      if (!fundingIsPositive) fundingDetailsField.set('value', '');
    },

    validateCustomFields: function validateCustomFields() {
      var isValid = true;
      var message = '';

      var fields = this.get('fields');

      var fundingFieldsValidationResult = this.validateFundingFields(fields);
      if (!fundingFieldsValidationResult.isValid) return fundingFieldsValidationResult;

      var publishedTimesValidationResult = this.validatePublishedTimes(fields);
      if (!publishedTimesValidationResult.isValid) return publishedTimesValidationResult;

      return { isValid: isValid, message: message };
    },

    validateFundingFields: function validateFundingFields(fields) {
      var isValid = true;
      var message = '';

      var fundingField = fields.findBy('label', 'organization_funding');
      var fundingDetailsField = fields.findBy('label', 'organization_funding_details');
      var fundingIsPositive = fundingField.get('value') === 'yes';

      if (fundingIsPositive && fundingDetailsField.get('value').trim() === '') {
        isValid = false;
        message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.funding_details_required').toString();
        return { isValid: isValid, message: message };
      }

      return { isValid: isValid, message: message };
    },

    // jshint maxcomplexity:16
    validatePublishedTimes: function validatePublishedTimes(fields) {
      var isValid = true;
      var message = '';

      var publishedStartField = fields.findBy('label', 'published_start_time');
      var publishedEndField = fields.findBy('label', 'published_end_time');

      var _ref = publishedStartField.value ? publishedStartField.value.split(':') : [];

      var _ref2 = _slicedToArray(_ref, 2);

      var publishStartHours = _ref2[0];
      var publishStartMinutes = _ref2[1];

      var _ref3 = publishedEndField.value ? publishedEndField.value.split(':') : [];

      var _ref32 = _slicedToArray(_ref3, 2);

      var publishEndHours = _ref32[0];
      var publishEndMinutes = _ref32[1];

      publishStartHours = parseInt(publishStartHours, 10);
      publishStartMinutes = parseInt(publishStartMinutes, 10);
      publishEndHours = parseInt(publishEndHours, 10);
      publishEndMinutes = parseInt(publishEndMinutes, 10);

      var isPublishedStartSelected = Number.isInteger(publishStartHours) || Number.isInteger(publishStartMinutes);
      var isPublishedStartValid = Number.isInteger(publishStartHours) && Number.isInteger(publishStartMinutes);

      var isPublishedEndSelected = Number.isInteger(publishEndHours) || Number.isInteger(publishEndMinutes);
      var isPublishedEndValid = Number.isInteger(publishEndHours) && Number.isInteger(publishEndMinutes);

      // Either the minutes or the hours of Start Time are not selected
      if (isPublishedStartSelected && !isPublishedStartValid) {
        isValid = false;
        message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.published_start_time_is_invalid').toString();
        return { isValid: isValid, message: message };
      }

      // Either the minutes or the hours of End Time are not selected
      if (isPublishedEndSelected && !isPublishedEndValid) {
        isValid = false;
        message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.published_end_time_is_invalid').toString();
        return { isValid: isValid, message: message };
      }

      // If start is valid check that the publish start happens after the From time (booking start time)
      if (isPublishedStartValid) {
        var _get$split = this.get('fromTime').split(':');

        var _get$split2 = _slicedToArray(_get$split, 2);

        var fromTimeHours = _get$split2[0];
        var fromTimeMinutes = _get$split2[1];

        fromTimeHours = parseInt(fromTimeHours, 10);
        fromTimeMinutes = parseInt(fromTimeMinutes, 10);

        if (publishStartHours < fromTimeHours || publishStartHours === fromTimeHours && publishStartMinutes < fromTimeMinutes) {
          isValid = false;
          message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.publish_start_greater_than_from_time').toString();
          return { isValid: isValid, message: message };
        }
      }

      // If end is valid check that the publish end happens before the to time (booking end time)
      if (isPublishedEndValid) {
        var _get$split3 = this.get('toTime').split(':');

        var _get$split32 = _slicedToArray(_get$split3, 2);

        var toTimeHours = _get$split32[0];
        var toTimeMinutes = _get$split32[1];

        toTimeHours = parseInt(toTimeHours, 10);
        toTimeMinutes = parseInt(toTimeMinutes, 10);

        if (publishEndHours > toTimeHours || publishEndHours === toTimeHours && publishEndMinutes > toTimeMinutes) {
          isValid = false;
          message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.publish_end_lower_than_to_time').toString();
          return { isValid: isValid, message: message };
        }
      }

      // Finally if both are valid make sure end time is happening after the start time
      if (isPublishedStartValid && isPublishedEndValid) {
        if (publishStartHours > publishEndHours || publishStartHours === publishEndHours && publishStartMinutes > publishEndMinutes) {
          isValid = false;
          message = this.get('i18n').t('components.booking_request_form.description_custom_fields.errors.publish_end_lower_than_to_publish_start').toString();
          return { isValid: isValid, message: message };
        }
      }

      return { isValid: isValid, message: message };
    },

    actions: {
      clearPublishedTime: function clearPublishedTime(label) {
        var fields = this.get('fields');
        var field = fields.findBy('label', label);
        field.set('value', null);
      }
    }
  });
});