define('scientia-resourcebooker/pods/logged-in/booking-type/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/mixins/resource-filters'], function (exports, _ember, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerMixinsResourceFilters) {

  /* This controller is used for the page which displays resources of
  the booking type and the resources of resource groups
  logged-in.booking-type.show & logged-in/resource-group.show */
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], _scientiaResourcebookerMixinsResourceFilters['default'], {

    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    queryParams: ['query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'page', { 'resourceGroupId': 'group' }],

    shouldShowPaging: _ember['default'].computed.gt('model.resourcesMeta.totalPages', 1),

    boundQuery: _ember['default'].computed.oneWay('query'),

    query: '',
    availableNow: false,
    spanMultipleDays: false,
    firstDay: null,
    lastDay: null,
    fromTime: '',
    toTime: '',
    duration: null,
    filters: null,
    page: 1,
    resourceGroupId: '',

    isResourceGroup: _ember['default'].computed.bool('resourceGroupId'),
    isLoadingMore: false,
    hasNoMoreResults: false,
    isSearching: false,

    // Holds the booking type Alert and Info messages which will be set when the booking type is loaded
    bookingTypeAlert: null,
    bookingTypeAlertIsDismissed: false,

    bookingTypeInfo: null,
    bookingTypeInfoIsDismissed: false,

    bookingTypeAlertIsPopup: _ember['default'].computed.equal('bookingTypeAlert.type', 1),
    bookingTypeInfoIsPopup: _ember['default'].computed.equal('bookingTypeInfo.type', 1),

    bookingTypeAlertIsBanner: _ember['default'].computed.equal('bookingTypeAlert.type', 0),
    bookingTypeInfoIsBanner: _ember['default'].computed.equal('bookingTypeInfo.type', 0),

    showBookingTypeHelpMessageModal: false,
    // disableRecurring: Ember.computed.or('systemSettings.options.disableRecurring', 'model.bookingType.canBookRecurring'),

    // Pagination should be reset when the query params change or we might end up
    //in an invalid page with no results
    resetPaginationOnParamsChange: (function () {
      _ember['default'].run.once(this, 'set', 'page', 1);
    }).observes('query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration'),

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.get('appInsights').trackEvent('search-resource', {
        query: this.get('boundQuery'),
        bookingTypeName: this.get('model.bookingType.name'),
        bookingTypeId: this.get('model.bookingType.id'),
        isResourceGroup: this.get('isResourceGroup'),
        resourceGroupId: this.get('resourceGroupId')
      });

      this.set('query', this.get('boundQuery'));
    },

    actions: {
      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 1000, false);
      },

      loadMore: function loadMore() {
        this.send('loadMoreResources');
        this.get('appInsights').trackEvent('load-more', {
          bookingTypeName: this.get('model.bookingType.name'),
          bookingTypeId: this.get('model.bookingType.id'),
          isResourceGroup: this.get('isResourceGroup'),
          resourceGroupId: this.get('resourceGroupId'),
          resourceGroupName: this.get('model.resourceGroup') ? this.get('model.resourceGroup.name') : ''
        });
      },

      onBookingTypeHelpTriggerClick: function onBookingTypeHelpTriggerClick() {
        this.get('appInsights').trackEvent('template-help-click', {
          bookingTypeName: this.get('model.bookingType.name'),
          bookingTypeId: this.get('model.bookingType.id'),
          isResourceGroup: this.get('isResourceGroup'),
          resourceGroupId: this.get('resourceGroupId'),
          resourceGroupName: this.get('model.resourceGroup') ? this.get('model.resourceGroup.name') : ''
        });
        this.set('showBookingTypeHelpMessageModal', true);
      },

      onInfoAlertDismissal: function onInfoAlertDismissal(type) {
        var types = ['info', 'alert'];

        if (type === types[0]) {

          this.setProperties({
            bookingTypeInfo: null,
            bookingTypeInfoIsDismissed: true
          });
        } else if (type === types[1]) {

          this.setProperties({
            bookingTypeAlert: null,
            bookingTypeAlertIsDismissed: true
          });
        }
      },

      onLogFilter: function onLogFilter(filterData) {

        // Don't log if level 1 or lower
        if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) <= 1) {
          return;
        }

        filterData.bookingType = this.get('model.bookingType.name');
        filterData.bookingTypeId = this.get('model.bookingType.id');

        this.get('appInsights').trackEvent('resource-filter', filterData);
      },

      leaveResourceGroup: function leaveResourceGroup() {
        this.transitionToRoute('logged-in.booking-type.show', this.get('model.bookingType.id'));
      }

    },

    // Seeing this controller is used for 2 routes we need to check
    // if we have a resourceGroup to know if we are in the resource group route or not
    breadCrumbs: (function () {
      var bread = [{
        label: this.get('i18n').t('pods.logged_in.booking_type.show.bookings'),
        path: 'logged-in.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        path: 'logged-in.booking-type.show',
        model: this.get('model.bookingType.id'),
        linkable: !!this.get('model.resourceGroup') // Only make it clickable if there is a resourceGroup
      }];

      if (this.get('model.resourceGroup')) {
        bread.pushObject({
          label: this.get('model.resourceGroup.name'),
          linkable: false
        });
      }

      return bread;
    }).property('model.bookingType.name', 'model.resourceGroup.name', 'i18n.locale')

  });
});