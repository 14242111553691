define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/time-picker/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':bookingRequestForm-label', 'inline:bookingRequestForm-label--inlineItems', 'center:bookingRequestForm-label--centerItems'],

    label: (function () {
      return 'components.booking_request_form.description_custom_fields.' + this.get('field').label;
    }).property('field'),

    actions: {
      clearPublishedTime: function clearPublishedTime() {
        this.sendAction('clearPublishedTime', this.get('field.label'));
      }
    }
  });
});