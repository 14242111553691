define('scientia-resourcebooker/services/-gestures', ['exports', 'ember', 'scientia-resourcebooker/config/environment', 'ember-gestures/services/-gestures'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment, _emberGesturesServicesGestures) {

  var merge = _ember['default'].assign || _ember['default'].merge;

  var gestures = merge({}, {
    useCapture: false
  });
  gestures = merge(gestures, _scientiaResourcebookerConfigEnvironment['default'].gestures);

  exports['default'] = _emberGesturesServicesGestures['default'].extend({
    useCapture: gestures.useCapture
  });
});