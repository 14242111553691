define('scientia-resourcebooker/pods/components/manage-resources/remove-unavailability-weekday-slot/component', ['exports', 'ember'], function (exports, _ember) {

  var SECONDS_IN_DAY = 86400;

  exports['default'] = _ember['default'].Component.extend({
    weekdayIndex: null,
    weekdayFromTime: null,
    weekdayToTime: null,
    userGroup: null,
    isSaving: false,

    formattedDate: (function () {
      if (this.get('weekdayIndex') === null) return;

      return moment().weekday(this.get('weekdayIndex')).format('dddd');
    }).property('weekdayIndex'),

    formattedWeekdayFromTime: (function () {
      return this._formatWeekdayTime(this.get('weekdayFromTime'));
    }).property('weekdayFromTime'),

    formattedWeekdayToTime: (function () {
      return this._formatWeekdayTime(this.get('weekdayToTime'));
    }).property('weekdayToTime'),

    isAllDay: (function () {
      if (this.get('weekdayIndex') === null) return;

      return this.get('weekdayFromTime').asSeconds() + this.get('weekdayToTime').asSeconds() === SECONDS_IN_DAY;
    }).property('weekdayToTime', 'weekdayFromTime'),

    duration: (function () {
      return this.get('weekdayToTime').asMinutes() - this.get('weekdayFromTime').asMinutes();
    }).property('weekdayFromTime', 'weekdayToTime'),

    _formatWeekdayTime: function _formatWeekdayTime(duration) {
      if (duration === null) return;

      return moment().startOf('day').add(duration.asSeconds(), 'seconds').format(this.get('i18n').t('components.remove_unavailability_slot.time_format').toString());
    },

    actions: {
      submit: function submit() {
        this.set('isSaving', true);

        var startTime = moment().startOf('day').add(this.get('weekdayFromTime').asSeconds(), 'seconds');

        this.attrs.deleteUnavailabilityWeekdaySlot({
          weekdayIndex: this.get('weekdayIndex'),
          specificDate: startTime,
          duration: this.get('duration'),
          userGroup: this.get('userGroup'),
          dayOffset: this.get('dayOffset'),
          dstDifference: this.get('dstDifference')
        });
      }
    }
  });
});