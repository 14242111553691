define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 23,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.minimum", ["loc", [null, [14, 10], [14, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.min"], [], ["loc", [null, [15, 16], [15, 80]]], 0, 0], "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing at-bookingFormField-numeric-limitRange"], ["loc", [null, [12, 4], [16, 200]]], 0, 0], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.maximum", ["loc", [null, [20, 11], [20, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.max"], [], ["loc", [null, [21, 17], [21, 81]]], 0, 0], "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing"], ["loc", [null, [18, 4], [22, 162]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 24,
              "column": 96
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "input-error");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "minimum"], [], [], 0, 0], "message"], [], ["loc", [null, [24, 50], [24, 85]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["components.manage_resources.booking_form_field.numeric.max_decimal_places"], [], ["loc", [null, [46, 6], [46, 87]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.max_decimal_places_explanation"], [], ["loc", [null, [48, 8], [48, 101]]], 0, 0], ["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.maxDecimalPlaces", ["loc", [null, [56, 12], [56, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 9, "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.no_maximum"], [], ["loc", [null, [59, 18], [59, 89]]], 0, 0], "class", "form-input manageResources-itemForm-form-input form-input manageResources-itemForm-form-input--integer manageResources-itemForm-form-input--editing"], ["loc", [null, [54, 6], [60, 163]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-numeric/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned at-bookingFormField");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "manageResources-itemForm-form-label-help");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(fragment, [3]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element4, 1, 1);
        morphs[3] = dom.createMorphAt(element4, 3, 3);
        morphs[4] = dom.createMorphAt(element4, 5, 5);
        morphs[5] = dom.createMorphAt(element6, 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[8] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["components.manage_resources.booking_form_field.numeric.limit_range"], [], ["loc", [null, [3, 4], [3, 78]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.limit_range_explanation"], [], ["loc", [null, [5, 6], [5, 92]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.isMinMaxRestricted", ["loc", [null, [10, 26], [10, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.isMinMaxRestricted", ["loc", [null, [10, 73], [10, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 68], [10, 98]]], 0, 0]], [], ["loc", [null, [10, 60], [10, 99]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [10, 2], [10, 140]]], 0, 0], ["block", "if", [["get", "model.isMinMaxRestricted", ["loc", [null, [11, 8], [11, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 2], [23, 11]]]], ["block", "if", [["get", "didValidate", ["loc", [null, [24, 8], [24, 19]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [24, 2], [24, 103]]]], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.allow_decimal"], [], ["loc", [null, [32, 4], [32, 80]]], 0, 0], ["inline", "t", ["components.manage_resources.booking_form_field.numeric.allow_decimal_explanation"], [], ["loc", [null, [34, 6], [34, 94]]], 0, 0], ["inline", "toggle-switch", [], ["checked", ["subexpr", "@mut", [["get", "model.allowDecimal", ["loc", [null, [39, 26], [39, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "model.allowDecimal", ["loc", [null, [39, 67], [39, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 62], [39, 86]]], 0, 0]], [], ["loc", [null, [39, 54], [39, 87]]], 0, 0], "location", "at-bookingFormField-options"], ["loc", [null, [39, 2], [39, 128]]], 0, 0], ["block", "if", [["get", "model.allowDecimal", ["loc", [null, [43, 6], [43, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [43, 0], [63, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});