define('scientia-resourcebooker/abilities/booking-type', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    canList: (function () {
      return this.get('currentUser').isTemplateOwner();
    }).property(),

    canCreate: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canEditDescription: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canDelete: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    // Can edit/show pencil should always be true because whatever backend returns it's because they canhave any sort permissions
    canEdit: true,

    canEditAll: _ember['default'].computed.alias('model.canEditAll'),
    canEditAvailability: _ember['default'].computed.alias('model.canEditAvailability'),
    canEditPermissions: _ember['default'].computed.alias('model.canEditPermissions'),
    canEditResourceRestrictions: _ember['default'].computed.alias('model.canEditResourceRestrictions'),
    canEditRules: _ember['default'].computed.alias('model.canEditRules'),
    canEditStatus: _ember['default'].computed.alias('model.canEditStatus'),

    canRecurring: _ember['default'].computed('systemSettings.options.disableRecurring', 'model.canBookRecurring', function () {
      if (this.get('systemSettings.options.disableRecurring')) return false;
      return this.get('model.canBookRecurring');
    })
  });
});