define("scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 25
            },
            "end": {
              "line": 4,
              "column": 111
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 10
              },
              "end": {
                "line": 25,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-2", [], ["query", "externalDataSourceSearch", "value", ["subexpr", "@mut", [["get", "model.value", ["loc", [null, [14, 20], [14, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionIdPath", "value", "optionLabelPath", "text", "searchEnabled", true, "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [18, 27], [18, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 22], [18, 36]]], 0, 0], "typeaheadSearchingText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadSearchingText"], [], ["loc", [null, [19, 43], [19, 124]]], 0, 0]], [], ["loc", [null, [19, 37], [19, 125]]], 0, 0], "typeaheadNoMatchesText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadNoMatchesText"], [], ["loc", [null, [20, 43], [20, 124]]], 0, 0]], [], ["loc", [null, [20, 37], [20, 125]]], 0, 0], "typeaheadErrorText", ["subexpr", "to-s", [["subexpr", "t", ["components.booking_request_form.fields.single_choice.typeaheadErrorText"], [], ["loc", [null, [21, 39], [21, 116]]], 0, 0]], [], ["loc", [null, [21, 33], [21, 117]]], 0, 0], "optionDescriptionPath", "we-dont-want-description", "class", "at-bookingRequestForm-field-singleChoice-dropdown"], ["loc", [null, [12, 12], [24, 14]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 10
              },
              "end": {
                "line": 36,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-2", [], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [27, 22], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.value", ["loc", [null, [28, 20], [28, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionIdPath", "value", "optionLabelPath", "text", "searchEnabled", true, "enabled", ["subexpr", "not", [["get", "disabled", ["loc", [null, [32, 27], [32, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 22], [32, 36]]], 0, 0], "optionDescriptionPath", "we-dont-want-description", "class", "at-bookingRequestForm-field-singleChoice-dropdown"], ["loc", [null, [26, 12], [35, 14]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 10
              },
              "end": {
                "line": 40,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("           ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "class", "button button--link button--icon at-manageResources-bookingTypeForm-bookingFormClear");
            var el2 = dom.createTextNode("✕");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element3);
            return morphs;
          },
          statements: [["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "model.value", ["loc", [null, [39, 41], [39, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 36], [39, 53]]], 0, 0], null], [], ["loc", [null, [39, 28], [39, 59]]], 0, 0]], [], ["loc", [null, [39, 19], [39, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "select2-container--withClearButton");
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element4, 1, 1);
          morphs[1] = dom.createMorphAt(element4, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "isUsingExternalDataContent", ["loc", [null, [11, 16], [11, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [11, 10], [36, 17]]]], ["block", "if", [["get", "model.value", ["loc", [null, [38, 16], [38, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [38, 10], [40, 17]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 12
                  },
                  "end": {
                    "line": 53,
                    "column": 165
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList--clearValue");
                var el2 = dom.createTextNode("✕");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element0);
                return morphs;
              },
              statements: [["element", "action", ["updateRadioValue", null], [], ["loc", [null, [53, 122], [53, 156]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 8
                },
                "end": {
                  "line": 55,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("           ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("label");
              dom.setAttribute(el1, "class", "bookingRequestForm-field-singleChoice-itemList");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "bookingRequestForm-field-singleChoice-itemList-label");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element1, 1, 1);
              morphs[1] = dom.createMorphAt(element2, 0, 0);
              morphs[2] = dom.createMorphAt(element2, 2, 2);
              return morphs;
            },
            statements: [["inline", "radio-button", [], ["name", ["subexpr", "@mut", [["get", "model.identity", ["loc", [null, [47, 17], [47, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [48, 18], [48, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "@mut", [["get", "checkedValue", ["loc", [null, [49, 20], [49, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "changed", "updateRadioValue", "class", "form-input at-bookingRequestForm-field-singleChoice-itemList", "type", "radio"], ["loc", [null, [46, 12], [51, 95]]], 0, 0], ["content", "item.text", ["loc", [null, [52, 79], [52, 92]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "checkedValue", ["loc", [null, [53, 22], [53, 34]]], 0, 0, 0, 0], ["get", "item.value", ["loc", [null, [53, 35], [53, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 18], [53, 46]]], 0, 0]], [], 0, null, ["loc", [null, [53, 12], [53, 172]]]]],
            locals: ["item"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 6
              },
              "end": {
                "line": 56,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.items", ["loc", [null, [44, 16], [44, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [44, 8], [55, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 6
            },
            "end": {
              "line": 56,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.isRadio", ["loc", [null, [43, 16], [43, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 6], [56, 6]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 58,
              "column": 6
            },
            "end": {
              "line": 58,
              "column": 129
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [58, 89], [58, 122]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 6
            },
            "end": {
              "line": 61,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("         ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "input-error");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "externalDataError", ["loc", [null, [60, 35], [60, 56]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/single-choice/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [1]);
        var element6 = dom.childAt(element5, [1, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(element5, 3, 3);
        morphs[3] = dom.createMorphAt(element5, 5, 5);
        morphs[4] = dom.createMorphAt(element5, 7, 7);
        return morphs;
      },
      statements: [["content", "model.description", ["loc", [null, [4, 4], [4, 25]]], 0, 0, 0, 0], ["block", "if", [["get", "model.isMandatory", ["loc", [null, [4, 31], [4, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 25], [4, 118]]]], ["block", "if", [["subexpr", "or", [["get", "model.isDropdown", ["loc", [null, [8, 16], [8, 32]]], 0, 0, 0, 0], ["get", "model.isSearch", ["loc", [null, [8, 33], [8, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 12], [8, 48]]], 0, 0]], [], 1, 2, ["loc", [null, [8, 6], [56, 13]]]], ["block", "if", [["subexpr", "and", [["get", "didValidate", ["loc", [null, [58, 17], [58, 28]]], 0, 0, 0, 0], ["subexpr", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [58, 29], [58, 60]]], 0, 0]], [], ["loc", [null, [58, 12], [58, 61]]], 0, 0]], [], 3, null, ["loc", [null, [58, 6], [58, 136]]]], ["block", "if", [["get", "externalDataError", ["loc", [null, [59, 12], [59, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [59, 6], [61, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});