define('scientia-resourcebooker/services/resource-filterer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    bookingTypeResourceSearcher: _ember['default'].inject.service(),

    filterBy: function filterBy(_ref) {
      var bookingTypeId = _ref.bookingTypeId;
      var resourceTypeId = _ref.resourceTypeId;
      var type = _ref.type;
      var _ref$filters = _ref.filters;
      var filters = _ref$filters === undefined ? {} : _ref$filters;
      var recurrence = _ref.recurrence;
      var pageNumber = _ref.pageNumber;
      var itemsPerPage = _ref.itemsPerPage;
      var _ref$loadedIds = _ref.loadedIds;
      var loadedIds = _ref$loadedIds === undefined ? [] : _ref$loadedIds;

      filters.pageNumber = pageNumber || 1;
      filters.itemsPerPage = type === 'resource' ? itemsPerPage || 5 : 50;
      filters.recurrencePattern = recurrence;

      // filters are all the filters
      if (type === 'resourceGroup' && filters.properties) delete filters.properties;

      return this.get('bookingTypeResourceSearcher').search({
        filters: filters,
        type: type,
        loadedIds: loadedIds,
        parentId: bookingTypeId
      });
    }
  });
});