define("scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.booking_type_changed"], [], ["loc", [null, [2, 2], [2, 81]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 16,
                        "column": 14
                      },
                      "end": {
                        "line": 20,
                        "column": 14
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "bookingRequestEditRestrictionsModal-affectedResource-name");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element3, 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "bookingRequestOverview-resourcesList-resource-icon"], ["loc", [null, [17, 20], [17, 109]]], 0, 0], ["content", "r.name", ["loc", [null, [18, 90], [18, 100]]], 0, 0, 0, 0]],
                  locals: ["r"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 12
                    },
                    "end": {
                      "line": 22,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("ul");
                  dom.setAttribute(el1, "class", "bookingRequestEditRestrictionsModal-affectedResources");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("            ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "each", [["get", "restrictionOther.affectedResources", ["loc", [null, [16, 22], [16, 56]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [16, 14], [20, 23]]]]],
                locals: ["restrictionOther"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 8
                  },
                  "end": {
                    "line": 23,
                    "column": 8
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("           ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_multiple"], [], ["loc", [null, [12, 14], [12, 84]]], 0, 0], ["block", "with", [["subexpr", "find-by", ["messageKey", "RR_Other", ["get", "model.restrictions", ["loc", [null, [14, 53], [14, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 20], [14, 72]]], 0, 0]], [], 0, null, ["loc", [null, [14, 12], [22, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.8.3",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 31,
                            "column": 16
                          },
                          "end": {
                            "line": 35,
                            "column": 16
                          }
                        },
                        "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                      },
                      isEmpty: false,
                      arity: 1,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("span");
                        dom.setAttribute(el2, "class", "bookingRequestEditRestrictionsModal-affectedResource-name");
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                  ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element2 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createMorphAt(element2, 0, 0);
                        morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
                        return morphs;
                      },
                      statements: [["inline", "inline-svg", ["icons/tag.svg"], ["class", "bookingRequestOverview-resourcesList-resource-icon"], ["loc", [null, [32, 22], [32, 111]]], 0, 0], ["content", "r.name", ["loc", [null, [33, 92], [33, 102]]], 0, 0, 0, 0]],
                      locals: ["r"],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 29,
                          "column": 14
                        },
                        "end": {
                          "line": 37,
                          "column": 14
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("              ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("ul");
                      dom.setAttribute(el1, "class", "bookingRequestEditRestrictionsModal-affectedResources");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("              ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "each", [["get", "restriction.affectedResources", ["loc", [null, [31, 24], [31, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 16], [35, 25]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 28,
                        "column": 12
                      },
                      "end": {
                        "line": 38,
                        "column": 12
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "unless", [["subexpr", "eq", [["subexpr", "underscore", [["get", "restriction.messageKey", ["loc", [null, [29, 40], [29, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 28], [29, 63]]], 0, 0], "rr_max_limit_changed"], [], ["loc", [null, [29, 24], [29, 87]]], 0, 0]], [], 0, null, ["loc", [null, [29, 14], [37, 25]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 25,
                      "column": 10
                    },
                    "end": {
                      "line": 39,
                      "column": 10
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", [["subexpr", "concat-strings", ["components.booking_request_edit_restrictions_modal.", ["subexpr", "underscore", [["get", "restriction.messageKey", ["loc", [null, [26, 101], [26, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 89], [26, 124]]], 0, 0]], [], ["loc", [null, [26, 19], [26, 125]]], 0, 0]], [], ["loc", [null, [26, 15], [26, 127]]], 0, 0], ["block", "if", [["get", "restriction.affectedResources", ["loc", [null, [28, 18], [28, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [28, 12], [38, 19]]]]],
                locals: ["restriction"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 8
                  },
                  "end": {
                    "line": 41,
                    "column": 6
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "with", [["get", "model.restrictions.firstObject", ["loc", [null, [25, 18], [25, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 10], [39, 19]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 42,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "hasMultipleRestrictions", ["loc", [null, [11, 14], [11, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [11, 8], [41, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 3
              },
              "end": {
                "line": 45,
                "column": 3
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasRestrictions", ["loc", [null, [9, 10], [9, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 4], [42, 11]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 4
                },
                "end": {
                  "line": 49,
                  "column": 4
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_occurrence"], [], ["loc", [null, [48, 6], [48, 78]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 4
                },
                "end": {
                  "line": 51,
                  "column": 3
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.rr_primary"], [], ["loc", [null, [50, 5], [50, 74]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 3
              },
              "end": {
                "line": 52,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.isOccurrence", ["loc", [null, [47, 10], [47, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [47, 4], [51, 10]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "confirmModal-message bookingRequestEditRestrictionsModal-message");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "canEdit", ["loc", [null, [7, 9], [7, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [7, 3], [52, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 2
                },
                "end": {
                  "line": 66,
                  "column": 2
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["components.booking_request_edit_restrictions_modal.edit"], [], ["loc", [null, [65, 4], [65, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 0
              },
              "end": {
                "line": 68,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--lightGrey at-lf-dialog-footer");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["element", "action", ["cancel"], [], ["loc", [null, [60, 10], [60, 29]]], 0, 0], ["inline", "t", ["general.cancel"], [], ["loc", [null, [61, 4], [61, 26]]], 0, 0], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "buttonIsDisabled", ["loc", [null, [64, 26], [64, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "confirm", "type", "submit", "class", "button button--primary at-lf-dialog-footer"], 0, null, ["loc", [null, [64, 2], [66, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 0
              },
              "end": {
                "line": 72,
                "column": 0
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--lightGrey at-lf-dialog-footer");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["cancel"], [], ["loc", [null, [69, 12], [69, 31]]], 0, 0], ["inline", "t", ["general.ok"], [], ["loc", [null, [70, 4], [70, 22]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 0
            },
            "end": {
              "line": 73,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "canEdit", ["loc", [null, [58, 6], [58, 13]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [58, 0], [72, 7]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 74,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-edit-restrictions-modal/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["needsCloseButton", false], 0, null, ["loc", [null, [1, 0], [3, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [5, 0], [54, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [56, 0], [73, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});