define("scientia-resourcebooker/pods/components/manage-resources/rules-form/types/numberarray/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/rules-form/types/numberarray/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "time-input", [], ["minutes", ["subexpr", "@mut", [["get", "availableMinutes", ["loc", [null, [11, 8], [11, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "allAvailableMinutesForHours", ["subexpr", "@mut", [["get", "availableMinutes", ["loc", [null, [12, 28], [12, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "subtractHours", 1, "forceInt", true, "value", ["subexpr", "readonly", [["get", "computedValue", ["loc", [null, [15, 16], [15, 29]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 6], [15, 30]]], 0, 0], "class", "at-rulesForm-numberArray", "onChange", ["subexpr", "action", ["setValue"], [], ["loc", [null, [17, 9], [17, 28]]], 0, 0]], ["loc", [null, [10, 0], [18, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});