define("scientia-resourcebooker/pods/logged-in/notifications/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-accept-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showAcceptModal", ["loc", [null, [9, 25], [9, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 41]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 48]]], 0, 0], "isAccepting", ["subexpr", "@mut", [["get", "isAccepting", ["loc", [null, [10, 18], [10, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["confirmAcceptRequest"], [], ["loc", [null, [11, 14], [11, 45]]], 0, 0]], ["loc", [null, [7, 9], [11, 46]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [12, 37], [12, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 27], [12, 42]]], 0, 0]], [], ["loc", [null, [12, 19], [12, 43]]], 0, 0]], ["loc", [null, [6, 7], [12, 44]]], 0, 0]], ["loc", [null, [4, 2], [12, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-reject-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showRejectModal", ["loc", [null, [21, 25], [21, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 20], [21, 41]]], 0, 0], false], [], ["loc", [null, [21, 12], [21, 48]]], 0, 0], "isRejecting", ["subexpr", "@mut", [["get", "isRejecting", ["loc", [null, [22, 18], [22, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["showRejectModal"], [], ["loc", [null, [23, 14], [23, 40]]], 0, 0]], ["loc", [null, [19, 9], [23, 41]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 37], [24, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 27], [24, 42]]], 0, 0]], [], ["loc", [null, [24, 19], [24, 43]]], 0, 0]], ["loc", [null, [18, 7], [24, 44]]], 0, 0]], ["loc", [null, [16, 2], [24, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 37,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-more-info-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showMoreInfoModal", ["loc", [null, [33, 25], [33, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 20], [33, 43]]], 0, 0], false], [], ["loc", [null, [33, 12], [33, 50]]], 0, 0], "isConfirmingMoreInfo", ["subexpr", "@mut", [["get", "isConfirmingMoreInfo", ["loc", [null, [34, 27], [34, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "confirm", ["subexpr", "action", ["showMoreInfoModal"], [], ["loc", [null, [35, 14], [35, 42]]], 0, 0]], ["loc", [null, [31, 9], [35, 43]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [36, 37], [36, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 27], [36, 42]]], 0, 0]], [], ["loc", [null, [36, 19], [36, 43]]], 0, 0]], ["loc", [null, [30, 7], [36, 44]]], 0, 0]], ["loc", [null, [28, 2], [36, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["notification-notes-modal"], ["model", ["subexpr", "@mut", [["get", "showNotesModal", ["loc", [null, [45, 12], [45, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showNotesModal", ["loc", [null, [46, 25], [46, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 20], [46, 40]]], 0, 0], false], [], ["loc", [null, [46, 12], [46, 47]]], 0, 0]], ["loc", [null, [43, 9], [46, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showNotesModal", ["loc", [null, [47, 32], [47, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 27], [47, 47]]], 0, 0], false], [], ["loc", [null, [47, 19], [47, 54]]], 0, 0]], ["loc", [null, [42, 7], [47, 55]]], 0, 0]], ["loc", [null, [40, 2], [47, 57]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 0
            },
            "end": {
              "line": 60,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [56, 12], [56, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", false, "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [58, 25], [58, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [58, 20], [58, 44]]], 0, 0], false], [], ["loc", [null, [58, 12], [58, 51]]], 0, 0]], ["loc", [null, [54, 9], [58, 52]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [59, 32], [59, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 27], [59, 51]]], 0, 0], false], [], ["loc", [null, [59, 19], [59, 58]]], 0, 0]], ["loc", [null, [53, 7], [59, 59]]], 0, 0]], ["loc", [null, [51, 2], [59, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 6
              },
              "end": {
                "line": 82,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "notificationSearcher-wrapper");
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["inline", "notification-searcher", [], ["content", ["subexpr", "@mut", [["get", "searchOptions", ["loc", [null, [72, 18], [72, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "optionSelected", ["subexpr", "@mut", [["get", "lastSearchOptionSelected", ["loc", [null, [73, 25], [73, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "query", ["subexpr", "@mut", [["get", "searchQuery", ["loc", [null, [74, 16], [74, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "search", "searchNotifications"], ["loc", [null, [71, 10], [76, 12]]], 0, 0], ["content", "help-trigger", ["loc", [null, [78, 10], [78, 26]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 4
            },
            "end": {
              "line": 83,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "media.isTiny", ["loc", [null, [67, 16], [67, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [67, 6], [82, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 5
            },
            "end": {
              "line": 95,
              "column": 5
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dc-tabs dc-tabs--fullWidth");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dc-tabs-list");
          dom.setAttribute(el2, "role", "tablist");
          dom.setAttribute(el2, "aria-multiselectable", "false");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          dom.setAttribute(el3, "class", "notificationTab notificationTab--search notificationTab dc-tabs-list-item");
          dom.setAttribute(el3, "selected", "selected");
          dom.setAttribute(el3, "aria-selected", "true");
          dom.setAttribute(el3, "ariaexpanded", "true");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "searchResults-notificationTab-close");
          var el5 = dom.createTextNode("X");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1, 1]);
          var element1 = dom.childAt(element0, [4]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          morphs[2] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/search.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [89, 16], [89, 75]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.search"], [], ["loc", [null, [89, 75], [89, 124]]], 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [90, 66], [90, 90]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 10
                  },
                  "end": {
                    "line": 101,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/bell.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [100, 12], [100, 69]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.all"], [], ["loc", [null, [100, 69], [100, 115]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 103,
                      "column": 135
                    },
                    "end": {
                      "line": 103,
                      "column": 241
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "badge badge--notification at-notificationTab");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                  return morphs;
                },
                statements: [["content", "toApproveCount", ["loc", [null, [103, 216], [103, 234]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 102,
                    "column": 12
                  },
                  "end": {
                    "line": 104,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/requested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [103, 14], [103, 84]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.requests"], [], ["loc", [null, [103, 84], [103, 135]]], 0, 0], ["block", "if", [["get", "toApproveCount", ["loc", [null, [103, 141], [103, 155]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [103, 135], [103, 248]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 106,
                      "column": 142
                    },
                    "end": {
                      "line": 106,
                      "column": 258
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "badge badge--notification at-notificationTab");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
                  return morphs;
                },
                statements: [["content", "moreInfoActionCount", ["loc", [null, [106, 228], [106, 251]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 105,
                    "column": 12
                  },
                  "end": {
                    "line": 107,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/moreinforequested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [106, 12], [106, 90]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.more_info"], [], ["loc", [null, [106, 90], [106, 142]]], 0, 0], ["block", "if", [["get", "moreInfoActionCount", ["loc", [null, [106, 148], [106, 167]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [106, 142], [106, 265]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 10
                  },
                  "end": {
                    "line": 110,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/requested.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [109, 12], [109, 82]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.requested"], [], ["loc", [null, [109, 82], [109, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 10
                  },
                  "end": {
                    "line": 113,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/accepted.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [112, 12], [112, 81]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.accepted"], [], ["loc", [null, [112, 81], [112, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 114,
                    "column": 10
                  },
                  "end": {
                    "line": 116,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/rejected.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [115, 12], [115, 81]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.rejected"], [], ["loc", [null, [115, 81], [115, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 117,
                    "column": 10
                  },
                  "end": {
                    "line": 119,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["notifications/cancelled.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [118, 12], [118, 82]]], 0, 0], ["inline", "t", ["components.notifications_list.tabs.cancelled"], [], ["loc", [null, [118, 82], [118, 134]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 8
                },
                "end": {
                  "line": 120,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], ["class", "notificationTab notificationTab--all"], 0, null, ["loc", [null, [99, 10], [101, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--requests"], 1, null, ["loc", [null, [102, 12], [104, 23]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--more-info"], 2, null, ["loc", [null, [105, 12], [107, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--requested"], 3, null, ["loc", [null, [108, 10], [110, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--accepted"], 4, null, ["loc", [null, [111, 10], [113, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--rejected"], 5, null, ["loc", [null, [114, 10], [116, 21]]]], ["block", "dc-tab", [], ["class", "notificationTab notificationTab--cancelled"], 6, null, ["loc", [null, [117, 10], [119, 21]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 5
              },
              "end": {
                "line": 121,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [98, 8], [120, 24]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 95,
              "column": 5
            },
            "end": {
              "line": 123,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth at-notifications", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [97, 75], [97, 86]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [97, 5], [121, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 127,
              "column": 8
            },
            "end": {
              "line": 132,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "emptyContainer");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.notifications.loading"], [], ["loc", [null, [129, 12], [129, 56]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child9 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 148,
                  "column": 12
                },
                "end": {
                  "line": 154,
                  "column": 12
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "resources-meta", [], ["changePage", "changePage", "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [151, 29], [151, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "currentPage", ["subexpr", "@mut", [["get", "model.pagination.currentPage", ["loc", [null, [152, 28], [152, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.pagination.totalPages", ["loc", [null, [153, 27], [153, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [149, 14], [153, 56]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 134,
                "column": 10
              },
              "end": {
                "line": 156,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "notifications-list", [], ["askMoreInfos", "askMoreInfos", "sendMoreInfos", "sendMoreInfos", "acceptRequest", "acceptRequest", "rejectRequest", "rejectRequest", "moreInfoRequest", "moreInfoRequest", "viewNotes", "viewNotes", "openEventOverview", "openEventOverview", "removeSearchTerm", "removeSearchTerm", "loadingBookingRequest", ["subexpr", "@mut", [["get", "loadingBookingRequest", ["loc", [null, [144, 36], [144, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "searchOptionsSelected", ["subexpr", "@mut", [["get", "searchOptionsSelected", ["loc", [null, [145, 36], [145, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "notifications", ["subexpr", "@mut", [["get", "model.results", ["loc", [null, [146, 28], [146, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [135, 12], [146, 43]]], 0, 0], ["block", "if", [["get", "shouldShowPaging", ["loc", [null, [148, 18], [148, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [148, 12], [154, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 160,
                  "column": 18
                },
                "end": {
                  "line": 162,
                  "column": 18
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["pods.logged_in.notifications.no_results"], [], ["loc", [null, [161, 20], [161, 67]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 163,
                    "column": 20
                  },
                  "end": {
                    "line": 165,
                    "column": 20
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message_approve"], [], ["loc", [null, [164, 22], [164, 80]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 165,
                    "column": 20
                  },
                  "end": {
                    "line": 167,
                    "column": 20
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "t", ["pods.logged_in.notifications.empty_message"], [], ["loc", [null, [166, 22], [166, 72]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 162,
                  "column": 18
                },
                "end": {
                  "line": 168,
                  "column": 18
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [163, 30], [163, 41]]], 0, 0, 0, 0], 1], [], ["loc", [null, [163, 26], [163, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [163, 20], [167, 27]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 158,
                "column": 10
              },
              "end": {
                "line": 170,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("               ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasSearchOptions", ["loc", [null, [160, 24], [160, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [160, 18], [168, 25]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 132,
              "column": 8
            },
            "end": {
              "line": 172,
              "column": 8
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "hasSearchOptions", ["loc", [null, [134, 20], [134, 36]]], 0, 0, 0, 0], ["get", "hasNotifications", ["loc", [null, [134, 37], [134, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [134, 16], [134, 54]]], 0, 0]], [], 0, null, ["loc", [null, [134, 10], [156, 17]]]], ["block", "if", [["subexpr", "not", [["get", "hasNotifications", ["loc", [null, [158, 21], [158, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [158, 16], [158, 38]]], 0, 0]], [], 1, null, ["loc", [null, [158, 10], [170, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 177,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/notifications/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-notifications");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("section");
        dom.setAttribute(el3, "class", "dc-tabs-panel active");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [11]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 3, 3);
        morphs[2] = dom.createMorphAt(element3, 5, 5);
        morphs[3] = dom.createMorphAt(element3, 7, 7);
        morphs[4] = dom.createMorphAt(element3, 9, 9);
        morphs[5] = dom.createMorphAt(element4, 1, 1);
        morphs[6] = dom.createMorphAt(element4, 3, 3);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "showAcceptModal", ["loc", [null, [3, 6], [3, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [13, 7]]]], ["block", "if", [["get", "showRejectModal", ["loc", [null, [15, 6], [15, 21]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 0], [25, 7]]]], ["block", "if", [["get", "showMoreInfoModal", ["loc", [null, [27, 6], [27, 23]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 0], [37, 7]]]], ["block", "if", [["get", "showNotesModal", ["loc", [null, [39, 6], [39, 20]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [39, 0], [48, 7]]]], ["block", "if", [["get", "showBookingRequest", ["loc", [null, [50, 6], [50, 24]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [50, 0], [60, 7]]]], ["block", "section-title", [], ["text", ["subexpr", "t", ["pods.logged_in.notifications.title"], [], ["loc", [null, [65, 26], [65, 66]]], 0, 0], "icon", "icons/bell.svg", "location", "at-notifications"], 5, null, ["loc", [null, [65, 4], [83, 22]]]], ["block", "if", [["get", "hasSearchOptions", ["loc", [null, [85, 11], [85, 27]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [85, 5], [123, 11]]]], ["block", "if", [["get", "isLoadingNotifications", ["loc", [null, [127, 14], [127, 36]]], 0, 0, 0, 0]], [], 8, 9, ["loc", [null, [127, 8], [172, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});