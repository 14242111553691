define('scientia-resourcebooker/pods/components/week-time-pattern/component', ['exports', 'ember'], function (exports, _ember) {

  var SLOT_DURATION_IN_SECONDS = 1800; // 30 minutes
  var ONE_HOUR_IN_SECONDS = 3600;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['chronos-weekView-event'],

    weekdayIndex: null,
    fromTime: null,
    toTime: null,
    dayOffset: null,
    dstDifference: null,

    onInit: (function () {

      _ember['default'].run.schedule('afterRender', this, function () {
        var fromTime = this.get('fromTime');
        var toTime = this.get('toTime');

        var secondDiff = toTime.asSeconds() - fromTime.asSeconds();
        var span = Math.round(secondDiff / SLOT_DURATION_IN_SECONDS * 10) / 10;

        var hourSlotHeight = _ember['default'].$('.' + this.get('slotClassName')).outerHeight();

        var top = this.get('fromTime').asHours() * hourSlotHeight;

        var topOffset = 0;
        var bottomOffset = 24 * hourSlotHeight;
        var maxHeight = bottomOffset - topOffset - top;
        var height = Math.min(span * hourSlotHeight / (ONE_HOUR_IN_SECONDS / SLOT_DURATION_IN_SECONDS), maxHeight);

        this.$().css({ height: height, top: top });

        // If 15min slot or less
        if (height <= 10) {
          this.$().addClass('chronos-weekView-event--tiny');
        }
      });
    }).on('init'),

    click: function click() {
      this.sendAction('showRemoveSlot', this.get('weekdayIndex'), this.get('fromTime'), this.get('toTime'), this.get('dayOffset'), this.get('dstDifference'));
    }
  });
});