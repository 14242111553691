define('scientia-resourcebooker/pods/components/recurring-event-fields/daily/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    systemSettings: _ember['default'].inject.service(),
    weekendsDisabledInConfig: _ember['default'].computed.not('systemSettings.options.calendarShowWeekends'),
    excludeWeekends: false,

    didReceiveAttrs: function didReceiveAttrs() {

      // We want to make sure that if ShowWeekends is disabled that the recurrence object
      // has excludeWeekends to true. This might cause an issue for old recurring bookings that were made
      // when ShowWeekends setting was different
      if (this.get('weekendsDisabledInConfig')) {
        this.set('excludeWeekends', true);
      }
    },

    actions: {
      updateExcludeWeekends: function updateExcludeWeekends(key, value) {
        this.set('excludeWeekends', !value);
      }
    }

  });
});