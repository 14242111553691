define('scientia-resourcebooker/services/nested-resource-store', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    createResource: function createResource(_ref) {
      var type = _ref.type;
      var parentId = _ref.parentId;
      var data = _ref.data;

      var url = this._urlFromTypeAndIds(type, parentId);

      return this.get('authenticatedRequest').post(url, data);
    },

    updateResource: function updateResource(_ref2) {
      var type = _ref2.type;
      var parentId = _ref2.parentId;
      var id = _ref2.id;
      var data = _ref2.data;

      var url = this._urlFromTypeAndIds(type, parentId, id);

      return this.get('authenticatedRequest').put(url, data);
    },

    destroyResource: function destroyResource(_ref3) {
      var _this = this;

      var type = _ref3.type;
      var id = _ref3.id;
      var parentId = _ref3.parentId;

      var url = this._urlFromTypeAndIds(type, parentId, id);

      return this.get('authenticatedRequest')['delete'](url).then(function () {
        return _this.get('store').unloadRecord(_this.get('store').peekRecord(type.split('.')[1], id));
      });
    },

    fetch: function fetch(_ref4) {
      var _this2 = this;

      var type = _ref4.type;
      var parentId = _ref4.parentId;
      var id = _ref4.id;
      var groupId = _ref4.groupId;
      var suffix = _ref4.suffix;
      var data = _ref4.data;
      var isSingleResource = _ref4.isSingleResource;

      isSingleResource = isSingleResource || !!id;
      var url = this._urlFromTypeAndIds(type, parentId, id, suffix, groupId);

      return this.get('authenticatedRequest').fetch(url, data).then(function (data) {
        var modelType = type.split('.')[1];
        var serializer = _this2.get('store').serializerFor(modelType);
        var typeClass = _this2.get('store').modelFor(modelType);

        var serializedData = undefined;
        var newResources = undefined;

        if (isSingleResource && !suffix) {
          serializedData = serializer.normalizeSingleResponse(_this2.get('store'), typeClass, data, data.id);
          newResources = _this2.get('store').push(serializedData);
        } else {

          serializedData = serializer.normalizeArrayResponse(_this2.get('store'), typeClass, data, null, 'query');
          newResources = _this2.get('store').push(serializedData);

          if (serializedData.meta) newResources.meta = serializedData.meta;
        }

        return newResources;
      });
    },

    _urlFromTypeAndIds: function _urlFromTypeAndIds(type, parentId) {
      var id = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
      var suffix = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
      var groupId = arguments.length <= 4 || arguments[4] === undefined ? null : arguments[4];

      var url = undefined;
      if (groupId) {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS, type).loc(parentId, groupId);
      } else {
        url = _ember['default'].get(_scientiaResourcebookerConfigEnvironment['default'].API.NESTED_LINKS_URLS, type).loc(parentId);
      }
      if (id) url += '/' + id;
      if (suffix) url += '/' + suffix;

      return url;
    }
  });
});