define('scientia-resourcebooker/pods/logged-in/booking-request/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/page-changer', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsPageChanger, _emberCan) {

  var LOCALE_PATH = 'pods.logged_in.booking_request.index.';

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsPageChanger['default'], _emberCan.CanMixin, {
    applicationController: _ember['default'].inject.controller('application'),

    nestedResourceStore: _ember['default'].inject.service(),
    resourceFilterer: _ember['default'].inject.service(),
    bookingRequestDestroyer: _ember['default'].inject.service(),
    bookingRequestErrorHandler: _ember['default'].inject.service(),

    errorHandler: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    localeProcessor: _ember['default'].inject.service(),
    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    queryParams: ['query', 'page', 'perPage', 'refreshCache', { formattedDisplayedDate: 'date' }, { bookingId: 'id' }, 'ocurrenceId'],

    page: 1,
    perPage: 10,
    query: '',
    bookingId: null,
    occurrenceId: null,

    startRange: null,
    endRange: null,

    refreshCache: false,

    inFetch: false,

    displayedDate: _ember['default'].computed('formattedDisplayedDate', {
      get: function get() {
        return this.get('formattedDisplayedDate') ? moment(this.get('formattedDisplayedDate')).format('YYYY-MM-DD') : null;
      },
      set: function set(key, value) {
        this.set('formattedDisplayedDate', value ? moment(value).format('YYYY-MM-DD') : '');
      }
    }),

    formattedDisplayedDate: '',

    boundQuery: _ember['default'].computed.oneWay('query'),

    showBookingRequest: null,
    showDayViewCalendarModal: null,
    showCannotEditDueToRecurringModal: false,

    editRestrictionsModal: null,

    showSearchResults: (function () {
      return this.get('query') && this.get('model.results.length') >= 0;
    }).property('query', 'model'),

    showSearchLoading: _ember['default'].computed('applicationController.loading', 'inFetch', function () {
      return this.get('applicationController.loading') && !this.get('inFetch');
    }),

    // shouldShowPaging: Ember.computed.gt('model.resourcesMeta.totalPages', 1),
    shouldShowPaging: _ember['default'].computed.gt('model.results.length', 0),

    bookingIdObserver: (function () {
      var _this = this;

      if (this.get('bookingId')) {
        _ember['default'].run.next(this, function () {
          var occurrenceId = _this.get('occurrenceId');
          _this.set('showBookingRequest', null); // Make sure we remove any booking already showing (ie.clicked edit)
          _this.send('openBookingRequestOverview', { id: _this.get('bookingId'), occurrenceId: occurrenceId });
          _this.set('bookingId', null);
          _this.set('occurrenceId', null);
        });
      }
    }).observes('bookingId'),

    filterEventsForDayViewCalendar: function filterEventsForDayViewCalendar(date) {
      var model = this.get('model');
      var calendarEvents = model.filter(function (booking) {
        var startDateTime = moment(booking.get('startDateTime')).startOf('day');
        var endDateTime = moment(booking.get('endDateTime')).startOf('day');
        return date.isSame(startDateTime) || date.isSame(endDateTime) || date.isBetween(startDateTime, endDateTime);
      });
      return calendarEvents;
    },

    _shouldShowBookingRequestActions: function _shouldShowBookingRequestActions(status) {
      var shouldShow = ['Cancelled', 'Rejected'].indexOf(status) === -1;
      this.set('showBookingRequestActions', shouldShow);
    },

    actions: {

      openBookingRequestOverview: function openBookingRequestOverview(chronosEvent) {
        var _this2 = this;

        var bookingPromise = undefined;
        if (chronosEvent.occurrenceId) {
          bookingPromise = this.store.queryRecord('booking-request', { bookingId: chronosEvent.id, occurrenceId: chronosEvent.occurrenceId });
        } else {
          bookingPromise = this.store.find('booking-request', chronosEvent.id);
        }

        bookingPromise.then(function (bookingRequest) {
          _this2.set('showBookingRequest', bookingRequest);
          _this2._shouldShowBookingRequestActions(bookingRequest.get('status'));
        });
      },

      cancelBookingRequest: function cancelBookingRequest(bookingRequest, cancelType) {
        var numberOfOcurrences = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

        var occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
        this.set('bookingRequestScheduledForCancellation', { booking: bookingRequest, cancelType: cancelType, occurrencesCount: occurrencesCount });
      },

      fetchBookingRequests: function fetchBookingRequests(start, end) {
        var _this3 = this;

        var clearCache = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        var model = this.get('model');

        // If there are search results left over do nothing
        if (model && !_ember['default'].isEmpty(model.results)) return false;

        this.set('inFetch', true);
        this.send('loadingWithoutTransition');
        var forceClearCache = this.get('refreshCache');
        this.set('startRange', start);
        this.set('endRange', end);
        var startRange = moment(start).subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm');
        var endRange = moment(end).add(1, 'day').utc().format('YYYY-MM-DD HH:mm');

        this.get('store').query('booking-request', { startRange: startRange, endRange: endRange, clearCache: clearCache || forceClearCache }).then(function (bookingRequests) {
          model.addObjects(bookingRequests.toArray());
        })['finally'](function () {
          _this3.set('refreshCache', false);
          _this3.send('finished');
          _this3.set('inFetch', false);
        });
      },

      editBookingRequestConfirm: function editBookingRequestConfirm(bookingRequest, queryParams) {

        this.transitionToRoute('logged-in.resource.show', bookingRequest.get('bookingTypeId'), bookingRequest.get('resources.firstObject.id'), { queryParams: queryParams });
      },

      editBookingRequest: function editBookingRequest(bookingRequest) {
        var _this4 = this;

        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

        if (bookingRequest.get('hasRecurrence') && !this.can('recurring booking-type', { model: bookingRequest.get('bookingType') })) {
          this.set('showCannotEditDueToRecurringModal', true);
          return false;
        }

        var bookingId = bookingRequest.get('eventId');
        var occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;

        var queryParams = {
          bookingId: bookingId,
          date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
          editFromPage: 'my-bookings'
        };

        if (occurrenceId) queryParams.occurrenceId = occurrenceId;

        // If there are no restrictions just proceed to edit
        if (_ember['default'].isEmpty(editRestrictions)) {
          this.send('editBookingRequestConfirm', bookingRequest, queryParams);
          return;
        }

        // Else create a deferred promise which will be resolved or rejected depending if the user chooses to continue with the edit or cancel
        var deferred = _ember['default'].RSVP.defer();

        //If user confirms then proceed to edit
        deferred.promise.then(function () {
          _this4.send('editBookingRequestConfirm', bookingRequest, queryParams);
        })['catch'](function () {
          _this4.set('editRestrictionsModal', null);
        });

        // Trigger the modal and pass in the deferred and restrictions
        this.set('editRestrictionsModal', {
          deferred: deferred,
          restrictions: editRestrictions,
          isOccurrence: singleOccurrenceEdit
        });
      },

      openDayViewCalendarModal: function openDayViewCalendarModal(date) {
        var bookingRequests = this.filterEventsForDayViewCalendar(date);
        if (bookingRequests.length) {
          this.set('withBookingRequest', true);
          this.set('formattedDisplayedDate', date.format('YYYY-MM-DD'));
          this.set('calendarEvents', bookingRequests);
          this.set('eventTitlePath', 'nameWithPrimaryResourceNames');
          this.set('showDayViewCalendarModal', date);
        }
      },

      dayViewCalendarEventsRefresh: function dayViewCalendarEventsRefresh() {
        var date = moment(this.get('displayedDate'), 'YYYY-MM-DD');
        var bookingRequestsFiltered = this.filterEventsForDayViewCalendar(date);
        this.set('calendarEvents', bookingRequestsFiltered);
      },

      onClickCountBadge: function onClickCountBadge(date) {
        this.send('openDayViewCalendarModal', date);
      },

      confirmBookingRequestCancellation: function confirmBookingRequestCancellation(bookingRequest, cancelType, note) {
        var _this5 = this;

        this.get('bookingRequestDestroyer').destroyRequest(bookingRequest, cancelType, note).then(function () {

          var model = _this5.get('model');

          var currentUserEmail = _this5.get('currentUser.user.email');
          var bookerEmail = bookingRequest.get('bookerEmailAddress');
          _this5.get('appInsights').trackEvent('booking-cancelled', {
            type: cancelType === 1 ? 'occurrence' : 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequest.get('id'),
            bookingName: bookingRequest.get('name'),
            from: 'my-bookings'
          });

          // If we are in search results
          if (model.results && model.results.length) {
            model.results.removeObject(bookingRequest);
            _this5.deleteSuccessFlash('booking-request');
            _this5.send('invalidateModel');
            return;
          }

          model.removeObject(bookingRequest);

          if (_this5.get('showDayViewCalendarModal')) {
            _this5.send('dayViewCalendarEventsRefresh');
          }

          // If this is a recurring booking and user chose to delete all occurrences
          if (bookingRequest.get('hasRecurrence') && cancelType === 2) {
            var occurrences = model.filterBy('occurrenceGroupId', bookingRequest.get('occurrenceGroupId'));
            model.removeObjects(occurrences);
          }

          _this5.deleteSuccessFlash('booking-request');
        })['catch'](function (error) {

          if (error.responseJSON) {
            var errorMessage = _this5.get('bookingRequestErrorHandler').handle(error, LOCALE_PATH + 'flash_messages');

            if (errorMessage) {
              _this5.flashError(errorMessage);
              return;
            }
          }

          _this5.deleteErrorFlash('booking-request');
        })['finally'](function () {
          _this5.set('showBookingRequest', null);
          _this5.set('bookingRequestScheduledForCancellation', null);
        });
      },

      setDisplayedDate: function setDisplayedDate(displayedDate) {
        this.set('formattedDisplayedDate', displayedDate.format('YYYY-MM-DD'));
      },

      print: function print() {
        this.get('appInsights').trackEvent('my-bookings-print', { isSearchResults: !_ember['default'].isEmpty(this.get('showSearchResults')) });
        window.print();
      },

      submitSearch: function submitSearch() {
        this.setProperties({
          page: 1,
          query: this.get('boundQuery')
        });
      },

      clearSearch: function clearSearch() {
        this.setProperties({
          query: '',
          boundQuery: '',
          page: 1
        });
      },

      reloadView: function reloadView() {
        var query = this.get('query');
        var startRange = this.get('startRange');
        var endRange = this.get('endRange');
        if (query) {
          this.send('searchBookingRequests', query);
        } else {
          this.get('model').clear();
          this.send('fetchBookingRequests', startRange, endRange, true);
        }
      }

    }
  });
});