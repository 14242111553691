define('scientia-resourcebooker/pods/components/rb-calendar/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':rb-calendar', 'location'],
    systemSettings: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    currentLanguage: _ember['default'].computed.alias('i18n.locale'),
    localeProcessor: _ember['default'].inject.service(),
    calendar: null,
    calendarEvents: [],
    unavailableLabel: (0, _emberI18n.translationMacro)('components.rb_calendar.unavailable'),

    'event-id-path': 'id',
    'event-title-path': 'title',
    'event-subtitle-path': 'subtitle',
    'event-from-time-path': 'fromTime',
    'event-to-time-path': 'toTime',
    'event-tag-path': 'tag',
    'event-extra-class-names-path': 'extraClassNames',

    'displayed-date': null,
    'default-view-type': _ember['default'].computed.alias('systemSettings.options.calendarDefaultViewType'),
    'small-view-type': _ember['default'].computed.alias('systemSettings.options.calendarSmallViewType'),
    'show-agenda-button': true,
    'show-day-button': true,
    'show-week-button': true,
    'show-month-button': true,
    'show-year-button': false,
    'show-previous-arrow': true,
    'show-next-arrow': true,
    'show-today-button': true,
    'show-toolbar': true,
    'show-tag-button': false,
    'show-datepicker-button': true,
    'show-weekends': null,
    'weekend-days': '',
    'default-slot-start-offset-in-hours': null,
    'default-slot-end-offset-in-hours': null,
    'first-day-of-the-week-offset': _ember['default'].computed.alias('systemSettings.options.calendarFirstDayOfTheWeekOffset'),
    currentViewType: _ember['default'].computed.oneWay('default-view-type'),

    debounceDelay: _ember['default'].computed.alias('systemSettings.options.calendarNavigationLoadDelay'),

    updateCalendar: (function () {
      _ember['default'].run.once(this, '_replaceEvents');
    }).observes('calendarEvents.[]'),

    updateDisplayedDate: (function () {
      if (this.get('displayed-date')) {
        this.get('calendar').goToDate(this.get('displayed-date'));
      }
    }).observes('displayed-date'),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, 'setup');
    }).on('init'),

    setup: function setup() {
      var _this = this;

      var displayedDate = this.get('displayed-date');

      // let translator = this.get('chronosTranslator');
      // let locale = this.get('i18n.locale');
      // let translationObject = translator[locale];

      var translationObject = this.get('localeProcessor').getChronosTranslations();
      this.set('dataSource', new Chronos.DataSource([]));

      this.set('calendar', new Chronos.Calendar(this.$(), {
        // translations: window.translations.calendar,
        translations: translationObject,
        configuration: {
          displayedDate: moment(displayedDate).isValid() ? displayedDate : null,
          defaultViewType: this.get('default-view-type') || this.get('systemSettings').getSetting('calendarDefaultViewType'),
          toolbar: {
            showToolbar: this.get('show-toolbar'),
            showAgendaButton: this.get('show-agenda-button'),
            showDayButton: this.get('show-day-button'),
            showWeekButton: this.get('show-week-button'),
            showMonthButton: this.get('show-month-button'),
            showYearButton: this.get('show-year-button'),
            showPreviousArrow: this.get('show-previous-arrow'),
            showNextArrow: this.get('show-next-arrow'),
            showTodayButton: this.get('show-today-button'),
            showTagButton: this.get('show-tag-button'),
            showDatepickerButton: this.get('show-datepicker-button')
          },
          showWeekends: this.get('show-weekends') !== null ? this.get('show-weekends') : this.get('systemSettings').getSetting('calendarShowWeekends'),
          weekendDays: this.get('systemSettings').getSetting('weekendDays'),
          defaultSlotStartOffsetInHours: this.get('default-slot-start-offset-in-hours') !== null ? this.get('default-slot-start-offset-in-hours') : this.get('systemSettings').getSetting('calendarStartTimeOffset'),
          defaultSlotEndOffsetInHours: this.get('default-slot-end-offset-in-hours') !== null ? this.get('default-slot-end-offset-in-hours') : this.get('systemSettings').getSetting('calendarEndTimeOffset'),
          maximumConcurrentEvents: 5,
          firstDayOfTheWeekOffset: this.get('first-day-of-the-week-offset'),
          agendaShowDuration: this.get('systemSettings').getSetting('calendarAgendaViewShowDuration'),
          smallViewType: this.get('small-view-type') || 'month'
        },
        dataSource: this.get('dataSource'),
        onEventClick: function onEventClick(chronosEvent) {
          return _this.sendAction('on-event-click', chronosEvent);
        },
        onDateClick: function onDateClick(date) {
          return _this._onDateClick(date);
        },
        onDatepickerClick: function onDatepickerClick(date) {
          return _this._onDatepickerClick(date);
        },
        onSheetChange: function onSheetChange(startDate, endDate) {
          return _this.onSheetChange(startDate, endDate);
        },
        onViewTypeChanged: function onViewTypeChanged(viewType) {
          if (!_this.get('isDestroyed')) {
            _this.set('currentViewType', viewType);
            _this._datepickerUpdateViewMode(viewType);
          }
        }
      }));

      _ember['default'].run.later(this, function () {
        this._replaceEvents();
        if (this.get('show-datepicker-button')) {
          this._initializeDatepicker();
        }
        this.sendAction('on-calendar-loaded');
      });
    },

    onSheetChange: function onSheetChange(startDate, endDate) {
      _ember['default'].run.debounce(this, '_onSheetChange', startDate, endDate, this.get('debounceDelay') || 1000);
    },

    _onSheetChange: function _onSheetChange(startDate, endDate) {
      var calendar = this.get('calendar');
      if (calendar && calendar.$element) {
        this.sendAction('on-displayed-date-change', calendar.displayedDate);
        this.sendAction('on-sheet-change', startDate, endDate);
        this._datepickerUpdateDate(calendar.displayedDate);
      }
    },

    willDestroyElement: function willDestroyElement() {
      if (!this.get('isDestroyed') && this.get('calendar') && this.get('calendar.destroy')) {
        this.get('calendar').destroy();
      }
    },

    _replaceEvents: function _replaceEvents() {
      this.get('dataSource').replaceEvents(this._mappedEvents());
    },

    _mappedEvents: function _mappedEvents() {
      var _this2 = this;

      if (!this.get('calendarEvents') || !this.get('calendarEvents.length')) return [];
      return this.get('calendarEvents').map(function (chronosEvent) {

        if (get(chronosEvent, _this2.get('event-from-time-path')) === undefined) return false;

        return new Chronos.Event({
          id: get(chronosEvent, _this2.get('event-id-path')),
          title: _this2._getEventTitle(chronosEvent),
          fromTime: get(chronosEvent, _this2.get('event-from-time-path')),
          toTime: get(chronosEvent, _this2.get('event-to-time-path')),
          tag: get(chronosEvent, _this2.get('event-tag-path')) ? get(chronosEvent, _this2.get('event-tag-path')).toLowerCase() : '',
          hasRecurrence: chronosEvent.get('hasRecurrence'),
          extraClassNames: get(chronosEvent, _this2.get('event-extra-class-names-path')) || null,
          isMasking: get(chronosEvent, 'isMasking'),
          recurrenceText: !!chronosEvent.get('recurrencePattern') && !!chronosEvent.get('recurrencePattern.recurrenceTypeSelected') ? _this2.get('recurrenceProcessor').generateDescription(chronosEvent.get('recurrencePattern')) : ''
        });
      });
    },

    _initializeDatepicker: function _initializeDatepicker() {
      var _this3 = this;

      var input = _ember['default'].$('.chronos-headerView-datepicker-input');
      if (this.get('isDestroyed') || !input) return;

      input.attr('readonly', 'readonly');
      var datepicker = input.datetimepicker({
        locale: this.get('currentLanguage') === 'en' ? 'en_GB' : this.get('currentLanguage'),
        format: 'YYYY-MM-DD',
        date: this.get('displayed-date'),
        ignoreReadonly: true,
        debug: false,
        widgetPositioning: { vertical: 'bottom', horizontal: 'auto' }
      });

      datepicker.on('dp.change', function (e) {
        if (_this3.get('calendar') && _this3.get('calendar').$element) _this3.get('calendar').goToDate(e.date);
      });

      this.set('datepicker', datepicker.data('DateTimePicker'));

      this._datepickerUpdateViewMode(this.get('currentViewType'));
    },

    _onDatepickerClick: function _onDatepickerClick() {
      if (!this.get('datepicker')) return;
      this.get('datepicker').toggle();
    },

    _datepickerUpdateViewMode: function _datepickerUpdateViewMode(viewType) {
      if (!this.get('datepicker')) return;
      var mode = ['month', 'agenda'].includes(viewType) ? 'months' : 'days';
      this.get('datepicker').viewMode(mode);
    },

    _datepickerUpdateDate: function _datepickerUpdateDate(date) {
      if (!this.get('datepicker')) return;
      this.get('datepicker').date(date);
    },

    _getEventTitle: function _getEventTitle(event) {
      var title = get(event, this.get('event-title-path'));
      var subtitlePath = this.get('event-subtitle-path');

      if (title) {
        if (subtitlePath) {
          var subtitle = get(event, subtitlePath);
          if (subtitle) return title + (' (' + subtitle + ')');else return title;
        } else {
          return title;
        }
      }
      return this.get('unavailableLabel').toString();
    },

    _onDateClick: function _onDateClick(date) {
      if (this.get('calendar').viewType === 'month') {
        this.sendAction('on-event-count-badge-click', date);
      } else {
        this.sendAction('on-date-click', date);
      }
    }
  });
});