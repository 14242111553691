define('scientia-resourcebooker/pods/components/recurring-event-fields/monthly/component', ['exports', 'ember', 'scientia-resourcebooker/helpers/offset-array'], function (exports, _ember, _scientiaResourcebookerHelpersOffsetArray) {

  var MONTHLY_TYPE_DATES_OF_MONTH = 0;
  //const MONTHLY_TYPE_DAY_OF_WEEK = 1;

  exports['default'] = _ember['default'].Component.extend({

    recurrence: null,
    showWeekends: _ember['default'].computed.alias('systemSettings.options.calendarShowWeekends'),
    systemSettings: _ember['default'].inject.service(),

    monthDayIndexes: (function () {
      var indexes = [];

      for (var index = 1, _length = 31; index <= _length; index++) {
        indexes.pushObject(_ember['default'].Object.create({ id: index, label: index, value: index }));
      }

      return indexes;
    }).property('i18n.locale'),

    monthlyPeriods: (function () {
      var periods = this.get('i18n').t('components.recurring_event_fields.monthly_periods').toString().split(',');

      return periods.map(function (period, index) {
        return _ember['default'].Object.create({
          id: index === 4 ? -1 : index + 1,
          value: index === 4 ? -1 : index + 1,
          label: period
        });
      });
    }).property('i18n.locale'),

    weekdays: (function () {

      var weekDaysAbbr = this.get('i18n').t('general.weekdays_abbreviations').toString().split(',');

      // Generate all weekdays
      var weekdays = weekDaysAbbr.reduce(function (memo, weekday, index) {
        var day = _ember['default'].Object.create({
          id: index,
          value: index,
          label: weekday
        });
        memo.pushObject(day);
        return memo;
      }, []);

      // Offset them due to firstDayOfTheWeekOffset
      var firstDayOfTheWeekOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
      weekdays = (0, _scientiaResourcebookerHelpersOffsetArray.offsetArray)(weekdays, firstDayOfTheWeekOffset);

      // Filter weekends
      var showWeekends = this.get('systemSettings').getSetting('calendarShowWeekends');
      var weekendDays = this.get('systemSettings').getSetting('weekendDays');
      weekdays = !showWeekends ? weekdays.filter(function (item) {
        return !weekendDays.includes(item.id);
      }) : weekdays;
      return weekdays;
    }).property('i18n.locale'),

    actions: {
      itemToggled: function itemToggled(option) {
        if (parseInt(this.get('monthlyType')) === MONTHLY_TYPE_DATES_OF_MONTH) {
          this.set('datesOfMonth', [option.get('id')]);
        } else {
          this.set('dayOfWeek', [option.get('id')]);
        }
      },

      recurrenceMonthlyIntervalToggled: function recurrenceMonthlyIntervalToggled(monthlyInterval) {
        this.set('recurrenceMonthlyInterval', [monthlyInterval.get('id')]);
      }

    }

  });
});