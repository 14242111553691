define('scientia-resourcebooker/services/icons-fetcher', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_PACK = 'pack1';

  exports['default'] = _ember['default'].Service.extend({
    systemSettings: _ember['default'].inject.service(),

    fetch: function fetch(pack) {
      var _this = this;

      pack = pack || DEFAULT_PACK;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.getJSON(_this.get('systemSettings').getSetting('iconsTemplateUrl').loc(pack)).done(function (_ref) {
          var icons = _ref.icons;

          resolve(icons.map(function (url) {
            return _this._mapObject(url);
          }));
        }).fail(reject);
      });
    },

    _mapObject: function _mapObject(url) {
      return _ember['default'].Object.create({ url: url });
    }
  });
});