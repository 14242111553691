define('scientia-resourcebooker/pods/logged-in/user-profile/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    systemSettings: _ember['default'].inject.service(),
    profileProcessor: _ember['default'].inject.service(),
    isSaving: false,

    userProfileDisplayMode: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('userProfileDisplayMode');
    }),

    canShowUserProfile: _ember['default'].computed.gte('userProfileDisplayMode', 1),

    actions: {
      save: function save() {
        var _this = this;

        this.set('isSaving', true);

        this.get('profileProcessor').save(this.get('model')).then(function () {

          _this.send('refreshModel');
          _this.flashSuccess(_this.get('i18n').t('pods.logged_in.user_profile.saved'));
        })['catch'](function () {
          _this.flashError(_this.get('i18n').t('pods.logged_in.user_profile.error_saving'));
        })['finally'](function () {
          _this.set('isSaving', false);
        });
      }
    }
  });
});

// import config from '../../../config/environment';