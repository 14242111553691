define('scientia-resourcebooker/services/current-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    user: null,

    systemSettings: _ember['default'].inject.service(),

    userGroups: _ember['default'].computed.alias('user.userGroups'),

    getUser: function getUser() {
      return this.get('user');
    },

    setUser: function setUser(user) {
      this.set('user', user);
    },

    hasRole: function hasRole(role) {
      return this.get('user.roles').includes(role);
    },

    isInstitutionAdmin: function isInstitutionAdmin() {
      return this.hasRole('institution admin');
    },

    isAdmin: function isAdmin() {
      return this.hasRole('admin') || this.isInstitutionAdmin();
    },

    isResourceOwner: function isResourceOwner() {
      return this.hasRole('resource owner') || this.isInstitutionAdmin();
    },

    isUser: function isUser() {
      return this.hasRole('user') || this.isInstitutionAdmin();
    },

    isTemplateOwner: function isTemplateOwner() {
      return this.hasRole('template owner') || this.isInstitutionAdmin();
    },

    isUserGroupOwner: function isUserGroupOwner() {
      return this.hasRole('usergroup owner') || this.isInstitutionAdmin();
    },

    getInstitutionId: function getInstitutionId() {
      return this.get('user.institutionId');
    },

    getNameIdentifier: function getNameIdentifier() {
      return this.get('user.nameIdentifier');
    },

    canImportExportResourcePermissions: function canImportExportResourcePermissions() {
      return this.get('user.importExportPermissions.resourcePermissions');
    },

    canImportExportResourceCustomFields: function canImportExportResourceCustomFields() {
      return this.get('user.importExportPermissions.resourceCustomFields');
    },

    shouldSeeDetailedUnavailability: function shouldSeeDetailedUnavailability() {
      var detailedUnavailabilitySetting = this.get('systemSettings').getSetting('detailedUnavailabilityBookingDisplay').split(',');
      if (!detailedUnavailabilitySetting) return false;
      detailedUnavailabilitySetting = detailedUnavailabilitySetting.map(function (e) {
        return e.trim().toLowerCase();
      });
      if (detailedUnavailabilitySetting.includes('everyone')) return true;
      if (detailedUnavailabilitySetting.includes('admin') && this.isAdmin()) return true;
      if (detailedUnavailabilitySetting.includes('resource owner') & this.hasRole('resource owner')) return true;
      return false;
    }
  });
});