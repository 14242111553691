define('scientia-resourcebooker/pods/manage-resources/resource/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var UNAVAILABILITY_TYPES = ['resource', 'template', 'event', 'booking', 'general'];

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    nestedResourceStore: _ember['default'].inject.service(),
    bookingRequestCreator: _ember['default'].inject.service(),
    bookingRequestDestroyer: _ember['default'].inject.service(),
    bookingRequestErrorHandler: _ember['default'].inject.service(),
    resourceBookingRequestsFetcher: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    unavailabilityEventFetcher: _ember['default'].inject.service(),
    resourceEventsFetcher: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    localeProcessor: _ember['default'].inject.service(),
    canShowCalendar: _ember['default'].computed.alias('localeProcessor.canShowCalendar'),

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resource.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resource.resourceType.id')
      }, {
        label: this.get('model.resource.name'),
        linkable: false
      }];
    }).property('model.resource.resourceType.name', 'model.resource.name', 'i18n.locale'),

    queryParams: [{ formattedDisplayedDate: 'date' }, { bookingId: 'id' }, 'occurrenceId'],

    bookingId: null,
    occurrenceId: null,
    displayedDate: _ember['default'].computed('formattedDisplayedDate', {
      get: function get() {
        return this.get('formattedDisplayedDate') ? moment(this.get('formattedDisplayedDate')).format('YYYY-MM-DD') : null;
      },
      set: function set(key, value) {
        this.set('formattedDisplayedDate', value ? moment(value).format('YYYY-MM-DD') : '');
      }
    }),

    formattedDisplayedDate: '',

    editRestrictionsModal: null,
    showDayViewCalendarModal: null,

    showBookingRequest: null,
    // confirmMessage: null,
    // confirmTitle: null,

    showBookingRequestActions: (function () {
      if (this.get('showBookingRequest') && this.get('model.resource')) {
        return this.get('showBookingRequest.resources').objectAt(0).get('id') === this.get('model.resource.id');
      }

      return false;
    }).property('showBookingRequest.resources.@each.id', 'model.resource.id'),

    calendarEvents: _ember['default'].computed('displayedDate', 'model.events.[]', function () {
      var model = this.get('model.events');
      var date = moment(this.get('displayedDate'));
      return model.filter(function (booking) {
        var startDateTime = moment(booking.get('startDateTime')).startOf('day');
        var endDateTime = moment(booking.get('endDateTime')).startOf('day');
        return date.isSame(startDateTime) || date.isSame(endDateTime) || date.isBetween(startDateTime, endDateTime);
      });
    }),

    actions: {

      fetchEvents: function fetchEvents(start, end) {
        var _this = this;

        this.send('loadingWithoutTransition');

        var model = this.get('model');

        var startDate = moment(start);
        var endDate = moment(end);
        var resourceTypeId = model.resource.get('resourceTypeId');
        var resourceId = model.resource.get('id');

        this.set('currentTimeSpanStart', start);
        this.set('currentTimeSpanEnd', end);

        var eventUnavailabilityPromise = this._fetchUnavailability({ resourceId: resourceId, resourceTypeId: resourceTypeId, startDate: startDate, endDate: endDate });

        var bookingsUnavailabilityPromise = this._fetchBookings({ resourceId: model.resource.get('id'), startDate: start, endDate: end });

        var promises = [eventUnavailabilityPromise, bookingsUnavailabilityPromise];

        _ember['default'].RSVP.all(promises).then(function (evs) {
          var allEvents = evs.reduce(function (memo, events) {
            return memo.concat(events);
          }, []);
          model.events.clear();
          model.events.addObjects(allEvents);
        })['finally'](function () {
          _this.send('finished');
        })['catch'](function () {
          // no-op
        });
      },

      onEventClick: function onEventClick(chronosEvent) {

        if (chronosEvent.isMasking) return;

        var ev = this.get('model.events').findBy('id', chronosEvent.id);
        if (!ev) return;

        if (ev.constructor.modelName === 'booking-request') {
          this.send('openBookingRequestOverview', chronosEvent.id);
        } else if (ev.availabilityType === 2) {
          this.send('showEventOverview', ev);
        }
      },

      showEventOverview: function showEventOverview(event) {
        var _this2 = this;

        this.send('loadingWithoutTransition');
        this.get('unavailabilityEventFetcher').fetch(this.get('model.resource.resourceTypeId'), this.get('model.resource.id'), event.eventId, event.startDateTime, event.endDateTime).then(function (ev) {
          _this2.set('showEventOverview', ev[0]);
        })['catch'](function () {
          // no-op
        })['finally'](function () {
          _this2.send('finished');
        });
      },

      openBookingRequestOverview: function openBookingRequestOverview(eventId, occurrenceId) {
        var _this3 = this;

        if (occurrenceId && occurrenceId !== 'undefined') {
          this.store.queryRecord('booking-request', { bookingId: eventId, occurrenceId: occurrenceId }).then(function (bookingRequest) {
            _this3.set('showBookingRequest', bookingRequest);
          });
        } else {

          this.store.findRecord('booking-request', eventId).then(function (bookingRequest) {
            _this3.set('showBookingRequest', bookingRequest);
          });
        }
      },

      cancelBookingRequest: function cancelBookingRequest(bookingRequest, cancelType) {
        var numberOfOcurrences = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

        var occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
        this.set('bookingRequestScheduledForCancellation', { booking: bookingRequest, cancelType: cancelType, occurrencesCount: occurrencesCount });
      },

      // cancelBookingRequest(bookingRequest, cancelType, numberOfOcurrences = 1) {
      //   let localePath = 'pods.logged_in.booking_request.index.';
      //   this.set('confirmTitle', this.get('i18n').t(localePath + 'cancel_booking_confirm_title'));

      //   let confirmMessagePath = 'cancel_booking_confirm';
      //   let occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
      //   if (cancelType) {
      //     if (cancelType === 1) {
      //       confirmMessagePath = 'cancel_booking_confirm_single_occurrence';
      //     } else {
      //       confirmMessagePath = 'cancel_booking_confirm_all_occurrences';
      //     }
      //   }
      //   this.set('confirmMessage', this.get('i18n').t(localePath + confirmMessagePath, {count: occurrencesCount}));
      //   this.set('bookingRequestCancellationType', cancelType);
      //   this.set('bookingRequestScheduledForCancellation', bookingRequest);

      // },

      confirmBookingRequestCancellation: function confirmBookingRequestCancellation(bookingRequest, cancelType, note) {
        var _this4 = this;

        this.get('bookingRequestDestroyer').destroyRequest(bookingRequest, cancelType, note).then(function () {
          _this4.deleteSuccessFlash('booking-request');

          var currentUserEmail = _this4.get('currentUser.user.email');
          var bookerEmail = bookingRequest.get('bookerEmailAddress');
          _this4.get('appInsights').trackEvent('booking-cancelled', {
            type: cancelType === 1 ? 'occurrence' : 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequest.get('id'),
            bookingName: bookingRequest.get('name'),
            from: 'manage-resources'
          });

          var model = _this4.get('model').events;

          var modelObjectToRemove = model.findBy('id', bookingRequest.get('id'));
          model.removeObject(modelObjectToRemove);

          if (bookingRequest.get('hasRecurrence') && cancelType === 2) {
            var occurrences = model.filterBy('eventId', bookingRequest.get('eventId'));
            model.removeObjects(occurrences);

            var maskingObjects = model.filterBy('eventId', modelObjectToRemove.get('eventId') + '-masking');
            model.removeObjects(maskingObjects);
          }
        })['catch'](function (error) {
          if (error.responseJSON) {
            // let errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON, 'pods.logged_in.booking_request.index.flash_messages');
            var errorMessage = _this4.get('bookingRequestErrorHandler').handle(error, 'pods.logged_in.booking_request.index.flash_messages');
            errorMessage = errorMessage.replace(new RegExp('\<\/br\>', 'g'), ', ');
            if (errorMessage) {
              _this4.flashError(errorMessage);
              return;
            }
          }

          _this4.deleteErrorFlash('booking-request');
        })['finally'](function () {
          _this4.setProperties({
            showBookingRequest: null,
            bookingRequestScheduledForCancellation: null
          });
        });
      },

      onClickCountBadge: function onClickCountBadge(date) {
        this.send('openDayViewCalendarModal', date);
      },

      openDayViewCalendarModal: function openDayViewCalendarModal(date) {
        this.set('formattedDisplayedDate', date.format('YYYY-MM-DD'));
        this.set('withBookingRequest', true);
        this.set('showDayViewCalendarModal', date);
        this.set('eventTitlePath', 'nameWithFallback');
      },

      dayViewCalendarEventsRefresh: function dayViewCalendarEventsRefresh() {
        var date = this.get('displayedDate');
        var bookingRequestsFiltered = this.filterEventsForDayViewCalendar(date);
        this.set('calendarEvents', bookingRequestsFiltered);
      },

      editBookingRequest: function editBookingRequest(bookingRequest) {
        var _this5 = this;

        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

        var bookingId = bookingRequest.get('eventId');
        var occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;

        var queryParams = {
          bookingId: bookingId,
          date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
          editFromPage: 'manage-resources'
        };

        if (occurrenceId) queryParams.occurrenceId = occurrenceId;

        // If there are no restrictions just proceed to edit
        if (_ember['default'].isEmpty(editRestrictions)) {
          this.send('editBookingRequestConfirm', bookingRequest, queryParams);
          return;
        }

        // Else create a deferred promise which will be resolved or rejected depending if the user chooses to continue with the edit or cancel
        var deferred = _ember['default'].RSVP.defer();

        //If user confirms then proceed to edit
        deferred.promise.then(function () {
          _this5.send('editBookingRequestConfirm', bookingRequest, queryParams);
        })['catch'](function () {
          _this5.set('editRestrictionsModal', null);
        });

        // Trigger the modal and pass in the deferred and restrictions
        this.set('editRestrictionsModal', {
          deferred: deferred,
          restrictions: editRestrictions,
          isOccurrence: singleOccurrenceEdit
        });
      },

      editBookingRequestConfirm: function editBookingRequestConfirm(bookingRequest, queryParams) {

        this.transitionToRoute('logged-in.resource.show', bookingRequest.get('bookingTypeId'), bookingRequest.get('resources.firstObject.id'), { queryParams: queryParams });
      },

      setDisplayedDate: function setDisplayedDate(displayedDate) {
        this.set('formattedDisplayedDate', displayedDate.format('YYYY-MM-DD'));
      }

    },

    _fetchBookings: function _fetchBookings(_ref) {
      var resourceId = _ref.resourceId;
      var startDate = _ref.startDate;
      var endDate = _ref.endDate;

      return this.get('resourceBookingRequestsFetcher').fetch({ resourceId: resourceId, startDate: startDate, endDate: endDate }).then(function (bookings) {
        return bookings.map(function (booking) {
          booking.setProperties({
            tag: UNAVAILABILITY_TYPES[3].capitalize(),
            extraClassNames: 'chronos-event--' + booking.get('status').toLowerCase() });
          return booking;
        });
      });
    },

    _fetchUnavailability: function _fetchUnavailability(_ref2) {
      var resourceId = _ref2.resourceId;
      var resourceTypeId = _ref2.resourceTypeId;
      var startDate = _ref2.startDate;
      var endDate = _ref2.endDate;

      return this.get('resourceEventsFetcher').fetch({ resourceId: resourceId, resourceTypeId: resourceTypeId, startDate: startDate, endDate: endDate }).then(function (evs) {

        return evs.filter(function (event) {
          return event.availabilityType !== 3;
        }).reduce(function (memo, event) {

          var isPattern = event.repeats;

          var startDateTime = undefined;

          // If it's a pattern, we need to keep the date intact to match the correct day of the week)
          // but adjust the time to local
          if (isPattern) {
            var localStart = moment(event.startDateTime);

            var startDateTimeSplit = event.startDateTime.split('-');
            var year = startDateTimeSplit[0];
            var month = parseInt(startDateTimeSplit[1]) - 1;
            var date = startDateTimeSplit[2].split('T');
            localStart.year(year).month(month).date(date[0]);
            startDateTime = localStart;
          } else {
            startDateTime = moment(event.startDateTime);
          }

          var endDateTime = startDateTime.clone().add(event.duration, 'minutes');
          var availabilityType = event.availabilityType;
          var slot = _ember['default'].Object.create({
            id: availabilityType === 4 ? null : event.id,
            eventId: event.eventId,
            tag: UNAVAILABILITY_TYPES[event.availabilityType].capitalize(),
            name: event.name || null,
            availabilityType: event.availabilityType,
            extraClassNames: 'chronos-unavailability ' + (isPattern ? 'chronos-unavailability-pattern' : ''),
            startDateTime: startDateTime,
            endDateTime: endDateTime
          });

          // let slotMasking = Ember.Object.create({
          //   id: `${event.id}-masking`,
          //   isMasking: true,
          //   startDateTime,
          //   endDateTime});

          memo.pushObjects([slot /*, slotMasking*/]);
          return memo;
        }, []);
      });
    }
  });
});