define("scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/item/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/item/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "component", [["get", "valueTypeComponent", ["loc", [null, [1, 12], [1, 30]]], 0, 0, 0, 0]], ["field", ["subexpr", "@mut", [["get", "field", ["loc", [null, [1, 37], [1, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "center", ["subexpr", "@mut", [["get", "center", ["loc", [null, [1, 50], [1, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "inline", ["subexpr", "@mut", [["get", "inline", ["loc", [null, [1, 64], [1, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "clearPublishedTime", "clearPublishedTime"], ["loc", [null, [1, 0], [1, 112]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});