define('scientia-resourcebooker/abilities/resource-group', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canEditAll: _ember['default'].computed.alias('model.canEditAll'),
    canEditPermissions: _ember['default'].computed.alias('model.canEditPermissions'),
    canEditDescription: _ember['default'].computed.alias('model.canEditDescription'),
    canEditResources: _ember['default'].computed.alias('model.canEditResources'),
    canAddResources: _ember['default'].computed.alias('model.canAddResources'),

    canDelete: _ember['default'].computed('model.hasAssociatedBookingTypes', 'model.canDelete', function () {
      return !this.get('model.hasAssociatedBookingTypes') && this.get('model.canDelete');
    })

  });
});