define('scientia-resourcebooker/pods/manage-resources/booking-type/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: {
      selectedTab: 'section'
    },

    selectedTab: 0,

    name: '',
    description: '',
    permissions: [],
    imageUri: '',
    bookingForm: null,

    isSaving: false,
    error: '',
    showIconSelector: false,

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_type.index.booking_types'),
        path: 'manage-resources.booking-type.index'
      }, {
        label: this.get('i18n').t('pods.manage_resources.booking_type.new.page_title'),
        linkable: false
      }];
    }).property('i18n.locale'),

    actions: {
      save: function save() {
        var bookingForm = this.get('bookingForm');
        this.send('createBookingType', {
          name: this.get('name'),
          mode: 3, // Offline
          description: this.get('description'),
          imageUri: this.get('imageUri'),
          bookingForm: bookingForm
        });
      },

      clearImageUri: function clearImageUri() {
        this.set('imageUri', '');
      },

      setIcon: function setIcon(icon) {
        this.set('showIconSelector', false);
        this.set('imageUri', icon.url);
      },

      searchBookingForm: function searchBookingForm(term, deferred) {
        this.get('store').query('booking-form', { query: term }).then(function (results) {
          results = results.map(function (r) {
            r.identity = r.id;
            return r;
          });
          deferred.resolve(results);
        });
      }
    }
  });
});