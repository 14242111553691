define('scientia-resourcebooker/pods/logged-in/booking-type/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.logged_in.booking_type.index.bookings'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: [{ bookingID: 'id' }],
    bookingID: null,
    showBookingRequest: null,

    bookingTypeSorting: ['name'],
    bookingTypes: _ember['default'].computed.sort('model.bookingTypes', 'bookingTypeSorting'),
    showBookingRequestActions: false,

    bookingIDObserver: (function () {
      var _this = this;

      if (this.get('bookingID')) {
        _ember['default'].run.once(this, function () {
          _this.set('showBookingRequest', _this.get('model.bookingRequest'));
          _this.set('bookingID', null);
        });
      }
    }).observes('model.bookingRequest'),

    actions: {
      onConfirmNavigation: function onConfirmNavigation(bookingType) {
        this.transitionToRoute('logged-in.booking-type.show', bookingType.get('id'));
      }
    }

  });
});