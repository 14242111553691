define("scientia-resourcebooker/pods/manage-resources/user-group/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 10
                },
                "end": {
                  "line": 15,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [14, 12], [14, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.description"], [], ["loc", [null, [14, 73], [14, 130]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 12
                  },
                  "end": {
                    "line": 20,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/edit.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [19, 14], [19, 71]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.claims"], [], ["loc", [null, [19, 71], [19, 123]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 10
                },
                "end": {
                  "line": 21,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [18, 12], [20, 23]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 12
                  },
                  "end": {
                    "line": 26,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [25, 14], [25, 72]]], 0, 0], ["inline", "t", ["pods.manage_resources.user_group.edit.permissions"], [], ["loc", [null, [25, 72], [25, 129]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 10
                },
                "end": {
                  "line": 27,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [24, 12], [26, 23]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [13, 10], [15, 21]]]], ["block", "if", [["subexpr", "can", ["edit-claims user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [17, 52], [17, 67]]], 0, 0, 0, 0]], ["loc", [null, [17, 16], [17, 68]]], 0, 0]], [], 1, null, ["loc", [null, [17, 10], [21, 17]]]], ["block", "if", [["subexpr", "can", ["edit-permission-tab user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [23, 60], [23, 75]]], 0, 0, 0, 0]], ["loc", [null, [23, 16], [23, 76]]], 0, 0]], [], 2, null, ["loc", [null, [23, 10], [27, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/user-group-form", [], ["localePrefix", "components.manage_resources.user_group_edit", "name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [34, 17], [34, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "isDeprecatedRole", ["subexpr", "@mut", [["get", "isResourceOwnerGroup", ["loc", [null, [35, 29], [35, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "isSystemGroup", ["subexpr", "@mut", [["get", "model.userGroup.isSystemGroup", ["loc", [null, [36, 26], [36, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [32, 10], [37, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 10
                },
                "end": {
                  "line": 48,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/claims-form", [], ["localePrefix", "components.manage_resources.claims_form_edit", "claims", ["subexpr", "@mut", [["get", "model.userGroup.claims", ["loc", [null, [44, 21], [44, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [45, 19], [45, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "isDeprecatedRole", ["subexpr", "@mut", [["get", "isResourceOwnerGroup", ["loc", [null, [46, 31], [46, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 12], [47, 14]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 8
              },
              "end": {
                "line": 49,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-panel", [], ["class", "at-claimsForm"], 0, null, ["loc", [null, [41, 10], [48, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 10
                },
                "end": {
                  "line": 55,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h2");
              dom.setAttribute(el1, "class", "manageResources-itemForm-title at-permissionsForm");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["pods.manage_resources.resource_type.edit.permissions_title"], ["name", ["subexpr", "@mut", [["get", "model.userGroup.name", ["loc", [null, [53, 144], [53, 164]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [53, 74], [53, 166]]], 0, 0], ["inline", "manage-resources/permissions-form", [], ["permissions", ["subexpr", "@mut", [["get", "permissions", ["loc", [null, [54, 60], [54, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "permissionsTypes", ["subexpr", "@mut", [["get", "permissionsTypes", ["loc", [null, [54, 89], [54, 105]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [54, 12], [54, 107]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 51,
                "column": 8
              },
              "end": {
                "line": 56,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab-panel", [], ["class", "at-permissionsForm"], 0, null, ["loc", [null, [52, 10], [55, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 58,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [11, 8], [29, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [31, 8], [38, 25]]]], ["block", "if", [["subexpr", "can", ["edit-claims user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [40, 50], [40, 65]]], 0, 0, 0, 0]], ["loc", [null, [40, 14], [40, 66]]], 0, 0]], [], 2, null, ["loc", [null, [40, 8], [49, 15]]]], ["block", "if", [["subexpr", "can", ["edit-permission-tab user-group"], ["model", ["get", "model.userGroup", ["loc", [null, [51, 59], [51, 74]]], 0, 0, 0, 0]], ["loc", [null, [51, 15], [51, 75]]], 0, 0]], [], 3, null, ["loc", [null, [51, 8], [56, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 8
            },
            "end": {
              "line": 61,
              "column": 109
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["general.save"], [], ["loc", [null, [61, 89], [61, 109]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 8
            },
            "end": {
              "line": 62,
              "column": 117
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["general.save_back"], [], ["loc", [null, [62, 92], [62, 117]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/user-group/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-actions manageResources-itemForm-form-actions--dual-action");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(element3, 1, 1);
        morphs[5] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [2, 39], [2, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 53]]], 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.user_group.edit.title"], ["name", ["get", "model.userGroup.name", ["loc", [null, [5, 79], [5, 99]]], 0, 0, 0, 0]], ["loc", [null, [5, 25], [5, 100]]], 0, 0]], ["loc", [null, [5, 4], [5, 102]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [8, 24], [8, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 6], [8, 31]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [10, 59], [10, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [10, 6], [58, 18]]]], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [61, 32], [61, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["save"], [], ["loc", [null, [61, 48], [61, 63]]], 0, 0], "class", "button--primary"], 1, null, ["loc", [null, [61, 8], [61, 126]]]], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [62, 32], [62, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", ["save", true], [], ["loc", [null, [62, 48], [62, 68]]], 0, 0], "class", "button--white"], 2, null, ["loc", [null, [62, 8], [62, 134]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});