define("scientia-resourcebooker/pods/components/manage-resources/booking-form-field/booking-form-field-numeric/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/booking-form-field/booking-form-field-numeric/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item--content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "number", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.no_minimum"], [], ["loc", [null, [2, 36], [2, 107]]], 0, 0], "class", "form-input manageResources-itemForm-form-input manageResources-customFieldItem-valueTypes-integer-input", "value", ["subexpr", "@mut", [["get", "placeholderMin", ["loc", [null, [2, 226], [2, 240]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "displayReadOnly", ["loc", [null, [2, 250], [2, 265]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 267]]], 0, 0], ["inline", "input", [], ["type", "number", "placeholder", ["subexpr", "t", ["components.manage_resources.booking_form_field.numeric.no_maximum"], [], ["loc", [null, [3, 36], [3, 107]]], 0, 0], "class", "form-input manageResources-itemForm-form-input manageResources-customFieldItem-valueTypes-integer-input", "value", ["subexpr", "@mut", [["get", "placeholderMax", ["loc", [null, [3, 226], [3, 240]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "displayReadOnly", ["loc", [null, [3, 250], [3, 265]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 2], [3, 267]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});