define("scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 6
            },
            "end": {
              "line": 5,
              "column": 92
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-labelText--required");
          var el2 = dom.createTextNode("*");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 130
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "input-error at-bookingRequestForm-field-attachment");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["inline", "get", [["subexpr", "get", [["get", "model.validations.attrs", [], 0, 0, 0, 0], "value"], [], [], 0, 0], "message"], [], ["loc", [null, [19, 90], [19, 123]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 20,
                "column": 114
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "input-error at-bookingRequestForm-field-attachment");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "errorMessage", ["loc", [null, [20, 91], [20, 107]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 23,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-fileInput--details");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestForm-fileInput--details");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [9]), 0, 0);
          return morphs;
        },
        statements: [["inline", "file-upload", [], ["url", ["subexpr", "@mut", [["get", "uploadURL", ["loc", [null, [11, 10], [11, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", ["subexpr", "@mut", [["get", "fileInputExtensions", ["loc", [null, [12, 13], [12, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "isUploading", ["loc", [null, [13, 15], [13, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "uploadStarted", ["subexpr", "action", ["uploadStarted"], [], ["loc", [null, [14, 20], [14, 44]]], 0, 0], "uploadProgress", ["subexpr", "action", ["uploadProgress"], [], ["loc", [null, [15, 21], [15, 46]]], 0, 0], "uploadFinished", ["subexpr", "action", ["uploadFinished"], [], ["loc", [null, [16, 21], [16, 46]]], 0, 0], "uploadFailed", ["subexpr", "action", ["uploadFailed"], [], ["loc", [null, [17, 19], [17, 42]]], 0, 0], "class", "form-input bookingRequestForm-input at-fileInput"], ["loc", [null, [10, 6], [18, 64]]], 0, 0], ["block", "if", [["get", "didValidate", ["loc", [null, [19, 12], [19, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 6], [19, 137]]]], ["block", "if", [["get", "errorMessage", ["loc", [null, [20, 12], [20, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 6], [20, 121]]]], ["inline", "t", ["components.booking_request_form.fields.attachment.max_file_size"], ["size", ["subexpr", "@mut", [["get", "maxFileSize", ["loc", [null, [21, 133], [21, 144]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 58], [21, 146]]], 0, 0], ["inline", "t", ["components.booking_request_form.fields.attachment.allowed_file_types"], ["types", ["subexpr", "@mut", [["get", "allowedTypes", ["loc", [null, [22, 139], [22, 151]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [22, 58], [22, 153]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fileUpload--status bs");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "progress");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "progress-bar progress-bar-striped active");
          dom.setAttribute(el3, "role", "progressbar");
          dom.setAttribute(el3, "aria-valuemin", "0");
          dom.setAttribute(el3, "aria-valuemax", "100");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", "sr-only");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("%");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1, 1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element2, 'aria-valuenow');
          morphs[1] = dom.createAttrMorph(element2, 'style');
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-valuenow", ["get", "uploadPercentageSafeString", ["loc", [null, [28, 95], [28, 121]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["get", "uploadPercentageCSS", ["loc", [null, [28, 170], [28, 189]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "uploadPercentageSafeString", ["loc", [null, [29, 30], [29, 60]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 2
            },
            "end": {
              "line": 40,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "fileUpload--status");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "fileUpload--status-filename");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "button button-small fileUpload--deleteFile");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "truncate", [["get", "fileName", ["loc", [null, [37, 55], [37, 63]]], 0, 0, 0, 0], 40], [], ["loc", [null, [37, 44], [37, 68]]], 0, 0], ["element", "action", ["removeFile"], [], ["loc", [null, [38, 10], [38, 33]]], 0, 0], ["inline", "t", ["components.booking_request_form.fields.attachment.remove_file"], [], ["loc", [null, [38, 85], [38, 154]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-form/fields/attachment/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bookingRequestForm-fields at-bookingRequestForm-customFields");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "bookingRequestForm-label");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bookingRequestForm-labelText bookingRequestForm-labelText--block");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(element3, 3, 3);
        morphs[3] = dom.createMorphAt(element3, 4, 4);
        morphs[4] = dom.createMorphAt(element3, 6, 6);
        return morphs;
      },
      statements: [["content", "model.description", ["loc", [null, [4, 6], [4, 27]]], 0, 0, 0, 0], ["block", "if", [["get", "model.isMandatory", ["loc", [null, [5, 12], [5, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [5, 99]]]], ["block", "unless", [["get", "model.value", ["loc", [null, [9, 14], [9, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [9, 4], [23, 17]]]], ["block", "if", [["subexpr", "and", [["get", "isUploading", ["loc", [null, [25, 13], [25, 24]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "isUploaded", ["loc", [null, [25, 30], [25, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 25], [25, 41]]], 0, 0]], [], ["loc", [null, [25, 8], [25, 42]]], 0, 0]], [], 2, null, ["loc", [null, [25, 2], [33, 9]]]], ["block", "if", [["get", "fileName", ["loc", [null, [35, 8], [35, 16]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [35, 2], [40, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});