define('scientia-resourcebooker/services/user-group-persister', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    persist: function persist(_ref) {
      var name = _ref.name;
      var _ref$claims = _ref.claims;
      var claims = _ref$claims === undefined ? [] : _ref$claims;
      var _ref$editClaimsPermissionGroups = _ref.editClaimsPermissionGroups;
      var editClaimsPermissionGroups = _ref$editClaimsPermissionGroups === undefined ? [] : _ref$editClaimsPermissionGroups;

      var userGroup = this.get('store').createRecord('user-group', { name: name, claims: claims, editClaimsPermissionGroups: editClaimsPermissionGroups });

      var promise = userGroup.save();

      promise['catch'](function () {
        userGroup.rollbackAttributes();
      });

      return promise;
    }
  });
});