define('scientia-resourcebooker/pods/components/round-ball/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'span',

        actions: {
            onClick: function onClick() {
                if (!this.attrs.click) return;
                this.attrs.click();
            }
        }
    });
});