define('scientia-resourcebooker/services/booking-form-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    bookingTypeResourceSearcher: _ember['default'].inject.service(),

    search: function search(_ref) /*, bookingTypeId*/{
      var resourceTypeId = _ref.resourceTypeId;
      var resourceGroups = _ref.resourceGroups;
      var query = _ref.query;

      // let url = config.API.RESOURCE_FILTERER_URL.resource.loc(bookingTypeId);

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.ADDITIONAL_RESOURCE_GROUP_FILTERER_URL.loc(resourceTypeId);
      var queryParams = { query: query, itemsPerPage: 20, pageNumber: 1 };
      var newURL = this._appendQueryParamsToURL(queryParams, url);
      var data = {};

      if (!_ember['default'].isEmpty(resourceGroups)) {
        var resourceGroupIds = resourceGroups.getEach('id');
        data.resourceGroupIds = resourceGroupIds;
      }

      return this.get('authenticatedRequest').post(newURL, data);
    },

    _appendQueryParamsToURL: function _appendQueryParamsToURL(queryParams, url) {

      url += '?';
      var paramKeys = Object.keys(queryParams);

      paramKeys.forEach(function (paramKey) {
        url += paramKey + '=' + queryParams[paramKey] + '&';
      });

      return url;
    }
  });
});