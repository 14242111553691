define('scientia-resourcebooker/pods/manage-resources/system/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.system.page_title'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: {
      selectedTab: 'section'
    },

    selectedTab: 0,
    templateProcessor: _ember['default'].inject.service('notificationsTemplatesProcessor'),
    systemSettings: _ember['default'].inject.service(),
    isSaving: false,

    actions: {
      saveTemplate: function saveTemplate(template) {
        var _this = this;

        this.get('templateProcessor').save(template).then(function () {
          _this.flashSuccess(_this.get('i18n').t('components.system.notifications_templates.flash_messages.saved'));
        })['catch'](function () /*error*/{
          _this.flashError(_this.get('i18n').t('components.system.notifications_templates.flash_messages.error_saving'));
        });
      },

      changeMode: function changeMode(mode) {
        this.set('model.options.systemMode', mode);
      },

      saveOptions: function saveOptions() {
        var _this2 = this;

        this.set('isSaving', true);

        this.get('systemSettings').update(this.get('model.options')).then(function (newOptions) {
          _this2.flashSuccess(_this2.get('i18n').t('pods.manage_resources.system.options_saved'));
          _this2.set('model.options', newOptions);

          // If there's an error saving the options, lets show an error and invalidate the model to undo the changes in the UI
        })['catch'](function () {
          _this2.send('invalidateModel');
          _this2.flashError(_this2.get('i18n').t('pods.manage_resources.system.options_not_saved'));
        })['finally'](function () {
          _this2.set('isSaving', false);
        });
      }
    }
  });
});