define('scientia-resourcebooker/pods/components/reports/reports-audit/component', ['exports', 'ember'], function (exports, _ember) {
  // import config from '../../../config/environment';
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tabForm at-reports at-reportsAudit'],

    todayDate: null,
    fromDate: null,
    toDate: null,

    locale: _ember['default'].computed.alias('i18n.locale'),

    dateFormat: (function () {
      return this.get('i18n').t('components.reports.audit.date_format').toString();
    }).property(),

    dateFormatCreated: (function () {
      return this.get('i18n').t('components.reports.audit.date_format_created').toString();
    }).property(),

    reports: [],
    sortedReports: _ember['default'].computed.sort('reports', 'sortedCriteria'),
    sortedCriteria: ['started:desc'],

    dateOptionSelected: 'actionDates',

    dateOptionsContent: (function () {
      return [{
        id: 'useBookingDates',
        label: this.get('i18n').t('components.reports.audit.booking_dates')
      }, {
        id: 'actionDates',
        label: this.get('i18n').t('components.reports.audit.action_dates')
      }];
    }).property('i18n.locale'),

    generateButtonDisabled: _ember['default'].computed('statusMessage', 'fromDate', 'toDate', function () {
      return !_ember['default'].isEmpty(this.get('statusMessage')) || !this.get('fromDate') || !this.get('toDate');
    }),

    statusMessage: _ember['default'].computed('fromDate', 'toDate', function () {
      if (!this.get('fromDate') || !this.get('toDate')) return;

      var fromDate = this.get('fromDate');
      var toDate = this.get('toDate');
      if (fromDate.isAfter(toDate)) {
        return this.get('i18n').t('components.reports.audit.from_date_cannot_be_after_to_date');
      }

      return;
    }),

    actions: {

      setDateOptionType: function setDateOptionType(value) {
        this.set('dateOptionSelected', value.id);
      },

      generate: function generate() {
        var useBookingDates = this.get('dateOptionSelected') === 'useBookingDates';
        this.sendAction('generate', this.get('fromDate'), this.get('toDate'), useBookingDates);
      },

      'delete': function _delete(report) {
        this.sendAction('delete', report);
      },

      download: function download(report) {
        this.sendAction('download', report);
      },

      updateFromDate: function updateFromDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!date.isSame(this.get('fromDate'), 'minute')) {
          this.set('fromDate', date);
        }
      },

      updateToDate: function updateToDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!date.isSame(this.get('toDate'), 'minute')) {
          this.set('toDate', date);
        }
      }
    }
  });
});