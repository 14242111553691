define("scientia-resourcebooker/pods/logged-in/booking-request/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [8, 12], [8, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", ["subexpr", "@mut", [["get", "showBookingRequestActions", ["loc", [null, [9, 32], [9, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [10, 25], [10, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 20], [10, 44]]], 0, 0], false], [], ["loc", [null, [10, 12], [10, 51]]], 0, 0], "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [11, 20], [11, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [12, 18], [12, 47]]], 0, 0]], ["loc", [null, [6, 9], [12, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [14, 32], [14, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 27], [14, 51]]], 0, 0], false], [], ["loc", [null, [14, 19], [14, 58]]], 0, 0]], ["loc", [null, [5, 7], [14, 59]]], 0, 0]], ["loc", [null, [3, 2], [14, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [23, 12], [23, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 9], [23, 34]]], 0, 0]], ["loc", [null, [20, 7], [23, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 37], [24, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 27], [24, 42]]], 0, 0]], [], ["loc", [null, [24, 19], [24, 43]]], 0, 0]], ["loc", [null, [18, 2], [25, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [34, 12], [34, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [35, 25], [35, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 20], [35, 64]]], 0, 0], null], [], ["loc", [null, [35, 12], [35, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [36, 14], [36, 58]]], 0, 0]], ["loc", [null, [32, 9], [36, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [37, 32], [37, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 27], [37, 71]]], 0, 0], null], [], ["loc", [null, [37, 19], [37, 77]]], 0, 0]], ["loc", [null, [31, 7], [37, 78]]], 0, 0]], ["loc", [null, [29, 2], [38, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [47, 25], [47, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 20], [47, 59]]], 0, 0], false], [], ["loc", [null, [47, 12], [47, 66]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_title"], [], ["loc", [null, [48, 19], [48, 95]]], 0, 0], "singleButtonMode", true, "confirmMessage", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_message"], [], ["loc", [null, [50, 21], [50, 99]]], 0, 0], "confirm", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [51, 27], [51, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 22], [51, 61]]], 0, 0], false], [], ["loc", [null, [51, 14], [51, 68]]], 0, 0]], ["loc", [null, [45, 9], [51, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [52, 32], [52, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 27], [52, 66]]], 0, 0], null], [], ["loc", [null, [52, 19], [52, 72]]], 0, 0]], ["loc", [null, [44, 7], [52, 73]]], 0, 0]], ["loc", [null, [42, 2], [53, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["withBookingRequest", ["subexpr", "@mut", [["get", "withBookingRequest", ["loc", [null, [61, 25], [61, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [62, 21], [62, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [63, 22], [63, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "nameWithPrimaryResourceNames", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [65, 25], [65, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 20], [65, 50]]], 0, 0], null], [], ["loc", [null, [65, 12], [65, 56]]], 0, 0], "on-event-click", ["subexpr", "action", ["openBookingRequestOverview"], [], ["loc", [null, [66, 21], [66, 58]]], 0, 0]], ["loc", [null, [59, 9], [66, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [67, 32], [67, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [67, 27], [67, 57]]], 0, 0], null], [], ["loc", [null, [67, 19], [67, 63]]], 0, 0]], ["loc", [null, [58, 7], [67, 64]]], 0, 0]], ["loc", [null, [57, 2], [68, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 8
              },
              "end": {
                "line": 96,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "button button--white button--withIcon button--print sectionTitle-search-button at-bookingRequest");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element4, 1, 1);
            morphs[2] = dom.createMorphAt(element4, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["print"], [], ["loc", [null, [92, 13], [92, 31]]], 0, 0], ["inline", "inline-svg", ["icons/print.svg"], ["class", "button-icon at-sectionTitle-button--white"], ["loc", [null, [93, 12], [93, 94]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.print"], [], ["loc", [null, [94, 12], [94, 62]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 97,
                "column": 8
              },
              "end": {
                "line": 97,
                "column": 48
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "help-trigger", ["loc", [null, [97, 32], [97, 48]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 4
            },
            "end": {
              "line": 100,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle--align-right at-bookingRequest");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 3, 3);
          morphs[2] = dom.createMorphAt(element5, 5, 5);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [84, 22], [84, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", ["subexpr", "action", ["submitSearch"], [], ["loc", [null, [84, 39], [84, 62]]], 0, 0], "placeholder", ["subexpr", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [84, 75], [84, 136]]], 0, 0], "class", "form-input form-input--white sectionTitle-search-input at-bookingRequest"], ["loc", [null, [84, 8], [84, 219]]], 0, 0], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [91, 18], [91, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [91, 8], [96, 19]]]], ["block", "unless", [["get", "media.isTiny", ["loc", [null, [97, 18], [97, 30]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [97, 8], [97, 59]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 115,
                  "column": 10
                },
                "end": {
                  "line": 117,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.resourcesMeta.currentPage", ["loc", [null, [116, 41], [116, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [116, 86], [116, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "totalPages", ["subexpr", "@mut", [["get", "model.resourcesMeta.totalPages", ["loc", [null, [116, 129], [116, 159]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [116, 12], [116, 161]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 112,
                "column": 8
              },
              "end": {
                "line": 118,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "rb-agenda-view", [], ["results", ["subexpr", "@mut", [["get", "model.results", ["loc", [null, [113, 35], [113, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-bookingRequest", "onEventClick", "openBookingRequestOverview"], ["loc", [null, [113, 10], [113, 118]]], 0, 0], ["block", "if", [["get", "shouldShowPaging", ["loc", [null, [115, 16], [115, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [115, 10], [117, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 120,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestSearch");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2, "class", "bookingRequestSearch-title");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("em");
          dom.setAttribute(el4, "class", "bookingRequestSearch-title-query");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.results_for"], [], ["loc", [null, [106, 12], [106, 68]]], 0, 0], ["content", "query", ["loc", [null, [107, 57], [107, 66]]], 0, 0, 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [109, 18], [109, 42]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.clear_search"], [], ["loc", [null, [109, 58], [109, 115]]], 0, 0], ["block", "if", [["get", "model.results.length", ["loc", [null, [112, 14], [112, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [112, 8], [118, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 4
              },
              "end": {
                "line": 124,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.booking_request.index.searchLoading"], [], ["loc", [null, [122, 8], [122, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 126,
                  "column": 6
                },
                "end": {
                  "line": 142,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [128, 25], [128, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "calendarEvents", ["subexpr", "@mut", [["get", "model", ["loc", [null, [130, 25], [130, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "event-id-path", "id", "event-title-path", "nameWithPrimaryResourceNames", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "status", "show-tag-button", false, "on-event-click", "openBookingRequestOverview", "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-sheet-change", "fetchBookingRequests"], ["loc", [null, [127, 8], [141, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 124,
                "column": 4
              },
              "end": {
                "line": 144,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [126, 12], [126, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [126, 6], [142, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 120,
              "column": 4
            },
            "end": {
              "line": 144,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showSearchLoading", ["loc", [null, [120, 14], [120, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [120, 4], [144, 4]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 147,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-bookingRequest");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [11, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[5] = dom.createMorphAt(element6, 1, 1);
        morphs[6] = dom.createMorphAt(element6, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "showBookingRequest", ["loc", [null, [2, 6], [2, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [15, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [17, 6], [17, 27]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [17, 0], [26, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [28, 6], [28, 44]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [39, 7]]]], ["block", "if", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [41, 6], [41, 39]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [41, 0], [54, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [56, 6], [56, 30]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [56, 0], [69, 7]]]], ["block", "section-title", [], ["icon", "icons/calendar.svg", "text", ["subexpr", "t", ["pods.logged_in.booking_request.index.my_bookings"], [], ["loc", [null, [76, 9], [76, 63]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [79, 16], [79, 75]]], 0, 0], "clickAction", "reloadView", "location", "at-bookingRequest"], 5, null, ["loc", [null, [74, 4], [100, 22]]]], ["block", "if", [["get", "showSearchResults", ["loc", [null, [102, 10], [102, 27]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [102, 4], [144, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});