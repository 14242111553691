define('scientia-resourcebooker/pods/components/language-selector/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    localeProcessor: _ember['default'].inject.service(),
    current: _ember['default'].computed.alias('i18n.locale'),

    locales: _ember['default'].computed('i18n.locale', 'i18n.locales', function () {
      var i18n = this.get('i18n');
      if (!this.get('i18n.locales')) return null;

      return this.get('i18n.locales').map(function (loc) {
        return { id: loc, text: i18n.t('languages.' + loc) };
      });
    }),

    actions: {
      changeLocale: function changeLocale(val) {
        this.get('localeProcessor').setLocale(val, true);
      }
    }
  });
});