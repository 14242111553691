define('scientia-resourcebooker/pods/components/time-input/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':timeInput', 'class'],
    systemSettings: _ember['default'].inject.service(),

    subtractHours: null,
    valueObserver: (function () {
      var value = this.get('value');
      if (!value) return null;

      var _value$split = value.split(':');

      var _value$split2 = _slicedToArray(_value$split, 2);

      var hours = _value$split2[0];
      var minutes = _value$split2[1];

      hours = hours === 'null' ? null : hours;
      minutes = minutes === 'null' ? null : minutes;

      this.set('value', (hours || this.get('hours.firstObject.value')) + ':' + (minutes || '00'));
      this.sendAction('onChange', value);
    }).observes('value'),

    value: _ember['default'].computed('timeHours', 'timeMinutes', {
      get: function get() {
        var hours = this.get('timeHours');
        var minutes = this.get('timeMinutes');

        if (hours || minutes) {
          return this.getWithDefault('timeHours', this.get('hours.firstObject.value')) + ':' + this.getWithDefault('timeMinutes', this.get('minutes.firstObject.value'));
        }

        return '';
      },

      // jshint maxcomplexity:10
      set: function set(key, value) {
        if (!value) {
          this.set('timeHours', null);
          this.set('timeMinutes', null);
          return value;
        }

        var _value$split3 = value.split(':');

        var _value$split32 = _slicedToArray(_value$split3, 2);

        var hours = _value$split32[0];
        var minutes = _value$split32[1];

        if (hours === '24') minutes = '00';
        if (hours || minutes) {
          var lastAvailableHourFromList = this.get('hours.lastObject').value;

          this.set('timeHours', hours || this.get('hours.firstObject.value'));
          this.set('timeMinutes', minutes || this.get('minutes.firstObject.value'));

          // If it's the last hour being generated we either show only 00 minutes
          // or if we explicitly subtracted X hours from the list still show all minute options
          if (hours === lastAvailableHourFromList) {
            if (this.get('subtractHours')) {
              this.set('minutes', this.get('allAvailableMinutesForHours'));
            } else {
              this.set('minutes', this.get('availableMinutesForLastHour'));
            }
          } else {
            this.set('minutes', this.get('allAvailableMinutesForHours'));
          }

          // If the set minutes is not a valid option for the current hour reset the minutes to the firstObject available
          if (_ember['default'].isEmpty(this.get('minutes').findBy('label', minutes))) {
            minutes = this.get('minutes.firstObject.label');
          }
          return hours + ':' + minutes;
        }

        return '';
      }
    }),

    hours: _ember['default'].computed('startHour', function () {

      // The start hour is either an hour which we have passed in manually or the instutition start time
      var startIndex = this.get('startHour') || this.get('systemSettings').getSetting('calendarStartTimeOffset');

      var endIndex = 24 - this.get('systemSettings').getSetting('calendarEndTimeOffset');
      if (this.get('subtractHours')) endIndex -= this.get('subtractHours');

      var hours = [];

      for (var index = startIndex; index <= endIndex; index++) {
        hours.push(_ember['default'].Object.create({
          value: index < 10 ? '0' + index : '' + index,
          label: index < 10 ? '0' + index : '' + index
        }));
      }

      return hours;
    }),

    minutes: [_ember['default'].Object.create({ value: '00', label: '00' }), _ember['default'].Object.create({ value: '15', label: '15' }), _ember['default'].Object.create({ value: '30', label: '30' }), _ember['default'].Object.create({ value: '45', label: '45' })],

    allAvailableMinutesForHours: [_ember['default'].Object.create({ value: '00', label: '00' }), _ember['default'].Object.create({ value: '15', label: '15' }), _ember['default'].Object.create({ value: '30', label: '30' }), _ember['default'].Object.create({ value: '45', label: '45' })],

    availableMinutesForLastHour: [_ember['default'].Object.create({ value: '00', label: '00' })]
  });
});