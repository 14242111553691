define('scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkImporterUploader'],
    uploadURL: null,
    isUploading: false,
    isUploaded: false,
    files: [],
    bulkExporter: _ember['default'].inject.service(),

    localePrefix: '',

    disableOptions: _ember['default'].computed('isUploading', 'files.length', function () {
      return this.get('isUploading') || !this.get('files.length');
    }),

    actions: {

      onDownloadTemplate: function onDownloadTemplate() {
        var url = this.get('bulkExporter').getDownloadURLForTemplate({ type: this.get('importType') });
        if (url) {
          window.open(url);
        }
      },

      onDownloadTemplateCustomFields: function onDownloadTemplateCustomFields(customFieldsType) {
        var url = this.get('bulkExporter').getDownloadURLForCustomFieldsTemplates(customFieldsType);
        if (url) {
          window.open(url);
        }
      }

    }
  });
});