define('scientia-resourcebooker/abilities/booking-request', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
    exports['default'] = _emberCan.Ability.extend({
        currentUser: _ember['default'].inject.service(),
        systemSettings: _ember['default'].inject.service(),

        // jshint maxcomplexity:10
        canEdit: (function () {

            // If booking canEdit flag is false do not allow
            if (this.get('systemSettings').getSetting('disableEditing')) return false;
            if (!this.get('bookingRequest.canEdit')) return false;

            if (this.get('systemSettings.isClosed')) return false;
            if (this.get('systemSettings.isAdminOnly') && !this.get('currentUser').isAdmin()) return false;

            var startDateTime = moment(this.get('bookingRequest.startDateTime'));
            if (startDateTime.isAfter(moment())) return true;

            // If set to allow everyone in the ENV return true
            if (this.get('systemSettings').getSetting('allowCancelPastBookings')) return true;

            // If user is admin and it's set to allow admins return true
            if (this.get('currentUser').isAdmin() && this.get('systemSettings').getSetting('allowCancelPastBookingsAdmin')) return true;

            if (this.get('currentUser').isResourceOwner() && this.get('systemSettings').getSetting('allowCancelPastBookingsAdmin')) return true;

            return false;
        }).property('systemSettings.options.systemMode', 'bookingRequest.startDateTime', 'bookingRequest.canEdit'),

        canCancel: (function () {

            // If booking canCancel flag is false do not allow
            if (!this.get('bookingRequest.canCancel')) return false;

            if (this.get('systemSettings.isClosed')) return false;
            if (this.get('systemSettings.isAdminOnly') && !this.get('currentUser').isAdmin()) return false;

            var startDateTime = moment(this.get('bookingRequest.startDateTime'));
            if (startDateTime.isAfter(moment())) return true;

            // If set to allow everyone in the ENV return true
            if (this.get('systemSettings').getSetting('allowCancelPastBookings')) return true;

            // If user is admin and it's set to allow admins return true
            if (this.get('currentUser').isAdmin() && this.get('systemSettings').getSetting('allowCancelPastBookingsAdmin')) return true;

            if (this.get('currentUser').isResourceOwner() && this.get('systemSettings').getSetting('allowCancelPastBookingsAdmin')) return true;

            return false;
        }).property('systemSettings.options.systemMode', 'bookingRequest.startDateTime', 'bookingRequest.canCancel')
    });
});