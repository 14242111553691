define('scientia-resourcebooker/pods/manage-resources/booking-form/edit/controller', ['exports', 'ember'], function (exports, _ember) {

  var ATTACHMENT = 'attachment';
  var LABEL = 'label';
  var MULTIPLECHOICE = 'multiple_choice';
  var NUMERIC = 'numeric';
  var SINGLECHOICE = 'single_choice';
  var TEXTBOX = 'textbox';
  var LINK = 'url';
  var FIELD_TYPES = [ATTACHMENT, LABEL, MULTIPLECHOICE, NUMERIC, SINGLECHOICE, TEXTBOX, LINK];

  exports['default'] = _ember['default'].Controller.extend({

    queryParams: {
      selectedTab: 'section'
    },

    resourceTypeSearcher: _ember['default'].inject.service(),

    selectedTab: 0,
    formTabActiveRank: 0,
    tabRankToRename: null,
    selectedNewFieldType: null,

    showConfirmDeleteTab: null,
    previousTransition: null,
    error: '',
    isSaving: false,

    tabSortingDirection: (function () {
      return this.get('media.isMobile') ? 'y' : 'x';
    }).property('media.isMobile'),

    actions: {

      addItem: function addItem(type) {
        this.set('didValidate', false);
        var section = this.get('model.sections').findBy('rank', this.get('formTabActiveRank'));

        var ranks = section.get('fields').getEach('rank');
        var highestRank = 0;

        if (!_ember['default'].isEmpty(ranks)) {
          highestRank = Math.max.apply(null, ranks);
        }

        var typeName = FIELD_TYPES[type];
        var newItem = this.get('store').createFragment('booking-form-field-' + typeName, {
          fieldType: type,
          fieldTypeName: 'booking-form-field-' + typeName,
          rank: Number.isInteger(highestRank) ? highestRank + 1 : 0,
          isEditing: true,
          isManaging: true
        });

        section.get('fields').pushObject(newItem);
      },

      addTab: function addTab() {

        var ranks = this.get('model.sections').getEach('rank');
        var highestRank = 0;

        if (!_ember['default'].isEmpty(ranks)) {
          highestRank = Math.max.apply(null, ranks);
        }

        var newSection = this.get('store').createFragment('booking-form-section', {
          name: this.get('i18n').t('components.manage_resources.booking_form_tab.new_section_title').toString(),
          rank: Number.isInteger(highestRank) ? highestRank + 1 : 0,
          fields: []
        });
        this.get('model.sections').pushObject(newSection);
        this.set('formTabActiveRank', newSection.get('rank'));
      },

      renameTab: function renameTab(tabRank) {
        if (tabRank === 0) return false; // Do not allow renaming of Main tab
        if (tabRank !== this.get('formTabActiveRank')) return false; // Do not trigger rename if tab is not active
        this.get('model.sections').findBy('rank', tabRank);
        this.set('tabRankToRename', tabRank);
        _ember['default'].run.later(this, function () {
          _ember['default'].$('input.at-bookingFormTab--editing').focus();
        }, 500);
      },

      renameTabFinished: function renameTabFinished(tab) {
        var tabName = tab.getWithDefault('name', '').trim();
        if (!_ember['default'].isEmpty(tabName)) {
          this.set('tabRankToRename', null);
        }
      },

      removeTab: function removeTab(tab) {
        if (tab.get('rank') === 0) return;
        this.set('showConfirmDeleteTab', tab);
      },

      removeTabConfirm: function removeTabConfirm() {
        this.set('formTabActiveRank', 0);
        this.get('model.sections').removeObject(this.get('showConfirmDeleteTab'));
        this.set('showConfirmDeleteTab', null);
        this.get('model.sections').forEach(function (s, i) {
          s.set('rank', i);
        });

        this.set('formTabActiveRank', 0);
      },

      reorderTabs: function reorderTabs(originalModel, sortedSections, draggedModel) {
        if (sortedSections.objectAt(0).get('rank') !== 0) return;

        sortedSections.forEach(function (s, i) {
          s.set('rank', i);
        });

        this.set('formTabActiveRank', draggedModel.get('rank'));
      },

      reorderItems: function reorderItems(originalModel, sortedItems) {
        sortedItems.forEach(function (s, i) {
          s.set('rank', i);
        });
      },

      deleteItem: function deleteItem(rank, field) {
        this.get('model.sections').findBy('rank', rank).get('fields').removeObject(field);
      },

      rollbackItem: function rollbackItem(rank, field) {
        field.rollbackAttributes();
      },

      toggleEditing: function toggleEditing(rank, field) {
        var _this = this;

        if (field.get('isEditing')) {
          field.validate().then(function (_ref) {
            var validations = _ref.validations;

            _this.set('didValidate', true);
            if (validations.get('isValid')) {
              field.toggleProperty('isEditing');
            }
          });
        } else {
          this.set('didValidate', false);
          field.toggleProperty('isEditing');
        }
      },

      searchResourceType: function searchResourceType(query, deferred) {
        this.get('resourceTypeSearcher').search(query).then(function (response) {
          deferred.resolve(response);
        });
      },

      leavePageConfirm: function leavePageConfirm() {
        this.get('model').rollbackAttributes();
        this.get('previousTransition').retry();
      },

      leavePageCancel: function leavePageCancel() {
        this.setProperties({
          previousTransition: null,
          showUnsavedChangesModal: false
        });
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_form.index.forms'),
        linkable: true,
        path: 'manage-resources.booking-form.index'
      }, {
        label: this.get('model.name'),
        linkable: false
      }];
    }).property('model.name', 'i18n.locale')

  });
});