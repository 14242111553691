define('scientia-resourcebooker/services/notifications-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),

    currentUser: _ember['default'].inject.service(),

    isResourceOwner: (function () {
      return this.get('currentUser').isResourceOwner();
    }).property('currentUser.user.roles.[]'),

    fetch: function fetch() {
      var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var page = opts.page || 1;
      var perPage = opts.perPage || 10;
      var data = { pageNumber: page, itemsPerPage: perPage };
      var tabIndex = opts.notificationType || null;
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS.FEED;

      // If there is a tabIndex passed in, match it to the correct notification settings
      if (tabIndex) {
        var notificationSettings = this._getNotificationSettingsByTabIndex(tabIndex);

        // If the settings include notificationType ID include in in the data
        if (notificationSettings.notificationTypeId) {
          data.notificationType = notificationSettings.notificationTypeId;
        }

        // If settings includes requiresAction include in in the data
        if (notificationSettings.requiresAction) {
          data.requiresAction = notificationSettings.requiresAction;
        }
      }

      return this.get('authenticatedRequest').fetch(url, data).then(this._serializeResults);
    },

    getCount: function getCount() {
      var data = arguments.length <= 0 || arguments[0] === undefined ? { requiresAction: false, notificationType: -1 } : arguments[0];

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS.COUNT;
      return this.get('authenticatedRequest').fetch(url, data);
    },

    search: function search(_ref) {
      var page = _ref.page;
      var perPage = _ref.perPage;
      var filters = _ref.filters;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.NOTIFICATIONS.SEARCH;
      url += '?PageNumber=' + page || 1;
      url += '&itemsPerPage=' + (perPage || 10);
      var filtersSerialized = this._serializeSearchFilters(filters);
      return this.get('authenticatedRequest').post(url, filtersSerialized).then(this._serializeResults);
    },

    _serializeSearchFilters: function _serializeSearchFilters(filters) {
      var _this = this;

      return filters.reduce(function (memo, filter) {
        memo[filter.key.camelize()] = _this._formatSearchValue(filter.value);
        return memo;
      }, {});
    },

    _formatSearchValue: function _formatSearchValue(value) {
      var isDate = moment.isMoment(value);
      return isDate ? value.startOf('minute').toISOString() : value;
    },

    // Convert the selected tabIndex to the correct API setttings
    _getNotificationSettingsByTabIndex: function _getNotificationSettingsByTabIndex(tabIndex) {

      var setingsList = {
        0: { notificationTypeId: null }, // All notifications
        1: { notificationTypeId: 2, requiresAction: true }, // To approve (show only requiresAction)
        2: { notificationTypeId: 32 }, // More info
        3: { notificationTypeId: 2, requiresAction: 'false' }, // Requested ( do not show requiresAction)
        4: { notificationTypeId: 8 }, // Accepted
        5: { notificationTypeId: 16 }, // Rejected
        6: { notificationTypeId: 1 } // Cancelled
      };

      return setingsList[tabIndex];
    },

    _serializeResults: function _serializeResults(jsonResults) {
      var results = jsonResults.results;

      // If the status is "Changed", change to "Accepted"
      results = results.map(function (notification) {
        if (notification.notificationType === 'Changed') {
          notification.notificationType = 'Accepted';
        }

        return notification;
      });

      return {
        results: results,
        pagination: {
          currentPage: jsonResults.currentPage || 1,
          totalPages: jsonResults.totalPages || 1
        }
      };
    }
  });
});