define("scientia-resourcebooker/pods/components/system/notifications-templates/list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/system/notifications-templates/list/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "notificationsTemplates-list-item-title");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["notificationsTemplates-list-item ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTemplate.name", ["loc", [null, [3, 55], [3, 76]]], 0, 0, 0, 0], ["get", "notification.name", ["loc", [null, [3, 77], [3, 94]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 51], [3, 95]]], 0, 0], "selected"], [], ["loc", [null, [3, 46], [3, 108]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectTemplate", ["get", "notification", ["loc", [null, [3, 136], [3, 148]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 110], [3, 150]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", ["components.system.notifications_templates.names.", ["subexpr", "to-lower", [["get", "notification.name", ["loc", [null, [4, 136], [4, 153]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 126], [4, 154]]], 0, 0]], [], ["loc", [null, [4, 59], [4, 155]]], 0, 0]], [], ["loc", [null, [4, 55], [4, 157]]], 0, 0], ["inline", "inline-svg", ["icons/pencil.svg"], ["class", "notificationsTemplates-list-item-icon-edit"], ["loc", [null, [5, 2], [5, 86]]], 0, 0]],
        locals: ["notification"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/system/notifications-templates/list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "each", [["get", "notificationsTemplates", ["loc", [null, [2, 8], [2, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [6, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});