define('scientia-resourcebooker/pods/logged-in/notifications/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/page-changer'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsPageChanger) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _scientiaResourcebookerMixinsPageChanger['default'], {

    notificationRequestProcessor: _ember['default'].inject.service(),
    notificationsFetcher: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    notification: null,

    showAcceptModal: null,
    showRejectModal: null,
    showMoreInfoModal: null,
    showNotesModal: null,

    loadingBookingRequest: false,
    isLoadingNotifications: false,

    isRejecting: false,
    isConfirmingMoreInfo: false,

    hasNotifications: _ember['default'].computed.gt('model.results.length', 0),
    hasSearchOptions: _ember['default'].computed.gt('searchOptionsSelected.length', 0),
    // shouldShowPaging: Ember.computed.gt('model.pagination.totalPages', 1),
    shouldShowPaging: _ember['default'].computed.gt('model.results.length', 0),

    queryParams: ['page', { selectedTab: 'type' }, 'perPage'],
    perPage: 10,
    page: 1,
    selectedTab: 3,

    toApproveCount: 0,
    moreInfoActionCount: 0,

    searchQuery: '',
    lastSearchOptionSelected: null,
    searchOptionsSelected: [],
    historyBookingId: null,

    notificationTypes: ['event_name', 'notification_type', 'booker_name', 'resource_name', 'booking_reference', 'actioned_by', 'created_at_start_range', 'created_at_end_range', 'booking_date_start_range', 'booking_date_end_range'],

    searchOptions: _ember['default'].computed(function () {
      var _this = this;

      var localePath = 'components.notification_searcher.search_options';
      return this.get('notificationTypes').map(function (type) {
        return { value: type, id: type, name: _this.get('i18n').t(localePath + '.' + type) };
      });
    }),

    selectedTabChanged: _ember['default'].observer('selectedTab', function () {
      _ember['default'].run.once(this, function () {
        this.set('page', 1);
      });
    }),

    actions: {

      searchNotifications: function searchNotifications() {

        if (_ember['default'].isEmpty(this.get('lastSearchOptionSelected')) || _ember['default'].isEmpty(this.get('searchQuery'))) return;

        var searchOption = this.get('lastSearchOptionSelected');
        var searchQuery = this.get('searchQuery');
        var searchOptionsSelected = this.get('searchOptionsSelected');

        if (_ember['default'].isEmpty(searchOptionsSelected)) this.set('isLoadingNotifications', true);

        var existingSearchExists = searchOptionsSelected.findBy('key', searchOption);
        if (existingSearchExists) searchOptionsSelected.removeObject(existingSearchExists);

        searchOptionsSelected.pushObject(_ember['default'].Object.create({
          key: searchOption,
          name: this.get('i18n').t('components.notification_searcher.search_options.' + searchOption),
          value: searchQuery }));

        this.setProperties({
          lastSearchOptionSelected: null,
          searchQuery: '',
          page: 1 });

        this.send('reload');
      },

      clearSearch: function clearSearch() {
        this.setProperties({
          searchOptionsSelected: [],
          page: 1
        });
        this.send('reload');
      },

      removeSearchTerm: function removeSearchTerm(key) {
        var searches = this.get('searchOptionsSelected');
        var toRemove = searches.findBy('key', key);
        searches.removeObject(toRemove);
        this.set('page', 1);

        this.send('reload');
      },

      acceptRequest: function acceptRequest(request) {
        this.set('showAcceptModal', request);
        // this.send('confirmAcceptRequest', request);
      },

      rejectRequest: function rejectRequest(request) {
        this.set('showRejectModal', request);
      },

      moreInfoRequest: function moreInfoRequest(request) {
        this.set('showMoreInfoModal', request);
      },

      viewNotes: function viewNotes(request) {
        this.set('showNotesModal', request);
      },

      confirmAcceptRequest: function confirmAcceptRequest() {
        var _this2 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isAccepting', true);
        var notificationId = this.get('showAcceptModal.eventId');

        this.get('notificationRequestProcessor').accept(this.get('showAcceptModal'), note).then(function () {
          _this2.flashSuccess(_this2.get('i18n').t('components.notification.flash_messages.accepted_booking'));
          _ember['default'].$('.notificationTab--accepted').addClass('dc-tabs-tabChanged');
          _ember['default'].run.later(_this2, function () {
            _ember['default'].$('.notificationTab--accepted').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);
        })['catch'](function () {
          _this2.flashError(_this2.get('i18n').t('components.notification.flash_messages.error_accept_booking'));
        })['finally'](function () {
          _this2.set('isAccepting', false);
          _this2.set('showAcceptModal', false);
          _this2.send('reload');
        });
      },

      showRejectModal: function showRejectModal() {
        var _this3 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isRejecting', true);
        var notificationId = this.get('showRejectModal.eventId');

        this.get('notificationRequestProcessor').reject(this.get('showRejectModal'), note).then(function () {
          _this3.set('showRejectModal', null);
          _this3.flashSuccess(_this3.get('i18n').t('components.notification.flash_messages.rejected_booking'));

          _ember['default'].$('.notificationTab--rejected').addClass('dc-tabs-tabChanged');

          _ember['default'].run.later(_this3, function () {
            _ember['default'].$('.notificationTab--rejected').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);

          _this3.send('reload');
        })['catch'](function () {
          _this3.flashError(_this3.get('i18n').t('components.notification.flash_messages.error_reject_booking'));
        })['finally'](function () {
          _this3.set('isRejecting', false);
        });
      },

      showMoreInfoModal: function showMoreInfoModal() {
        var _this4 = this;

        var note = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        this.set('isConfirmingMoreInfo', true);
        var notificationId = this.get('showMoreInfoModal.eventId');

        this.get('notificationRequestProcessor').moreInfo(this.get('showMoreInfoModal'), note).then(function () {
          _this4.set('showMoreInfoModal', null);
          _this4.flashSuccess(_this4.get('i18n').t('components.notification.flash_messages.moreinfo_booking'));

          _ember['default'].$('.notificationTab--more-info').addClass('dc-tabs-tabChanged');

          _ember['default'].run.later(_this4, function () {
            _ember['default'].$('.notificationTab--more-info').removeClass('dc-tabs-tabChanged');
            _ember['default'].$('.notificationsList-item[data-notificationid=' + notificationId + ']').fadeOut('slow', function () {
              _ember['default'].$(this).remove();
            });
          }, 1000);

          _this4.send('reload');
        })['catch'](function () {
          _this4.flashError(_this4.get('i18n').t('components.notification.flash_messages.error_moreinfo_booking'));
        })['finally'](function () {
          _this4.set('isConfirmingMoreInfo', false);
        });
      },

      openEventOverview: function openEventOverview(request) {
        var _this5 = this;

        this.set('loadingBookingRequest', request.eventId);

        var bookingRequestCached = this.store.peekAll('booking-request').findBy('id', request.eventId);
        if (bookingRequestCached) {
          this.store.unloadRecord(bookingRequestCached);
        }

        this.store.find('booking-request', request.eventId).then(function (bookingRequest) {
          _this5.set('historyBookingId', request.eventId);
          _this5.set('loadingBookingRequest', false);
          _this5.set('showBookingRequestActions', false);
          _this5.set('showBookingRequest', bookingRequest);
        })['catch'](function () {
          _this5.flashError(_this5.get('i18n').t('components.notification.flash_messages.unable_to_fetch_booking'));
          _this5.set('loadingBookingRequest', false);
        });
      }
    }
  });
});