define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    isVisible: _ember['default'].computed.alias('field.isVisible'),

    valueTypeComponent: (function () {
      return 'booking-request-form/description-custom-fields/' + this.get('field').valueType;
    }).property('field'),

    actions: {
      clearPublishedTime: function clearPublishedTime(label) {
        this.sendAction('clearPublishedTime', label);
      }
    }
  });
});