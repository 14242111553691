define("scientia-resourcebooker/pods/components/resources-list/items/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "class", "resourcesList-item-icon resourcesList-item-icon--group");
            dom.setAttribute(el1, "src", "icons/folder.svg");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "resourcesList-item-infos");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "resourcesList-item-small");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "resourcesList-item-name");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(element0, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["badge at-resourcesList-item ", ["subexpr", "if", [["get", "resourceGroup.resourceIds.length", ["loc", [null, [6, 52], [6, 84]]], 0, 0, 0, 0], "badge--dark"], [], ["loc", [null, [6, 47], [6, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "resourceGroup.resourceIds.length", ["loc", [null, [6, 102], [6, 138]]], 0, 0, 0, 0], ["content", "resourceGroup.name", ["loc", [null, [9, 46], [9, 68]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "resourcesList-item");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "link-to", ["logged-in.booking-type.show", ["get", "bookingType.id", ["loc", [null, [3, 45], [3, 59]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["date", ["get", "firstDay", ["loc", [null, [3, 79], [3, 87]]], 0, 0, 0, 0], "fromTime", ["get", "calendarFromTime", ["loc", [null, [3, 97], [3, 113]]], 0, 0, 0, 0], "toTime", ["get", "calendarToTime", ["loc", [null, [3, 121], [3, 135]]], 0, 0, 0, 0], "resourceGroupId", ["get", "resourceGroup.id", ["loc", [null, [3, 152], [3, 168]]], 0, 0, 0, 0]], ["loc", [null, [3, 60], [3, 169]]], 0, 0]], ["class", "resourcesList-item-link"], 0, null, ["loc", [null, [3, 4], [11, 16]]]]],
        locals: ["resourceGroup"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "resources-list/item", [], ["resource", ["subexpr", "@mut", [["get", "resource", ["loc", [null, [17, 13], [17, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "groupId", ["subexpr", "@mut", [["get", "groupId", ["loc", [null, [18, 12], [18, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "firstDay", ["subexpr", "@mut", [["get", "calendarDate", ["loc", [null, [19, 13], [19, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "lastDay", ["subexpr", "@mut", [["get", "lastDay", ["loc", [null, [20, 12], [20, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "fromTime", ["subexpr", "@mut", [["get", "calendarFromTime", ["loc", [null, [21, 13], [21, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "toTime", ["subexpr", "@mut", [["get", "calendarToTime", ["loc", [null, [22, 11], [22, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingType", ["subexpr", "@mut", [["get", "bookingType", ["loc", [null, [23, 16], [23, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 2], [23, 29]]], 0, 0]],
        locals: ["resource"],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "resourcesList-item resourcesList-item--empty");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["components.resources_list.items.empty"], [], ["loc", [null, [26, 61], [26, 106]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "resourceGroups.results", ["loc", [null, [25, 12], [25, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [25, 2], [27, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resources-list/items/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "resourceGroups.results", ["loc", [null, [1, 8], [1, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [13, 9]]]], ["block", "each", [["get", "resources.results", ["loc", [null, [15, 8], [15, 25]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [15, 0], [28, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});