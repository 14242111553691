define('scientia-resourcebooker/pods/components/week-time-picker/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    busyPatterns: [],
    systemSettings: _ember['default'].inject.service(),

    weekdays: (function () {
      var _this = this;

      // Make sure we offset the weekdays if there is a weekday offset in the ENV file
      var weekDays = this.get('i18n').t('general.weekdays').toString().split(',');
      var weekDaysCopy = weekDays.copy();
      var weekDayOffset = this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset');
      for (var i = 0; i < weekDayOffset; i++) {
        weekDaysCopy.push(weekDaysCopy.shift());
      }

      var result = weekDaysCopy.map(function (name, index) {

        var indexOffset = index + weekDayOffset;
        indexOffset = indexOffset >= 7 ? indexOffset - 7 : indexOffset;
        return {
          name: name,
          busyPatterns: _this.get('busyPatterns')[indexOffset],
          indexOffset: indexOffset
        };
      });

      return result;
    }).property('busyPatterns.[]'),

    hours: (function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      var startIndex = 0;
      var endIndex = 24;

      for (var index = startIndex; index < endIndex; index++) {
        hours.push(startTime.clone().add(index, 'hours'));
      }

      return hours;
    }).property(),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.sendAction('onInsertElement', moment(), moment());
      });
    }).on('init'),

    actions: {
      showAddSlot: function showAddSlot(weekdayIndex, hour) {
        this.sendAction('showAddSlot', weekdayIndex, hour.hours());
      },

      showRemoveSlot: function showRemoveSlot(weekdayIndex, fromTime, toTime, dayOffset, dstDifference) {
        this.sendAction('showRemoveSlot', weekdayIndex, fromTime, toTime, dayOffset, dstDifference);
      }
    }
  });
});