define('scientia-resourcebooker/pods/manage-resources/resource/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),

    queryParams: {
      formattedDisplayDate: { replace: true, refreshModel: false },
      bookingId: { replace: true, refreshModel: true },
      occurrenceId: { replace: true, refreshModel: true }
    },

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource.show.page_title') + ' — ' + model.resource.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),

    model: function model(params, transition) {
      return _ember['default'].RSVP.hash({
        resource: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: transition.params['manage-resources.resource'].resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        bookingTypes: this.store.findAll('booking-type')
      });
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id')
      });

      this.store.find('resource-type', model.resource.get('resourceTypeId')).then(function (resourceType) {
        return model.resource.set('resourceType', resourceType);
      });
    },

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        var con = this.controllerFor('manage-resources.resource.show');
        if (con.get('bookingId')) {
          var occurrenceId = con.get('occurrenceId');
          con.send('openBookingRequestOverview', con.get('bookingId'), occurrenceId);
          //con.setProperties({bookingId: null, occurrenceId: null});
        }
      },

      // willTransition(transition) {
      //   this._super(...arguments);
      //   let con = this.controllerFor('manage-resources.resource.show');
      //   let booking = con.get('showBookingRequest');
      //   if (booking) {
      //     this.store.unloadRecord(booking);
      //   }

      //   // We want to be sure that all is reloaded from the API
      //   // when going in the logged-in section

      //   // This is preventing us from editing bookings in the resourceCalendar as we're setting a booking and some
      //   // resources in the store and they're getting unloaded here
      //   // One idea is to only run unloadAll if there is no bookingRequestToEdit in the logged-in.resource.show controller
      //   if (transition.targetName.indexOf('manage-resources') !== 0) {
      //     this.store.unloadAll();
      //   }

      // },

      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          showBookingRequest: null,
          bookingId: null,
          occurrenceId: null,
          editRestrictionsModal: null
        });
      }
    }
  });
});