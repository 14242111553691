define('scientia-resourcebooker/pods/manage-resources/resource-type/show/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    appInsights: _ember['default'].inject.service(),

    queryParams: {
      query: { refreshModel: true },
      page: { refreshModel: true },
      groupPage: { refreshModel: true },
      perPage: { refreshModel: true },
      groupsPerPage: { refreshModel: true }
    },

    titleToken: function titleToken(model) {
      return model.resourceType.get('name') + ' — ' + this.get('i18n').t('pods.manage_resources.resource_type.show.resource_types');
    },

    nestedResourceStore: _ember['default'].inject.service(),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: params.id, adminPermissionsOnly: true }),
        resourceGroups: this.get('nestedResourceStore').fetch({ type: 'resourceType.resourceGroup', parentId: params.id, data: { pageNumber: params.groupPage || 1, itemsPerPage: params.groupsPerPage, query: params.query, getAssociatedTemplates: true, adminPermissionsOnly: true } }),
        resources: this.get('nestedResourceStore').fetch({ type: 'resourceType.resource', parentId: params.id, data: { pageNumber: params.page, query: params.query, itemsPerPage: params.perPage || 10, adminPermissionsOnly: true } })
      });
    },

    afterModel: function afterModel(model, transition) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resourceType.get('name'),
        id: model.resourceType.get('id'),
        page: transition.queryParams.page || '1'
      });
    },

    resetController: function resetController(controller, isExiting) {

      if (isExiting) {

        controller.setProperties({
          page: 1,
          groupPage: 1,
          query: '',
          boundQuery: '',
          perPage: 10,
          groupsPerPage: 10
        });
      }
    },

    actions: {
      reloadResourceGroups: function reloadResourceGroups() {
        var params = this.paramsFor(this.get('routeName'));
        var model = this.modelFor('manage-resources.resource-type.show');

        this.get('nestedResourceStore').fetch({ type: 'resourceType.resourceGroup', parentId: model.resourceType.get('id'), data: { pageNumber: params.groupPage, adminPermissionsOnly: true } }).then(function (resourceGroups) {
          model.resourceGroups.clear();
          model.resourceGroups.pushObjects(resourceGroups);
        });
      },

      reloadResources: function reloadResources() {
        var params = this.paramsFor(this.get('routeName'));
        var model = this.modelFor('manage-resources.resource-type.show');

        this.get('nestedResourceStore').fetch({ type: 'resourceType.resource', parentId: model.resourceType.get('id'), data: { pageNumber: params.page, adminPermissionsOnly: true } }).then(function (resources) {
          model.resources.clear();
          model.resources.pushObjects(resources);
        });
      }
    }
  });
});