define('scientia-resourcebooker/services/booking-request-updater', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    updateRequest: function updateRequest(_ref) {
      var bookingRequestId = _ref.bookingRequestId;
      var startDateTime = _ref.startDateTime;
      var endDateTime = _ref.endDateTime;
      var recurrencePattern = _ref.recurrencePattern;
      var updateOccurrencePattern = _ref.updateOccurrencePattern;
      var description = _ref.description;
      var name = _ref.name;
      var resources = _ref.resources;
      var formData = _ref.formData;

      var record = this.get('store').peekRecord('booking-request', bookingRequestId);
      var request = record.toJSON();
      request.name = name;
      request.identity = record.get('eventId');
      request.description = description.trim();
      request.startDateTime = startDateTime.startOf('minute').toISOString();
      request.endDateTime = endDateTime.startOf('minute').toISOString();
      request.bookingTypeIdentity = record.get('bookingTypeId');
      request.recurrencePattern = recurrencePattern || null;
      request.skipUpdateOccurrencePattern = !updateOccurrencePattern;
      request.bookingRequestFormValue = formData;
      request.resources = resources.map(function (resource) {
        return {
          identity: _ember['default'].get(resource, 'id'),
          resourceRestrictionId: _ember['default'].get(resource, 'resourceRestrictionId'),
          isPrimary: _ember['default'].get(resource, 'isPrimary') };
      });
      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.SINGLE.loc(record.get('eventId')), request);
    },

    updateOccurrence: function updateOccurrence(_ref2) {
      var parentId = _ref2.parentId;
      var occurrenceId = _ref2.occurrenceId;
      var startDateTime = _ref2.startDateTime;
      var endDateTime = _ref2.endDateTime;
      var resources = _ref2.resources;

      var record = this.get('store').peekRecord('booking-request', occurrenceId);
      var request = record.toJSON();

      request.startDateTime = startDateTime.startOf('minute').toISOString();
      request.endDateTime = endDateTime.startOf('minute').toISOString();
      request.resources = resources.map(function (resource) {
        return {
          identity: _ember['default'].get(resource, 'id'),
          resourceRestrictionId: _ember['default'].get(resource, 'resourceRestrictionId'),
          isPrimary: _ember['default'].get(resource, 'isPrimary') };
      });
      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_REQUEST.OCCURRENCE.loc(parentId, occurrenceId), request);
    }
  });
});