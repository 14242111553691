define('scientia-resourcebooker/pods/components/manage-resources/rules-form/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var LOCALE_PREFIX = 'components.manage_resources.rules_form';
  var MAXIMUM_DAYS_IN_ADVANCE_RULE_ID = 'c187ca70-c4a0-4e54-9b4b-8e7d95494bb2';

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    classNames: ['manageResources-rulesForm'],

    disabled: false,

    existingRules: [],
    availableRules: [],

    availableRulesI18n: _ember['default'].computed('availableRules', 'i18n.locale', function () {
      var _this = this;

      return this.get('availableRules').map(function (rule) {
        var path = 'components.manage_resources.rules_form.rules.' + rule.identity;
        var name = _this.get('i18n').t(path) ? _this.get('i18n').t(path).toString() : rule.name;
        _ember['default'].set(rule, 'name', name);
        return rule;
      });
    }),

    ruleIdSelected: null,
    ruleToAdd: null,

    isAddingRule: false,
    isSavingRule: false,

    resource: null,
    rulesProcessor: _ember['default'].inject.service(),
    userGroupSearcher: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),

    // This will remove any invalid class when a field is focused
    // If it was set by the validation function in the route
    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        if (!this.get('isDestroyed')) {
          _ember['default'].run.later(function () {
            _ember['default'].$(document).on('focusin', '.manageResources-itemForm-form-item', function () {
              _ember['default'].$(this).removeClass('rule-property-invalid');
            });
          });
        }
      });
    }).on('init'),

    // Remove the event listener for the fields
    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(document).off('focusin', '.manageResources-itemForm-form-item');
    },

    actions: {
      addNewRule: function addNewRule() {
        if (this.get('disabled')) return false;
        if (!this.get('ruleIdSelected')) return;

        // Let's create a rule object from a rule type
        var rule = this.get('availableRules').findBy('identity', this.get('ruleIdSelected'));
        var ruleToAdd = _ember['default'].Object.create(JSON.parse(JSON.stringify(rule))); // Let's clone the rule template

        // Move rule type related properties to ruleType property
        ruleToAdd.ruleType = {
          name: ruleToAdd.name,
          identity: ruleToAdd.id,
          runOnCreateType: ruleToAdd.runOnCreateType,
          runOnUpdateType: ruleToAdd.runOnUpdateType,
          runOnDeleteType: ruleToAdd.runOnDeleteType,
          runOnUpdateAsCreate: ruleToAdd.runOnUpdateAsCreate
        };

        if (this.get('ruleIdSelected') === MAXIMUM_DAYS_IN_ADVANCE_RULE_ID) {
          ruleToAdd.properties.filterBy('valueType', 'Bool').forEach(function (prop) {
            return prop.value = prop.value || false;
          });
        }

        ruleToAdd.properties.filterBy('valueType', 'NumberArray').setEach('value', [0, 0]);

        // Set default values for actions
        ruleToAdd.runOnCreate = ruleToAdd.ruleType.runOnCreateType === 1 ? true : false;
        ruleToAdd.runOnUpdate = ruleToAdd.ruleType.runOnUpdateType === 1 ? true : false;
        ruleToAdd.runOnDelete = ruleToAdd.ruleType.runOnDeleteType === 1 ? true : false;

        // Delete unnecessary properties
        var props = ['name', 'id', 'identity', 'runOnCreateType', 'runOnUpdateType', 'runOnDeleteType', 'runOnUpdateAsCreate'];
        props.forEach(function (prop) {
          delete ruleToAdd[prop];
        });

        this.set('ruleToAdd', ruleToAdd);
        this.set('isAddingRule', true);
      },

      saveNewRule: function saveNewRule() {
        var _this2 = this;

        if (this.get('disabled')) return false;
        var ruleToAdd = JSON.parse(JSON.stringify(this.get('ruleToAdd')));

        // This sets the Update property to the same as the create property if the ruleType is runOnUpdateAsCreate
        if (ruleToAdd.ruleType.runOnUpdateAsCreate) {
          ruleToAdd.runOnUpdate = ruleToAdd.runOnCreate;
        }

        // Validate the properties of the new rule to make sure nothing is missing
        var errorValidatingNewRule = this._newRuleValidate(ruleToAdd);
        if (errorValidatingNewRule) {
          this.flashError(this.get('i18n').t(LOCALE_PREFIX + '.flash_messages.new_rule_validation_failed'));
          return;
        }

        this.set('isSavingRule', true);

        // Also make sure our rule is a copy and not the original one
        this.get('rulesProcessor').create({ model: this.get('resource'), rule: ruleToAdd }).then(function (newRule) {
          _this2.set('isAddingRule', false);
          _this2.get('existingRules').pushObject(newRule);
          _this2.set('ruleToAdd', null);
          _this2.flashSuccess(_this2.get('i18n').t(LOCALE_PREFIX + '.flash_messages.created'));
        })['catch'](function () {
          _this2.flashError(_this2.get('i18n').t(LOCALE_PREFIX + '.flash_messages.error_creating'));
        })['finally'](function () {
          _this2.set('isSavingRule', false);
        });
      },

      cancelNewRule: function cancelNewRule() {
        this.set('ruleToAdd', null);
        this.set('isAddingRule', false);
      },

      deleteRule: function deleteRule(rule) {
        var _this3 = this;

        if (this.get('disabled')) return false;
        this.get('globalConfirmer').confirm(this.get('i18n').t(LOCALE_PREFIX + '.confirm_delete')).then(function () {
          return _this3.send('confirmDeleteRule', rule);
        });
      },

      confirmDeleteRule: function confirmDeleteRule(rule) {
        var _this4 = this;

        this.get('rulesProcessor')['delete']({ model: this.get('resource'), rule: rule }).then(function () {
          _this4.flashSuccess(_this4.get('i18n').t(LOCALE_PREFIX + '.flash_messages.deleted'));
          _this4.get('existingRules').removeObject(rule);
        })['catch'](function () {
          _this4.flashError(_this4.get('i18n').t(LOCALE_PREFIX + '.flash_messages.error_deleting'));
        });
      },

      searchUserGroups: function searchUserGroups(query, deferred) {
        _ember['default'].run.debounce(this, '_searchUserGroups', query, deferred, 1000);
      }
    },

    _searchUserGroups: function _searchUserGroups(query, deferred) {
      this.get('userGroupSearcher').search(query).then(function (data) {
        deferred.resolve(data);
      });
    },

    // Check for any invalid properties in the creation of a new rule (Existing rules are checked in the route)
    // jshint maxcomplexity:10
    _newRuleValidate: function _newRuleValidate(rule) {

      var errors = [];
      var properties = rule.properties;

      // Validate each rule property
      properties.forEach(function (property) {
        var valueType = property.valueType.toLowerCase();
        var value = property.value;
        if (valueType === 'bool') {
          return; // Don't check boolean props as they are always false (true/false)

          // Set date default here
        } else if (valueType === 'date') {
            if (_ember['default'].isEmpty(property.value)) property.value = moment().startOf('day').toISOString();
          } else if (valueType === 'number') {
            if (value === undefined || !Number.isInteger(parseInt(value, 10)) || parseInt(value, 10) < 0) {
              errors.push(property.name);
            }
          } else {
            if (_ember['default'].isEmpty(property.value) || !property.value) {
              errors.push(property.name);
            }
          }
      });

      // Validate "Applies to" selection (Is any user group selected?)
      if (_ember['default'].isEmpty(rule.appliesTo)) errors.push('applies_to');

      // Validates "Action" selection ( Create / Update / Delete)
      if (!rule.runOnCreate && !rule.runOnUpdate && !rule.runOnDelete) errors.push('action');

      // Validate Exemption User Groups
      if (rule.permissionGroupsExempt && _ember['default'].isEmpty(rule.exemptPermissionGroups)) {
        errors.push('exemptiongroup');
      }

      // If there are errors display an error message and highlight them
      if (!_ember['default'].isEmpty(errors)) {
        this._newRuleValidateHighlightErrors(errors);
        return errors;
      }

      return false;
    },

    // Highlight fields which are invalid
    _newRuleValidateHighlightErrors: function _newRuleValidateHighlightErrors(errors) {
      var _this5 = this;

      var itemClass = 'manageResources-itemForm-form-item';

      errors.forEach(function (error) {
        var containerObject = '.manageResources-itemForm-form-group-newRule';
        var itemObject = '.' + itemClass + '[data-property-name="' + error + '"]';
        _this5.$().find(containerObject + ' ' + itemObject).addClass('rule-property-invalid');
      });
    }

  });
});