define('scientia-resourcebooker/pods/components/booking-request-form/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog, _emberCan) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], _emberCan.CanMixin, {
    tagName: 'form',
    classNames: ['bookingRequestForm-content'],
    classNameBindings: ['bookingTypeNameClass'],

    systemOptions: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    bookingTypeNameClass: (function () {
      return ('form-' + this.get('bookingType.name')).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }).property('bookingType'),

    recurrenceProcessor: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    isEditing: _ember['default'].computed.bool('model.id'),
    isNew: _ember['default'].computed.not('isEditing'),
    isSaving: false,

    enableDescriptionFormField: _ember['default'].computed.alias('systemSettings.options.enableDescriptionFormField'),

    bookingDescription: _ember['default'].computed.readOnly('model.description'),
    shouldShowDescriptionField: _ember['default'].computed('isEditing', 'enableDescriptionFormField', 'model.hasDirtyAttributes', function () {

      if (this.get('enableDescriptionFormField')) return true;
      if (!this.get('model')) return false; // fallback when the model gets unloaded

      var changedAttrs = this.get('model').changedAttributes();
      var hasOriginalDescription = this.get('model.hasDirtyAttributes') && !_ember['default'].isEmpty(changedAttrs.description) && !_ember['default'].isEmpty(changedAttrs.description[0]) || !_ember['default'].isEmpty(this.get('model.description'));
      return this.get('isEditing') && hasOriginalDescription;
    }),

    resourcesSelected: [],

    // BOBO
    configEnableBoBo: _ember['default'].computed.bool('systemSettings.options.enableBobo'),
    canBookOnBehalfOf: _ember['default'].computed.and('configEnableBoBo', 'bookingType.canBookOnBehalfOf', 'isNew'),

    // BASE
    configEnableBase: _ember['default'].computed.bool('systemSettings.options.enableBase'),
    canBookAsSomeoneElse: _ember['default'].computed.and('configEnableBase', 'bookingType.canBookAsSomeoneElse', 'isNew'),

    spanMultipleDays: false,
    notSpanMultipleDays: _ember['default'].computed.not('spanMultipleDays'),

    bookingMode: 1, // 1 for single booking, 2 for Recurring
    repeatBooking: _ember['default'].computed.equal('bookingMode', 2),

    resource: null,

    // Custom Fields
    hasCUHKCustomFields: _ember['default'].computed.bool('systemSettings.options.customFieldsCUHK'),
    showCUHKCustomFields: _ember['default'].computed.and('isNew', 'hasCUHKCustomFields'),
    cuhkCustomFields: { activityTypeSelected: null, costCentre: null, projectCode: null },

    hasDescriptionCustomFields: _ember['default'].computed.bool('systemSettings.options.customFieldsLSE'),
    showDescriptionCustomFields: _ember['default'].computed.and('isNew', 'hasDescriptionCustomFields'),
    customFieldsValue: '',
    customFieldsIsValid: true,
    customFieldsErrorMessage: '',
    customFieldsDescriptionMaxLength: 80,

    cuhkCustomFieldsDescription: '',

    bookingForm: null,
    didValidate: false,

    updateOccurrencePattern: false,

    customFieldsDescriptionLength: (function () {
      return this.get('model.description.length') || '0';
    }).property('model.description.length'),

    // This is the current HOUR value set on the "FromTime" filter so that we can pass it to the "ToTime" filter
    // so it will only render times after the "FromTime" unless the spanMultipleDays is selected
    fromTimeHour: _ember['default'].computed('model.fromTime', function () {
      if (this.get('model.fromTime')) {
        return parseInt(this.get('model.fromTime').split(':')[0]);
      }
    }),

    disableRecurring: _ember['default'].computed('bookingRequestToEditIsSingleOccurrence', 'bookingType.id', function () {
      return this.get('bookingRequestToEditIsSingleOccurrence') || !this.can('recurring booking-type', { model: this.get('bookingType') });
    }),

    didInsertElement: function didInsertElement() {
      if (!this.get('model.name')) this.$().find('.bookingRequestForm-title-input').focus();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },

    submit: function submit(event) {
      event.preventDefault();
      this.send('save');
    },

    lseCustomFields: [_ember['default'].Object.create({ label: 'published_start_time', valueType: 'time-picker', value: null, inline: true }), _ember['default'].Object.create({ label: 'published_end_time', valueType: 'time-picker', value: null, inline: true }), _ember['default'].Object.create({ valueType: 'separator' }), _ember['default'].Object.create({ label: 'outside_school_organization', valueType: 'boolean', value: 'no' }), _ember['default'].Object.create({ label: 'organization_funding', valueType: 'boolean', value: 'no' }), _ember['default'].Object.create({ label: 'organization_funding_details', valueType: 'text', maxLength: 100, value: '', isVisible: false }), _ember['default'].Object.create({ valueType: 'separator' }), _ember['default'].Object.create({ label: 'audience', valueType: 'radio-choices', value: 'LSE only', choices: ['lse', 'other'] }), _ember['default'].Object.create({ valueType: 'separator' }), _ember['default'].Object.create({ label: 'event_type', valueType: 'select-choices-explanation', value: 'Academic lecture/debate', maxLength: 220, explanation: '', choices: ['academic_lecture', 'academix_teaching', 'catered', 'internal_committee', 'dissemination_research', 'seminar', 'other'] }), _ember['default'].Object.create({ label: 'catering_requirement', valueType: 'select-choices', value: 'None', choices: ['none', 'tea_coffee', 'food_soft_drink', 'food_alcool'] })],

    //On component initialization create an array of the default fields values so we can set them back on willDestroyElement
    onInitBackupValues: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        this.set('lseCustomFieldsDefaultValues', this.get('lseCustomFields').getEach('value'));
      });
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      var _this = this;

      if (this.get('isDestroyed') || this.get('isDestroying')) return;

      try {
        (function () {

          var defaults = _this.get('lseCustomFieldsDefaultValues');

          // Loop through each field
          _this.get('lseCustomFields').forEach(function (field, i) {

            // Set its value to default
            field.set('value', defaults[i]);

            // If the field is of type select-choices-explanation then we should also
            // empty the explanation property which is a textarea input
            if (field.get('valueType') === 'select-choices-explanation') {
              field.set('explanation', '');
            }
          });
        })();
      } catch (error) {
        // no-op
      }
    },

    actions: {

      updateBookingMode: function updateBookingMode(mode) {
        this.set('bookingMode', mode);
      },

      updateStartDateTime: function updateStartDateTime(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!this.get('model') || this.get('model.isDeleted')) return;
        this.set('model.startDateTime', date);
      },

      updateEndDateTime: function updateEndDateTime(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') || !date) return;
        if (!this.get('model') || this.get('model.isDeleted')) return;
        this.set('model.endDateTime', date);
      },

      updateCustomFieldsValue: function updateCustomFieldsValue(value, isValid, errorMessage) {
        if (this.get('isDestroyed') || this.get('isDestroying')) return;
        this.set('customFieldsValue', value);
        this.set('customFieldsIsValid', isValid);
        this.set('customFieldsErrorMessage', errorMessage);
      },

      onTabClick: function onTabClick(section) {
        var _this2 = this;

        var sectionId = section.get('identity');
        var $section = _ember['default'].$('.collapse[data-collapse-id="' + sectionId + '"]');
        var sectionIsCollapsed = $section.attr('aria-expanded') === 'false';
        var $firstFieldInSection = $section.find('.bookingRequestForm-field').first();
        if (sectionIsCollapsed && $firstFieldInSection) {
          (function () {
            var $parentDiv = _ember['default'].$('.lf-dialog-content');
            _ember['default'].run.later(_this2, function () {
              var calc = $parentDiv.scrollTop() + $firstFieldInSection.position().top - $parentDiv.height() / 2 + $firstFieldInSection.height() / 2;
              $parentDiv.animate({ scrollTop: calc });
            }, 500);
          })();
        }
      },

      // jshint maxcomplexity:21
      save: function save() {
        var _this3 = this;

        // this.set('isSaving', true);
        if (this.get('bookingForm') && this.get('bookingForm.sections')) {
          var _ret3 = (function () {
            var customFieldsAreValid = true;

            var firstValidationError = undefined;

            _this3.get('bookingForm.sections').forEach(function (section) {
              section.get('fields').forEach(function (field) {
                var _field$validateSync = field.validateSync();

                var validations = _field$validateSync.validations;

                if (!validations.get('isValid')) {
                  // console.log(field.get('description'), field, validations.get('errors'));
                  if (!firstValidationError) {
                    firstValidationError = { sectionId: section.get('identity'), fieldId: field.get('identity') };
                    _this3._highlightFieldValidationError(firstValidationError);
                  }
                  customFieldsAreValid = false;
                }
              });
            });

            _this3.set('didValidate', true);
            if (!customFieldsAreValid) return {
                v: undefined
              };
          })();

          if (typeof _ret3 === 'object') return _ret3.v;
        }

        if (!this._validateDefaultFields()) return;
        if (!this._validateCustomFields()) return;
        if (!this._validateCUHKCustomFields()) return;

        var description = this.get('model.description');
        var descriptionAddition = this.get('customFieldsValue');
        var isRepeatBooking = this.get('repeatBooking');

        var startTime = isRepeatBooking ? this.get('model.recurrencePattern.fromTime') : this.get('model.fromTime');
        var endTime = isRepeatBooking ? this.get('model.recurrencePattern.toTime') : this.get('model.toTime');

        var _startTime$split = startTime.split(':');

        var _startTime$split2 = _slicedToArray(_startTime$split, 2);

        var startHours = _startTime$split2[0];
        var startMinutes = _startTime$split2[1];

        var _endTime$split = endTime.split(':');

        var _endTime$split2 = _slicedToArray(_endTime$split, 2);

        var endHours = _endTime$split2[0];
        var endMinutes = _endTime$split2[1];

        // Get start Date depending if it is a recurring booking
        var startDate = isRepeatBooking ? this.get('model.recurrencePattern.startDate') : this.get('model.startDateTime');

        // Get end date depending if it is a recurring booking. This is not a typo, we are using startDate
        // here as well because we don't want to allow a different endDate from the startDate (not allow spanning days)
        var endDate = isRepeatBooking ? this.get('model.recurrencePattern.startDate').clone() : this.get('model.endDateTime');

        // Override endDate with startDate if it has externallyManagedResources as we do not allow span multiple days
        if (this.get('bookingType.hasExternallyManagedResources')) endDate = startDate.clone();

        var recurrencePattern = this.get('model.recurrencePattern');
        var recurrenceGenerated = undefined;

        // Validate Pattern if is set to Recurring booking
        if (isRepeatBooking) {
          recurrenceGenerated = this._validateRecurrencePattern(recurrencePattern);
          if (!recurrenceGenerated) return;
        }
        var startDateTime = startDate.clone().hours(startHours).minutes(startMinutes);

        // If user select 00:00 and it's the same start and endDate then make it 24h
        if (startDate.day() === endDate.day() && endHours === '00' && endMinutes === '00') {
          endHours = 24;
        }

        var endDateTime = endDate.clone().hours(endHours).minutes(endMinutes);

        if (!this._validateDateTimes(startDateTime, endDateTime)) return;

        var params = {
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          recurrencePattern: recurrenceGenerated,
          description: descriptionAddition || description,
          name: this.get('model.name')
        };

        if (!this._applyBoBoAndBaseSettings(params)) return;

        var isOccurrence = this.get('bookingRequestToEditIsSingleOccurrence');
        if (this.get('isEditing')) {
          // If its a single occurrence edit, set parentId (groupId) and occurrenceId (id)
          if (isOccurrence) {
            params.occurrenceId = this.get('model.id');
            params.parentId = this.get('model.occurrenceGroupId');
          } else {
            params.bookingRequestId = this.get('model.id');
          }
        }

        var deferred = _ember['default'].RSVP.defer();

        // Check if isEditing and if isEditing a singleOccurrence or normal booking
        if (this.get('isEditing')) {
          this.sendAction(isOccurrence ? 'updateSingle' : 'update', params, deferred);
        } else {
          // Else is a normal booking
          this.sendAction('book', params, deferred);
        }

        // deferred.promise.finally(() => {
        //   if (!this.get('isDestroyed')) {
        //     this.set('isSaving', false);
        //   }
        // });
      },

      toggleUpdateOccurrencePattern: function toggleUpdateOccurrencePattern() {
        this.toggleProperty('updateOccurrencePattern');
      }

    },

    _validateDefaultFields: function _validateDefaultFields() {
      if (!this.get('model.name') || _ember['default'].isEmpty(this.get('model.name').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.title_required'));
        return false;
      }
      if (!this.get('model.fromTime') || _ember['default'].isEmpty(this.get('model.fromTime').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.from_time_required'));
        return false;
      }
      if (!this.get('model.toTime') || _ember['default'].isEmpty(this.get('model.toTime').trim())) {
        this._displayError(this.get('i18n').t('components.booking_request_form.to_time_required'));
        return false;
      }

      return true;
    },

    _validateCustomFields: function _validateCustomFields() {
      if (!this.get('customFieldsIsValid')) {
        this._displayError(this.get('customFieldsErrorMessage'));
        return false;
      }

      return true;
    },

    // jshint maxcomplexity:26
    _validateCUHKCustomFields: function _validateCUHKCustomFields() {
      if (this.get('showCUHKCustomFields')) {
        var fields = this.get('cuhkCustomFields');

        if (!fields.activityTypeSelected) {
          this._displayError('You must input the Type of Activity for this booking');
          return false;
        } else if (!fields.ugcVote1) {
          this._displayError('You need to select either UGC vote or non-UGC vote under Funding 1');
          return false;
        } else if (!fields.percentage1) {
          this._displayError('You must input a percentage value under Funding 1');
          return false;
        }

        var percentageValidationFailed = false;
        for (var i = 1; i <= 3; i++) {
          if (fields['percentage' + i]) {
            if (!parseFloat(fields['percentage' + i]) || parseFloat(fields['percentage' + i]) > 100) {
              this._displayError('The percentage value under Funding ' + i + ' needs to be between 0 and 100. e.g 17.50 or 75');
              percentageValidationFailed = true;
            }
          }
        }
        if (percentageValidationFailed) return false;

        var description = this.getWithDefault('model.description', '');
        var separator = description !== '' ? ' - ' : '';
        var output = '' + description + separator;

        output += '[Activity Type: ' + fields.activityTypeSelected + ']';
        if (fields.costCentre1) output += '[Cost Centre 1: ' + fields.costCentre1 + ']';
        if (fields.projectCode1) output += '[Project Code 1: ' + fields.projectCode1 + ']';
        if (fields.internalOrder1) output += '[Internal Order 1: ' + fields.internalOrder1 + ']';
        if (fields.ugcVote1) output += '[Ugc/Non-UGC 1: ' + fields.ugcVote1 + ']';
        if (fields.percentage1) output += '[Percentage 1: ' + fields.percentage1 + ']';

        if (fields.costCentre2) output += '[Cost Centre 2: ' + fields.costCentre2 + ']';
        if (fields.projectCode2) output += '[Project Code 2: ' + fields.projectCode2 + ']';
        if (fields.internalOrder2) output += '[Internal Order 2: ' + fields.internalOrder2 + ']';
        if (fields.ugcVote2) output += '[Ugc/Non-UGC 2: ' + fields.ugcVote2 + ']';
        if (fields.percentage2) output += '[Percentage 2: ' + fields.percentage2 + ']';

        if (fields.costCentre3) output += '[Cost Centre 3: ' + fields.costCentre3 + ']';
        if (fields.projectCode3) output += '[Project Code 3: ' + fields.projectCode3 + ']';
        if (fields.internalOrder3) output += '[Internal Order 3: ' + fields.internalOrder3 + ']';
        if (fields.ugcVote3) output += '[Ugc/Non-UGC 3: ' + fields.ugcVote3 + ']';
        if (fields.percentage3) output += '[Percentage 3: ' + fields.percentage3 + ']';

        this.set('customFieldsValue', output);
      }

      return true;
    },

    _validateDateTimes: function _validateDateTimes(start, end) {
      if (end.hour() !== 0 && (end.isBefore(start) || end.isSame(start, 'minute'))) {
        this._displayError(this.get('i18n').t('components.booking_request_form.to_time_before_start_time'));
        return false;
      }

      return true;
    },

    _validateRecurrencePattern: function _validateRecurrencePattern(pattern) {
      var errors = this.get('recurrenceProcessor').validate(pattern);
      if (!_ember['default'].isEmpty(errors)) {
        if (errors.includes('to_time')) {
          this._displayError(this.get('i18n').t('components.booking_request_form.to_time_before_start_time'));
        } else {
          this._displayError(this.get('i18n').t('components.booking_request_form.recurring_error'));
        }
        return false;
      }

      // return true;
      return this.get('recurrenceProcessor').generate(pattern);
    },

    _displayError: function _displayError(errorMessage) {
      this.set('formError', errorMessage);
      _ember['default'].$('.lf-dialog-content').animate({ scrollTop: 0 }, 'slow');
    },

    _applyBoBoAndBaseSettings: function _applyBoBoAndBaseSettings(params) {
      // BOBO feature
      if (this.get('boboBaseSettings.bookOnBehalfOfChecked')) {
        var emailField = this.get('boboBaseSettings.bookOnBehalfOfText');

        if (_ember['default'].isEmpty(emailField.trim())) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.no_emails'));
          return false;
        }

        var emails = this._checkEmailAddresses(emailField);
        if (emails.hasInvalidEmails) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.invalid_emails', { emails: emails.invalidEmails }));
          return false;
        }

        params.additionalEmailAddresses = emails.emailAddresses;
      }

      // BASE feature
      if (this.get('boboBaseSettings.bookAsSomeoneElseChecked')) {
        var ownerField = this.get('boboBaseSettings.bookAsSomeoneElseOwner');
        var emailField = this.get('boboBaseSettings.bookAsSomeoneElseEmail');
        var ownerNameField = this.get('boboBaseSettings.bookAsSomeoneElseName');

        if (_ember['default'].isEmpty(ownerField.trim()) || _ember['default'].isEmpty(emailField.trim()) || _ember['default'].isEmpty(ownerNameField.trim())) {
          this._displayError(this.get('i18n').t('components.booking_request_form.base.no_identity_or_email'));
          return false;
        }

        var emails = this._checkEmailAddresses(emailField);
        if (emails.hasInvalidEmails) {
          this._displayError(this.get('i18n').t('components.booking_request_form.bobo.invalid_emails', { emails: emails.invalidEmails }));
          return false;
        }

        params.owner = ownerField;
        params.bookerEmailAddress = emailField;
        params.bookerName = ownerNameField;
      }

      return true;
    },

    _checkEmailAddresses: function _checkEmailAddresses(emails) {
      var emailAddresses = emails.split('\n');
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var invalidEmails = [];

      emailAddresses = emailAddresses.map(function (email) {
        email = email.trim();
        if (!email.match(emailRegex)) invalidEmails.push(email);
        return email;
      });

      var response = {
        emailAddresses: emailAddresses,
        hasInvalidEmails: !_ember['default'].isEmpty(invalidEmails),
        invalidEmails: invalidEmails.join(', ')
      };

      return response;
    },

    _highlightFieldValidationError: function _highlightFieldValidationError(_ref) {
      var _this4 = this;

      var sectionId = _ref.sectionId;
      var fieldId = _ref.fieldId;

      var $section = _ember['default'].$('.collapse[data-collapse-id="' + sectionId + '"]');
      var sectionIsCollapsed = $section.attr('aria-expanded') === 'false';
      if (sectionIsCollapsed) {
        $section.collapse('show');
      }

      var $field = _ember['default'].$('.bookingRequestForm-field[data-field-id=' + fieldId + ']');

      if ($field.length) {
        (function () {
          var $parentDiv = _ember['default'].$('.lf-dialog-content');
          var calc = undefined;
          // Make sure we give enough time for the section to expand before scrolling to the field or the calc will not be correct
          if (!sectionIsCollapsed) {
            calc = $parentDiv.scrollTop() + $field.position().top - $parentDiv.height() / 2 + $field.height() / 2;
            $parentDiv.animate({ scrollTop: calc });
          } else {
            _ember['default'].run.later(_this4, function () {
              calc = $parentDiv.scrollTop() + $field.position().top - $parentDiv.height() / 2 + $field.height() / 2;
              $parentDiv.animate({ scrollTop: calc });
            }, 1000);
          }
        })();
      }
    },

    _localePrefix: function _localePrefix() {
      if (this.get('isEditing')) {
        return 'components.booking_request_form.edit';
      } else {
        return 'components.booking_request_form.create';
      }
    }
  });
});