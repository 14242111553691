define('scientia-resourcebooker/mixins/authenticated-route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    configFetcher: _ember['default'].inject.service(),

    appName: _ember['default'].computed.alias('configFetcher.appName'),

    title: function title(tokens) {
      var appName = this.get('appName') || 'Resource Booker';
      return tokens.join(' — ') + ' — ' + appName;
    },

    session: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    currentUserFetcher: _ember['default'].inject.service(),
    notificationsCountFetcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session.authorization') === null) {
        this.get('session').timeout();
      }
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        user: this.get('currentUser').getUser(),
        notificationsCount: 0 //this.get('notificationsCountFetcher').fetch()
      });
    },

    afterModel: function afterModel() {
      var shouldFetchNotifications = this.get('notificationsCountFetcher').shouldFetchForMenuItem();
      if (shouldFetchNotifications) {
        this.get('notificationsCountFetcher').updateNotificationCount();
      }
    },

    actions: {
      logout: function logout() {
        var singleLogoutMode = parseInt(this.get('systemSettings').getSetting('singleLogoutMode'));
        this.controllerFor('application').setProperties({
          confirmLogout: true,
          singleLogoutMode: singleLogoutMode,
          singleLogoutCheckboxValue: [2, 3, 4].includes(singleLogoutMode)
        });
      },

      error: function error(_error) {
        // If the user is unauthorized, forbidden or we get a CORS error or a timeout
        if (typeof _error.status === 'number') {
          switch (_error.status) {
            case 0:
            case 401:
              this.get('session').timeout();
              break;
            case 403:
              this.transitionTo('/');
              break;
          }
        }

        return true;
      },

      willTransition: function willTransition() {

        var controller = this.get('isManageResources') ? this.controllerFor('manage-resources') : this.controllerFor('logged-in');

        if (controller.get('isSidebarOpen')) {
          controller.set('isSidebarOpen', false);
        }
      }
    }
  });
});