define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/select-choices/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':bookingRequestForm-label'],

    label: (function () {
      return 'components.booking_request_form.description_custom_fields.' + this.get('field').label;
    }).property('field'),

    choices: (function () {
      var _this = this;

      return this.get('field.choices').map(function (choice) {
        return {
          label: _this.get('i18n').t('components.booking_request_form.description_custom_fields.choices.' + choice).toString()
        };
      });
    }).property('field')
  });
});