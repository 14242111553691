define("scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 16,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "manage-resources/bulk-importer/importer-option", [], ["optionId", ["subexpr", "@mut", [["get", "option.id", ["loc", [null, [8, 17], [8, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "type", ["subexpr", "@mut", [["get", "option.valueType", ["loc", [null, [9, 13], [9, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "option.value", ["loc", [null, [10, 14], [10, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "option.options", ["loc", [null, [11, 16], [11, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onFileChanged", ["subexpr", "@mut", [["get", "attrs.filesDidChange", ["loc", [null, [12, 22], [12, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "file", ["subexpr", "find-by", ["optionId", ["get", "option.id", ["loc", [null, [13, 33], [13, 42]]], 0, 0, 0, 0], ["get", "files", ["loc", [null, [13, 43], [13, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 13], [13, 49]]], 0, 0], "localePrefix", ["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [14, 37], [14, 49]]], 0, 0, 0, 0], "options."], [], ["loc", [null, [14, 21], [14, 61]]], 0, 0]], ["loc", [null, [7, 8], [15, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 17,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["subexpr", "eq", [["get", "option.valueType", ["loc", [null, [6, 20], [6, 36]]], 0, 0, 0, 0], "hidden"], [], ["loc", [null, [6, 16], [6, 46]]], 0, 0]], [], 0, null, ["loc", [null, [6, 6], [16, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["subexpr", "not", [["get", "option.dependsOn", ["loc", [null, [5, 19], [5, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 14], [5, 36]]], 0, 0], ["subexpr", "get", [["subexpr", "find-by", ["id", ["get", "option.dependsOn", ["loc", [null, [5, 56], [5, 72]]], 0, 0, 0, 0], ["get", "importOptions", ["loc", [null, [5, 73], [5, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 42], [5, 88]]], 0, 0], "value"], [], ["loc", [null, [5, 37], [5, 97]]], 0, 0]], [], ["loc", [null, [5, 10], [5, 98]]], 0, 0]], [], 0, null, ["loc", [null, [5, 4], [17, 11]]]]],
        locals: ["option"],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [25, 26], [25, 38]]], 0, 0, 0, 0], "preview"], [], ["loc", [null, [25, 10], [25, 49]]], 0, 0]], [], ["loc", [null, [25, 6], [25, 51]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bulkExporter-download at-bulkExportTemplates");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bulkExporter-download at-bulkExportTemplates");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bulkExporter-download at-bulkExportTemplates");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 0]);
          var element2 = dom.childAt(fragment, [3, 0]);
          var element3 = dom.childAt(fragment, [5, 0]);
          var morphs = new Array(9);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          morphs[2] = dom.createMorphAt(element1, 2, 2);
          morphs[3] = dom.createElementMorph(element2);
          morphs[4] = dom.createMorphAt(element2, 0, 0);
          morphs[5] = dom.createMorphAt(element2, 2, 2);
          morphs[6] = dom.createElementMorph(element3);
          morphs[7] = dom.createMorphAt(element3, 0, 0);
          morphs[8] = dom.createMorphAt(element3, 2, 2);
          return morphs;
        },
        statements: [["element", "action", [["subexpr", "action", ["onDownloadTemplateCustomFields", "FIELDS"], [], ["loc", [null, [35, 75], [35, 125]]], 0, 0]], [], ["loc", [null, [35, 66], [35, 127]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "bulkExporter-download-icon"], ["loc", [null, [35, 128], [35, 198]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [35, 219], [35, 231]]], 0, 0, 0, 0], "export_import_file"], [], ["loc", [null, [35, 203], [35, 253]]], 0, 0]], [], ["loc", [null, [35, 199], [35, 255]]], 0, 0], ["element", "action", [["subexpr", "action", ["onDownloadTemplateCustomFields", "FIELDS_WITH_MAPPING_DATA"], [], ["loc", [null, [36, 75], [36, 143]]], 0, 0]], [], ["loc", [null, [36, 66], [36, 145]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "bulkExporter-download-icon"], ["loc", [null, [36, 146], [36, 216]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [36, 237], [36, 249]]], 0, 0, 0, 0], "export_import_file_with_mapping"], [], ["loc", [null, [36, 221], [36, 284]]], 0, 0]], [], ["loc", [null, [36, 217], [36, 286]]], 0, 0], ["element", "action", [["subexpr", "action", ["onDownloadTemplateCustomFields", "MAPPING_FILE"], [], ["loc", [null, [37, 75], [37, 131]]], 0, 0]], [], ["loc", [null, [37, 66], [37, 133]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "bulkExporter-download-icon"], ["loc", [null, [37, 134], [37, 204]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [37, 225], [37, 237]]], 0, 0, 0, 0], "export_mapping_file"], [], ["loc", [null, [37, 209], [37, 260]]], 0, 0]], [], ["loc", [null, [37, 205], [37, 262]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bulkExporter-download at-bulkExportTemplates");
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 0]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          morphs[2] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["element", "action", [["subexpr", "action", ["onDownloadTemplate"], [], ["loc", [null, [39, 75], [39, 104]]], 0, 0]], [], ["loc", [null, [39, 66], [39, 106]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "bulkExporter-download-icon"], ["loc", [null, [39, 107], [39, 177]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [39, 198], [39, 210]]], 0, 0, 0, 0], "export_import_file"], [], ["loc", [null, [39, 182], [39, 232]]], 0, 0]], [], ["loc", [null, [39, 178], [39, 234]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/bulk-importer/importer-uploader/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bulkImporterUploader-options ");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-item manageResources-itemForm-form-item--topAligned");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-label manageResources-itemForm-form-label--editing");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "manageResources-itemForm-form-item--content");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bulkExporter-section");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bulkExporter-section at-bulkExportTemplates");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "bulkExporter-section-title");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "bulkExporter-section-subtitle");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [6]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(element4, 5, 5);
        return morphs;
      },
      statements: [["block", "each", [["subexpr", "reject-by", ["hide", ["get", "importOptions", ["loc", [null, [2, 28], [2, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 10], [2, 42]]], 0, 0]], [], 0, null, ["loc", [null, [2, 2], [18, 11]]]], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isUploading", ["loc", [null, [24, 28], [24, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "button button--primary at-bulkImporterUploader", "disableButton", ["subexpr", "@mut", [["get", "disableOptions", ["loc", [null, [24, 109], [24, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "action", ["subexpr", "action", [["get", "onPreviewImport", ["loc", [null, [24, 139], [24, 154]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 131], [24, 155]]], 0, 0]], 1, null, ["loc", [null, [24, 4], [26, 21]]]], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [31, 61], [31, 73]]], 0, 0, 0, 0], "export_template_title"], [], ["loc", [null, [31, 45], [31, 98]]], 0, 0]], [], ["loc", [null, [31, 41], [31, 100]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [32, 63], [32, 75]]], 0, 0, 0, 0], "export_template_subtitle"], [], ["loc", [null, [32, 47], [32, 103]]], 0, 0]], [], ["loc", [null, [32, 43], [32, 105]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "importType", ["loc", [null, [34, 10], [34, 20]]], 0, 0, 0, 0], "customFields"], [], ["loc", [null, [34, 6], [34, 36]]], 0, 0]], [], 2, 3, ["loc", [null, [34, 0], [40, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});