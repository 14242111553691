define("scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 16,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "manage-resources/resource-custom-fields-inputs/multipleselections/item", [], ["choice", ["subexpr", "@mut", [["get", "choice", ["loc", [null, [9, 13], [9, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "uniqueId", ["subexpr", "@mut", [["get", "choice.id", ["loc", [null, [10, 15], [10, 24]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [11, 15], [11, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "checked", ["subexpr", "not", [["subexpr", "is-empty", [["subexpr", "find-by", ["identity", ["get", "choice.identity", ["loc", [null, [12, 49], [12, 64]]], 0, 0, 0, 0], ["get", "property.value", ["loc", [null, [12, 65], [12, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 29], [12, 80]]], 0, 0]], [], ["loc", [null, [12, 19], [12, 81]]], 0, 0]], [], ["loc", [null, [12, 14], [12, 82]]], 0, 0], "onChecked", "addToList", "onUnchecked", "removeFromList"], ["loc", [null, [8, 4], [15, 6]]], 0, 0]],
        locals: ["choice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/resource-custom-fields-inputs/multipleselections/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        dom.setAttribute(el1, "class", "manageResources-itemForm-form-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "manageResources-itemForm-inputList");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["content", "property.name", ["loc", [null, [2, 2], [2, 19]]], 0, 0, 0, 0], ["block", "each", [["get", "property.valueChoicesWithId", ["loc", [null, [6, 10], [6, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 2], [16, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});