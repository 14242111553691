define('scientia-resourcebooker/pods/manage-resources/resource-group/resources/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    nestedResourceStore: _ember['default'].inject.service(),
    queryParams: ['page', 'perPage'],
    page: 1,
    perPage: 10,

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resourceType.id')
      }, {
        label: this.get('model.resourceGroup.name'),
        linkable: false
      }];
    }).property('model.resourceType.name', 'model.resourceType.id', 'model.resourceGroup.name', 'i18n.locale'),

    proxiedResources: _ember['default'].computed.map('model.resources', function (model) {
      return _ember['default'].ObjectProxy.create({
        content: model,
        isSelected: false
      });
    }),

    persistedResources: _ember['default'].computed.filterBy('proxiedResources', 'isDeleted', false),
    resources: _ember['default'].computed.sort('persistedResources', 'resourceSorting'),
    resourceSorting: ['name'],

    checkedResources: _ember['default'].computed.filterBy('proxiedResources', 'isSelected', true),
    confirmBulkRemoveModal: false,

    actions: {

      confirmBulkRemoveFromResourceGroup: function confirmBulkRemoveFromResourceGroup() {
        var _this = this;

        // let resourceTypeId = this.get('model').resourceType.get('id');
        var resourceGroup = this.get('model').resourceGroup;
        var resources = this.get('checkedResources');
        var resourceIds = resources.mapBy('id');

        var groupResourceIds = resourceGroup.get('resourceIds').mapBy('id').reject(function (id) {
          return resourceIds.includes(id);
        }).map(function (id) {
          return { id: id };
        });

        // const data = resourceGroup.toJSON();
        // data.resourceIds = groupResourceIds;
        // return this.get('nestedResourceStore').updateResource({
        //   type: 'resourceType.resourceGroup',
        //   parentId: resourceTypeId,
        //   id: resourceGroup.get('id'),
        //   data: data
        // })
        resourceGroup.set('resourceIds', groupResourceIds);
        resourceGroup.save().then(function () {
          var message = _this.get('i18n').t('pods.manage_resources.resource_group.flash_messages.remove_from_resource_group_success', { count: resources.get('length') });
          _this.flashSuccess(message);

          _this.set('page', 1);
          _this.send('reloadResources');
          _this.set('confirmBulkRemoveModal', false);
          _this.set('confirmTitle', '');
          _this.set('confirmMessage', '');
        })['catch'](function () {
          var message = _this.get('i18n').t('pods.manage_resources.resource_group.flash_messages.remove_from_resource_group_error');
          _this.flashError(message);
        });
      }
    }
  });
});