define('scientia-resourcebooker/pods/components/manage-resources/booking-type-permissions-form/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_LOCALE_PREFIX = 'components.manage_resources.permissions_form';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-bookingTypePermissionsForm'],
    userGroupSearcher: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    enableBobo: _ember['default'].computed.bool('systemSettings.options.enableBobo'),
    enableBase: _ember['default'].computed.bool('systemSettings.options.enableBase'),
    disabled: false,
    canEditAll: false,
    canEditPermissions: false,
    permissionsTypes: [],

    permissions: [],

    permissionsTypeValues: (function () {
      var _this = this;

      return this.get('permissionsTypes').map(function (permission) {

        // If permission is separator make sure to add --- and read only
        if (permission.value === 'separator') return { value: null, label: '------------------', disabled: true };

        var labelKey = permission.value.underscore();
        if (labelKey === 'read') labelKey = 'show'; // Rename Read to View for booking types only
        return {
          value: permission.value,
          disabled: permission.disabled,
          deprecated: permission.deprecated,
          label: _this.get('i18n').t('components.manage_resources.permissions_form.permission_types.' + labelKey) };
      });
    }).property('i18n.locale', 'permissionsTypes'),

    cannotAddPermission: (function () {
      return this.get('permissions').findBy('permissionValue', '');
    }).property('permissions.@each.permissionValue'),

    _searchUserGroups: function _searchUserGroups(query, deferred) {
      this.get('userGroupSearcher').search(query).then(function (data) {
        deferred.resolve(data);
      });
    },

    actions: {
      searchUserGroups: function searchUserGroups(query, deferred) {
        _ember['default'].run.debounce(this, '_searchUserGroups', query, deferred, 1000);
      },

      addPermission: function addPermission() {
        if (this.get('disabled')) return false;
        this.get('permissions').addObject(this.get('store').createFragment('permission-group', { permissionValue: '', permissionType: 'read' }));
      },

      didSelectPermissionTarget: function didSelectPermissionTarget(permission, value) {
        if (permission.get('permissionValue.permissionTarget')) {
          permission.set('permissionValue.permissionTarget', value);
        } else {
          permission.set('permissionValue', { permissionTarget: value });
        }
      },

      deletePermission: function deletePermission(index) {
        var _this2 = this;

        if (this.get('disabled')) return false;
        this.get('globalConfirmer').confirm(this.get('i18n').t(DEFAULT_LOCALE_PREFIX + '.confirm_delete')).then(function () {
          return _this2.get('permissions').removeAt(index);
        });
      },

      permissionShouldBeDisabled: function permissionShouldBeDisabled(permission, disabled) {
        if (disabled) return true;

        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permission.get('permissionType'));
        if (_ember['default'].isEmpty(perm)) {
          return true;
        }
        return perm.disabled || false;
      },

      permissionShouldBeHidden: function permissionShouldBeHidden(permission) {
        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permission.get('permissionType'));
        return _ember['default'].isEmpty(perm);
      },

      permissionIsDeprecated: function permissionIsDeprecated(permissionType) {
        var values = this.get('permissionsTypeValues');
        var perm = values.findBy('value', permissionType);
        if (_ember['default'].isEmpty(perm)) return true; // If permission not enabled then it probably means it was a deprecated perm that has now been hidden
        return perm.deprecated;
      }
    }
  });
});