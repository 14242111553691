define('scientia-resourcebooker/pods/components/container-sidebar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':container-sidebar', ':sidebar', 'isSidebarOpen:container-sidebar--open'],
    session: _ember['default'].inject.service(),

    user: null,
    notificationsCount: 0,

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.sendAction('toggleSidebar');
      },

      logout: function logout() {
        this.sendAction('logout');
      }
    }
  });
});