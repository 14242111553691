define('scientia-resourcebooker/pods/manage-resources/resource-type/edit/controller', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend({
    queryParams: {
      selectedTab: 'section'
    },

    permissionsProcessor: _ember['default'].inject.service(),
    permissionDeprecatedChecker: _ember['default'].inject.service(),

    can: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    selectedTab: 0,

    properties: [],

    isSaving: false,
    error: '',
    showIconSelector: false,

    // Abilities
    canEditAll: _ember['default'].computed('model.resourceType', 'model.resourceType.id', function () {
      return this.get('can').can('edit-all resource-type', { model: this.get('model.resourceType') });
    }),
    canEditDescription: _ember['default'].computed('model.resourceType', 'model.resourceType.id', function () {
      return this.get('can').can('edit-description resource-type', { model: this.get('model.resourceType') });
    }),
    canEditCustomFields: _ember['default'].computed('model.resourceType', 'model.resourceType.id', function () {
      return this.get('can').can('edit-custom-fields resource-type', { model: this.get('model.resourceType') });
    }),
    canEditPermissions: _ember['default'].computed('model.resourceType', 'model.resourceType.id', function () {
      return this.get('can').can('edit-permissions resource-type', { model: this.get('model.resourceType') });
    }),

    permissions: (function () {
      var model = this.get('model.resourceType');
      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(model);
      return fragments;
    }).property('model.resourceType.readPermissionGroups', 'model.resourceType.writePermissionGroups', 'model.resourceType.manageGroupsPermissionGroups', 'model.resourceType.manageResourcesPermissionGroups', 'model.resourceType.editAllPermissionGroups', 'model.resourceType.editDescriptionPermissionGroups', 'model.resourceType.editCustomFieldsPermissionGroups', 'model.resourceType.editPermissionsPermissionGroups'),

    /**
    * Returns all the permission types available which will be displayed in the dropdown
    */
    permissionsTypes: _ember['default'].computed('canEditAll', 'canEditPermissions', function () {
      var _permissionTypes,
          _this = this;

      var canEditPermissions = this.get('canEditPermissions');
      var canEditAll = this.get('canEditAll');

      var toObject = function toObject(value, disabled) {
        var deprecated = _this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource-type', name: value });
        return { value: value, disabled: disabled, deprecated: deprecated };
      };

      // Add initial permissions
      var initialPermissions = ['read', 'write', 'separator'];
      var editPermissions = ['edit-all', 'edit-description', 'edit-custom-fields', 'edit-permissions', 'separator'];
      var managePermissions = ['manage-groups', 'manage-resources'];

      var permissionTypes = [];

      // Map them from string to a value object
      initialPermissions = initialPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      editPermissions = editPermissions.map(function (p) {
        return toObject(p, !canEditAll);
      });
      managePermissions = managePermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });

      // Shove editPermissions into previous array and return them all
      (_permissionTypes = permissionTypes).push.apply(_permissionTypes, _toConsumableArray(initialPermissions).concat(_toConsumableArray(editPermissions), _toConsumableArray(managePermissions)));

      // Write permission should also be disabled if !canEditAll
      permissionTypes.find(function (p) {
        return p.value === 'write';
      }).disabled = !canEditAll;

      // Strip out deprecated properties if hidePermissionVersion3 is set
      if (this.get('systemSettings').getSetting('hidePermissionsVersion3')) {
        permissionTypes = permissionTypes.filter(function (perm) {
          return !_this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource-type', name: perm.value });
        });

        // If after removing deprecated properties the first property is a separator, then remove it
        if (permissionTypes.get('firstObject.value') === 'separator') permissionTypes.shift();
      }
      return permissionTypes;
    }),

    actions: {
      save: function save() {
        var transitionIndex = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        this.set('error', '');
        this.send('updateResourceType', transitionIndex);
      },

      openIconSelector: function openIconSelector() {
        this.set('showIconSelector', true);
      },

      clearImageUri: function clearImageUri() {
        this.set('model.resourceType.imageUri', '');
      },

      setIcon: function setIcon(icon) {
        this.set('showIconSelector', false);
        this.set('model.resourceType.imageUri', icon.url);
      },

      reorderCustomFields: function reorderCustomFields(sortedItems) {
        sortedItems.forEach(function (s, i) {
          s.set('rank', i);
        });
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource_type.index.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        linkable: false
      }];
    }).property('model.resourceType.name', 'i18n.locale')
  });
});