define('scientia-resourcebooker/pods/components/dropdown-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['dropDownButton-wrapper'],
    classNameBindings: ['location', 'isShowing:dropDownButton-wrapper--showing'],
    label: '',
    disabled: false,
    classes: '',
    location: '',

    isShowing: false,

    mouseEnter: function mouseEnter() {
      this.set('isShowing', true);
      this._adjustContent();
    },

    mouseLeave: function mouseLeave() {
      this.set('isShowing', false);
    },

    click: function click() {
      this._adjustContent();
    },

    _adjustContent: function _adjustContent() {
      var $content = this.$('.dropdownButton-content');
      var documentHeight = _ember['default'].$(document).height();

      var contentHeight = $content.height();
      var contentOffsetTop = $content.offset().top;

      var contentWidth = $content.width();
      var contentOffsetLeft = $content.offset().left;

      var containerWidth = _ember['default'].$('.lf-dialog').width();

      if (contentOffsetTop + contentHeight + 10 > documentHeight) {
        $content.addClass('at-positionTop');
      }

      if (contentOffsetLeft + contentWidth + 20 > containerWidth) {
        $content.addClass('at-positionLeft');
      }
    }
  });
});