define('scientia-resourcebooker/pods/manage-resources/route', ['exports', 'ember', 'ember-can', 'scientia-resourcebooker/mixins/authenticated-route'], function (exports, _ember, _emberCan, _scientiaResourcebookerMixinsAuthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_emberCan.CanMixin, _scientiaResourcebookerMixinsAuthenticatedRoute['default'], {

    systemSettings: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    isManageResources: true,

    beforeModel: function beforeModel() {
      var _this = this;

      this._super();
      _ember['default'].$('.progressLoading').remove();

      this.get('systemSettings').fetch();
      return this.get('currentUserFetcher').fetch().then(function (user) {
        if (_ember['default'].isEmpty(user.name) || _ember['default'].isEmpty(user.nameIdentifier) || _ember['default'].isEmpty(user.email)) {
          _this.get('session').logout();
          return;
        }
        _this.get('currentUser').setUser(user);
      });
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        user: this.get('currentUser').getUser(),
        notificationsCount: 0
      });
    },

    afterModel: function afterModel() {
      this._super.apply(this, arguments);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        // We want to be sure that all is reloaded from the API
        // when going in the logged-in section

        if (transition.targetName.indexOf('manage-resources') !== 0) {
          this.store.unloadAll('booking-form');
          this.store.unloadAll('booking-form-section');
          this.store.unloadAll('booking-form-field-attachment');
          this.store.unloadAll('booking-form-field-label');
          this.store.unloadAll('booking-form-field-multiple-choice');
          this.store.unloadAll('booking-form-field-multiple-choice-item');
          this.store.unloadAll('booking-form-field-single-choice');
          this.store.unloadAll('booking-form-field-single-choice-item');
          this.store.unloadAll('booking-form-field-textbox');
          this.store.unloadAll('booking-form-field-url');
          this.store.unloadAll('booking-type');
        }
      }
    }
  });
});