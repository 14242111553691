define('scientia-resourcebooker/pods/logged-in/booking-type/index/route', ['exports', 'ember', 'ember-can', 'scientia-resourcebooker/mixins/app-insights'], function (exports, _ember, _emberCan, _scientiaResourcebookerMixinsAppInsights) {
  exports['default'] = _ember['default'].Route.extend(_emberCan.CanMixin, _scientiaResourcebookerMixinsAppInsights['default'], {

    queryParams: {
      bookingID: { replace: true, refreshModel: true }
    },

    i18n: _ember['default'].inject.service(),
    titleToken: (function () {
      return this.get('i18n').t('pods.logged_in.booking_type.index.bookings');
    }).property(),

    beforeModel: function beforeModel() {

      if (!this.can('access bookings')) {
        this.transitionTo('logged-in.booking-request.index');
      }

      // Make sure we unload all booking-types  in case we are coming from administration page
      this.store.unloadAll('booking-type');

      // When entering booking types reset the bookingMode back to singleBooking
      this._resetBookingTypeShowProperties();
    },

    model: function model(params) {
      var model = {};
      model.bookingTypes = this.store.findAll('booking-type');

      if (params.bookingID) {
        model.bookingRequest = this.store.find('booking-request', params.bookingID);
      }

      return _ember['default'].RSVP.hash(model);
    },

    actions: {
      invalidateModel: function invalidateModel() {}
    },

    _resetBookingTypeShowProperties: function _resetBookingTypeShowProperties() {
      var bookingTypeShow = this.controllerFor('logged-in.booking-type.show');
      bookingTypeShow.setProperties({
        bookingMode: 1,
        resourceGroupId: '',
        recurrence: null
      });
    }

  });
});