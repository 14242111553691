define('scientia-resourcebooker/pods/components/manage-resources/remove-unavailability-date-slot/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    specificFromTime: null,
    specificToTime: null,
    userGroup: null,
    isSaving: false,

    formattedSpecificDate: (function () {
      if (this.get('specificFromTime') != null) {
        return this.get('specificFromTime').format(this.get('i18n').t('components.remove_unavailability_slot.date_format').toString());
      }
    }).property('specificFromTime'),

    formattedSpecificFromTime: (function () {
      if (this.get('specificFromTime') != null) {
        return this.get('specificFromTime').format(this.get('i18n').t('components.remove_unavailability_slot.datetime_format').toString());
      }
    }).property('specificFromTime'),

    formattedSpecificToTime: (function () {
      if (this.get('specificToTime') != null) {
        return this.get('specificToTime').format(this.get('i18n').t('components.remove_unavailability_slot.datetime_format').toString());
      }
    }).property('specificToTime'),

    startTime: _ember['default'].computed.alias('specificFromTime'),
    endTime: _ember['default'].computed.alias('specificToTime'),

    duration: (function () {
      return this.get('endTime').diff(this.get('startTime'), 'minutes');
    }).property('startTime', 'endTime'),

    actions: {
      submit: function submit() {
        this.set('isSaving', true);

        this.attrs.deleteUnavailabilityDateSlot({
          specificDate: this.get('specificFromTime'),
          duration: this.get('duration'),
          userGroup: this.get('userGroup')
        });
      }
    }
  });
});