define('scientia-resourcebooker/pods/manage-resources/resource/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/availability-form', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerMixinsAvailabilityForm, _scientiaResourcebookerConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var TABS = { DESCRIPTION: 0, FIELDS: 1, AVAILABILITY: 2, PERMISSIONS: 3 };

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsAvailabilityForm['default'], {
    queryParams: {
      selectedTab: 'section'
    },

    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),
    permissionDeprecatedChecker: _ember['default'].inject.service(),
    resourcePermissionSummaryFetcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    can: _ember['default'].inject.service(),

    type: 'resource',
    typeParentId: 'resourceTypeId',
    selectedTab: 0,

    isSaving: false,
    error: '',

    showResourcePermissionSummaryModal: false,

    canEditAll: _ember['default'].computed('model.resource', 'model.resource.id', function () {
      return this.get('can').can('edit-all resource', { model: this.get('model.resource') });
    }),
    canEditDescription: _ember['default'].computed('model.resource', 'model.resource.id', function () {
      return this.get('can').can('edit-description resource', { model: this.get('model.resource') });
    }),
    canEditCustomFields: _ember['default'].computed('model.resource', 'model.resource.id', function () {
      return this.get('can').can('edit-custom-fields resource', { model: this.get('model.resource') });
    }),
    canEditAvailability: _ember['default'].computed('model.resource', 'model.resource.id', function () {
      return this.get('can').can('edit-availability resource', { model: this.get('model.resource') });
    }),
    canEditPermissions: _ember['default'].computed('model.resource', 'model.resource.id', function () {
      return this.get('can').can('edit-permissions resource', { model: this.get('model.resource') });
    }),

    permissionsTypesLabelOverrides: { 'view-calendars': 'view-calendar' },
    permissionsTypes: _ember['default'].computed('canEditAll', 'canEditPermissions', function () {
      var _permissionTypes,
          _this = this;

      var canEditPermissions = this.get('canEditPermissions');
      var canEditAll = this.get('canEditAll');

      var toObject = function toObject(value, disabled) {
        var deprecated = _this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource', name: value });
        return { value: value, disabled: disabled, deprecated: deprecated };
      };

      var initialPermissions = ['read', 'write', 'approve', 'separator', 'book', 'request', 'separator', 'view-calendars', 'manage-bookings', 'separator'];
      var editPermissions = ['edit-description', 'edit-custom-fields', 'edit-availability', 'edit-permissions', 'edit-all'];

      var permissionTypes = [];

      // Map them from string to a value object
      initialPermissions = initialPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      editPermissions = editPermissions.map(function (p) {
        return toObject(p, !canEditAll);
      });

      // Shove editPermissions into previous array and return them all
      (_permissionTypes = permissionTypes).push.apply(_permissionTypes, _toConsumableArray(initialPermissions).concat(_toConsumableArray(editPermissions)));

      // Write and Approve permissions should also be disabled if !canEditAll
      permissionTypes.find(function (p) {
        return p.value === 'write';
      }).disabled = !canEditAll;
      permissionTypes.find(function (p) {
        return p.value === 'approve';
      }).disabled = !canEditAll;

      // Strip out deprecated properties if hidePermissionVersion3 is set
      if (this.get('systemSettings').getSetting('hidePermissionsVersion3')) {
        permissionTypes = permissionTypes.filter(function (perm) {
          return !_this.get('permissionDeprecatedChecker').isDeprecated({ type: 'resource', name: perm.value });
        });

        // If after removing deprecated properties the first property is a separator, then remove it
        if (permissionTypes.get('firstObject.value') === 'separator') permissionTypes.shift();
      }
      return permissionTypes;
    }),

    permissions: _ember['default'].computed('model.resource.readPermissionGroups', 'model.resource.writePermissionGroups', 'model.resource.approvePermissionGroups', 'model.resource.bookPermissionGroups', 'model.resource.requestPermissionGroups', 'model.resource.viewCalendarsPermissionGroups', 'model.resource.manageBookingsPermissionGroups', 'model.resource.editDescriptionPermissionGroups', 'model.resource.editCustomFieldsPermissionGroups', 'model.resource.editAvailabilityPermissionGroups', 'model.resource.editPermissionsPermissionGroups', 'model.resource.editAllPermissionGroups', function () {

      var model = this.get('model.resource');

      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(model);
      return fragments;
    }),

    hasNoProperties: _ember['default'].computed.empty('filteredProperties'),

    filteredProperties: _ember['default'].computed.filter('model.resource.properties', function (property) {
      return _scientiaResourcebookerConfigEnvironment['default'].validPropertyValueType.includes(property.get('valueType').toLowerCase());
    }),

    actions: {
      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      save: function save() {
        var transitionIndex = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var model = this.get('model').resource;

        var name = model.get('name') || '';
        if (_ember['default'].isEmpty(name.trim())) {
          return this._displayError(this.get('i18n').t('error_messages.resource_noname'), TABS.DESCRIPTION);
        }

        // Validate permissions
        var permissions = this.get('permissions');
        var permissionsKeys = this.get('model.resource.permissionsKeys');
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, permissionsKeys);
        Object.keys(permissionFragments).forEach(function (type) {
          model.get(type).setObjects(permissionFragments[type]);
        });

        this.send('updateResource', model, transitionIndex);
      },

      showResourcePermissionSummary: function showResourcePermissionSummary() {
        var _this2 = this;

        if (this.get('isLoadingPermissionSummary')) return;

        this.set('isLoadingPermissionSummary', true);
        this.get('resourcePermissionSummaryFetcher').fetch(this.get('model.resourceType.id'), this.get('model.resource.id')).then(function (result) {

          _this2.set('showResourcePermissionSummaryModal', result);
        })['finally'](function () {
          _this2.set('isLoadingPermissionSummary', false);
        });
      }
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        return this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), TABS.PERMISSIONS);
      }

      return true;
    },

    _displayError: function _displayError(error, tabIndex) {
      this.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.resource.new.resource_types'),
        path: 'manage-resources.resource-type.index'
      }, {
        label: this.get('model.resourceType.name'),
        path: 'manage-resources.resource-type.show',
        model: this.get('model.resourceType.id')
      }, {
        label: this.get('model.resource.name'),
        linkable: false
      }];
    }).property('model.resource.name', 'model.resource.id', 'model.resourceType.name', 'model.resourceType.id', 'i18n.locale')

  });
});