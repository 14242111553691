define('scientia-resourcebooker/pods/logged-in/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/authenticated-controller', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsAuthenticatedController, _emberCan) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsAuthenticatedController['default'], _emberCan.CanMixin, {
    showBookingTypesModal: false,
    bookingTypesAvailableForResourceSearching: null,
    globalSearchResourceClicked: null,
    globalSearchResourceClickedType: null,

    language: _ember['default'].computed('i18n.locale', function () {
      var locale = this.get('i18n.locale');
      return locale === 'zh-cn' ? null : locale;
    }),
    configFetcher: _ember['default'].inject.service(),
    appName: _ember['default'].computed.alias('configFetcher.appName'),

    bookingTypeTileMessage: _ember['default'].computed('showDisabledBookingTypeModal.tileMessage', function () {
      return this.get('showDisabledBookingTypeModal.tileMessage') || this.get('i18n').t('components.resources_grid.resource_disabled');
    }),

    disabledBookingTypeConfirmMessage: _ember['default'].computed('bookingTypeTileMessage', function () {
      var message = this.get('bookingTypeTileMessage');
      if (this.can('access admin')) message += '<p>' + this.get('i18n').t('components.resources_grid.resource_disabled_admin_extra') + '</p>';
      return _ember['default'].String.htmlSafe(message);
    }),

    actions: {
      showDisabledBookingTypeModal: function showDisabledBookingTypeModal(bookingType) {
        this.set('showDisabledBookingTypeModal', bookingType);
      },
      onCloseDisabledResourceModal: function onCloseDisabledResourceModal() {
        this.set('showDisabledBookingTypeModal', null);
      },

      onCloseBookingTypesModal: function onCloseBookingTypesModal() {
        this.set('showBookingTypesModal', false);
        this.send('clearBookingTypesModal');
      }
    }
  });
});