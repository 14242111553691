define('scientia-resourcebooker/pods/manage-resources/reports/index/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {
    reportsProcessor: _ember['default'].inject.service(),
    globalConfirmer: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.reports.page_title'), linkable: false }];
    }).property('i18n.locale'),

    queryParams: [{ selectedTab: 'section' }, 'page', 'perPage'],

    shouldShowPaging: _ember['default'].computed.gt('model.results.length', 0),

    selectedTab: 0,
    page: 1,
    perPage: 10,

    generateFromDate: null,
    generateToDate: null,

    hideDashboard: _ember['default'].computed(function () {
      return this.get('systemSettings').getSetting('hideDashboard');
    }),

    actions: {
      generateReport: function generateReport(fromDate, toDate, useBookingDates) {
        var _this = this;

        this.set('page', 1);
        this.get('reportsProcessor').generate({ fromDate: fromDate, toDate: toDate, useBookingDates: useBookingDates }).then(function (newReport) {
          _this.get('model.results').pushObject(newReport);
          _this.send('invalidateModel');
        })['catch'](function () {
          _this.flashError(_this.get('i18n').t('components.reports.flash_messages.generate_fail'));
        })['finally'](function () {});
      },

      // We open the url in a new window and refresh the current model so we get fresh download links
      downloadReport: function downloadReport(report) {
        this.get('reportsProcessor').downloadReport(report.downloadLinkUri);
        _ember['default'].run.later(this, 'send', 'invalidateModel', 2000);
      },

      deleteReport: function deleteReport(report) {
        var _this2 = this;

        this.get('globalConfirmer').confirm(this.get('i18n').t('components.reports.flash_messages.delete_confirm')).then(function () {
          return _this2.send('confirmDeleteReport', report);
        });
      },

      confirmDeleteReport: function confirmDeleteReport(report) {
        var _this3 = this;

        this.get('reportsProcessor')['delete'](report.id).then(function () {
          _this3.send('invalidateModel');
          _this3.flashSuccess(_this3.get('i18n').t('components.reports.flash_messages.delete_success'));
        })['catch'](function () {
          _this3.flashError(_this3.get('i18n').t('components.reports.flash_messages.delete_fail'));
        });
      }

    }
  });
});