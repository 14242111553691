define('scientia-resourcebooker/pods/components/manage-resources/booking-form-field/type-specific-options/booking-form-field-multiple-choice/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    store: _ember['default'].inject.service(),
    classNames: ['manageResources-itemForm-form-item-group'],

    emptyItemText: _ember['default'].computed.filterBy('model.items', 'text', ''),
    emptyItemValue: _ember['default'].computed.filterBy('model.items', 'value', ''),

    numberOfItemsToAdd: 1,
    numberOfItemsContent: (function () {
      var values = [];
      for (var i = 1; i <= 10; i++) {
        values.push(_ember['default'].Object.create({ value: i, label: i }));
      }

      return values;
    }).property(),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('model.isUsingExternalDataSource') && this.get('model.items.length') === 0) {
        this.send('addItem');
      }
    },

    hasDuplicateValues: _ember['default'].computed('model.items.@each.value', function () {
      return this.get('model.items').uniqBy('value').get('length') !== this.get('model.items.length');
    }),

    actions: {
      toggleIsUsingExternalDataSource: function toggleIsUsingExternalDataSource(isUsingExternalDataSource) {
        this.set('model.isUsingExternalDataSource', isUsingExternalDataSource);

        // Add the first item if no items exist
        if (isUsingExternalDataSource && this.get('model.items.length') === 0) {
          this.send('addItem');
        }
      },

      addItem: function addItem() {
        var numberOfItemsToAdd = this.get('numberOfItemsToAdd');
        for (var i = 0; i < numberOfItemsToAdd; i++) {
          this.get('model.items').pushObject(this.get('store').createFragment('booking-form-field-multiple-choice-item'));
        }
      },

      removeItem: function removeItem(item) {
        if (this.get('model.items.length') === 1) return; // Do not allow to delete last item
        this.get('model.items').removeObject(item);
        if (this.get('model.defaultItem') && this.get('model.defaultItem.value') === item.get('value')) {
          this.send('setDefaultItem', item);
        }
      },

      setDefaultItem: function setDefaultItem(item) {
        if (this.get('hasDuplicateValues') || !item.get('text') || !item.get('value')) return;
        item.toggleProperty('checked');
        // if (this.get('model.defaultItem') && this.get('model.defaultItem.value') === item.get('value')) {
        //   this.set('model.defaultItem', null);
        //   this.flashSuccess(this.get('i18n').t('components.manage_resources.booking_form_field.single_choice.set_as_default_removed', {name: item.get('text')}));
        // } else {
        //   this.set('model.defaultItem', item);
        //   this.flashSuccess(this.get('i18n').t('components.manage_resources.booking_form_field.single_choice.set_as_default', {name: item.get('text')}));

        // }
      }
    }
  });
});