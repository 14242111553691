define('scientia-resourcebooker/pods/logged-in/booking-type/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken(model) {
      return model.bookingType.get('name') + ' — ' + this.get('i18n').t('pods.logged_in.booking_type.show.bookings');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    resourceFilterer: _ember['default'].inject.service(),
    bookingTypeResourceSearcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    queryParams: {
      query: { replace: true, refreshModel: true },
      spanMultipleDays: { replace: true },
      availableNow: { replace: true, refreshModel: true },
      firstDay: { replace: true, refreshModel: true },
      lastDay: { replace: true, refreshModel: true },
      duration: { replace: true, refreshModel: true },
      fromTime: { replace: true, refreshModel: true },
      toTime: { replace: true, refreshModel: true },
      page: { replace: true, refreshModel: true },
      resourceGroupId: { refreshModel: true }
    },

    customFilters: null,
    lastParams: {},
    lastTransition: {},

    isResourceGroup: false,

    beforeModel: function beforeModel() {
      var _this = this;

      // Listens for the "clearResourceFilters" event sent by the 3 required
      // routes "this one", "resource-group/show" and "resource/show" and resets the resource Filters
      this.get('customEvents').one('clearResourceFilters', function () {
        _this._resetControllerProperties();
      });

      this._createResourcePatternFragment();
    },

    // jshint maxcomplexity:10
    model: function model(params, transition) {
      var _this2 = this;

      this.set('isResourceGroup', !!params.resourceGroupId);

      var controller = this.controllerFor('logged-in.booking-type.show');
      var filters = this.getResourceFilters(transition.queryParams);
      var resourcesPerPage = this.get('systemSettings').getSetting('resourcesListInitialLoadSize');
      var customFiltersSelected = this.controllerFor('logged-in.booking-type.show').get('customFiltersSelected');
      if (!_ember['default'].isEmpty(customFiltersSelected)) {
        filters.properties = customFiltersSelected;
        //resourcesPerPage = this.get('systemSettings').getSetting('resourcesListAdditionalLoadSize');
      }

      // Backup params and transition
      this.set('lastParams', params);
      this.set('lastTransition', transition);
      controller.set('isSearching', true);
      var recurrenceEnabled = controller.get('bookingMode') === 2;
      var recurrence = recurrenceEnabled ? controller.get('recurrence') : null;
      recurrence = recurrence ? this.get('recurrenceProcessor').generate(recurrence) : null;

      if (params.resourceGroupId) {
        filters.resourceGroupIds = [params.resourceGroupId];
      }

      var hash = _ember['default'].RSVP.hash({

        bookingType: this.store.find('booking-type', params.id)['catch'](function () {
          _this2.transitionTo('logged-in.booking-type.index').then(function () {
            _this2.flashError(_this2.get('i18n').t('pods.logged_in.booking_type.show.error_not_found').toString(), 15000);
          });
        }),

        filteredResources: this.get('resourceFilterer').filterBy({
          type: 'resource',
          bookingTypeId: params.id,
          filters: filters,
          recurrence: recurrence,
          itemsPerPage: resourcesPerPage
        })['catch'](function (error) {
          return _this2._handleErrors(error);
        }),

        // Only fetch resource groups if not inside a group
        filteredResourceGroups: params.resourceGroupId ? null : this.get('resourceFilterer').filterBy({
          bookingTypeId: params.id,
          type: 'resourceGroup',
          filters: filters,
          pageNumber: params.page
        }),

        // Fetch group only if there is a resourceGroupId
        resourceGroup: !params.resourceGroupId ? null : this.get('nestedResourceStore').fetch({
          type: 'bookingType.resourceGroup',
          parentId: params.id,
          id: params.resourceGroupId
        })['catch'](function () {})

      });

      return hash;
    },

    loadMore: function loadMore() {
      var itemsPerPage = this.get('systemSettings').getSetting('resourcesListAdditionalLoadSize');

      var params = this.get('lastParams');
      var transition = this.get('lastTransition');
      var controller = this.controllerFor('logged-in.booking-type.show');
      var filters = this.getResourceFilters(transition.queryParams);

      if (params.resourceGroupId) {
        filters.resourceGroupIds = [params.resourceGroupId];
      }

      var customFiltersSelected = this.controllerFor('logged-in.booking-type.show').get('customFiltersSelected');
      if (!_ember['default'].isEmpty(customFiltersSelected)) {
        filters.properties = customFiltersSelected;
      }

      var loadedIds = controller.get('model.filteredResources.results').getEach('id');
      controller.set('isLoadingMore', true);

      var recurrenceEnabled = controller.get('bookingMode') === 2;
      var recurrence = recurrenceEnabled ? controller.get('recurrence') : null;
      recurrence = recurrence ? this.get('recurrenceProcessor').generate(recurrence) : null;

      this.get('resourceFilterer').filterBy({
        bookingTypeId: params.id,
        type: 'resource',
        filters: filters,
        loadedIds: loadedIds,
        itemsPerPage: itemsPerPage,
        recurrence: recurrence

      }).then(function (results) {
        controller.get('model.filteredResources.results').pushObjects(results.results);
        controller.set('hasNoMoreResults', !results || results.results.length < itemsPerPage);
      })['finally'](function () {
        controller.set('isLoadingMore', false);
      });
    },

    afterModel: function afterModel(model) {
      var _this3 = this;

      if (model.bookingType.get('mode') === '1' && !this.get('currentUser').isAdmin()) {
        this.transitionTo('logged-in.booking-type.index');
        return;
      }

      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.bookingType.get('name'),
        id: model.bookingType.get('id')
      });

      this.setupBookingTypeNotifications(model.bookingType);

      // Check if on initial load there are more resources
      var controller = this.controllerFor('logged-in.booking-type.show');
      var initialLoadSize = this.get('systemSettings').getSetting('resourcesListInitialLoadSize');
      var hasNoMoreResults = model && model.filteredResources && model.filteredResources.results && model.filteredResources.results.length < initialLoadSize;
      controller.set('hasNoMoreResults', hasNoMoreResults);
      controller.set('isSearching', false);
      var meta = model.filteredResources;

      if (meta && meta.currentPage) {
        model.resourcesMeta = {
          currentPage: meta.currentPage,
          totalPages: meta.totalPages
        };
      }

      var resourceTypeId = model.bookingType.get('resourceRestrictionRules').filterBy('isPrimary').get('firstObject.resourceType.id');

      // If custom filters have already been fetched just retrieve them. No need to refetch every time
      // a filter is changed
      if (resourceTypeId) {

        // If we already have filters saved just retrieve them
        // if (this.get('customFilters')) {
        //   model.customFilters = this.get('customFilters');
        //   return;
        // }

        // Else fetch custom filters (resource type properties)
        return this.get('nestedResourceStore').fetch({ type: 'bookingType.resourceType', parentId: model.bookingType.get('id'), isSingleResource: true }).then(function (resourceType) {
          model.customFilters = resourceType.get('properties');
          model.customFilters = model.customFilters.filter(function (property) {
            return property.get('isFilterable') && !property.get('isObsolete');
          });

          // And store them
          _this3.set('customFilters', model.customFilters);
        });
      }
    },

    setupBookingTypeNotifications: function setupBookingTypeNotifications(bookingType) {
      var controller = this.controllerFor('logged-in.booking-type.show');

      if (!bookingType.get('alertEnabled')) {
        controller.set('bookingTypeAlert', null);
      } else {
        if (controller.get('bookingTypeAlert.bookingTypeId') !== bookingType.id || !controller.get('bookingTypeAlertIsDismissed')) {
          controller.set('bookingTypeAlert', {
            type: bookingType.get('alertType'),
            bookingTypeId: bookingType.get('id'),
            alert: true,
            message: _ember['default'].String.htmlSafe(bookingType.get('alertMessage'))
          });
        } else {
          controller.set('bookingTypeAlert', null);
        }
      }

      if (!bookingType.get('infoEnabled')) {
        controller.set('bookingTypeInfo', null);
      } else {
        if (controller.get('bookingTypeInfo.bookingTypeId') !== bookingType.id || !controller.get('bookingTypeInfoIsDismissed')) {
          controller.set('bookingTypeInfo', {
            type: bookingType.get('infoType'),
            bookingTypeId: bookingType.get('id'),
            info: true,
            message: _ember['default'].String.htmlSafe(bookingType.get('infoMessage'))
          });
        } else {
          controller.set('bookingTypeInfo', null);
        }
      }
    },

    _createResourcePatternFragment: function _createResourcePatternFragment() {
      var controller = this.controllerFor('logged-in.booking-type.show');
      var pattern = controller.get('recurrence');
      if (!pattern) {
        controller.set('recurrence', this.store.createFragment('recurrence-pattern', {
          fromTime: null,
          toTime: null
        }));
      }
    },

    getResourceFilters: function getResourceFilters(transition) {
      var properties = _ember['default'].getProperties(transition, ['query', 'availableNow', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'page', 'resourceGroupId']);

      return this.hasOneFilterAttribute(properties) ? properties : {};
    },

    hasOneFilterAttribute: function hasOneFilterAttribute(_ref) {
      var availableNow = _ref.availableNow;
      var firstDay = _ref.firstDay;
      var lastDay = _ref.lastDay;
      var fromTime = _ref.fromTime;
      var toTime = _ref.toTime;
      var duration = _ref.duration;
      var query = _ref.query;
      var page = _ref.page;
      var resourceGroupId = _ref.resourceGroupId;

      return this.hasOneTimeFilterAttribute({ availableNow: availableNow, firstDay: firstDay, lastDay: lastDay, fromTime: fromTime, toTime: toTime, duration: duration }) || this.hasOneSearchFilterAttribute({ query: query, resourceGroupId: resourceGroupId }) || this.hasOneMetaFilterAttribute({ page: page });
    },

    hasOneTimeFilterAttribute: function hasOneTimeFilterAttribute(_ref2) {
      var availableNow = _ref2.availableNow;
      var firstDay = _ref2.firstDay;
      var lastDay = _ref2.lastDay;
      var fromTime = _ref2.fromTime;
      var toTime = _ref2.toTime;
      var duration = _ref2.duration;

      return !!(availableNow || firstDay || lastDay || fromTime || toTime || duration);
    },

    hasOneSearchFilterAttribute: function hasOneSearchFilterAttribute(_ref3) {
      var query = _ref3.query;
      var resourceGroupId = _ref3.resourceGroupId;

      return !!query || !!resourceGroupId;
    },

    hasOneMetaFilterAttribute: function hasOneMetaFilterAttribute(_ref4) {
      var page = _ref4.page;

      return !!page;
    },

    // Clears all the resource filters and related properties whenever we are leaving the 3 resource routes
    // which are this one, resource-group/show and resource/show.
    _resetControllerProperties: function _resetControllerProperties() {

      var bookingTypeShowController = this.controllerFor('logged-in.booking-type.show');
      this.get('bookingTypeResourceSearcher').searchAbort(this.get('lastParams.id'));

      this.setProperties({
        customFilters: null,
        lastParams: null,
        lastTransition: null
      });

      bookingTypeShowController.setProperties({
        query: '',
        boundQuery: '',
        availableNow: false,
        spanMultipleDays: false,
        firstDay: null,
        lastDay: null,
        fromTime: '',
        toTime: '',
        duration: null,
        recurrenceErrors: [],
        customFiltersSelected: [],
        page: 1,
        showBookingTypeHelpMessageModal: false,
        bookingTypeAlert: null,
        bookingTypeInfo: null,
        bookingTypeAlertIsDismissed: false,
        bookingTypeInfoIsDismissed: false
      });
    },

    _handleErrors: function _handleErrors(error) {

      // We really just want to check for the Max Ocurrences limit and ignore any other API exceptions
      if (error.responseJSON && error.responseJSON.ExceptionMessageKey === 'maxoccurrenceslimit') {
        var errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON);

        if (errorMessage) {
          this.controllerFor('logged-in.booking-type.show').set('recurrenceErrorsServer', errorMessage.toString());
          return;
        }
      }
    },

    actions: {
      loadMoreResources: function loadMoreResources() {
        this.loadMore();
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      // When transitioning away from this route
      willTransition: function willTransition(transition) {
        // this._super(...arguments);
        var controller = this.controllerFor('logged-in.booking-type.show');

        // If we're navigating to a route other than the 2 below we need to clear the resource filters
        var names = ['logged-in.resource.show', 'logged-in.booking-type.show'];
        if (!names.includes(transition.targetName)) {
          // Trigger the event
          this.get('customEvents').triggerClearResourceFilters();
        }

        // If we're going to the resource calendar make sure we pass in the current bookingMode selected so that the bookingform
        // shows either Single or Recuring by default. Also pass in the startDate if it is a recurring booking
        if (transition.targetName === 'logged-in.resource.show') {
          var resourceShowController = this.controllerFor('logged-in.resource.show');
          resourceShowController.set('bookingMode', parseInt(controller.get('bookingMode')));
          resourceShowController.set('recurrencePattern', controller.get('recurrence'));
          this._logResourceClick(transition);
        }

        if (transition.targetName.indexOf('logged-in') !== 0) {
          this.store.unloadAll();
        }

        return true;
      }

    },

    _logResourceClick: function _logResourceClick(transition) {
      var _this4 = this;

      // Don't log if value is 0
      if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) === 0) {
        return;
      }

      try {
        (function () {
          var resource = _this4.controller.get('model.filteredResources.results').findBy('id', transition.intent.contexts[1]);
          var filters = _this4.controller.get('customFiltersSelected').map(function (filter) {
            return { name: filter.name, value: filter.value, type: filter.type };
          });

          var logData = {
            resourceName: resource.name,
            bookingTypeId: _this4.controller.get('model.bookingType.id'),
            bookingTypeName: _this4.controller.get('model.bookingType.name'),
            filters: JSON.stringify(filters)
          };

          var props = _this4.controller.getProperties(['query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration']);

          Object.keys(props).forEach(function (k) {
            logData[k] = _ember['default'].isEmpty(props[k]) ? null : props[k];
          });

          var durations = 'All Day,15m,30m,1h,2h,3h'.split(','); // we want to log in english no matter what the lang is selected
          logData.duration = _ember['default'].isEmpty(logData.duration) ? null : durations[parseInt(logData.duration)];

          _this4.get('appInsights').trackEvent('resource-found-filters', logData);
        })();
      } catch (e) {
        LE.error('Unable to log resource-found-filters', e);
      }
    }

  });
});