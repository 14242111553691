define('scientia-resourcebooker/pods/components/manage-resources/icon-selector/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    iconsFetcher: _ember['default'].inject.service(),

    classNames: ['iconSelector'],
    selectedIcon: null,
    icons: [],
    pack: null,
    hasLoadingError: false,

    // By default we only load the manifest for pack1.
    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        this.get('iconsFetcher').fetch(this.get('pack')).then(function (icons) {
          _this.set('icons', icons);
        })['catch'](function () {
          return _this.set('hasLoadingError', true);
        });
      });
    }).on('init'),

    actions: {
      selectIcon: function selectIcon(icon) {
        this.set('selectedIcon', icon);
      }
    }
  });
});