define('scientia-resourcebooker/pods/components/progress-bar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['progressBar'],
    classNameBindings: ['class'],
    percentage: 0,
    cssWidth: _ember['default'].computed('percentage', function () {
      var width = this.get('percentage') || 0;
      return _ember['default'].String.htmlSafe('width:' + width + '%');
    })
  });
});