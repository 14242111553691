define('scientia-resourcebooker/abilities/resource-type', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _emberCan.Ability.extend({
    currentUser: _ember['default'].inject.service(),

    canList: (function () {
      return this.get('currentUser').isResourceOwner();
    }).property(),

    canCreate: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canEdit: (function () {
      return this.get('model.canEdit');
    }).property('model.canEdit'),

    // Only admins can delete resource types
    canDelete: (function () {
      return this.get('currentUser').isAdmin();
    }).property(),

    canImportPermissions: (function () {
      // return true;
      return this.get('currentUser').canImportExportResourcePermissions();
    }).property(),

    canImportCustomFields: (function () {
      // return false;
      return this.get('currentUser').canImportExportResourceCustomFields();
    }).property(),

    canEditAll: _ember['default'].computed.alias('model.canEditAll'),
    canEditPermissions: _ember['default'].computed.alias('model.canEditPermissions'),
    canEditDescription: _ember['default'].computed.alias('model.canEditDescription'),
    canEditCustomFields: _ember['default'].computed.alias('model.canEditCustomFields'),
    canCreateResourceGroups: _ember['default'].computed.alias('model.canCreateResourceGroups'),
    canCreateResources: _ember['default'].computed.alias('model.canCreateResources')

  });
});