define("scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 98
            },
            "end": {
              "line": 10,
              "column": 209
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["icons/repeat.svg"], ["class", "bookingRequestOverview-recurrence-icon"], ["loc", [null, [10, 129], [10, 209]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 121
              },
              "end": {
                "line": 11,
                "column": 241
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("(");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(": ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(")");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_overview.occurrences"], [], ["loc", [null, [11, 162], [11, 217]]], 0, 0], ["content", "occurrencesLength", ["loc", [null, [11, 219], [11, 240]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 256
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "bookingRequestOverview-recurrenceDescription");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["content", "recurrenceDescription", ["loc", [null, [11, 95], [11, 120]]], 0, 0, 0, 0], ["block", "if", [["get", "showOccurrencesCountAfterPattern", ["loc", [null, [11, 127], [11, 159]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 121], [11, 248]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "button-print", [], ["onClick", ["subexpr", "route-action", ["onPrintClick", ["subexpr", "hash", [], ["type", "booking", "model", ["get", "model", ["loc", [null, [15, 85], [15, 90]]], 0, 0, 0, 0]], ["loc", [null, [15, 58], [15, 91]]], 0, 0]], [], ["loc", [null, [15, 29], [15, 92]]], 0, 0], "location", "at-bookingRequestOverview"], ["loc", [null, [15, 6], [15, 131]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [14, 14], [14, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 4], [16, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "bookingRequestOverview-timeDate-left");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bookingRequestOverview-timeDate-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("time");
        dom.setAttribute(el3, "class", "timeDate-date");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("time");
        dom.setAttribute(el3, "class", "timeDate-start");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" -\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("time");
        dom.setAttribute(el3, "class", "timeDate-end");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2, 1]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element2, 'datetime');
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(element1, 6, 6);
        morphs[6] = dom.createMorphAt(element1, 8, 8);
        morphs[7] = dom.createMorphAt(element1, 10, 10);
        return morphs;
      },
      statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "bookingRequestOverview-timeDate-icon"], ["loc", [null, [2, 2], [2, 82]]], 0, 0], ["attribute", "datetime", ["get", "model.startDateTime", ["loc", [null, [6, 21], [6, 40]]], 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "moment-format", [["get", "model.startDateTime", ["loc", [null, [6, 81], [6, 100]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "bookingDateFormat", ["loc", [null, [6, 107], [6, 124]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 101], [6, 125]]], 0, 0]], [], ["loc", [null, [6, 65], [6, 127]]], 0, 0], ["inline", "moment-format", [["get", "model.startDateTime", ["loc", [null, [9, 49], [9, 68]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "bookingTimeFormat", ["loc", [null, [9, 75], [9, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 69], [9, 93]]], 0, 0]], [], ["loc", [null, [9, 33], [9, 95]]], 0, 0], ["inline", "moment-format", [["get", "model.endDateTime", ["loc", [null, [10, 47], [10, 64]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "bookingTimeFormat", ["loc", [null, [10, 71], [10, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 65], [10, 89]]], 0, 0]], [], ["loc", [null, [10, 31], [10, 91]]], 0, 0], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [10, 104], [10, 127]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 98], [10, 216]]]], ["block", "if", [["get", "model.recurrencePattern", ["loc", [null, [11, 10], [11, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 4], [11, 263]]]], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [13, 12], [13, 26]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [13, 2], [17, 13]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});