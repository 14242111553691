define('scientia-resourcebooker/services/booking-type-resource-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    search: function search(_ref) {
      var parentId = _ref.parentId;
      var _ref$filters = _ref.filters;
      var filters = _ref$filters === undefined ? {} : _ref$filters;
      var type = _ref.type;
      var _ref$loadedIds = _ref.loadedIds;
      var loadedIds = _ref$loadedIds === undefined ? [] : _ref$loadedIds;

      var data = {};
      data = this.buildAvailabilityParams(filters, data);
      data = this.buildSearchParams(filters, data);
      data = this.buildLoadedIds(loadedIds, data);

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_FILTERER_URL[type].loc(parentId);
      var method = type === 'resource' ? 'post' : 'fetch';
      return this.get('authenticatedRequest')[method](url, data)['catch'](function (xhr) {
        return _ember['default'].RSVP.reject(xhr);
      });
    },

    buildSearchParams: function buildSearchParams(filters, data) {

      data.itemsPerPage = filters.itemsPerPage || 10;
      data.query = filters.query;
      data.properties = this._serializeProperties(filters.properties);
      data.pageNumber = filters.page;
      data.resourceGroupIds = filters.resourceGroupIds;

      return data;
    },

    buildLoadedIds: function buildLoadedIds(ids, data) {
      if (!_ember['default'].isEmpty(ids)) data.loadedIds = ids;
      return data;
    },

    shouldCheckAvailability: function shouldCheckAvailability(filters) {
      return !!filters.availableNow || !!filters.firstDay || !!filters.lastDay || !!filters.fromTime || !!filters.toTime || !!filters.recurrencePattern || !!filters.duration;
    },

    // jshint maxcomplexity:11
    buildAvailabilityParams: function buildAvailabilityParams(filters, data) {

      if (!this.shouldCheckAvailability(filters)) {
        return data;
      }

      var dateFormat = 'YYYY-MM-DD';
      var timeFormat = 'HH:mm';

      data.checkAvailability = true;
      // If there is a recurrencePattern just use it and skip the single availability properties
      if (!_ember['default'].isEmpty(filters.recurrencePattern)) {
        data.recurrencePattern = filters.recurrencePattern;

        var momentStart = moment(filters.recurrencePattern.startDateTime);
        var momentEnd = moment(filters.recurrencePattern.endDateTime);

        data.rangeStartDate = momentStart.utc().format(dateFormat);
        data.rangeEndDate = momentEnd.utc().format(dateFormat);

        data.rangeStartTime = momentStart.utc().format(timeFormat);
        data.rangeEndTime = momentEnd.utc().format(timeFormat);

        data.duration = momentEnd.utc().diff(momentStart.utc(), 'minute');
        return data;
      }

      // If there is no recurrence lets just generate the usual availability filters

      // Set start date to now if its empty or invalid
      var rangeStartDate = filters.firstDay ? moment(filters.firstDay, dateFormat) : moment();
      rangeStartDate = rangeStartDate.isValid() ? rangeStartDate : moment();

      // Set end date to start date if lastDay filter is not present or invalid
      var rangeEndDate = filters.lastDay ? moment(filters.lastDay, dateFormat) : null;
      rangeEndDate = rangeEndDate && rangeEndDate.isValid() ? rangeEndDate : rangeStartDate.clone();

      // Get institution offsets

      var _getInstitutionHours2 = this._getInstitutionHours();

      var _getInstitutionHours22 = _slicedToArray(_getInstitutionHours2, 2);

      var institutionStartHour = _getInstitutionHours22[0];
      var institutionEndHour = _getInstitutionHours22[1];

      var rangeStartTime = null;
      var rangeEndTime = null;

      // Convert fromTime to a moment object
      if (filters.fromTime) {
        var _filters$fromTime$split = filters.fromTime.split(':');

        var _filters$fromTime$split2 = _slicedToArray(_filters$fromTime$split, 2);

        var hours = _filters$fromTime$split2[0];
        var minutes = _filters$fromTime$split2[1];

        rangeStartTime = rangeStartDate.clone().hours(hours).minutes(minutes);
      } else {
        rangeStartTime = rangeStartDate.clone().hours(institutionStartHour).minutes(0);
      }

      // Convert toTime to a moment object
      if (filters.toTime) {
        var _filters$toTime$split = filters.toTime.split(':');

        var _filters$toTime$split2 = _slicedToArray(_filters$toTime$split, 2);

        var hours = _filters$toTime$split2[0];
        var minutes = _filters$toTime$split2[1];

        // Make sure we clone rangeStartTime to take into account any DST changes
        rangeEndTime = rangeEndDate.clone().hours(hours).minutes(minutes);
      } else {
        rangeEndTime = rangeEndDate.clone().hours(institutionEndHour).minutes(0);
      }

      var durationValue = parseInt(filters.duration);
      var durationInMinutes = this.getDuration(filters, rangeStartTime, rangeEndTime);

      // If available now is set, set the startTime to currentTime + next quarter min
      if (!!filters.availableNow) {
        rangeStartTime = moment().startOf('minute');
        var min = rangeStartTime.minute();
        rangeStartTime.subtract(min - Math.floor(min / 15) * 15 % 60, 'minute');

        durationInMinutes = this.getDuration(filters, rangeStartTime, rangeEndTime);
        // If duration is "AllDay (0)" set the endTime to the institution End hour
        if (durationValue === 0) {
          rangeEndTime = rangeStartTime.clone().hour(institutionEndHour).startOf('hour');
        } else {
          rangeEndTime = rangeStartTime.clone().add(durationInMinutes, 'minute');
        }
      }

      data.rangeStartDate = rangeStartTime.utc().format(dateFormat);
      data.rangeEndDate = rangeEndTime.utc().format(dateFormat);
      data.rangeStartTime = rangeStartTime.utc().format(timeFormat);
      data.rangeEndTime = rangeEndTime.utc().format(timeFormat);
      data.duration = durationInMinutes;

      return data;
    },

    getDuration: function getDuration(filters, rangeStartTime, rangeEndTime) {
      // set duration to null if fromTime and toTime are set. If they are set, check for a user selected duration instead
      var filterDuration = undefined;

      if (parseInt(filters.duration) === 0 || filters.fromTime && filters.toTime) {
        filterDuration = this.getDurationfromTimes(rangeStartTime, rangeEndTime);
      } else {
        var vals = [0, 15, 30, 60, 120, 180];
        filterDuration = vals[parseInt(filters.duration)];
      }

      return filterDuration || 60; // If there is no times nor duration return default 60min
    },

    getDurationfromTimes: function getDurationfromTimes(fromTime, toTime) {

      //Get duration in minutes between the to & from times
      var durationInMinutes = toTime.startOf('minute').diff(fromTime.startOf('minute'), 'minutes');
      return durationInMinutes <= 0 ? null : durationInMinutes;
    },

    _getInstitutionHours: function _getInstitutionHours() {
      var institutionStartHour = this.get('systemSettings').getSetting('calendarStartTimeOffset');
      var institutionEndHour = 24 - this.get('systemSettings').getSetting('calendarEndTimeOffset');
      return [institutionStartHour, institutionEndHour];
    },

    // If property.value is an object - then it's a min/max filter - and we need to classify() the keys
    // as the API checks to see if the keys are "Max" || "Min" (case sensitive) and won't find them otherwise
    _serializeProperties: function _serializeProperties() {
      var _this = this;

      var properties = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var props = [];
      Object.keys(properties).forEach(function (key) {
        var property = properties[key];
        var newProp = { id: property.identity, value: property.value };
        if (typeof newProp.value === 'object' && !_ember['default'].isArray(newProp.value)) {
          newProp.value = _this.get('authenticatedRequest')._serializeObject(newProp.value);
        }
        props.push(newProp);
      });
      return props;
    },

    searchAbort: function searchAbort(parentId) {
      this.get('authenticatedRequest').abortRequest(_scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_FILTERER_URL.resource.loc(parentId));
    }

  });
});