define('scientia-resourcebooker/pods/manage-resources/user-group/importer/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    errorHandler: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    userGroupSearcher: _ember['default'].inject.service(),
    bulkExporter: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    selectedTab: 0,

    previousTransition: null,
    showUnsavedChangesModal: false,

    userGroupsSelected: [],

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    importOptions: _ember['default'].computed('isAdmin', function () {

      var options = [{
        id: 'import_file',
        valueType: 'file',
        key: 'import_file',
        value: null
      }, {
        id: 'existing_user_groups',
        valueType: 'toggle',
        key: 'updateMode',
        options: [{ id: 'update', label: 'update', value: 1 }, { id: 'replace', label: 'replace', value: 0 }],
        value: ['update']
      }, {
        id: 'create_new_groups',
        key: 'createUserGroups',
        isCreateKey: true,
        hide: !this.get('isAdmin'),
        valueType: 'boolean',
        value: false
      }, {
        id: 'has_headers',
        key: 'hasHeader',
        valueType: 'boolean',
        value: true
      }];

      return options;
    }),

    isExportingAll: false,
    isExportingSelected: false,

    actions: {
      onUserGroupSearch: function onUserGroupSearch(query, deferred) {
        query.restrictedAccess = true;

        this.get('userGroupSearcher').search(query).then(function (response) {
          deferred.resolve(response);
        });
      },

      onUserGroupSelect: function onUserGroupSelect(groups) {
        this.set('userGroupsSelected', groups);
      },

      onExportAll: function onExportAll() {
        var _this = this;

        this.set('isExportingAll', true);
        return this.get('bulkExporter').exportAll({ type: 'claims' }).then(function (downloadId) {
          var download = _this.get('bulkExporter').getDownloadURL(downloadId);
          return download;
        })['finally'](function () {
          return _this.set('isExportingAll', false);
        });
      },

      onExportSelected: function onExportSelected() {
        var _this2 = this;

        this.set('isExportingSelected', true);
        var ids = this.get('userGroupsSelected').getEach('id');
        return this.get('bulkExporter').exportSelected({ type: 'claims', ids: ids }).then(function (downloadId) {
          var download = _this2.get('bulkExporter').getDownloadURL(downloadId);
          return download;
        })['finally'](function () {
          return _this2.set('isExportingSelected', false);
        });
      }

    },

    breadCrumbs: (function () {
      return [{ label: this.get('i18n').t('pods.manage_resources.user_group.index.user_groups'), linkable: true, path: 'manage-resources.user-group.index' }, { label: this.get('i18n').t('pods.manage_resources.user_group.importer.import_export'), linkable: false }];
    }).property('i18n.locale')
  });
});