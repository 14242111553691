define('scientia-resourcebooker/pods/manage-resources/resource/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    appInsights: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    titleToken: function titleToken(model) {
      return this.get('i18n').t('pods.manage_resources.resource.edit.page_title') + ' — ' + model.resource.get('name');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    resourceBusyTimesFetcher: _ember['default'].inject.service(),
    resourceBusyPatternsFetcher: _ember['default'].inject.service(),

    model: function model(params, transition) {
      var resourceTypeId = transition.params['manage-resources.resource'].resourceTypeId;

      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: resourceTypeId, adminPermissionsOnly: true }),
        resource: this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: resourceTypeId,
          id: params.id,
          data: { adminPermissionsOnly: true }
        }),
        events: [],
        eventPatterns: []
      });
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.resource.get('name'),
        id: model.resource.get('id'),
        resourceTypeName: model.resourceType.get('name'),
        resourceTypeId: model.resourceType.get('id')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          error: '',
          isSaving: false
        });
      }
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.resource.edit');
        this.store.peekAll('property').filterBy('resource', model.resource).invoke('rollbackAttributes');
        if (model.resource.get('hasDirtyAttributes')) model.resource.rollbackAttributes();
        model.resource.unloadRecord();
      },

      updateResource: function updateResource(model, transitionIndex) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.resource.edit');

        controller.set('isSaving', true);
        controller.set('error', '');

        model.save().then(function () {
          _this.editSuccessFlash('resource');

          if (transitionIndex) {
            return _this.transitionTo('manage-resources.resource-type.show', model.get('resourceTypeId'));
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.editErrorFlash('resource');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      fetchBusyTimes: function fetchBusyTimes(startDate, endDate, userGroupId) {
        var _this2 = this;

        var model = this.modelFor('manage-resources.resource.edit');
        this.send('loading');
        return this.get('resourceBusyTimesFetcher').fetch({
          type: 'resource',
          id: model.resource.get('id'),
          parentId: model.resource.get('resourceTypeId'),
          startDate: startDate,
          endDate: endDate,
          userGroupId: userGroupId
        }).then(function (events) {
          model.events.clear();
          model.events.pushObjects(events);
        })['catch'](function () {
          // this.get('flashMessages').danger(foreigner.t('general.generic_error'));
        })['finally'](function () {
          _this2.send('finished');
        });
      },

      fetchBusyPatterns: function fetchBusyPatterns(userGroupId) {
        var _this3 = this;

        var model = this.modelFor('manage-resources.resource.edit');
        this.send('loading');
        return this.get('resourceBusyPatternsFetcher').fetch({
          type: 'resource',
          id: model.resource.get('id'),
          parentId: model.resource.get('resourceTypeId'),
          userGroupId: userGroupId
        }).then(function (events) {
          model.eventPatterns.clear();
          model.eventPatterns.pushObjects(events);
        })['catch'](function () {
          // this.get('flashMessages').danger(foreigner.t('general.generic_error'));
        })['finally'](function () {
          _this3.send('finished');
        });
      },

      reload: function reload(_ref) {
        var specificDate = _ref.specificDate;
        var startDate = _ref.startDate;
        var endDate = _ref.endDate;
        var userGroup = _ref.userGroup;

        var model = this.modelFor('manage-resources.resource.edit');

        this.get('nestedResourceStore').fetch({
          type: 'resourceType.resource',
          parentId: model.resource.get('resourceTypeId'),
          id: model.resource.get('id'),
          data: { adminPermissionsOnly: true }
        });

        if (specificDate) {
          this.send('fetchBusyTimes', startDate, endDate, userGroup);
        } else {
          this.send('fetchBusyPatterns', userGroup);
        }
      }
    }
  });
});