define('scientia-resourcebooker/pods/components/rb-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':rb-dropdown', 'dropdownExpanded:rb-dropdown--active', 'iconOnly:rb-dropdown--iconOnly', 'location'],
    buttonLabel: null,
    buttonIcon: null,
    dropdownExpanded: false,
    iconOnly: false,

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var _this = this;

        _ember['default'].$(document.body).on('click', function (event) {
          var $target = _ember['default'].$(event.target);
          var $dropDownContent = _ember['default'].$('.rb-dropdown-content');

          if (_this.get('dropdownExpanded') && !$target.hasClass('rb-dropdown-trigger') && !$target.hasClass('rb-dropdown-content') && !$dropDownContent.has(event.target).length) {

            _this.set('dropdownExpanded', false);
          }
        });
      });
    }).on('init'),

    expandedObserver: (function () {
      _ember['default'].$('.liquid-container').toggleClass('liquid-container--noTransform');
    }).observes('dropdownExpanded'),

    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('dropdownExpanded');
      }
    }
  });
});