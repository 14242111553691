define("scientia-resourcebooker/pods/components/rb-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          dom.setAttribute(el1, "disabled", "");
          dom.setAttribute(el1, "hidden", "");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'selected');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "selected", ["subexpr", "if", [["subexpr", "eq", [["get", "value", ["loc", [null, [14, 39], [14, 44]]], 0, 0, 0, 0], null], [], ["loc", [null, [14, 35], [14, 50]]], 0, 0], "selected"], [], ["loc", [null, [null, null], [14, 63]]], 0, 0], 0, 0, 0, 0], ["content", "placeholder", ["loc", [null, [14, 71], [14, 86]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'disabled');
          morphs[1] = dom.createAttrMorph(element0, 'value');
          morphs[2] = dom.createAttrMorph(element0, 'selected');
          morphs[3] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "disabled", ["get", "contentChoice.disabled", ["loc", [null, [17, 23], [17, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["subexpr", "get", [["get", "contentChoice", ["loc", [null, [17, 60], [17, 73]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [17, 74], [17, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [17, 91]]], 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "value", ["loc", [null, [17, 106], [17, 111]]], 0, 0, 0, 0], ["subexpr", "get", [["get", "contentChoice", ["loc", [null, [17, 117], [17, 130]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [17, 131], [17, 146]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 112], [17, 147]]], 0, 0]], [], ["loc", [null, [null, null], [17, 149]]], 0, 0], 0, 0, 0, 0], ["inline", "get", [["get", "contentChoice", ["loc", [null, [17, 156], [17, 169]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [17, 170], [17, 185]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 150], [17, 187]]], 0, 0]],
        locals: ["contentChoice"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/rb-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("select");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element2, 'disabled');
        morphs[1] = dom.createAttrMorph(element2, 'onchange');
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(element2, 2, 2);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [12, 19], [12, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["updateValue"], ["value", "target.value"], ["loc", [null, [null, null], [12, 84]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["rb-select-select ", ["get", "class", ["loc", [null, [12, 111], [12, 116]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showPlaceholder", ["loc", [null, [13, 8], [13, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 2], [15, 9]]]], ["block", "each", [["get", "content", ["loc", [null, [16, 10], [16, 17]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 2], [18, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});