define('scientia-resourcebooker/pods/components/recurring-event-fields-limited/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recurringEventFields recurringEventFieldsLimited'],
    classNameBindings: ['class'],
    recurrenceProcessor: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    firstDayOfTheWeekOffset: _ember['default'].computed.alias('systemSettings.options.calendarFirstDayOfTheWeekOffset'),

    lastWeekendDay: _ember['default'].computed('firstDayOfTheWeekOffset', function () {
      var lastDay = this.get('firstDayOfTheWeekOffset') - 1;
      return lastDay < 0 ? lastDay + 7 : lastDay;
    }),

    fromTimeHour: _ember['default'].computed('model.fromTime', function () {

      if (this.get('model.fromTime')) {
        return parseInt(this.get('model.fromTime').split(':')[0]);
      }
    }),

    daysOfWeekDisabledStart: _ember['default'].computed('firstDayOfTheWeekOffset', function () {
      var firstDayOfTheWeekOffset = this.get('firstDayOfTheWeekOffset');
      return [0, 1, 2, 3, 4, 5, 6].without(firstDayOfTheWeekOffset);
    }),

    daysOfWeekDisabledEnd: _ember['default'].computed('lastWeekendDay', function () {
      return [0, 1, 2, 3, 4, 5, 6].without(this.get('lastWeekendDay'));
    }),

    maxDateForUntilDate: null,

    // We need to set these values on the pattern seeing it is a restricted pattern
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('model')) return;

      this.get('model').setProperties({
        isSPlusRestricted: true,
        recurrenceTypeSelected: 1, // weekly
        untilType: 1 // untilDate
      });
    },

    recurrenceDescription: _ember['default'].computed('i18n.locale', 'model.recurrenceTypeSelected', 'model.startDate', 'model.interval', 'model.untilType', 'model.fromTime', 'model.toTime', 'model.recurringDaysOfWeek.length', 'model.untilDate', function () {

      return this.get('recurrenceProcessor').generateDescription(this.get('model'));
    }),

    actions: {
      updateStartWeek: function updateStartWeek(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model')) return;

        if (date && !date.isSame(this.get('model.startDate'))) {
          this.set('model.startDate', date);
        }

        this.set('maxDateForUntilDate', date.clone().add('3', 'years'));
      },

      updateEndWeek: function updateEndWeek(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model')) return;

        if (date && !date.isSame(this.get('model.untilDate'))) {
          this.set('model.untilDate', date);
        }
      },

      isValidEndDate: function isValidEndDate(date) {
        var startDate = this.get('model.startDate') || moment();
        return date.isAfter(startDate, 'd');
      }
    }

  });
});