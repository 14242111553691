define("scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "title", ["loc", [null, [2, 2], [2, 11]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 48,
                          "column": 20
                        },
                        "end": {
                          "line": 50,
                          "column": 20
                        }
                      },
                      "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
                    },
                    isEmpty: false,
                    arity: 1,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                      ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue at-requestPermissionGroupApprovers");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                      return morphs;
                    },
                    statements: [["content", "approver.name", ["loc", [null, [49, 114], [49, 131]]], 0, 0, 0, 0]],
                    locals: ["approver"],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 45,
                        "column": 18
                      },
                      "end": {
                        "line": 52,
                        "column": 18
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "permissionsSummary-content-request");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "permissionsSummary-content-permissionValue at-requestPermissionGroupRequester");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
                    morphs[1] = dom.createMorphAt(element1, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "permission.requester.name", ["loc", [null, [47, 112], [47, 141]]], 0, 0, 0, 0], ["block", "each", [["get", "permission.approvers", ["loc", [null, [48, 28], [48, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 20], [50, 29]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 52,
                        "column": 18
                      },
                      "end": {
                        "line": 54,
                        "column": 18
                      }
                    },
                    "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "permissionsSummary-content-permissionValue");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "permission.name", ["loc", [null, [53, 77], [53, 96]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 16
                    },
                    "end": {
                      "line": 56,
                      "column": 16
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "permission.permissionName", ["loc", [null, [45, 28], [45, 53]]], 0, 0, 0, 0], "request"], [], ["loc", [null, [45, 24], [45, 64]]], 0, 0]], [], 0, 1, ["loc", [null, [45, 18], [54, 25]]]]],
                locals: ["permission"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 34,
                    "column": 12
                  },
                  "end": {
                    "line": 61,
                    "column": 12
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "permissionsSummary-content-table");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "permissionsSummary-content-permissionTypeName");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n\n\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "permissionsSummary-content-permissionTypeValues");
                var el3 = dom.createTextNode("\n\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element2, [4]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", [["subexpr", "concat-strings", ["components.manage_resources.permissions_form.permission_types.", ["get", "permissionType.name", ["loc", [null, [38, 159], [38, 178]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 78], [38, 179]]], 0, 0]], [], ["loc", [null, [38, 74], [38, 181]]], 0, 0], ["block", "each", [["get", "permissionType.permissions", ["loc", [null, [44, 24], [44, 50]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [44, 16], [56, 25]]]]],
              locals: ["permissionType"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 6
                },
                "end": {
                  "line": 64,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "permissionsSummary-content-object");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "permissionsSummary-content-objectName");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
              morphs[1] = dom.createMorphAt(element3, 4, 4);
              return morphs;
            },
            statements: [["content", "resource.name", ["loc", [null, [32, 62], [32, 79]]], 0, 0, 0, 0], ["block", "each", [["get", "resource.permissions", ["loc", [null, [34, 20], [34, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 12], [61, 21]]]]],
            locals: ["resource"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 6
                },
                "end": {
                  "line": 66,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "permissionsSummary-noPermissions");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["components.permissions_summary.no_permissions"], [], ["loc", [null, [65, 50], [65, 103]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 4
              },
              "end": {
                "line": 69,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "permissionsSummary-content-section");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h2");
            dom.setAttribute(el2, "class", "permissionsSummary-content-rootName");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element4, [2]), 0, 0);
            morphs[1] = dom.createMorphAt(element4, 4, 4);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat-strings", ["components.permissions_summary.", ["get", "permissionTypeValue.name", ["loc", [null, [27, 108], [27, 132]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 58], [27, 133]]], 0, 0]], [], ["loc", [null, [27, 54], [27, 135]]], 0, 0], ["block", "each", [["get", "permissionTypeValue.values", ["loc", [null, [28, 14], [28, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [28, 6], [66, 15]]]]],
          locals: ["permissionType", "permissionTypeValue"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 72,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-tabs");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "permissionsSummary-content");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [0]);
          var element6 = dom.childAt(element5, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element5, [3]);
          var element9 = dom.childAt(element8, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element6, 'class');
          morphs[1] = dom.createElementMorph(element6);
          morphs[2] = dom.createMorphAt(element7, 1, 1);
          morphs[3] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
          morphs[4] = dom.createAttrMorph(element8, 'class');
          morphs[5] = dom.createElementMorph(element8);
          morphs[6] = dom.createMorphAt(element9, 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [7, 33], [7, 44]]], 0, 0, 0, 0], 1], [], ["loc", [null, [7, 29], [7, 47]]], 0, 0], "selected"], [], ["loc", [null, [7, 24], [7, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tabChange", 1], [], ["loc", [null, [7, 62], [7, 86]]], 0, 0], ["inline", "inline-svg", ["icons/security.svg"], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [9, 8], [9, 79]]], 0, 0], ["inline", "t", ["components.permissions_summary.administrative"], [], ["loc", [null, [10, 14], [10, 67]]], 0, 0], ["attribute", "class", ["concat", ["button ", ["subexpr", "if", [["subexpr", "eq", [["get", "selectedTab", ["loc", [null, [13, 35], [13, 46]]], 0, 0, 0, 0], 2], [], ["loc", [null, [13, 31], [13, 49]]], 0, 0], "selected"], [], ["loc", [null, [13, 26], [13, 62]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["tabChange", 2], [], ["loc", [null, [13, 64], [13, 88]]], 0, 0], ["inline", "inline-svg", ["icons/user.svg"], ["class", "permissionsSummaryTabs-icon"], ["loc", [null, [15, 8], [15, 75]]], 0, 0], ["inline", "t", ["components.permissions_summary.booking"], [], ["loc", [null, [16, 14], [16, 60]]], 0, 0], ["block", "each-in", [["get", "permissionData", ["loc", [null, [23, 15], [23, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 4], [69, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 0
            },
            "end": {
              "line": 76,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "button button--lightGrey at-lf-dialog-footer");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["element", "action", [["get", "close", ["loc", [null, [75, 19], [75, 24]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 10], [75, 26]]], 0, 0], ["inline", "t", ["general.close"], [], ["loc", [null, [75, 80], [75, 101]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/manage-resources/permissions-summary/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-header", [], ["onClose", ["subexpr", "action", [["get", "close", ["loc", [null, [1, 32], [1, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 24], [1, 38]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [3, 17]]]], ["block", "modal-content", [], [], 1, null, ["loc", [null, [5, 0], [72, 18]]]], ["block", "modal-footer", [], [], 2, null, ["loc", [null, [74, 0], [76, 17]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});