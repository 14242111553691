define('scientia-resourcebooker/services/print-handler', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    router: _ember['default'].inject.service(),

    handle: function handle(_ref) {
      var type = _ref.type;
      var model = _ref.model;

      var router = this.get('router');

      var host = router.get('location.location.host');

      var url = undefined;
      if (model.get('hasRecurrence')) {
        url = router.urlFor('print.booking', model.get('eventId'), { queryParams: { occurrenceId: model.get('id') } });
      } else {
        url = router.urlFor('print.booking', model.get('eventId'));
      }
      window.open('//' + host + url, '_blank', 'height=800,width=1024');
    },

    isPrintRoute: function isPrintRoute() {
      var currentRoute = this.get('router').get('currentRouteName');
      if (currentRoute) return currentRoute.indexOf('print') === 0;
    }
  });
});