define("scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 202
                },
                "end": {
                  "line": 8,
                  "column": 223
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("*");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "bookingRequestOverview-occurrencesList--expand");
              var el2 = dom.createTextNode("... ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(element2, 1, 1);
              return morphs;
            },
            statements: [["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "isShowingOccurrences", ["loc", [null, [10, 95], [10, 115]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 90], [10, 116]]], 0, 0], true], [], ["loc", [null, [10, 82], [10, 122]]], 0, 0]], [], ["loc", [null, [10, 73], [10, 124]]], 0, 0], ["inline", "t", ["components.booking_request_overview.show_all"], [], ["loc", [null, [10, 129], [10, 181]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 6
              },
              "end": {
                "line": 13,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("time");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-occurrence-date");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("time");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-occurrence-time");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("-");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(element4, 0, 0);
            morphs[3] = dom.createMorphAt(element4, 2, 2);
            morphs[4] = dom.createMorphAt(element4, 3, 3);
            morphs[5] = dom.createMorphAt(element3, 5, 5);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["bookingRequestOverview-occurrencesList-occurrence ", ["subexpr", "if", [["get", "occ.isEdited", ["loc", [null, [6, 74], [6, 86]]], 0, 0, 0, 0], "bookingRequestOverview-occurrencesList-occurrence--edited"], [], ["loc", [null, [6, 69], [6, 148]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "moment-format", [["get", "occ.startDateTime", ["loc", [null, [7, 93], [7, 110]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceDateFormat", ["loc", [null, [7, 117], [7, 137]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 111], [7, 138]]], 0, 0]], [], ["loc", [null, [7, 77], [7, 140]]], 0, 0], ["inline", "moment-format", [["get", "occ.startDateTime", ["loc", [null, [8, 93], [8, 110]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceTimeFormat", ["loc", [null, [8, 117], [8, 137]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 111], [8, 138]]], 0, 0]], [], ["loc", [null, [8, 77], [8, 140]]], 0, 0], ["inline", "moment-format", [["get", "occ.endDateTime", ["loc", [null, [8, 157], [8, 172]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceTimeFormat", ["loc", [null, [8, 179], [8, 199]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 173], [8, 200]]], 0, 0]], [], ["loc", [null, [8, 141], [8, 202]]], 0, 0], ["block", "if", [["get", "occ.isEdited", ["loc", [null, [8, 208], [8, 220]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 202], [8, 230]]]], ["block", "if", [["subexpr", "eq", [["get", "index", ["loc", [null, [9, 18], [9, 23]]], 0, 0, 0, 0], 2], [], ["loc", [null, [9, 14], [9, 26]]], 0, 0]], [], 1, null, ["loc", [null, [9, 8], [11, 15]]]]],
          locals: ["occ", "index"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 6
              },
              "end": {
                "line": 17,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "bookingRequestOverview-occurrencesList-editedNote");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-editedNote-star");
            var el3 = dom.createTextNode("*");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_overview.edited_note"], [], ["loc", [null, [16, 150], [16, 205]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["subexpr", "take", [3, ["get", "occurrences", ["loc", [null, [5, 22], [5, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 14], [5, 34]]], 0, 0]], [], 0, null, ["loc", [null, [5, 6], [13, 15]]]], ["block", "if", [["get", "hasEditedOccurrencesFirstThree", ["loc", [null, [15, 12], [15, 42]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [15, 6], [17, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 267
                },
                "end": {
                  "line": 24,
                  "column": 288
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("*");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 6
              },
              "end": {
                "line": 26,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("time");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-occurrence-date bookingRequestOverview-occurrencesList-occurrence-date--expanded");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("time");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-occurrence-time bookingRequestOverview-occurrencesList-occurrence-time--expanded");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("-");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[2] = dom.createMorphAt(element1, 0, 0);
            morphs[3] = dom.createMorphAt(element1, 2, 2);
            morphs[4] = dom.createMorphAt(element1, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["bookingRequestOverview-occurrencesList-occurrence ", ["subexpr", "if", [["get", "occ.isEdited", ["loc", [null, [22, 74], [22, 86]]], 0, 0, 0, 0], "bookingRequestOverview-occurrencesList-occurrence--edited"], [], ["loc", [null, [22, 69], [22, 148]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "moment-format", [["get", "occ.startDateTime", ["loc", [null, [23, 158], [23, 175]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceDateFormat", ["loc", [null, [23, 182], [23, 202]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 176], [23, 203]]], 0, 0]], [], ["loc", [null, [23, 142], [23, 205]]], 0, 0], ["inline", "moment-format", [["get", "occ.startDateTime", ["loc", [null, [24, 158], [24, 175]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceTimeFormat", ["loc", [null, [24, 182], [24, 202]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 176], [24, 203]]], 0, 0]], [], ["loc", [null, [24, 142], [24, 205]]], 0, 0], ["inline", "moment-format", [["get", "occ.endDateTime", ["loc", [null, [24, 222], [24, 237]]], 0, 0, 0, 0], ["subexpr", "to-s", [["get", "occurrenceTimeFormat", ["loc", [null, [24, 244], [24, 264]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 238], [24, 265]]], 0, 0]], [], ["loc", [null, [24, 206], [24, 267]]], 0, 0], ["block", "if", [["get", "occ.isEdited", ["loc", [null, [24, 273], [24, 285]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 267], [24, 295]]]]],
          locals: ["occ"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 30,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "bookingRequestOverview-occurrencesList-editedNote");
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "bookingRequestOverview-occurrencesList-editedNote-star");
            var el3 = dom.createTextNode("*");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["components.booking_request_overview.edited_note"], [], ["loc", [null, [29, 150], [29, 205]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "occurrences", ["loc", [null, [21, 14], [21, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [21, 6], [26, 15]]]], ["block", "if", [["get", "hasEditedOccurrences", ["loc", [null, [28, 12], [28, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [28, 6], [30, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/booking-request-overview/occurrences-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "bookingRequestOverviewData-sectionName");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" (");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(")");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createMorphAt(element6, 0, 0);
        morphs[2] = dom.createMorphAt(element6, 2, 2);
        morphs[3] = dom.createMorphAt(element5, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["bookingRequestOverview-occurrencesList ", ["subexpr", "if", [["get", "isShowingOccurrences", ["loc", [null, [1, 56], [1, 76]]], 0, 0, 0, 0], "bookingRequestOverview-occurrencesList--expanded"], [], ["loc", [null, [1, 51], [1, 129]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["components.booking_request_overview.occurrences"], [], ["loc", [null, [2, 53], [2, 108]]], 0, 0], ["content", "occurrences.length", ["loc", [null, [2, 110], [2, 132]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "gt", [["get", "occurrences.length", ["loc", [null, [3, 19], [3, 37]]], 0, 0, 0, 0], 3], [], ["loc", [null, [3, 15], [3, 40]]], 0, 0], ["subexpr", "not", [["get", "isShowingOccurrences", ["loc", [null, [3, 46], [3, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 41], [3, 67]]], 0, 0]], [], ["loc", [null, [3, 10], [3, 68]]], 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [31, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});