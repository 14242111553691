define('scientia-resourcebooker/pods/application/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    session: _ember['default'].inject.service(),
    flashMessages: _ember['default'].inject.service(),
    isSessionTimedout: _ember['default'].computed.alias('session.isSessionTimedout'),
    appInsights: _ember['default'].inject.service(),

    helpFetcher: _ember['default'].inject.service(),
    router: _ember['default'].inject.service(),

    showHelpModal: false,
    helpDataForPage: null,

    init: function init() {
      var _this = this;

      this._super();

      this.get('session').on('authorizationInvalidated', function () {
        _this.get('session').timeout();
      });

      this.get('i18n').on('missing', function (locale, key, context) {
        if (key.indexOf('booking_window_custom') === -1 && (!context || !context.optional)) {
          LE.error('Missing translation:', { locale: locale, key: key, context: context });
        }
      });
    },

    sessionTimeoutObserver: (function () {
      var _this2 = this;

      if (!this.get('isSessionTimedout')) {
        return false;
      }

      this.flashClear();
      this.flashCustom({ timeout: 10000, message: this.get('i18n').t('general.session_timeout_error'), type: 'danger alert-session-timeout' });

      var loggedInAt = this.get('session.loggedInAt');

      if (moment().diff(loggedInAt, 'seconds') > 30) {

        _ember['default'].run.later(this, function () {
          _this2.get('session').saveRedirectURL();
          _this2.get('session').logout();
        }, 5000);
      } else {
        this.get('session').saveRedirectURL();
        this.get('session').logout();
      }
    }).observes('isSessionTimedout'),

    actions: {
      confirmLogout: function confirmLogout(shouldSLO) {
        this.get('appInsights').trackEvent('logout');
        this.get('session').logout(true, shouldSLO);
      },

      onShowHelpModal: function onShowHelpModal() {
        var _this3 = this;

        this.set('showHelpModal', true);
        var routePath = this.get('router').get('currentRouteName');
        this.get('helpFetcher').fetch(routePath).then(function (data) {
          data = data || {};
          data.path = routePath;
          data.home = _this3.get('helpFetcher').getHomepageForLocale(_this3.get('i18n.locale'));
          _this3.set('helpDataForPage', data);
        });
      }
    }
  });
});