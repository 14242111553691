define('scientia-resourcebooker/pods/components/manage-resources/permissions-summary/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['permissionsSummary'],

    selectedTab: 1,

    permissionData: _ember['default'].computed('selectedTab', function () {
      return this.get('selectedTab') === 1 ? this.get('model.admin') : this.get('model.book');
    }),

    actions: {
      tabChange: function tabChange(tab) {
        this.set('selectedTab', tab);
      }
    }
  });
});