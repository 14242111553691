define('scientia-resourcebooker/services/reports-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    timezone: _ember['default'].computed.alias('systemSettings.options.timeZone'),
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetch: function fetch(_ref) {
      var page = _ref.page;
      var itemsPerPage = _ref.itemsPerPage;

      var dateFormat = 'YYYY-MM-DD';
      var data = {
        start: moment().subtract(5, 'years').format(dateFormat),
        end: moment().add(1, 'month').format(dateFormat),
        pageNumber: page,
        itemsPerPage: itemsPerPage || 10
      };
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.REPORTS.FEED, data);
    },

    generate: function generate(_ref2) {
      var _this = this;

      var fromDate = _ref2.fromDate;
      var toDate = _ref2.toDate;
      var _ref2$fileType = _ref2.fileType;
      var fileType = _ref2$fileType === undefined ? 'csv' : _ref2$fileType;
      var _ref2$useBookingDates = _ref2.useBookingDates;
      var useBookingDates = _ref2$useBookingDates === undefined ? false : _ref2$useBookingDates;

      this.get('appInsights').trackEvent('report-audit-generate', { fromDate: fromDate.format(), toDate: toDate.format(), useBookingDates: useBookingDates });

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.REPORTS.BOOKING_REQUESTS;

      // We want to pass local times so that we can generate a report with local times as well
      // We also need to encode because the format() function adds the timezone with the + char and it doesn't work with the api
      var formattedFromDate = encodeURIComponent(fromDate.format());
      var formattedToDate = encodeURIComponent(toDate.format());
      url += '?start=' + formattedFromDate + '&end=' + formattedToDate + '&reportType=' + fileType + '&useBookingDates=' + useBookingDates;

      var columnNames = ['identity', 'reference', 'title', 'description', 'startTimeUtc', 'endTimeUtc', 'startTimeLocal', 'endTimeLocal', 'durationMinutes', 'bookerId', 'bookerName', 'bookerEmailAddress', 'resourceType', 'resourceName', 'templateName', 'currentStatus', 'statusAfterAction', 'action', 'actionDateUtc', 'actionDateLocal', 'actionedBy', 'notes', 'form'];

      var translationPrefix = 'components.reports.audit.column_names.';
      var columnsTranslations = columnNames.reduce(function (memo, column) {
        memo[column] = _this.get('i18n').t(translationPrefix + column).toString();
        return memo;
      }, {});

      return this.get('authenticatedRequest').post(url, columnsTranslations, true);
    },

    'delete': function _delete(reportId) {
      return this.get('authenticatedRequest')['delete'](_scientiaResourcebookerConfigEnvironment['default'].API.REPORTS.SINGLE.loc(reportId));
    },

    downloadReport: function downloadReport(url) {
      url += '?timezone=' + this.get('timezone');
      window.open(url);
    }

  });
});