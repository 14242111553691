define('scientia-resourcebooker/pods/components/rb-select/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':rb-select', 'isFocused:rb-select--focused', 'class'],
    attributeBindings: ['disabled'],
    content: null,
    value: null,
    placeholder: (0, _emberI18n.translationMacro)('components.rb_select.placeholder'),
    disabled: false,
    optionLabelPath: 'label',
    optionValuePath: 'value',
    showPlaceholder: false,
    forceInt: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.$('.rb-select-select').on('focus', function () {
        _ember['default'].run(function () {
          return _this.set('isFocused', true);
        });
      });

      this.$('.rb-select-select').on('blur', function () {
        _ember['default'].run(function () {
          return _this.set('isFocused', false);
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.get('isDestroyed')) this.$('.rb-select-select').off('focus blur');
    },

    actions: {
      updateValue: function updateValue(value) {
        if (this.get('forceInt')) value = parseInt(value);
        this.set('value', value);
        this.sendAction('onChange', value);
      }
    }
  });
});