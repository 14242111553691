define('scientia-resourcebooker/pods/components/notifications-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['notificationsList'],
    classNameBindings: ['noNotifications'],
    notifications: [],
    loadingBookingRequest: false,
    noNotifications: _ember['default'].computed.not('notifications.length'),

    searchOptionsSelected: [],
    hasSearchOptions: _ember['default'].computed.gt('searchOptionsSelected', 0),

    dateFields: ['created_at_start_range', 'created_at_end_range', 'booking_date_start_range', 'booking_date_end_range'],

    actions: {
      accept: function accept(request) {
        this.sendAction('acceptRequest', request);
      },

      reject: function reject(request) {
        this.sendAction('rejectRequest', request);
      },

      moreInfo: function moreInfo(request) {
        this.sendAction('moreInfoRequest', request);
      },

      viewNotes: function viewNotes(request) {
        this.sendAction('viewNotes', request);
      },

      openEventOverview: function openEventOverview(request) {
        this.sendAction('openEventOverview', request);
      },

      removeSearchTerm: function removeSearchTerm(searchKey) {
        this.sendAction('removeSearchTerm', searchKey);
      }
    }
  });
});