define("scientia-resourcebooker/pods/logged-in/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 14,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["resources-grid"], ["bookingTypes", ["subexpr", "@mut", [["get", "bookingTypesAvailableForResourceSearching", ["loc", [null, [9, 19], [9, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "isModal", true, "close", ["subexpr", "action", ["onCloseBookingTypesModal"], [], ["loc", [null, [11, 12], [11, 47]]], 0, 0]], ["loc", [null, [7, 9], [11, 48]]], 0, 0], "onOutsideClick", ["subexpr", "action", ["onCloseBookingTypesModal"], [], ["loc", [null, [12, 19], [12, 54]]], 0, 0], "dialogClass", "resources-grid-modal"], ["loc", [null, [6, 7], [13, 39]]], 0, 0]], ["loc", [null, [4, 0], [13, 41]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "route-action", ["onCancelTransition"], [], ["loc", [null, [23, 12], [23, 47]]], 0, 0], "location", "at-bookingTypeAlert", "confirmTitle", ["subexpr", "@mut", [["get", "showDisabledBookingTypeModal.name", ["loc", [null, [25, 19], [25, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "confirmMessage", ["subexpr", "@mut", [["get", "disabledBookingTypeConfirmMessage", ["loc", [null, [26, 21], [26, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "singleButtonMode", ["subexpr", "cannot", ["access admin"], [], ["loc", [null, [27, 23], [27, 46]]], 0, 0], "headerIcon", "icons/attention-exclamation-triangular-signal.svg", "headerIconClass", "confirmModal-headerIcon at-bookingTypeAlert", "singleButtonAction", ["subexpr", "route-action", ["onCancelTransition"], [], ["loc", [null, [30, 25], [30, 60]]], 0, 0], "confirm", ["subexpr", "route-action", ["onConfirmTransition"], [], ["loc", [null, [31, 14], [31, 50]]], 0, 0]], ["loc", [null, [21, 9], [31, 51]]], 0, 0], "onOutsideClick", ["subexpr", "route-action", ["onCancelTransition"], [], ["loc", [null, [32, 19], [32, 54]]], 0, 0]], ["loc", [null, [20, 7], [32, 55]]], 0, 0]], ["loc", [null, [18, 1], [33, 4]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sidebar-overlay");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["toggleSidebar"], [], ["loc", [null, [39, 31], [39, 57]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 2
            },
            "end": {
              "line": 45,
              "column": 50
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "help-trigger", [], ["mobile", true], ["loc", [null, [45, 22], [45, 50]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 60,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "sidebar-toggler at-root");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "sidebar-toggler-open-wrapper");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "sidebar-toggler-name");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [5]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createAttrMorph(element1, 'lang');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createMorphAt(element3, 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[7] = dom.createMorphAt(element2, 5, 5);
        morphs[8] = dom.createMorphAt(element1, 5, 5);
        morphs[9] = dom.createMorphAt(element1, 7, 7);
        morphs[10] = dom.createMorphAt(element1, 9, 9);
        return morphs;
      },
      statements: [["block", "if", [["get", "showBookingTypesModal", ["loc", [null, [3, 6], [3, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [14, 7]]]], ["block", "if", [["get", "showDisabledBookingTypeModal", ["loc", [null, [17, 6], [17, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [17, 0], [34, 7]]]], ["attribute", "lang", ["concat", [["get", "language", ["loc", [null, [37, 31], [37, 39]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "isSidebarOpen", ["loc", [null, [38, 6], [38, 19]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [38, 0], [40, 7]]]], ["element", "action", ["toggleSidebar"], [], ["loc", [null, [43, 45], [43, 71]]], 0, 0], ["inline", "inline-svg", ["icons/list.svg"], ["class", "sidebar-toggler-open at-root"], ["loc", [null, [43, 72], [43, 140]]], 0, 0], ["inline", "if", [["get", "appName", ["loc", [null, [44, 42], [44, 49]]], 0, 0, 0, 0], ["get", "appName", ["loc", [null, [44, 50], [44, 57]]], 0, 0, 0, 0], "Resource Booker"], [], ["loc", [null, [44, 37], [44, 77]]], 0, 0], ["block", "if", [["get", "media.isTiny", ["loc", [null, [45, 8], [45, 20]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [45, 2], [45, 57]]]], ["inline", "container-sidebar", [], ["isSidebarOpen", ["subexpr", "@mut", [["get", "isSidebarOpen", ["loc", [null, [49, 16], [49, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "user", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [50, 7], [50, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "notificationsCount", ["subexpr", "@mut", [["get", "notificationsCountFormatted", ["loc", [null, [51, 21], [51, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "toggleSidebar", "toggleSidebar", "logout", "logout"], ["loc", [null, [48, 0], [54, 2]]], 0, 0], ["content", "liquid-outlet", ["loc", [null, [56, 0], [56, 17]]], 0, 0, 0, 0], ["content", "modal-target", ["loc", [null, [58, 0], [58, 16]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});