define('scientia-resourcebooker/pods/application/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    configFetcher: _ember['default'].inject.service(),
    printHandler: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      return this.get('configFetcher').fetch();
    },

    actions: {
      loading: function loading() {
        var controller = this.controllerFor('application');
        controller.set('loading', true);
        this.router.one('didTransition', function () {
          return controller.set('loading', false);
        });
      },

      loadingWithoutTransition: function loadingWithoutTransition() {
        var controller = this.controllerFor('application');
        controller.set('loading', true);
      },

      finished: function finished() {
        this.controllerFor('application').set('loading', false);
      },

      error: function error() {
        this.controllerFor('application').set('loading', false);
        this.flashError(this.get('i18n').t('general.generic_error'));
      },

      goBack: function goBack() {
        window.history.back();
      },

      // Work around for ember-route-action throwing an exception that could not find route handler when moving from "booking-type.index to manage-resources.booking-type.index"
      onClickBookingType: function onClickBookingType() {},

      didTransition: function didTransition() {
        _ember['default'].$('.progressLoading').remove();
      },

      onHelpClick: function onHelpClick() {
        this.controller.send('onShowHelpModal');
      },

      onPrintClick: function onPrintClick(_ref) {
        var type = _ref.type;
        var model = _ref.model;

        this.get('printHandler').handle({ type: type, model: model });
      }
    }

  });
});