define('scientia-resourcebooker/pods/manage-resources/resource-type/new/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/app-insights', 'ember-can'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAppInsights, _emberCan) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], _emberCan.CanMixin, _scientiaResourcebookerMixinsAppInsights['default'], {
    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.resource_type.new.page_title');
    },

    resourceTypePersister: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('systemSettings.options.isLite')) {
        var user = this.get('currentUser.user');
        LE.error('LITE LICENSE: Access attempt to resource-type/new from ' + user.name + ' - ' + user.email + ' - ' + user.nameIdentifier);
        return this.transitionTo('manage-resources.resource-type.index');
      }
      if (!this.can('create resource-type')) {
        return this.transitionTo('manage-resources.resource-type.index');
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          name: '',
          permissions: [],
          properties: [],
          error: '',
          isSaving: false
        });
      }
    },

    actions: {
      createResourceType: function createResourceType(data) {
        var _this = this;

        var controller = this.controllerFor('manage-resources.resource-type.new');

        if (_ember['default'].isEmpty(data.name)) {
          controller.set('error', this.get('i18n').t('error_messages.resourcetype_noname').toString());
          return;
        }

        controller.set('error', '');
        controller.set('isSaving', true);

        this.get('resourceTypePersister').persist(data).then(function (_ref) {
          var id = _ref.id;

          _this.createSuccessFlash('resource-type');
          return _this.transitionTo('manage-resources.resource-type.edit', id);
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this.createErrorFlash('resource-type');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      }
    }
  });
});