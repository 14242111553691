define('scientia-resourcebooker/mixins/description-custom-fields', ['exports', 'ember'], function (exports, _ember) {

  var LOCALE_PREFIX = 'components.booking_request_form.description_custom_fields';

  exports['default'] = _ember['default'].Mixin.create({

    currentUser: _ember['default'].inject.service(),

    classNames: ['bookingRequestForm-fields--separator'],
    isValid: true,
    errorMessage: '',

    optionsDidChange: (function () {
      var _this = this;

      var notes = ' *Notes*';
      var output = '';
      var fields = this.get('fields');

      fields.forEach(function (field) {

        if (field.get('valueType') === 'separator') return;

        // Get original field key/Value and translated label string
        var fieldKey = field.label;
        var fieldValue = field.get('value') ? field.get('value').toString() : field.get('value');
        var fieldLabel = _this.get('i18n').t(LOCALE_PREFIX + '.' + field.label).toString();

        if (fieldKey === 'published_start_time' || fieldKey === 'published_end_time') {
          fieldValue = _this._formatPublishDate(fieldValue);
          output += '*' + fieldLabel + '*' + fieldValue + '; ';
        } else {
          if (fieldValue) {
            if (field.get('explanation')) fieldValue += ' - ' + field.get('explanation');
          }
          notes += fieldLabel.toString() + ' ' + _this._capitalizeString(fieldValue) + '. ';
        }
      });

      var currentUser = this.get('currentUser.user');
      var username = currentUser.name;
      var nameIdentifier = currentUser.nameIdentifier;
      var description = this.get('description') || '';

      // If is baseBooking change user,email and id
      var isBaseBooking = 'No';
      if (this.get('isBaseBooking')) {
        isBaseBooking = 'Yes';
        nameIdentifier = this.get('bookAsSomeoneElseOwner');
        username = this.get('bookAsSomeoneElseName');
      }

      output += '*LSE id*' + nameIdentifier + '; ';
      output += '*User name*' + username + '; ';
      output += '*Description*' + description + ';';
      output += '*Booked As Someone Else*' + isBaseBooking + ';';
      output += notes;
      this.set('value', output);
      this.sendAction('onValueChange', output);
    }).observes('fields.@each.value', 'fields.@each.explanation', 'description', 'bookAsSomeoneElseOwner', 'bookAsSomeoneElseName', 'isBaseBooking').on('init'),

    // return "<blank><blank:<blank><blank>" if no date
    _formatPublishDate: function _formatPublishDate(date) {
      return date ? date : '  :  ';
    },

    _capitalizeString: function _capitalizeString(text) {
      if (!text) return '';
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

  });
});