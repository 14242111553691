define('scientia-resourcebooker/pods/components/manage-resources/add-unavailability-date-slot/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/modal-dialog'], function (exports, _ember, _scientiaResourcebookerMixinsModalDialog) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], {
    specificStartDate: null,
    specificEndDate: null,
    userGroup: null,
    isSaving: false,

    // We need to use this 2 intermiedary properties as mutating the original properties closes the datepicker
    specificStartDateValue: null,
    specificEndDateValue: null,

    formattedDate: (function () {
      if (this.get('specificStartDate') === null) return;
      return this.get('specificStartDate').format(this.get('i18n').t('components.add_unavailability_slot.date_format').toString());
    }).property('specificStartDate'),

    actions: {
      submit: function submit() {
        this.set('isSaving', true);
        var start = this.get('specificStartDateValue') || this.get('specificStartDate');
        var end = this.get('specificEndDateValue') || this.get('specificEndDate');

        if (end.isBefore(start) || end.isSame(start)) {
          this.set('error', this.get('i18n').t('components.add_unavailability_slot.end_cannot_be_before'));
          this.set('isSaving', false);
          return;
        }

        this.attrs.saveUnavailabilityDateSlot({
          specificStartDate: this.get('specificStartDateValue') || this.get('specificStartDate'),
          specificEndDate: this.get('specificEndDateValue') || this.get('specificEndDate'),
          userGroup: this.get('userGroup')
        });
      },

      updateStartDate: function updateStartDate(value) {
        this.set('specificStartDateValue', value);
      },

      updateEndDate: function updateEndDate(value) {
        this.set('specificEndDateValue', value);
      }
    }
  });
});