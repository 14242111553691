define('scientia-resourcebooker/serializers/book-permission-group', ['exports', 'ember-data/serializers/json'], function (exports, _emberDataSerializersJson) {
  exports['default'] = _emberDataSerializersJson['default'].extend({

    normalize: function normalize(type, payload, prop) {

      var newPayload = this.store.serializerFor('application')._camelizeKeys(payload);
      var results = this._super(type, newPayload, prop);
      return results;
    }
  });
});
// Serializers for fragments work just as with models