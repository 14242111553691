define('scientia-resourcebooker/pods/manage-resources/user-group/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {

  var TABS = { DESCRIPTION: 0, CLAIMS: 1, PERMISSIONS: 2 };

  exports['default'] = _ember['default'].Controller.extend({
    queryParams: {
      selectedTab: 'section'
    },

    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),
    name: '',

    selectedTab: 0,
    isSaving: false,
    error: '',
    permissionsTypes: [{ value: 'edit-claims' }],

    isResourceOwnerGroup: _ember['default'].computed('model.userGroup.id', function () {
      return this.get('model.userGroup.id') === _scientiaResourcebookerConfigEnvironment['default'].IDS.USER_GROUP_RESOURCE_OWNER;
    }),

    permissions: _ember['default'].computed('model.userGroup.editClaimsPermissionGroups', function () {
      var model = this.get('model.userGroup');
      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(model);
      return fragments;
    }),

    actions: {
      save: function save() {
        var transitionIndex = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var model = this.get('model.userGroup');

        this.set('error', null);

        if (_ember['default'].isEmpty(model.get('name'))) {
          return this._displayError(this.get('i18n').t('error_messages.usergroup_noname').toString(), TABS.DESCRIPTION);
        }

        // Validate claim
        var emptyClaim = model.get('claims').find(function (claim) {
          return _ember['default'].isEmpty(claim.get('claimValue'));
        });
        if (emptyClaim) {
          var errorMessage = this.get('i18n').t('pods.manage_resources.user_group.index.empty_claim_error');
          return this._displayError(errorMessage, TABS.CLAIMS);
        }

        // Validate permissions
        var permissions = this.get('permissions');
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, model.get('permissionsKeys'));
        Object.keys(permissionFragments).forEach(function (type) {
          model.get(type).setObjects(permissionFragments[type]);
        });

        this.send('updateUserGroup', model, transitionIndex);
      }
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        return this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), TABS.PERMISSIONS);
      }

      return true;
    },

    _displayError: function _displayError(error, tabIndex) {
      this.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      return false;
    },

    breadCrumbs: _ember['default'].computed('i18n.locale', 'model.userGroup.name', function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.user_group.index.user_groups'),
        path: 'manage-resources.user-group.index'
      }, {
        label: this.get('model.userGroup.name'),
        linkable: false
      }];
    })
  });
});