define('scientia-resourcebooker/pods/manage-resources/booking-type/edit/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/availability-form'], function (exports, _ember, _scientiaResourcebookerMixinsAvailabilityForm) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsAvailabilityForm['default'], {
    queryParams: {
      selectedTab: 'section'
    },

    nestedResourceStore: _ember['default'].inject.service(),
    permissionsProcessor: _ember['default'].inject.service(),
    permissionDeprecatedChecker: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    can: _ember['default'].inject.service(),
    // resourceTypeSearcher: Ember.inject.service(),

    type: 'bookingType',
    typeParentId: null,
    selectedTab: 0,

    enableBobo: _ember['default'].computed.bool('systemSettings.options.enableBobo'),
    enableBase: _ember['default'].computed.bool('systemSettings.options.enableBase'),

    name: '',

    isSaving: false,
    error: '',
    showIconSelector: false,

    showDeleteRestrictionWarning: false,
    resourceRestrictionToDeleteDeferred: null,

    canEditAll: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-all booking-type', { model: this.get('model.bookingType') });
    }),
    canEditDescription: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-description booking-type', { model: this.get('model.bookingType') });
    }),
    canEditPermissions: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-permissions booking-type', { model: this.get('model.bookingType') });
    }),
    canEditAvailability: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-availability booking-type', { model: this.get('model.bookingType') });
    }),
    canEditStatus: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-status booking-type', { model: this.get('model.bookingType') });
    }),
    canEditResourceRestrictions: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-resource-restrictions booking-type', { model: this.get('model.bookingType') });
    }),
    canEditRules: _ember['default'].computed('model.bookingType', 'model.bookingType.id', function () {
      return this.get('can').can('edit-rules booking-type', { model: this.get('model.bookingType') });
    }),

    permissionsTypes: _ember['default'].computed('canEditAll', 'canEditPermissions', function () {
      var _this = this;

      var canEditPermissions = this.get('canEditPermissions');
      var canEditAll = this.get('canEditAll');

      // Add initial permissions with base/bobo if enabled
      var initialPermissions = ['read', 'book', 'request', 'recurring', 'separator'];
      var editPermissions = ['edit-all', 'edit-status', 'edit-availability', 'edit-resource-restrictions', 'edit-permissions', 'edit-rules'];

      if (this.get('enableBase')) initialPermissions.push('base');
      if (this.get('enableBobo')) initialPermissions.push('bobo');
      // if (this.get('enableBase') || this.get('enableBobo')) initialPermissions.push('separator');

      var toObject = function toObject(value, disabled) {
        var deprecated = _this.get('permissionDeprecatedChecker').isDeprecated({ type: 'booking-type', name: value });
        return { value: value, disabled: disabled, deprecated: deprecated };
      };

      var permissionTypes = [];

      // Map them from string to a value object
      initialPermissions = initialPermissions.map(function (p) {
        return toObject(p, !canEditPermissions);
      });
      editPermissions = editPermissions.map(function (p) {
        return toObject(p, !canEditAll);
      });

      // Shove editPermissions into previous array and return them all
      permissionTypes.push.apply(permissionTypes, _toConsumableArray(initialPermissions).concat(_toConsumableArray(editPermissions)));

      return permissionTypes;
    }),

    permissions: (function () {
      var bookingType = this.get('model.bookingType');

      var fragments = this.get('permissionsProcessor').modelPermissionGroupsToGenericPermissionGroup(bookingType);
      return fragments;
    }).property('model.bookingType.readPermissionGroups', 'model.bookingType.bookPermissionGroups', 'model.bookingType.basePermissionGroups', 'model.bookingType.boboPermissionGroups', 'model.bookingType.recurringPermisionGroups', 'model.bookingType.requestPermisionGroups', 'model.bookingType.editAllPermissionGroups', 'model.bookingType.editAvailabilityPermissionGroups', 'model.bookingType.editStatusPermissionGroups', 'model.bookingType.editRulesPermisionGroups', 'model.bookingType.editResourceRestrictionsPermisionGroups', 'model.bookingType.editPermissionsPermisionGroups'),

    actions: {
      fetchBusyTimes: function fetchBusyTimes(startDate, endDate) {
        this.set('startDate', startDate);
        this.set('endDate', endDate);

        return true;
      },

      save: function save(transitionIndex) {
        this.send('updateBookingType', transitionIndex);
      },

      searchResourceType: function searchResourceType(query, deferred) {
        // this.get('resourceTypeSearcher').search(query).then(response => {
        //   deferred.resolve(response);
        // });

        var data = { pageNumber: query.page || 1, query: query.term, itemsPerPage: 30 };

        this.get('store').query('resource-type', data).then(function (response) {
          var meta = { currentPage: response.meta.currentPage, totalPages: response.meta.totalPages };
          var hasMoreResults = meta.currentPage < meta.totalPages;
          var responseData = { data: response, more: hasMoreResults };

          deferred.resolve(responseData);
        });
      },

      searchResourceGroup: function searchResourceGroup(rule, query, deferred) {
        this.get('nestedResourceStore').fetch({
          type: 'resourceType.resourceGroup',
          parentId: rule.get('resourceType.id'),
          data: { query: query.term, pageNumber: query.page }
        }).then(function (response) {

          var meta = { currentPage: response.meta.currentPage, totalPages: response.meta.totalPages };
          var hasMoreResults = meta.currentPage < meta.totalPages;
          // delete response.meta;
          var responseData = { data: response, more: hasMoreResults };

          responseData.data = responseData.data.map(function (r) {
            return { id: r.id, name: r.get('name') };
          });
          deferred.resolve(responseData);
        });
      },

      searchBookingForm: function searchBookingForm(term, deferred) {
        this.get('store').query('booking-form', { query: term }).then(function (results) {
          results = results.map(function (r) {
            r.identity = r.id;
            return r;
          });
          deferred.resolve(results);
        });
      },

      onDeleteResourceRestriction: function onDeleteResourceRestriction(deferred) {
        this.set('showDeleteRestrictionWarning', true);
        this.set('resourceRestrictionToDeleteDeferred', deferred);
      },

      onDeleteResourceRestrictionConfirm: function onDeleteResourceRestrictionConfirm() {
        this.get('resourceRestrictionToDeleteDeferred').resolve();
        this.set('resourceRestrictionToDeleteDeferred', null);
        this.set('showDeleteRestrictionWarning', false);
      },

      setIcon: function setIcon(icon) {
        this.set('showIconSelector', false);
        this.set('model.bookingType.imageUri', icon.url);
      }
    },

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.manage_resources.booking_type.index.booking_types'),
        path: 'manage-resources.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        linkable: false
      }];
    }).property('model.bookingType.name', 'i18n.locale')
  });
});