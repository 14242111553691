define('scientia-resourcebooker/pods/components/manage-resources/add-unavailability-weekday-slot/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    weekdayIndex: null,
    weekdayHour: null,
    userGroup: null,
    isSaving: false,

    startTimeInMinutes: null,
    endTimeInMinutes: null,

    formattedDate: (function () {
      if (this.get('weekdayIndex') === null) return;
      return moment().day(this.get('weekdayIndex')).format('dddd');
    }).property('weekdayIndex'),

    fromHours: (function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      // hours.push({id: 0, value: '', label: '—'});

      var startIndex = 0;
      var endIndex = 23;

      var minutes = 0;
      for (var hourIndex = startIndex; hourIndex <= endIndex; hourIndex++) {
        for (var minutesIndex = 0; minutesIndex < 60; minutesIndex += 15) {
          var time = startTime.clone().add(minutes, 'minutes');
          hours.push({ id: minutes, value: minutes, label: time.format(this.get('i18n').t('components.time_picker.hour_format').toString()) });
          minutes += 15;
        }
      }

      return hours;
    }).property(),

    toHours: (function () {
      var startTime = moment('2015-01-01').startOf('day');
      var hours = [];

      // hours.push({id: 0, value: '', label: '—'});

      var startIndex = 1;
      var endIndex = 24;

      var minutes = 0;
      for (var hourIndex = startIndex; hourIndex <= endIndex; hourIndex++) {
        for (var minutesIndex = 0; minutesIndex < 60; minutesIndex += 15) {
          var time = startTime.clone().add(minutes, 'minutes');
          hours.push({ id: minutes, value: minutes, label: time.format(this.get('i18n').t('components.time_picker.hour_format').toString()) });
          minutes += 15;
        }
      }
      var midnight = startTime.clone().add(1440, 'minute');
      hours.shift();
      hours.push({ id: 1440, value: 1440, label: midnight.format(this.get('i18n').t('components.time_picker.hour_format').toString()) });

      return hours;
    }).property(),

    actions: {
      submit: function submit() {
        this.set('isSaving', true);

        var startTime = moment().startOf('day').add(this.get('startTimeInMinutes'), 'minutes');
        var duration = this.get('endTimeInMinutes') - this.get('startTimeInMinutes');
        if (duration <= 0) {
          this.set('error', this.get('i18n').t('components.add_unavailability_slot.end_cannot_be_before'));
          this.set('isSaving', false);
          return;
        }

        this.attrs.saveUnavailabilityWeekdaySlot({
          weekdayIndex: this.get('weekdayIndex'),
          specificDate: startTime,
          duration: duration,
          userGroup: this.get('userGroup')
        });
      }
    }
  });
});