define('scientia-resourcebooker/services/resource-permission-summary-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var MAPPING = {
    admin: {
      'templatePermissions': ['editPermissionsGroups'],
      'resourceTypePermissions': ['readPermissionGroups', 'writePermissionGroups', 'editPermissionsGroups', 'manageGroupsPermissionGroups', 'manageResourcesPermissionGroups'],
      'resourceGroupPermissions': ['showPermissionGroups', 'readPermissionGroups', 'writePermissionGroups', 'editPermissionsGroups', 'editNestedPermissionsGroups', 'viewCalendarsPermissionGroups', 'manageBookingsPermissionGroups'],
      'resourcePermissions': ['readPermissionGroups', 'writePermissionGroups', 'approvePermissionGroups', 'viewCalendarsPermissionGroups', 'manageBookingsPermissionGroups', 'editPermissionsGroups', 'resourceOwners']
    },

    book: {
      'templatePermissions': ['readPermissionGroups', 'bookPermissionGroups', 'requestPermissionGroups', 'recurringPermissionGroups', 'basePermissionGroups', 'boboPermissionGroups'],
      'resourceTypePermissions': [],
      'resourceGroupPermissions': ['readPermissionGroups', 'showPermissionGroups', 'requestPermissionGroups', 'bookPermissionGroups'],
      'resourcePermissions': ['readPermissionGroups', 'bookPermissionGroups', 'requestPermissionGroups', 'resourceOwners']
    }
  };

  // These are the 4 keys that we receive from the API
  var PERMISSION_TYPES = ['templatePermissions', 'resourceTypePermissions', 'resourceGroupPermissions', 'resourcePermissions'];

  // These are the related translation keys for them
  var PERMISSION_TYPES_LOCALE = ['templates', 'resource_type', 'resource_groups', 'resource'];

  var EDIT_PERMISSIONS_NAMES = ['editAllPermissionGroups', 'editStatusPermissionGroups', 'editAvailabilityPermissionGroups', 'editResourceRestrictionsPermissionGroups', 'editPermissionsPermissionGroups', 'editRulesPermissionGroups', 'editDescriptionPermissionGroups', 'editCustomFieldsPermissionGroups', 'editResourcesPermissionGroups'];

  var EDIT_NESTED_PERMISSION_NAMES = ['resourcesEditAllPermissionGroups', 'resourcesEditStatusPermissionGroups', 'resourcesEditAvailabilityPermissionGroups', 'resourcesEditResourceRestrictionPermissionGroups', 'resourcesEditPermissionsPermissionGroups', 'resourcesEditRulesPermissionGroups', 'resourcesEditDescriptionPermissionGroups', 'resourcesEditCustomFieldsPermissionGroups', 'resourcesEditResourcesPermissionGroups'];

  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetch: function fetch(resourceTypeId, resourceId) {
      var _this = this;

      this.get('appInsights').trackEvent('resource-summary', { resourceTypeId: resourceTypeId, resourceId: resourceId });
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_PERMISSION_SUMMARY.loc(resourceTypeId, resourceId)).then(function (results) {
        return _this._normalizeResponse(results);
      });
    },

    _normalizeResponse: function _normalizeResponse(response) {
      response.resourcePermissions = _ember['default'].isEmpty(response.resourcePermissions) ? [] : [response.resourcePermissions];
      response.resourceTypePermissions = _ember['default'].isEmpty(response.resourceTypePermissions) ? [] : [response.resourceTypePermissions];
      response.resourceGroupPermissions = _ember['default'].isEmpty(response.resourceGroupPermissions) ? [] : response.resourceGroupPermissions;
      response.templatePermissions = _ember['default'].isEmpty(response.templatePermissions) ? [] : response.templatePermissions;

      try {
        var normalizedData = this._normalizeFromMapping(response);
        return normalizedData;
      } catch (error) {
        LE.error('Unable to normalize permissions summary', error);
        return { admin: [], book: [] };
      }
    },

    _normalizeFromMapping: function _normalizeFromMapping(data) {
      var _this2 = this;

      var response = { admin: [], book: [] };

      Object.keys(response).forEach(function (tab) {

        PERMISSION_TYPES.forEach(function (rootPermissionType, i) {
          if (tab === 'book' && rootPermissionType === 'resourceTypePermissions') return;

          response[tab][rootPermissionType] = { name: PERMISSION_TYPES_LOCALE[i], values: [] };

          data[rootPermissionType].forEach(function (resourceObject) {
            MAPPING[tab][rootPermissionType].forEach(function (permissionKey) {
              if (permissionKey in resourceObject) {
                if (!_ember['default'].isEmpty(resourceObject[permissionKey])) {
                  (function () {
                    if (_ember['default'].isEmpty(response[tab][rootPermissionType].values.findBy('name', resourceObject.name))) {
                      response[tab][rootPermissionType].values.push({ name: resourceObject.name, permissions: [] });
                    }
                    var lastIndex = response[tab][rootPermissionType].values.length - 1;
                    var permissionData = resourceObject[permissionKey];

                    // If is a nestedPermission
                    if (permissionKey === 'editPermissionsGroups' || permissionKey === 'editNestedPermissionsGroups') {
                      var permissionsArray = _this2._nestedPermissionDataNormalize(permissionData, permissionKey);
                      permissionsArray.forEach(function (permissionGroup) {
                        var newPermission = { name: _this2._getPermissionNameByPermissionKey(permissionGroup.name), permissions: permissionGroup.permissions };

                        // if permission already exists, push this one into the existing array
                        var existingPermission = response[tab][rootPermissionType].values[lastIndex].permissions.findBy('name', newPermission.name);
                        if (!_ember['default'].isEmpty(existingPermission)) {
                          var _existingPermission$permissions;

                          (_existingPermission$permissions = existingPermission.permissions).push.apply(_existingPermission$permissions, _toConsumableArray(newPermission.permissions));

                          // If not just push new one
                        } else {
                            response[tab][rootPermissionType].values[lastIndex].permissions.push(newPermission);
                          }
                      });
                    } else {
                      var newPermission = { name: _this2._getPermissionNameByPermissionKey(permissionKey, rootPermissionType), permissions: _this2._normalizePermissionGroup(resourceObject[permissionKey], permissionKey, rootPermissionType) };
                      response[tab][rootPermissionType].values[lastIndex].permissions.push(newPermission);
                    }
                  })();
                }
              }
            });
          });
        });
      });

      return response;
    },

    _nestedPermissionDataNormalize: function _nestedPermissionDataNormalize(permissionData, permissionKey) {
      return permissionData.map(function (p) {
        var permissionName = permissionKey === 'editNestedPermissionsGroups' ? EDIT_NESTED_PERMISSION_NAMES[p.editPermissionType] : EDIT_PERMISSIONS_NAMES[p.editPermissionType];
        return { name: permissionName, permissionName: permissionName, permissions: [{ id: p.id, name: p.name }] };
      }, []);
    },

    _normalizePermissionGroup: function _normalizePermissionGroup(data, permissionKey, permissionType) {
      var _this3 = this;

      if (!data) return null;

      if (permissionKey === 'requestPermissionGroups') {
        return data.map(function (p) {
          return {
            permissionName: _this3._getPermissionNameByPermissionKey(permissionKey),
            requester: { id: p.permissionTarget.id, name: p.permissionTarget.name },
            approvers: p.approvers
          };
        });
      } else {
        return data.map(function (p) {
          return { id: p.id, name: p.name, permissionName: _this3._getPermissionNameByPermissionKey(permissionKey, permissionType) };
        });
      }
    },

    // These allow us to get the translation string for the permissionKey and map any exceptional property names that not adhere to our convention
    // such as resourceOwners->Approve, etc..
    _getPermissionNameByPermissionKey: function _getPermissionNameByPermissionKey(permissionKey, type) {
      var k = undefined;

      if (permissionKey === 'editPermissionsPermissionGroups') {
        k = 'edit_permissions';
      } else if (permissionKey === 'resourcesEditPermissionsPermissionGroups') {
        k = 'resources_edit_permissions';
      } else if (permissionKey === 'manageBookingsPermissionGroups' && type === 'resourceGroupPermissions') {
        k = 'resources_manage_bookings';
      } else if (permissionKey === 'viewCalendarsPermissionGroups' && type === 'resourcePermissions') {
        k = 'view_calendar';
      } else if (permissionKey === 'resourceOwners') {
        k = 'approve';
      } else {
        k = permissionKey.split('Permission')[0].underscore();
      }

      if (type === 'templatePermissions' && k === 'read') k = 'show';

      return k;
    }
  });
});