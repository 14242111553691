define('scientia-resourcebooker/services/error-handler', ['exports', 'ember'], function (exports, _ember) {

  var WHITESPACE = '\\s*';
  var IDENTIFIER = '[a-z0-9_\\$]+';
  var SWITCHES_REGEXP = '' + '\\{' + WHITESPACE + '(' + IDENTIFIER + ')' + WHITESPACE + ',' + WHITESPACE + '(select|plural)' + WHITESPACE + ',' + WHITESPACE + '((?:\\w+' + WHITESPACE + '\\{[^\\}]+\\}' + WHITESPACE + ')+)' + '\\}';
  var INTERPOLATION_REGEXP = '\\{' + WHITESPACE + '(' + IDENTIFIER + ')' + WHITESPACE + '\\}';
  var TOKEN_WITH_FORMAT_REGEXP = '\\{\\s*([a-z0-9_\\$]+)\\s*:([^\\}]+)\\s*\\}';
  var TOKEN_REGEXP = new RegExp('(?:' + SWITCHES_REGEXP + ')|(?:' + INTERPOLATION_REGEXP + ')|(?:' + TOKEN_WITH_FORMAT_REGEXP + ')', 'gi');

  exports['default'] = _ember['default'].Service.extend({
    dateTimeFormatter: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    buildParams: function buildParams(exceptionMessageKey, exceptionMessageParams, forceUTCDateFormatting) {
      var jsonLocaleParams = {};
      for (var prop in exceptionMessageParams) {
        var val = exceptionMessageParams[prop];
        if (this.get('dateTimeFormatter').isDate(val)) {
          jsonLocaleParams[prop] = this.get('dateTimeFormatter').format(exceptionMessageKey, prop, val, forceUTCDateFormatting);
        } else {
          jsonLocaleParams[prop] = val;
        }
      }
      return jsonLocaleParams;
    },
    buildErrorMessage: function buildErrorMessage(message) {
      var localePath = arguments.length <= 1 || arguments[1] === undefined ? 'error_messages' : arguments[1];
      var forceUTCDateFormatting = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var exceptionMessageKey = message.ExceptionMessageKey;
      var fallbackMessage = message.ExceptionMessage;
      if (exceptionMessageKey) {

        var exceptionMessage = message.ExceptionMessage;
        var exceptionMessageParams = message.ExceptionMessageParams;
        var fullLocalePath = localePath + '.' + exceptionMessageKey;
        var fullGenericErrorPath = 'error_messages.generic_error';

        var jsonLocaleParams = this.buildParams(fullLocalePath, exceptionMessageParams, forceUTCDateFormatting);

        if (jsonLocaleParams.__customMessage) {
          var localisedMessage = this.tp(jsonLocaleParams.__customMessage, jsonLocaleParams, exceptionMessageParams);
          if (localisedMessage != null) {
            return localisedMessage;
          }
        } else {
          var localisedMessage = this.get('i18n').t(fullLocalePath, jsonLocaleParams);
          if (localisedMessage != null) {
            return localisedMessage;
          }
        }

        if (exceptionMessage) {
          return exceptionMessage;
        }

        // no specific message has been passed back - all we can do use a generic error message
        var genericErrorMessage = this.get('i18n').t(fullGenericErrorPath, jsonLocaleParams);

        if (genericErrorMessage != null) {
          return genericErrorMessage;
        }

        // no generic error message - return hard-coded error text
        return this.get('i18n').t('general.generic_error');
      }
      if (fallbackMessage) return fallbackMessage;

      return message;
    },

    tp: function tp(string, params, jsonParams) {

      var result;
      var tokens = [];
      while ((result = TOKEN_REGEXP.exec(string)) !== null) {
        var token = {
          toReplace: result[0],
          key: ''
        };

        if (result[6]) {
          token.key = result[5];
          token.format = result[6];
        } else {
          token.key = result[4];
        }
        tokens.push(token);
      }

      for (var index = 0, length = tokens.length; index < length; index++) {
        var value = undefined;
        var _token = tokens[index];
        var format = _token.format;
        var dataValue = format ? moment(jsonParams[_token.key]).format(format) : null;
        value = dataValue || params[_token.key];
        string = string.replace(_token.toReplace, value);
      }

      return string;
    }
  });
});