define('scientia-resourcebooker/pods/manage-resources/resource-group/resources/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      page: { refreshModel: true },
      perPage: { refreshModel: true }
    },

    titleToken: function titleToken(model) {
      return model.resourceType.get('name') + ' — ' + this.get('i18n').t('pods.manage_resources.resource_type.show.resource_types');
    },

    nestedResourceStore: _ember['default'].inject.service(),

    model: function model(params, transition) {
      var resourceTypeId = transition.params['manage-resources.resource-group'].resourceTypeId;

      return _ember['default'].RSVP.hash({
        resourceType: this.store.queryRecord('resource-type', { id: resourceTypeId, adminPermissionsOnly: true }),
        resourceGroup: this.get('nestedResourceStore').fetch({ type: 'resourceType.resourceGroup', parentId: resourceTypeId, id: params.id, data: { adminPermissionsOnly: true } }),
        resources: this.get('nestedResourceStore').fetch({ type: 'resourceGroup.resource', parentId: resourceTypeId, id: params.id, suffix: 'Resources', data: { pageNumber: params.page, itemsPerPage: params.perPage || 10, adminPermissionsOnly: true } })
      });
    },

    resetController: function resetController(controller, isExiting) {

      if (isExiting) {
        controller.setProperties({
          page: 1,
          perPage: 10
        });
      }
    },

    actions: {
      reloadResources: function reloadResources() {
        this.refresh();
        // let model = this.modelFor('manage-resources.resource-group.resources');

        // this.get('nestedResourceStore').fetch({
        //   type: 'resourceGroup.resource',
        //   parentId: model.resourceType.get('id'),
        //   id: model.resourceGroup.get('id'),
        //   suffix: 'Resources'
        // }).then((resources) => {
        //   model.resources.clear();
        //   model.resources.pushObjects(resources);
        // });
      }
    }
  });
});