define('scientia-resourcebooker/pods/components/manage-resources/rules-form/types/numberarray/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['manageResources-itemForm-numberArray'],
    value: [],

    computedValue: _ember['default'].computed('value', 'value.[]', function () {
      var val = this.get('value');
      if (!val) return '00:00';

      var hours = val[0].toString();
      if (hours.length === 1) hours = '0' + hours;

      var minutes = val[1].toString();
      if (minutes.length === 1) minutes = '0' + minutes;
      return hours + ':' + minutes;
    }),

    availableMinutes: _ember['default'].computed(function () {
      var range = 59;
      var minutes = [];
      for (var i = 0; i <= range; i++) {
        var val = i <= 9 ? '0' + i : i.toString();
        minutes.push(_ember['default'].Object.create({ value: val, label: val }));
      }

      return minutes;
    }),

    actions: {

      setValue: function setValue(value) {
        // const val = this.get('value') || [];
        var split = value.split(':');
        var newValue = [split[0], split[1]];
        this.set('value', newValue);
      },
      updateValue: function updateValue(index, event) {
        var eventValue = parseInt(event.currentTarget.value, 10);
        var valueArray = this.get('value') || [];
        valueArray[index] = eventValue;
        this.set('value', valueArray);
      }
    }

  });
});