define('scientia-resourcebooker/serializers/request-permission-group', ['exports', 'ember-data/serializers/json'], function (exports, _emberDataSerializersJson) {
  exports['default'] = _emberDataSerializersJson['default'].extend({

    normalize: function normalize(type, payload, prop) {

      var newPayload = this.store.serializerFor('application')._camelizeKeysDeep(payload);

      if (newPayload.approvers) {
        newPayload.approvers.forEach(function (a) {
          a.id = a.identity || a.id;
        });
      }

      if (newPayload.permissionTarget) {
        newPayload.permissionTarget.id = newPayload.permissionTarget.identity;
      }

      var results = this._super(type, newPayload, prop);
      return results;
    }
  });
});
// Serializers for fragments work just as with models