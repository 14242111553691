define('scientia-resourcebooker/services/system-settings', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    localeProcessor: _ember['default'].inject.service(),

    options: null,

    defaults: {
      resourcesListInitialLoadSize: 10,
      resourcesListAdditionalLoadSize: 10,
      bookingHistoryAdminOnly: false,
      'detailedUnavailabilityBookingDisplay': '',
      attachmentFileTypes: {
        Image: 'jpg, gif',
        Word: 'doc, docx',
        Excel: 'xlx, xlsx',
        Pdf: 'pdf',
        Text: 'txt',
        Archive: 'zip, rar'
      },
      maxFileSizeKB: 2048,
      weekendDays: [],
      calendarShowWeekends: false,
      calendarFirstDayOfTheWeekOffset: 0,
      calendarStartTimeOffset: 0,
      calendarEndTimeOffset: 0,
      calendarAgendaViewShowDuration: false,
      calendarDefaultViewType: 'week',
      calendarSmallViewType: 'month',
      helpPageUrl: null,
      contactPageUrl: null,
      enableBase: false,
      enableBobo: false,
      userProfileDisplayMode: 3,
      singleLogoutMode: 0,
      notificationsCountRefreshMinutes: 5,
      pendingNotificationsDisplayMode: 3,
      disableBookingSummaryPrint: false,
      enableDescriptionFormField: false,
      hideDashboard: false,
      resourceFilterAppInsightsLogLevel: 2,
      disableHelpNonAdmin: false,
      disableHelpAdmin: false
    },

    getSetting: function getSetting(key) {
      if (this.get('options') && key in this.get('options') && this.get('options.' + key) !== null) {
        return this.get('options.' + key);
      } else {

        // Only log warning if default is not null or false
        if (this.get('defaults.' + key) !== null && this.get('defaults.' + key) !== false) {
          LE.warn('InstitutionSettings key with name "' + key + '" not found. Using the default value of "' + this.defaults[key] + '". This might cause unexpected behaviour. To suppress this warning please set a value for "' + key + '" in InstitutionSettings.');
        }

        // Return default value
        return this.defaults[key];
      }
    },

    fetch: function fetch() {
      var _this = this;

      var forceReload = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      if (this.get('options') && !forceReload) return this.get('options');
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.INSTITUTION_SETTINGS).then(function (options) {
        _this.set('options', options);

        moment.tz.setDefault(options.timeZone);
        if (!moment.tz.zone(options.timeZone)) {
          LE.error('Unable to set timeZone to "' + options.timeZone + '" This might cause unexpected behaviour. Please check azure storage');
        }

        _this.get('localeProcessor').initLocale();

        return options;
      });
    },

    reload: function reload() {
      return this.fetch(true);
    },

    update: function update(options) {
      var _this2 = this;

      return this.get('authenticatedRequest').put(_scientiaResourcebookerConfigEnvironment['default'].API.INSTITUTION_SETTINGS, options).then(function (newOptions) {
        _this2.set('options', newOptions);
        return _ember['default'].RSVP.resolve(newOptions);
      });
    },

    isOpen: (function () {
      return this.get('options.systemMode') === 'Open';
    }).property('options.systemMode'),

    isAdminOnly: (function () {
      return this.get('options.systemMode') === 'AdminOnly';
    }).property('options.systemMode'),

    isClosed: (function () {
      return this.get('options.systemMode') === 'Closed';
    }).property('options.systemMode')
  });
});