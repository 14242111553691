define('scientia-resourcebooker/pods/components/manage-resources/item-types-list/component', ['exports', 'ember', 'ember-can'], function (exports, _ember, _emberCan) {
  exports['default'] = _ember['default'].Component.extend(_emberCan.CanMixin, {
    tagName: 'ul',
    classNames: ['manageResourcesItemsList'],

    iconUri: 'icons/tag.svg',
    items: [],
    showItemPath: '',
    editItemPath: '',
    hideShowAction: false,
    hideDeleteAction: false,
    hideEditAction: false,
    ballPrefixPath: 'mode',
    editAbilityString: (function () {
      return 'edit ' + this.get('type');
    }).property(),

    deleteAbilityString: (function () {
      return 'delete ' + this.get('type');
    }).property()
  });
});