define("scientia-resourcebooker/pods/manage-resources/booking-type/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["manage-resources/icon-selector"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [7, 25], [7, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 20], [7, 42]]], 0, 0], false], [], ["loc", [null, [7, 12], [7, 49]]], 0, 0], "submit", ["subexpr", "action", ["setIcon"], [], ["loc", [null, [8, 13], [8, 31]]], 0, 0]], ["loc", [null, [5, 9], [8, 32]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [9, 32], [9, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 27], [9, 49]]], 0, 0], false], [], ["loc", [null, [9, 19], [9, 56]]], 0, 0]], ["loc", [null, [4, 7], [9, 57]]], 0, 0]], ["loc", [null, [2, 0], [10, 2]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 10
                },
                "end": {
                  "line": 23,
                  "column": 140
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/document.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [23, 21], [23, 82]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.new.description"], [], ["loc", [null, [23, 82], [23, 140]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 11
                },
                "end": {
                  "line": 26,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/power.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [25, 12], [25, 70]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.edit.status"], [], ["loc", [null, [25, 70], [25, 124]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 10
                },
                "end": {
                  "line": 29,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/calendar.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [28, 12], [28, 73]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.edit.availability"], [], ["loc", [null, [28, 73], [28, 133]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 10
                },
                "end": {
                  "line": 32,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/cog.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [31, 12], [31, 68]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.edit.resource_restrictions"], [], ["loc", [null, [31, 68], [31, 137]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 10
                },
                "end": {
                  "line": 34,
                  "column": 151
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/users.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [34, 35], [34, 93]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.new.permissions"], [], ["loc", [null, [34, 93], [34, 151]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 10
                },
                "end": {
                  "line": 37,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["icons/papers.svg"], ["class", "dc-tabs-item-icon"], ["loc", [null, [36, 12], [36, 71]]], 0, 0], ["inline", "t", ["pods.manage_resources.booking_type.edit.rules"], [], ["loc", [null, [36, 71], [36, 124]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 8
              },
              "end": {
                "line": 38,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "dc-tab", [], [], 0, null, ["loc", [null, [23, 10], [23, 151]]]], ["block", "dc-tab", [], ["disabled", true], 1, null, ["loc", [null, [24, 11], [26, 21]]]], ["block", "dc-tab", [], ["disabled", true], 2, null, ["loc", [null, [27, 10], [29, 21]]]], ["block", "dc-tab", [], ["disabled", true], 3, null, ["loc", [null, [30, 10], [32, 21]]]], ["block", "dc-tab", [], ["disabled", true], 4, null, ["loc", [null, [34, 10], [34, 162]]]], ["block", "dc-tab", [], ["disabled", true], 5, null, ["loc", [null, [35, 10], [37, 21]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 8
              },
              "end": {
                "line": 51,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "manage-resources/booking-type-form", [], ["localePrefix", "components.manage_resources.booking_type_new", "openIconSelector", ["subexpr", "action", [["subexpr", "mut", [["get", "showIconSelector", ["loc", [null, [43, 42], [43, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 37], [43, 59]]], 0, 0], true], [], ["loc", [null, [43, 29], [43, 65]]], 0, 0], "clearImageUri", ["subexpr", "action", [["subexpr", "mut", [["get", "clearImageUri", ["loc", [null, [44, 39], [44, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 34], [44, 53]]], 0, 0], ""], [], ["loc", [null, [44, 26], [44, 57]]], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [45, 17], [45, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "description", ["subexpr", "@mut", [["get", "description", ["loc", [null, [46, 24], [46, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "imageUri", ["subexpr", "@mut", [["get", "imageUri", ["loc", [null, [47, 21], [47, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "bookingForm", ["subexpr", "@mut", [["get", "bookingForm", ["loc", [null, [48, 24], [48, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "searchBookingForm", ["subexpr", "action", ["searchBookingForm"], [], ["loc", [null, [49, 30], [49, 58]]], 0, 0]], ["loc", [null, [41, 10], [50, 12]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 8
              },
              "end": {
                "line": 53,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 54,
                "column": 8
              },
              "end": {
                "line": 54,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 8
              },
              "end": {
                "line": 55,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 8
              },
              "end": {
                "line": 56,
                "column": 25
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 6
            },
            "end": {
              "line": 57,
              "column": 6
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "dc-tab-list", [], [], 0, null, ["loc", [null, [22, 8], [38, 24]]]], ["block", "dc-tab-panel", [], [], 1, null, ["loc", [null, [40, 8], [51, 25]]]], ["block", "dc-tab-panel", [], [], 2, null, ["loc", [null, [53, 8], [53, 42]]]], ["block", "dc-tab-panel", [], [], 3, null, ["loc", [null, [54, 8], [54, 42]]]], ["block", "dc-tab-panel", [], [], 4, null, ["loc", [null, [55, 8], [55, 42]]]], ["block", "dc-tab-panel", [], [], 5, null, ["loc", [null, [56, 8], [56, 42]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 8
            },
            "end": {
              "line": 62,
              "column": 131
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["pods.manage_resources.booking_type.new.save"], [], ["loc", [null, [62, 80], [62, 131]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/booking-type/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3, "class", "container-content-columns");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-note");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "manageResources-itemForm-form-actions");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(element2, 1, 1);
        morphs[5] = dom.createMorphAt(element2, 3, 3);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showIconSelector", ["loc", [null, [1, 6], [1, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [11, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [13, 39], [13, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 0], [13, 53]]], 0, 0], ["inline", "section-title", [], ["text", ["subexpr", "t", ["pods.manage_resources.booking_type.new.title"], [], ["loc", [null, [16, 25], [16, 75]]], 0, 0]], ["loc", [null, [16, 4], [16, 77]]], 0, 0], ["element", "action", ["save"], ["on", "submit"], ["loc", [null, [18, 10], [18, 39]]], 0, 0], ["inline", "error-box", [], ["error", ["subexpr", "@mut", [["get", "error", ["loc", [null, [19, 22], [19, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 4], [19, 29]]], 0, 0], ["block", "dc-tabs", [], ["class", "dc-tabs--fullWidth", "selected-index", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [21, 59], [21, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [21, 6], [57, 18]]]], ["inline", "t", ["pods.manage_resources.booking_type.new.disabled_tab_note"], [], ["loc", [null, [59, 52], [59, 116]]], 0, 0], ["block", "async-button", [], ["loading", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [62, 32], [62, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "submit", "class", "button--primary"], 2, null, ["loc", [null, [62, 8], [62, 148]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});