define('scientia-resourcebooker/pods/components/manage-resources/bulk-exporter/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['bulkExporter'],
    classNameBindings: ['location'],
    location: '',
    localePrefix: 'components.manage_resources.bulk_exporter.claims.',
    disableExportSelected: false,
    bulkExporter: _ember['default'].inject.service(),
    exportType: '',

    resourcesSelected: [],

    downloadAllURL: null,
    downloadSelectedURL: null,

    actions: {

      onExportAll: function onExportAll() {
        var _this = this;

        this.attrs.onExportAll().then(function (downloadURL) {
          _this.set('downloadAllURL', downloadURL);

          // Download link has an expiry time of 60sec so we need to remove the link after that
          _ember['default'].run.later(_this, function () {
            if (!this.get('isDestroyed')) this.set('downloadAllURL', null);
          }, 60000);
        });
      },

      onExportSelected: function onExportSelected() {
        var _this2 = this;

        this.attrs.onExportSelected().then(function (downloadURL) {
          _this2.set('downloadSelectedURL', downloadURL);
          // Download link has an expiry time of 60sec so we need to remove the link after that
          _ember['default'].run.later(_this2, function () {
            if (!this.get('isDestroyed')) this.set('downloadSelectedURL', null);
          }, 60000);
        });
      },

      onSearch: function onSearch(query, deferred) {
        _ember['default'].run.debounce(this, 'send', 'onSearchDebounced', query, deferred, 500);
      },

      onSearchDebounced: function onSearchDebounced(query, deferred) {
        this.attrs.onSearch(query, deferred);
      },

      onDownload: function onDownload(downloadURLProperty, url) {
        window.open(url);
        this.set(downloadURLProperty, null);
      },

      // onDownloadTemplate() {
      //   const url = this.get('bulkExporter').getDownloadURLForTemplate({type: this.get('exportType')});
      //   if (url) {
      //     window.open(url);
      //   }
      // },

      // onDownloadCustomFields(customFieldsType) {
      //   const url = this.get('bulkExporter').getDownloadURLForCustomFieldsTemplates(customFieldsType);
      //   if (url) {
      //     window.open(url);
      //   }
      // },

      didSelectResources: function didSelectResources(resources) {
        this.set('resourcesSelected', resources);
        this.attrs.onSelect(resources);
      },

      triggerListOpen: function triggerListOpen() {
        this.$().find('.select2-choices').trigger('click');
      }
    }

  });
});