define('scientia-resourcebooker/pods/components/recurring-event-fields/component', ['exports', 'ember'], function (exports, _ember) {
  var DAILY = 0;
  var WEEKLY = 1;
  var MONTHLY = 2;
  // const YEARLY = 3;

  var UNTIL_NR_OCCURRENCES = 0;
  var UNTIL_DATE = 1;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['recurringEventFields'],
    classNameBindings: ['class'],
    recurrenceProcessor: _ember['default'].inject.service(),

    i18n: _ember['default'].inject.service(),

    isDaily: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === DAILY;
    }),

    isWeekly: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === WEEKLY;
    }),

    isMonthly: _ember['default'].computed('model.recurrenceTypeSelected', function () {
      return parseInt(this.get('model.recurrenceTypeSelected')) === MONTHLY;
    }),

    recurrenceTypeStringKey: (function () {
      var type = this.get('model.recurrenceTypeSelected') || 0;
      var locs = {
        0: 'days',
        1: 'weeks',
        2: 'months',
        3: 'years'
      };

      return locs[type];
    }).property('model.recurrenceTypeSelected', 'i18n.locale'),

    maxDateForUntilDate: null,

    recurrenceTypes: (function () {
      return [{
        id: DAILY,
        value: DAILY,
        label: this.get('i18n').t('components.recurring_event_fields.daily').toString()
      }, {
        id: WEEKLY,
        value: WEEKLY,
        label: this.get('i18n').t('components.recurring_event_fields.weekly').toString()
      }, {
        id: MONTHLY,
        value: MONTHLY,
        label: this.get('i18n').t('components.recurring_event_fields.monthly').toString()
      }
      /*,
      {
        id: YEARLY,
        value: YEARLY,
        label: t('components.recurring_event_fields.yearly')
      }*/
      ];
    }).property('i18n.locale'),

    untilOptions: (function () {
      return [{
        id: UNTIL_DATE,
        value: UNTIL_DATE,
        label: this.get('i18n').t('components.recurring_event_fields.until_date').toString()
      }, {
        id: UNTIL_NR_OCCURRENCES,
        value: UNTIL_NR_OCCURRENCES,
        label: this.get('i18n').t('components.recurring_event_fields.until_nr_occurrences').toString()
      }];
    }).property('i18n.locale'),

    recurrenceDescription: _ember['default'].computed('i18n.locale', 'model.recurrenceTypeSelected', 'model.startDate', 'model.interval', 'model.excludeWeekends', 'model.untilType', 'model.fromTime', 'model.toTime', 'model.numberOfOccurrences', 'model.recurringDaysOfWeek.length', 'model.datesOfMonth.length', 'model.recurrenceMonthlyInterval.length', 'model.dayOfWeek.length', 'model.untilDate', function () {

      return this.get('recurrenceProcessor').generateDescription(this.get('model'));
    }),

    fromTimeHour: _ember['default'].computed('model.fromTime', function () {

      if (this.get('model.fromTime')) {
        return parseInt(this.get('model.fromTime').split(':')[0]);
      }
    }),

    actions: {
      updateStartDate: function updateStartDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model') || !date) return;
        if (!date.isSame(this.get('model.startDate'))) {
          this.set('model.startDate', moment(date));
        }

        this.set('maxDateForUntilDate', date.clone().add(3, 'y'));
      },

      updateUntilDate: function updateUntilDate(date) {
        if (this.get('isDestroyed') || this.get('isDestroying') | !this.get('model') || !date) return;
        if (moment(date).isBefore(this.get('model.startDate'))) {
          date = moment(this.get('model.startDate'));
        }

        if (!date.isSame(this.get('model.untilDate'))) {
          this.set('model.untilDate', moment(date));
        }
      },

      updateRecurrenceTypeSelected: function updateRecurrenceTypeSelected(type) {
        this.set('model.recurrenceTypeSelected', parseInt(type));
      },

      updateUntilType: function updateUntilType(type) {
        var untilDate = this.get('model.untilDate');

        if (type === 1 && untilDate) {
          if (untilDate.format && untilDate.format('YYYY') === '0001') {
            this.set('model.untilDate', moment());
          }
        }
        this.set('model.untilType', type);
      },

      isValidUntilDate: function isValidUntilDate(date) {
        var startDate = this.get('model.startDate') || moment();
        return date.isAfter(startDate, 'd');
      }
    }

  });
});