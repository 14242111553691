define('scientia-resourcebooker/pods/components/booking-request-form/description-custom-fields/text/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':bookingRequestForm-label'],

    maxLength: _ember['default'].computed.alias('field.maxLength'),

    currentLength: (function () {
      return this.get('field.value.length') || '0';
    }).property('field.value.length'),

    label: (function () {
      return 'components.booking_request_form.description_custom_fields.' + this.get('field').label;
    }).property('field')
  });
});