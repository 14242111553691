define('scientia-resourcebooker/services/app-insights', ['exports', 'ember'], function (exports, _ember) {
  /*
  ----EVENTS-----
  
  login
  logout
  language-change : {language}
  show-availability
  
  booking-created : {resourceId, resourceName, bookingTypeId, bookingTypeName}
  booking-edited : {resourceId, resourceName, bookingTypeId, bookingTypeName, bookingId, occurrenceId, isOwner, type, from}
  booking-cancelled: {isOwner, type, bookingId, bookingName, from}
  search-global: {query, type}
  search-resource: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, query}
  load-more
  notification-accept
  notification-request
  notification-more-info
  
  3.0 added:
  bulk-import-template-download {type : custom-fields/resources, file: with-mapping/no-mapping/mapping-file}
  bulk-export {all: true, ids: []}
  bulk-import: {step: preview/confirm, type, request, response}
  report-dashboard-view: {reportId, reportName}
  report-audit-generate: {fromDate, toDate, useBookingDates}
  template-help-click: {bookingTypeName, bookingTypeId, isResourceGroup, resourceGroupId, resourceGroupName }
  global-help-click: {path, locale, hasHelpData}
  global-help-topic-click': {path, topic, locale, hasHelpDataInCurrentLocale}
  resource-summary: {resourceTypeId, resourceId}
  
  booking-created: added -> {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-edited: added => {startDate, endDate, duration, isRecurring, recurrenceType}
  booking-print: {bookingTypeId}
  booking-search: {query, count, currentPage, totalPages}
  my-bookings-print, {isSearchResults}
  
  resource-filter: {bookingType, bookingTypeId, name, value, type}
  resource-found-filters: {resourceName, bookingTypeId, bookingTypeName, filters, duration, query, availableNow, spanMultipleDays,firstDay, lastDay,fromTime, toTime}
  
  ----------------------------------------
   */
  exports['default'] = _ember['default'].Service.extend({
    trackEvent: function trackEvent(event, data) {
      // console.log(event, data);
      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackEvent(event, data);
    },

    trackPageView: function trackPageView(name) {
      var properties = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (!appInsights || appInsights.config.instrumentationKey === 'APP_INSIGHTS_TOKEN_PLACEHOLDER') return;
      appInsights.trackPageView(name, null, properties);
    }
  });
});