define("scientia-resourcebooker/pods/manage-resources/resource-group/resources/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["confirmTitle", ["subexpr", "t", ["pods.manage_resources.resource_group.resources.confirm_bulk_remove.title"], [], ["loc", [null, [7, 19], [7, 97]]], 0, 0], "confirmMessage", ["subexpr", "t", ["pods.manage_resources.resource_group.resources.confirm_bulk_remove.message"], ["count", ["get", "checkedResources.length", ["loc", [null, [8, 107], [8, 130]]], 0, 0, 0, 0]], ["loc", [null, [8, 21], [8, 131]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "confirmBulkRemoveModal", ["loc", [null, [9, 25], [9, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 48]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 55]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBulkRemoveFromResourceGroup"], [], ["loc", [null, [10, 14], [10, 59]]], 0, 0]], ["loc", [null, [5, 9], [10, 60]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [11, 37], [11, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 27], [11, 42]]], 0, 0]], [], ["loc", [null, [11, 19], [11, 43]]], 0, 0]], ["loc", [null, [4, 7], [11, 44]]], 0, 0]], ["loc", [null, [2, 0], [11, 46]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 8
              },
              "end": {
                "line": 30,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "sectionTitle-append buttonGroup");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "button button--white at-buttonGroup at-sectionTitle-append");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            morphs[2] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["element", "action", [["subexpr", "action", [["subexpr", "mut", [["get", "confirmBulkRemoveModal", ["loc", [null, [25, 40], [25, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [25, 35], [25, 63]]], 0, 0], true], [], ["loc", [null, [25, 27], [25, 69]]], 0, 0]], [], ["loc", [null, [25, 18], [25, 71]]], 0, 0], ["inline", "inline-svg", ["icons/trash.svg"], ["class", "sectionTitle-append-icon"], ["loc", [null, [26, 14], [26, 79]]], 0, 0], ["inline", "t", ["pods.manage_resources.resource_group.resources.bulk_delete"], ["count", ["subexpr", "@mut", [["get", "checkedResources.length", ["loc", [null, [27, 85], [27, 108]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 14], [27, 110]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 10
                },
                "end": {
                  "line": 39,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button button--primary");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["pods.manage_resources.resource_group.resources.edit_group"], [], ["loc", [null, [37, 14], [37, 79]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 9
              },
              "end": {
                "line": 40,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "link-to", ["manage-resources.resource-group.edit", ["get", "model.resourceGroup.resourceTypeId", ["loc", [null, [35, 60], [35, 94]]], 0, 0, 0, 0], ["get", "model.resourceGroup.id", ["loc", [null, [35, 95], [35, 117]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [35, 10], [39, 22]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 40,
                "column": 10
              },
              "end": {
                "line": 44,
                "column": 10
              }
            },
            "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--primary");
            dom.setAttribute(el1, "disabled", "");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["pods.manage_resources.resource_group.resources.edit_group"], [], ["loc", [null, [42, 14], [42, 79]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 46,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "sectionTitle-content");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "class", "sectionTitle-icon");
          dom.setAttribute(el2, "src", "icons/folder.svg");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "sectionTitle-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle-actions");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 5, 5);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["content", "model.resourceGroup.name", ["loc", [null, [20, 40], [20, 68]]], 0, 0, 0, 0], ["block", "if", [["get", "checkedResources.length", ["loc", [null, [23, 14], [23, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [23, 8], [30, 15]]]], ["block", "if", [["subexpr", "can", ["edit resource"], ["model", ["get", "model.resourceGroup", ["loc", [null, [34, 42], [34, 61]]], 0, 0, 0, 0]], ["loc", [null, [34, 15], [34, 62]]], 0, 0]], [], 1, 2, ["loc", [null, [34, 9], [44, 17]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/manage-resources/resource-group/resources/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-content at-resourceTypesList");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var element3 = dom.childAt(element2, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "confirmBulkRemoveModal", ["loc", [null, [1, 6], [1, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [12, 7]]]], ["inline", "manage-resources/header", [], ["bread-crumbs", ["subexpr", "@mut", [["get", "bread-crumbs", ["loc", [null, [14, 39], [14, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 0], [14, 53]]], 0, 0], ["block", "section-title", [], ["location", "at-manageResourcesResourceTypesList"], 1, null, ["loc", [null, [17, 4], [46, 22]]]], ["inline", "manage-resources/resources-list", [], ["resources", ["subexpr", "@mut", [["get", "resources", ["loc", [null, [49, 16], [49, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "hideDeleteAction", true, "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [51, 19], [51, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "currentPage", ["subexpr", "@mut", [["get", "model.resources.meta.currentPage", ["loc", [null, [52, 18], [52, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "totalPages", ["subexpr", "@mut", [["get", "model.resources.meta.totalPages", ["loc", [null, [53, 17], [53, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage"], ["loc", [null, [48, 4], [55, 6]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});