define('scientia-resourcebooker/pods/components/resource-filters/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'form',
    classNames: ['resourceFilters resourceFilter-type-boolean'],
    availableNow: false,
    spanMultipleDays: false,
    firstDay: null,
    lastDay: null,
    fromTime: '',
    toTime: '',
    duration: null,
    customFilters: [],
    filters: [],
    systemSettings: _ember['default'].inject.service(),
    isSearching: false,

    recurrence: null,
    recurrenceErrors: null,
    recurringFiltersEnabled: false,

    customFiltersSelected: [],
    isDebouncing: false,

    // 1: Single Booking, 2: Recurring Booking
    bookingMode: 1,

    // Reset duration if both from and toTime are selected
    fromToTimeChanged: (function () {
      var _this = this;

      var fromTime = this.get('fromTime');
      var toTime = this.get('toTime');

      this._debounceLogFilter({ type: 'time', from: fromTime, to: toTime });

      if (fromTime && toTime && !this.get('spanMultipleDays')) {
        var _fromTime$split = fromTime.split(':');

        var _fromTime$split2 = _slicedToArray(_fromTime$split, 2);

        var fromHours = _fromTime$split2[0];
        var fromMinutes = _fromTime$split2[1];

        var _toTime$split = toTime.split(':');

        var _toTime$split2 = _slicedToArray(_toTime$split, 2);

        var toHours = _toTime$split2[0];
        var toMinutes = _toTime$split2[1];

        var fromMoment = moment({ hour: fromHours, minute: fromMinutes }).startOf('minute');
        var toMoment = moment({ hour: toHours, minute: toMinutes }).startOf('minute');

        if (fromMoment.isSame(toMoment) || fromMoment.isAfter(toMoment)) {
          (function () {
            var adjustedToTime = fromMoment.clone().add(15, 'minute');

            // Need to run on next run loop as dropdown won't update if we manually adjust toTIme.This fixes RB-206
            _ember['default'].run.next(_this, function () {
              this.set('toTime', adjustedToTime.format('HH:mm'));
            });
            // const toTime = this.$('.resourceFilters-timeInput-toTime .timeInput-select-minutes select');
            // Ember.run.next(this, function() {
            //   if (toTime) toTime.val(adjustedToTime.format('mm'));
            // });
          })();
        }
      }

      if (this.get('fromTime') && this.get('toTime')) this.set('duration', null);
      this.setDefaultStartDateIfNeeded();
    }).observes('fromTime', 'toTime', 'spanMultipleDays'),

    toTimeChanged: (function () {
      if (this.get('isDestroyed') || this.get('isDestroying')) return;

      if (this.get('toTime') && this.get('duration')) {
        this.set('duration', null);
      }

      this.setDefaultStartDateIfNeeded();
    }).observes('toTime'),

    fromTimeActivated: _ember['default'].computed('fromTime', {
      get: function get() {
        return !!this.get('fromTime');
      },

      set: function set(key, value) {
        if (value) {
          var firstHourAvailable = this.get('systemSettings').getSetting('calendarStartTimeOffset');
          var fromTime = moment().hour(firstHourAvailable).minute(0);
          this.set('fromTime', fromTime.format('HH:mm'));
          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('fromTime', '');
        }
        return value;
      }
    }),

    toTimeActivated: _ember['default'].computed('toTime', {
      get: function get() {
        return !!this.get('toTime');
      },

      set: function set(key, value) {
        if (value) {
          var firstHourAvailable = this.get('systemSettings').getSetting('calendarStartTimeOffset');
          var toTime = moment().hour(firstHourAvailable).minute(0);
          this.set('toTime', toTime.format('HH:mm'));
          this.set('duration', null);

          this.setDefaultStartDateIfNeeded();
        } else {
          this.set('toTime', '');
        }
        return value;
      }
    }),

    durationItems: (function () {
      var labels = this.get('i18n').t('components.resource_filters.duration_item_labels').toString().split(',');

      return [{ id: '1', value: '1', label: labels[0] }, { id: '2', value: '2', label: labels[1] }, { id: '3', value: '3', label: labels[2] }, { id: '4', value: '4', label: labels[3] }, { id: '5', value: '5', label: labels[4] }, { id: '0', value: '0', label: labels[5] }];
    }).property('i18n.locale'),

    availableNowObserver: (function () {
      this.set('firstDay', null);
      this.set('lastDay', null);
      this.set('fromTime', '');
      this.set('toTime', '');

      if (this.get('availableNow')) this.set('spanMultipleDays', false);

      this._debounceLogFilter({ type: 'available-now', value: this.get('availableNow') });
    }).observes('availableNow'),

    _updateSelectedDates: function _updateSelectedDates() {
      var firstDay = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var lastDay = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (!this.get('isDestroyed')) {
        this.sendAction('logFilter', { type: 'calendar', from: firstDay, to: lastDay, spanMultipleDays: this.get('spanMultipleDays') });
        this.set('firstDay', firstDay);
        this.set('lastDay', lastDay);
      }
    },

    _debounceLogFilter: function _debounceLogFilter(data) {
      _ember['default'].run.debounce(this, 'sendAction', 'logFilter', data, 500);
    },

    spanMultipleDaysChanged: (function () {
      // Ember.run.next(this, function() {
      if (this.get('isDestroyed') || this.get('isDestroying')) return;
      if (this.get('spanMultipleDays')) {
        var institutionSartOffset = this.get('systemSettings').getSetting('calendarStartTimeOffset');
        var institutionEndOffset = this.get('systemSettings').getSetting('calendarEndTimeOffset');
        var startTime = moment().startOf('day').hour(institutionSartOffset);
        var endTime = startTime.clone().hour(24 - institutionEndOffset);
        this.setProperties({
          duration: null,
          firstDay: startTime.format('YYYY-MM-DD'),
          lastDay: startTime.clone().endOf('day').add(1, 'day').format('YYYY-MM-DD'),
          fromTime: startTime.format('HH:mm'),
          toTime: endTime.hour() === 0 ? '24:00' : endTime.format('HH:mm')
        });
      } else {
        this.setProperties({
          firstDay: null,
          lastDay: null,
          fromTime: '',
          toTime: ''
        });
      }
      // });
    }).observes('spanMultipleDays'),

    setDefaultStartDateIfNeeded: function setDefaultStartDateIfNeeded() {
      if (!this.get('firstDay')) this.set('firstDay', moment().startOf('day').format('YYYY-MM-DD'));
    },

    durationChanged: (function () {
      this.setDefaultStartDateIfNeeded();

      if (this.get('duration')) {
        try {
          var durations = 'All Day,15m,30m,1h,2h,3h'.split(','); // we want to log in english no matter what the lang is selected
          var durationValue = durations[this.get('duration')];
          this._debounceLogFilter({ type: 'duration', value: durationValue });
        } catch (e) {
          //no-op
        }
      }
    }).observes('duration'),

    actions: {

      customFilterNumberChanged: function customFilterNumberChanged(propertyId, propertyValue, propertyName) {
        this.sendAction('customFilterNumberChanged', propertyId, propertyValue, propertyName);
      },

      updateSelectedDates: function updateSelectedDates() {
        var firstDay = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
        var lastDay = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        if (this.get('spanMultipleDays') && firstDay && firstDay === lastDay) return;
        if (this.get('spanMultipleDays') && (!firstDay || !lastDay)) return;
        _ember['default'].run.debounce(this, '_updateSelectedDates', firstDay, lastDay, 500);
      },

      resetFilters: function resetFilters() {
        this.sendAction('resetFilters');
      },

      updateRecurrence: function updateRecurrence(recurrence) {
        this.sendAction('updateRecurrence', recurrence);
      }

      // searchRecurring() {
      //   this.attrs.searchRecurring(this.get('bookingType.hasExternallyManagedResources'));
      // }
    }
  });
});