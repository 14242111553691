define('scientia-resourcebooker/pods/manage-resources/booking-form/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {

  var TABS = { DESCRIPTION: 0, FIELDS: 1 };

  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    appInsights: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_form.edit.page_title');
    },

    model: function model(params) {
      return this.store.queryRecord('booking-form', { id: params.id, isManaging: true });
    },

    afterModel: function afterModel(model) {
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.get('name'),
        id: model.get('id')
      });
    },

    actions: {

      save: function save(transitionIndex) {
        var _this = this;

        var controller = this.get('controller');
        var model = controller.get('model');

        controller.set('error', '');
        var name = model.get('name') || '';
        if (_ember['default'].isEmpty(name.trim())) {
          var errorMessage = this.get('i18n').t('error_messages.bookingformvalidation_nonameonform');
          return this._displayError(errorMessage, TABS.DESCRIPTION);
        }

        var isEditingAnyField = false;
        if (model.get('sections')) {
          model.get('sections').getEach('fields').forEach(function (fieldGroup) {
            if (fieldGroup.isAny('isEditing')) {
              isEditingAnyField = true;
            }
          });
        }

        if (isEditingAnyField) {
          this.flashError(this.get('i18n').t('pods.manage_resources.booking_form.edit.field_is_editing').toString());
          return;
        }

        controller.set('isSaving', true);
        model.save().then(function () {
          _this.editSuccessFlash('booking-form');

          if (transitionIndex) {
            return _this.transitionTo('manage-resources.booking-form.index');
          }
        })['catch'](function (error) {
          var errorMessage = _this.get('errorHandler').buildErrorMessage(error);
          if (errorMessage) {
            _this.set('error', errorMessage.toString());
          } else {
            _this.editErrorFlash('booking-form');
          }
        })['finally'](function () {
          return controller.set('isSaving', false);
        });
      },

      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        if (this.get('controller.model').get('hasDirtyAttributes')) {
          this.get('controller').setProperties({
            showUnsavedChangesModal: true,
            previousTransition: transition
          });
          transition.abort();
          return false;
        }

        return true;
      }

    },
    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.setProperties({
          selectedTab: 0,
          formTabActiveRank: 0,
          selectedNewFieldType: null,
          previousTransition: null,
          showUnsavedChangesModal: false
        });
      }
    },

    _displayError: function _displayError(error, tabIndex) {
      var controller = this.controllerFor('manage-resources.booking-form.edit');
      controller.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    }
  });
});