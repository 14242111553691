define('scientia-resourcebooker/pods/logged-in/resource-group/show/controller', ['exports', 'ember', 'scientia-resourcebooker/mixins/page-changer', 'scientia-resourcebooker/mixins/resource-filters'], function (exports, _ember, _scientiaResourcebookerMixinsPageChanger, _scientiaResourcebookerMixinsResourceFilters) {
  exports['default'] = _ember['default'].Controller.extend(_scientiaResourcebookerMixinsPageChanger['default'], _scientiaResourcebookerMixinsResourceFilters['default'], {

    breadCrumbs: (function () {
      return [{
        label: this.get('i18n').t('pods.logged_in.booking_type.show.bookings'),
        path: 'logged-in.booking-type.index'
      }, {
        label: this.get('model.bookingType.name'),
        path: 'logged-in.booking-type.show',
        model: this.get('model.bookingType.id')
      }, {
        label: this.get('model.resourceGroup.name'),
        linkable: false
      }];
    }).property('model.resourceGroup.name'),

    queryParams: ['query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration', 'page'],

    shouldShowPaging: _ember['default'].computed.gt('model.resourcesMeta.totalPages', 1),

    boundQuery: _ember['default'].computed.oneWay('query'),

    query: '',
    availableNow: false,
    spanMultipleDays: false,
    firstDay: null,
    lastDay: null,
    fromTime: '',
    toTime: '',
    duration: null,
    filters: null,
    page: 1,

    // Pagination should be reset when the query params change or we might end up
    //in an invalid page with no results
    resetPaginationOnParamsChange: (function () {
      _ember['default'].run.once(this, 'set', 'page', 1);
    }).observes('query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration'),

    boundQueryDidChange: (function () {
      this.send('submitSearch');
    }).observes('boundQuery'),

    _search: function _search() {
      this.set('query', this.get('boundQuery'));
    },

    actions: {
      submitSearch: function submitSearch() {
        _ember['default'].run.debounce(this, '_search', 1000, false);
      },

      leaveResourceGroup: function leaveResourceGroup() {
        this.transitionToRoute('logged-in.booking-type.show', this.get('model.bookingType.id'));
      }
    }

  });
});